import React from 'react';
import { gql } from '@apollo/client';
import { Dialog, DialogContent, Box, DialogProps, Typography } from '@mui/material';

import { useApplyOracleAnswerUpdateMutation } from '@backed-fi/graphql';
import { InfoLabel } from '@backed-fi/compound';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';


// region Props

interface Props extends DialogProps {
  /**
   * The ID of the pending price updaet
   */
  priceUpdateId: string;

  /**
   * The new price that will be applied
   */
  newAnswer: string;

  /**
   * The price that was previously set
   */
  previousAnswer: string;

}

// endregion

// region Graph

gql`
  mutation ApplyOracleAnswerUpdate($input: ApplyOracleAnswerUpdateInput!) {
    applyOracleAnswerUpdate(input: $input)
  }
`;

// endregion

export const AppleOracleAnswerUpdateDialog: React.FC<Props> = ({
  priceUpdateId,
  newAnswer,
  previousAnswer,
  ...props
}) => {
  const snackbar = useSnackbar();

  // region Networking

  const [applyPriceMutation, { loading }] = useApplyOracleAnswerUpdateMutation();

  // endregion

  // region Action

  const onApplyPrice = async () => {
    await applyPriceMutation({
      variables: {
        input: {
          priceUpdateId
        }
      },
      refetchQueries: [
        'tokenCollateral'
      ],
      awaitRefetchQueries: true
    });

    snackbar.enqueueSnackbar('Oracle price update successfully approved', {
      variant: 'success'
    });
  };

  // endregion

  return (
    <Dialog {...props}>
      <DialogContent>
        <Typography variant="title">
          Apply Price Update
        </Typography>

        <InfoLabel
          label="Previous Price"
          content={previousAnswer}
        />

        <InfoLabel
          label="Proposed Price"
          content={newAnswer}
        />

        <Box
          sx={{
            gap: '1rem',
            display: 'flex',
            alignItems: 'flex-end'
          }}
        >
          <LoadingButton
            onClick={props.onClose as any}
          >
            Ignore
          </LoadingButton>

          <LoadingButton
            loading={loading}
            onClick={onApplyPrice}
          >
            Apply Proposed Price
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
