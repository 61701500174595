import React from 'react';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { useUpdateInteractionProductsMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

// region Graph

gql`
  mutation updateInteractionProducts($interactionId: String!) {
    updateInteraction(
      where: {
        id: $interactionId
      },
      data: {
        updateProducts: true
      }
    ) {
      id
    }
  }
`;

// endregion

// region Props

interface Props extends React.ComponentProps<typeof LoadingButton> {
  interactionId: string;
}

// endregion

export const UpdateInteractionProductsButton: React.FC<Props> = ({
  interactionId,
  ...buttonProps
}) => {
  const snackbar = useSnackbar();

  const [updateProductsMutation, { loading }] = useUpdateInteractionProductsMutation();

  const onUpdate = async () => {
    try {
      await updateProductsMutation({
        variables: {
          interactionId
        }
      });

      snackbar.enqueueSnackbar('Successfully updated the interaction products', {
        variant: 'success'
      });
    } catch (e) {
      console.error(e);

      snackbar.enqueueSnackbar('An error occurred while updating the interaction products', {
        variant: 'error'
      });
    }
  };

  return (
    <LoadingButton
      {...buttonProps}
      loading={loading}
      onClick={onUpdate}
    >
      Update Products
    </LoadingButton>
  );
};
