import React from 'react';

import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { useToggle } from '@backed-fi/hooks';
import { Title } from '@backed-fi/compound';
import { z } from 'zod';
import { BlockchainNetwork, useInjectIncomingTransactionDefinitionLazyQuery } from '@backed-fi/graphql';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { useInjectionContext } from '../InjectionContext';

// region Schema

const Schema = z.object({
  network: z.nativeEnum(BlockchainNetwork),
  hash: z.string()
});

// endregion

// region Graph

gql`
  query injectIncomingTransactionDefinition($input: InteractionTransactionInjectDefinitionInput!) {
    injectionTransactionDefinitionQuery(input: $input) {
      index
      hash
      network

      contractAddress
      benefactorAddress
      beneficiaryAddress

      blockTimestamp
      decimals
      amount
      symbol

      client {
        id
        name
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  transactionType: 'outgoing' | 'incoming';
}

// endregion

export const InjectTransactionButton: React.FC<Props> = ({ transactionType }) => {
  const snackbar = useSnackbar();
  const toggle = useToggle();
  const context = useInjectionContext();

  // region Networking

  const [fetchDefinition] = useInjectIncomingTransactionDefinitionLazyQuery();

  // endregion

  // region From Control

  const baseForm = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const { isSubmitting } = baseForm.formState;

  // endregion

  // region Actions

  const onAddTransaction = baseForm.handleSubmit(async (data) => {
    const { data: definition } = await fetchDefinition({
      variables: {
        input: data
      }
    });

    if (definition) {
      if (definition.injectionTransactionDefinitionQuery.some((x) => !!x.client && x.client.id !== context.clientId)) {
        snackbar.enqueueSnackbar('Cannot add transactions that are created by other clients', {
          variant: 'error'
        });
      } else {
        context.setInjectionState((ctx) => {
          return ({
            ...ctx,
            [`${transactionType}Transactions`]: uniqBy([
              ...ctx?.[`${transactionType}Transactions`],
              ...definition.injectionTransactionDefinitionQuery
            ], (x) => [x.hash, x.network].join(', '))
          });
        });
      }
    }

    toggle.setFalse();
    baseForm.reset({});
  });

  // endregion

  return (
    <React.Fragment>
      <Dialog
        {...toggle}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Title
            title="Inject Transaction"
            subtitle="Define new transaction for injection"
          />

          <Box
            sx={{
              mt: '1rem',
              gap: '.5rem',
              display: 'flex',
              flexFlow: 'column'
            }}
          >
            <TextField
              fullWidth
              label="Hash"
              {...baseForm.register('hash')}
            />

            <TextField
              select
              label="Blockchain Network"
              {...baseForm.register('network')}
            >
              <option selected disabled>
                Select Network
              </option>

              {Object.keys(BlockchainNetwork).map((network) => (
                <option value={network} key={network}>
                  {network}
                </option>
              ))}
            </TextField>

            <LoadingButton
              loading={isSubmitting}
              onClick={onAddTransaction}
            >
              Add Transaction
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>

      <Button onClick={toggle.setTrue}>
        Inject Another
      </Button>
    </React.Fragment>
  );
};
