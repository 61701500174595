import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { InteractionsTable } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionsTable';


export const InteractionsListPage = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <PageHeading
        title="Interactions"
        breadcrumbs={[
          {
            text: 'Interactions'
          }
        ]}
      >
        <Button
          onClick={() => navigate('/interactions/injections/new')}
        >
          Inject Interaction
        </Button>
      </PageHeading>

      <InteractionsTable />
    </Box>
  );
};
