import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { TokenCollateralizationQuery, useTokenCollateralizationLazyQuery, AdminPermission, useUpdateTokenManagementFeeMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { Protector, Title } from '@backed-fi/compound';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

// region Graph

gql`
  mutation updateTokenManagementFee($input: UpdateTokenManagementFeeInput!) {
    updateTokenManagementFee(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  tokenId: string;
}

// endregion

const Schema = z.object({
  feeValue: z.preprocess(
    (v) => Number(v || '0'),
    z.number().gte(0).lte(1_00_00)
  )
});

type Schema = z.infer<typeof Schema>;

export const UpdateManagementFeeDialog: React.FC<Props> = ({ tokenId }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const [updateManagementFee, { loading }] = useUpdateTokenManagementFeeMutation();
  const [fetchData, { data }] = useTokenCollateralizationLazyQuery();

  const { token } = (data ?? {}) as TokenCollateralizationQuery;

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
  });
  const {
    errors
  } = form.formState;
  // region Effect

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          tokenId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(async ({ feeValue }) => {
    try {
      await updateManagementFee({
        awaitRefetchQueries: true,
        refetchQueries: [
          'tokenCollateralization'
        ],
        variables: {
          input: {
            tokenId,
            feeValue
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully proposed new Annual Management Fee', {
        variant: 'success'
      });
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred while proposing new Annual Management Fee', {
        variant: 'error'
      });
    } finally {
      dialog.setFalse();
    }
  });

  // endregion

  return (
    <Protector permissions={[AdminPermission.BlockchainManage, AdminPermission.SystemManage]}>
      <Button onClick={dialog.setTrue}>
        Update Fee
      </Button>

      {dialog.open && token && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Update Annual Management Fee"
              subtitle="Define annual management fee (in bips)"
            />

            <TextField
              size="small"
              label="New annual management fee in bips"
              type="number"
              sx={{
                marginRight: '16px',
                width: '100%'
              }}

              error={!!errors.feeValue}
              helperText={errors.feeValue?.message}
              {...form.register('feeValue')}
            />

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={loading}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={loading}
                onClick={onApprove}
              >
                Update
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Protector>
  );
};
