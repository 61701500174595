import { Box } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { InteractionType, useInjectionClientLazyQuery } from '@backed-fi/graphql';
import { EnumSelect, Title } from '@backed-fi/compound';
import { useInjectionContext } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionContext';
import { ClientSearch } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/ClientSearch';
import { NavigationButtons } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/NavigationButtons';

// region Graph

gql`
  query InjectionClient($clientId: ID!) {
    client(clientId: $clientId) {
      id
      humanId

      name
      jurisdiction
      contactEmail
    }
  }
`;

// endregion

export const GeneralInformation: React.FC = () => {
  const context = useInjectionContext();
  const [fetchClient, { data }] = useInjectionClientLazyQuery();

  // region Actions

  const setInteractionType = (e: React.ChangeEvent) => {
    context.setInjectionState((p) => ({
      ...p,
      type: (e.target as any).value as InteractionType
    }));
  };

  const setClientId = async (clientId: string) => {
    context.setInjectionState((prev) => ({
      ...prev,
      clientId
    }));

    await fetchClient({
      variables: {
        clientId
      }
    });
  };

  const onGoToNextStep = () => {
    return !!context.clientId;
  };

  // endregion

  return (
    <Box>
      <EnumSelect
        fullWidth
        label="Interaction Type"
        enum={InteractionType}
        value={context.type}
        onChange={setInteractionType}
      />

      <ClientSearch
        onChange={async (e, v: any) => {
          if (v) {
            await setClientId(v.id);
          }
        }}
      />

      {data && (
        <Box
          sx={{
            padding: '1rem'
          }}
        >
          <Title
            title={data.client.name}
            subtitle={data.client.contactEmail}
          />
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <NavigationButtons
          hidePrevious
          validateBeforeNext={onGoToNextStep}
        />
      </Box>
    </Box>
  );
};
