import React from 'react';
import { Route } from 'react-router-dom';

import { RefundsListPage } from './RefundsListPage';

export const RefundPages = (
  <Route path='refunds'>
    <Route
      index
      element={<RefundsListPage />}
    />
  </Route>
);
