import React, { useMemo, useState } from "react";
import { gql } from "@apollo/client";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Chip, LinearProgress, Typography } from "@mui/material";

import { ExportButton } from "@backed-fi/admin/src/app/components/ExportButton";
import { BlockchainNetworkLabel } from "@backed-fi/shared/components/compound/BlockchainNetworkLabel";

import { usePaginationModel } from "@backed-fi/hooks";
import {
  IncomingTransactionStatus,
  RefundStatus,
  TokenMultiplierChangeReason,
  TokenMultiplierChangeStatus,
  useIncomingTransactionsQuery,
  useTokenMultiplierChangesQuery,
} from "@backed-fi/graphql";

import { DateTimeFormatter } from "@backed-fi/shared";
import { compareDesc } from "date-fns";
import Decimal from "decimal.js";
import { ExecuteMultiplierUpdateDialog } from "./ExecuteMultiplierUpdateDialog";

gql`
  query tokenMultiplierChanges(
    $tokenId: String!
    $where: MultiplierChangesWhereInput!
  ) {
    token(id: $tokenId) {
      deployments {
        network
        multiplierChanges(where: $where) {
          id
          executedAt
          multiplier
          reason
          status
          previousMultiplier
          initiatorId
        }
      }
    }
  }
`;

// region Props

interface Props {
  tokenId: string;
  status: TokenMultiplierChangeStatus[];
}

// endregion

export const TokenMultiplierChangesTable: React.FC<Props> = ({
  tokenId,
  status,
}) => {

  // region State

  // endregion

  // region Networking

  const { data, loading } = useTokenMultiplierChangesQuery({
    variables: {
      tokenId,
      where: {
        status,
      },
    },
  });

  const token = data?.token;

  const multiplierChangesRows = useMemo(
    () =>
      token?.deployments
        .flatMap((x) =>
          x.multiplierChanges.map((z) => ({
            ...z,
            network: x.network,
          }))
        )
        .sort((a, b) =>
          compareDesc(new Date(a.executedAt), new Date(b.executedAt))
        ) ?? [],
    [token]
  );

  // endregion

  // region Effects

  // endregion

  return (
    <Box
      sx={{
        height: "100%",
      }}
    >
      <Box
        sx={{
          overflowX: "scroll",
        }}
      >
        <DataGrid
          autoHeight
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          rows={multiplierChangesRows}
          columns={[
            {
              flex: 2,
              field: "previousMultiplier",
              headerName: "Previous Multiplier Value",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {`${new Decimal(value).div(Decimal.pow(10, 18).toString())}`}
                </Typography>
              ),
            },
            {
              flex: 2,
              field: "multiplier",
              headerName: "New Multiplier Value",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {`${new Decimal(value).div(Decimal.pow(10, 18).toString())}`}
                </Typography>
              ),
            },
            {
              flex: 2,
              field: "network",
              headerName: "Network",
              renderCell: ({ value }) => {
                return <BlockchainNetworkLabel network={value} />;
              },
            },
            {
              flex: 2,
              field: "reason",
              headerName: "Reason",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {value}
                </Typography>
              ),
            },
            ...(status.includes(TokenMultiplierChangeStatus.Executed) ? [{
              flex: 2,
              field: "executedAt",
              headerName: "Executed At",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {DateTimeFormatter.format(new Date(value))}
                </Typography>
              )
            } as GridColDef] : []),
            ...(status.length > 1 ? [{
              flex: 2,
              field: "status",
              headerName: "Status",
              renderCell: ({ value }) => (
                <Typography
                  sx={{
                    height: "21px",
                    gap: "0.25rem",
                    display: "flex",
                    alignItems: "center",
                    fontSize: 14,
                  }}
                >
                  {value}
                </Typography>
              )
            } as GridColDef] : []),
            ...(status.includes(TokenMultiplierChangeStatus.PendingApproval) ? [{
              flex: 2,
              field: "actions",
              headerName: "",
              renderCell: ({ row }) => (
                <Box>
                {row.status === TokenMultiplierChangeStatus.PendingApproval && (
                  <ExecuteMultiplierUpdateDialog
                    multiplierChangeId={row.id}
                    initiatorId={row.initiatorId!}
                    previousMultiplier={row.previousMultiplier}
                    newMultiplier={row.multiplier}
                    network={row.network}
                  />
                )}
                </Box>
              )
            } as GridColDef] : []),
          ]}
        />
      </Box>
    </Box>
  );
};
