import React from 'react';
import { Box, Checkbox, CircularProgress, Dialog, DialogContent, FormControlLabel, TextField } from '@mui/material';
import { FormGroup, InfoLabel, PageHeading, Title } from '@backed-fi/compound';
import { BlockchainNetwork, OracleUpdateSchedule, TokenDeploymentDetailsQuery, useCreateDeploymentMutation, useTokenDeploymentDetailsQuery } from '@backed-fi/graphql';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { z } from 'zod';
import { useErrorSnackbar, useIncrement, useToggle } from '@backed-fi/hooks';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

// region Graph

gql`
  query tokenDeploymentDetails($tokenId: String!) {
    token(id: $tokenId) {
      id

      name
      symbol

      isPublic
      description

      deployments {
        network
        address
      }

      collateral {
        oracles {
          network
          isActive
        }
      }
    }
  }

  mutation createDeployment($input: CreateDeploymentInput!) {
    createDeployment(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Control

const Schema = z.object({
  deployContractsOn: z.array(
    z.nativeEnum(BlockchainNetwork)
  ),

  deployOraclesOn: z.array(
    z.nativeEnum(BlockchainNetwork)
  ),

  contract: z.record(
    z.nativeEnum(BlockchainNetwork),
    z.object({
      ownerAddress: z.string(),
      pauserAddress: z.string(),
      minterAddress: z.string(),
      burnerAddress: z.string(),
      sanctionListAddress: z.string()
    })
  ),

  oracle: z.record(
    z.nativeEnum(BlockchainNetwork),
    z.object({
      updaterWallet: z.string(),
      updateSchedule: z.nativeEnum(OracleUpdateSchedule)
    })
  )
});

// endregion

export const DeploymentCreatePage: React.FC = () => {
  let [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const errorSnackbar = useErrorSnackbar();

  const confirmToggle = useToggle();
  const confirmSteps = useIncrement({
    max: 2
  });

  // region Networking

  const [createDeploymentMutation] = useCreateDeploymentMutation();

  const { data } = useTokenDeploymentDetailsQuery({
    variables: {
      tokenId: searchParams.get('tokenId')!
    }
  });

  const { token } = (data || {}) as TokenDeploymentDetailsQuery;

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema),
    defaultValues: {
      deployOraclesOn: [],
      deployContractsOn: [],
      contract: {},
      oracle: {}
    }
  });

  const { isSubmitting } = form.formState;

  const deployOraclesOn = form.watch('deployOraclesOn');
  const deployContractsOn = form.watch('deployContractsOn');

  const toggleNetwork = (network: BlockchainNetwork, toggleFor: 'deployContractsOn' | 'deployOraclesOn') => () => {
    const currentNetworks = form.getValues(toggleFor);

    const shouldDelete = currentNetworks.includes(network);

    form.setValue(
      toggleFor,
      shouldDelete
        ? currentNetworks.filter((x) => x !== network)
        : [...currentNetworks, network]
    );

    if (shouldDelete) {
      if (toggleFor === 'deployContractsOn') {
        const currentContractPayload = form.getValues('contract');

        // @ts-ignore
        delete currentContractPayload[network as any];

        form.resetField('contract', {
          defaultValue: currentContractPayload
        });
      }

      if (toggleFor === 'deployOraclesOn') {
        const currentOraclesPayload = form.getValues('oracle');

        // @ts-ignore
        delete currentOraclesPayload[network as any];

        form.resetField('oracle', {
          defaultValue: currentOraclesPayload
        });
      }
    }
  };

  // endregion

  // region Actions

  const onCreateDeployment = form.handleSubmit(async (data) => {
    const {
      oracle,
      contract,
      deployOraclesOn,
      deployContractsOn,
      ...rest
    } = data;

    try {
      const deployment = await createDeploymentMutation({
        variables: {
          input: {
            ...rest,
            tokenId: searchParams.get('tokenId')!,

            tokenContracts: Object.entries(contract)
              .map(([network, values]) => ({
                network: network as BlockchainNetwork,
                ...values
              })),

            deployOraclesOn: Object.entries(oracle)
              .map(([network, values]) => ({
                network: network as BlockchainNetwork,
                ...values
              }))
          }
        }
      });

      if (deployment.data) {
        navigate(`/internal/deployments/details/${deployment.data.createDeployment.id}`);
      }
    } catch (e) {
      errorSnackbar(e);
    }
  }, console.error);

  // endregion

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth="sm"
        {...confirmToggle}
      >
        <DialogContent>
          <Title
            title="Confirm Deployment"
            subtitle="Please confirm that all the data looks correct"
          />

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              loading={isSubmitting}
              onClick={onCreateDeployment}
            >
              Start Deployment
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>

      <PageHeading
        title="Create Deployment"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Deployments',
            link: '/internal/deployments'
          }, {
            text: 'Create Deployment'
          }
        ]}
      />

      {!token && (
        <Box
          sx={{
            mt: '5rem',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center'
          }}
        >
          <CircularProgress />

          <Title
            title="Loading"
            subtitle="Loading token details"
          />
        </Box>
      )}

      {token && (
        <React.Fragment>
          <Title
            title="Token Details"
            subtitle="Details for the token, for which you want to create deployment"
          />

          <Box
            sx={{
              gap: '10rem',
              display: 'flex'
            }}
          >
            <Box>
              <InfoLabel
                label="Name"
                content={token.name}
              />

              <InfoLabel
                label="Symbol"
                content={token.symbol}
              />

              <InfoLabel
                label="Is public?"
                content={
                  token.isPublic
                    ? 'Yes, it is public'
                    : 'No, it is private'
                }
              />

              <InfoLabel
                label="Existing deployments"
                content={
                  token.deployments.length
                    ? `Yes, on ${token.deployments.map((d) => d.network).join(', ')}`
                    : 'No, no deployments as of now'
                }
              />
            </Box>

            <Box>
              {token.deployments.map((d) => (
                <InfoLabel
                  key={d.network}
                  label={`Address on ${d.network}`}
                  content={d.address}
                />
              ))}
            </Box>
          </Box>

          <FormGroup
            noSeparator
            title="Token Contract"
            subtitle="Select the networks, on which you wish to deploy the token contracts"
          >
            {Object.keys(BlockchainNetwork)
              .map((network) => (
                <Box key={network}>
                  <FormControlLabel
                    label={network.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    control={
                      <Checkbox
                        disabled={token.deployments.some((x) => x.network === network)}
                        onClick={toggleNetwork(network as BlockchainNetwork, 'deployContractsOn')}
                        value={deployContractsOn?.includes(network as BlockchainNetwork)}
                      />
                    }
                  />


                  {deployContractsOn.includes(network as BlockchainNetwork) && (
                    <Box
                      sx={{
                        ml: '2rem',
                        display: 'flex',
                        flexFlow: 'column'
                      }}
                    >
                      {Object.keys(Schema.shape.contract.valueSchema.shape)
                        .map((key: string) => (
                          <TextField
                            key={key}
                            size="small"
                            sx={{
                              maxWidth: '500px',

                              '& > .MuiFormLabel-root': {
                                textTransform: 'capitalize'
                              }
                            }}
                            label={key.replace(/(?=[A-Z])/g, ' ')}
                            {...form.register(`contract.${network}.${key}` as any)}
                          />
                        )
                        )}
                    </Box>
                  )}
                </Box>
              ))
            }
          </FormGroup>

          <FormGroup
            title="Oracle Contract"
            subtitle="Select the networks, on which you wish to deploy the oracle contracts"
          >
            {Object.keys(BlockchainNetwork)
              .map((network) => (
                <Box key={network}>
                  <FormControlLabel
                    key={network}
                    label={network.replace(/([a-z])([A-Z])/g, '$1 $2')}
                    control={
                      <Checkbox
                        disabled={token.collateral.oracles.some((x) => x.network === network && x.isActive)}
                        onClick={toggleNetwork(network as BlockchainNetwork, 'deployOraclesOn')}
                        value={deployOraclesOn?.includes(network as BlockchainNetwork)}
                      />
                    }
                  />

                  {deployOraclesOn.includes(network as BlockchainNetwork) && (
                    <Box
                      sx={{
                        maxWidth: '500px',
                        ml: '2rem',
                        display: 'flex',
                        flexFlow: 'column'
                      }}
                    >
                      <TextField
                        size="small"
                        sx={{
                          maxWidth: '500px',

                          '& > .MuiFormLabel-root': {
                            textTransform: 'capitalize'
                          }
                        }}
                        label='Updater Wallet'
                        {...form.register(`oracle.${network}.updaterWallet` as any)}
                      />

                      <TextField
                        select
                        fullWidth
                        size="small"
                        label="Update Schedule"
                        {...form.register(`oracle.${network}.updateSchedule` as any)}
                        SelectProps={{
                          native: true
                        }}
                      >
                        <option disabled selected>
                          Please select
                        </option>

                        {Object.keys(OracleUpdateSchedule).map((schedule) => {
                          return (
                            <option key={schedule} value={schedule}>
                              {schedule}
                            </option>
                          );
                        })}
                      </TextField>
                    </Box>
                  )}
                </Box>
              ))
            }
          </FormGroup>

          <LoadingButton
            onClick={confirmToggle.setTrue}
            sx={{
              float: 'right'
            }}
          >
            Start Deployment
          </LoadingButton>
        </React.Fragment>
      )}
    </Box>
  );
};
