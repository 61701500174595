import React from 'react';
import { Route } from 'react-router-dom';

import { OracleDetailsPage } from '@backed-fi/admin/src/app/domain/Blockchain/oracles/page/OracleDetailsPage';

export const OracleRoutes = (
  <Route path="oracles">
    <Route
      path=":id/details"
      element={
        <OracleDetailsPage />
      }
    />
  </Route>
);
