import React from 'react';
import { InteractionStatus, useCreateRefundMutation, useInteractionStatusQuery } from '@backed-fi/graphql';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useToggle } from '@backed-fi/hooks';

// region Props

interface Props extends React.ComponentProps<typeof LoadingButton> {
  interactionId: string;
}

// endregion

// region Graph

gql`
  mutation createRefund($input: CreateRefundInput!) {
    createRefund(input: $input) {
      id
    }
  }
`;

// endregion

export const InteractionRefundButton: React.FC<Props> = ({ interactionId, ...buttonProps }) => {
  const refundCreated = useToggle();

  // region Networking

  const [createRefundMutation, { loading }] = useCreateRefundMutation();
  const { data } = useInteractionStatusQuery({
    variables: {
      interactionId
    }
  });

  // endregion

  // region Computed Fields

  const isDisabled = React.useMemo(() => (
    [
      InteractionStatus.Refunded,
      InteractionStatus.Completed,
      InteractionStatus.RefundPending
    ].includes(data?.interaction?.status as any)
  ), [data]);


  // endregion

  // region Actions

  const createRefund = async () => {
    await createRefundMutation({
      variables: {
        input: {
          interactionId,
          reasonForRefund: 'Manually requested refund'
        }
      }
    });

    refundCreated.setTrue();
  };

  // endregion

  return (
    <LoadingButton
      {...buttonProps}
      loading={!data || loading}
      disabled={isDisabled || refundCreated.value}
      onClick={createRefund}
    >
      Refund
    </LoadingButton>
  );
};
