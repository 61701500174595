import React from 'react';
import { gql } from '@apollo/client';
import * as z from 'zod';
import dayjs from 'dayjs';
import { Box, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { DatePicker } from '@mui/x-date-pickers';
import { Controller, useForm } from 'react-hook-form';
import { FiatCurrency, OrderSide, useCreateEodFormMutation, useEodFormLazyQuery } from '@backed-fi/graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

gql`
  query EODForm($input: EODFormInput!) {
    eodForm(input: $input) {
      client {
        name
        depositNumber
      }
      contact {
        name
        phone
        email
      }
      counterPartyContact {
        name
        phone
        email
      }
      instructions {
        name
        isin
        price
        quantity
        tradeDate
        currency
        settlement {
          date
          amount
        }
        ssiCounterparty {
           name
          bic
          code
        }
      }
      order {
        date
        side
      }
    }
  }

  mutation createEODForm($input: CreateEODFormInput!) {
    createEODForm(input: $input) {
      url
    }
  }
`;

const orderSchema = z.object({
  date: z.string(),
  side: z.nativeEnum(OrderSide)
});

const instructionsSchema = z.object({
  name: z.string()
    .min(1),
  isin: z.string(),

  price: z.string(),
  quantity: z.number().int(),
  currency: z.nativeEnum(FiatCurrency),

  settlement: z.object({
    date: z.string(),
    amount: z.string()
  }),
  tradeDate: z.string(),

  ssiCounterparty: z.object({
    name: z.string()
      .min(1),
    bic: z.string()
      .min(1),
    code: z.string()
      .min(1)
  })
});

const contactSchema = z.object({
  name: z.string()
    .min(1),
  phone: z.string()
    .min(1),
  email: z.string()
    .min(1)
});

const schema = z.object({
  order: orderSchema,
  instructions: instructionsSchema,

  client: z.object({
    name: z.string()
      .min(1),
    depositNumber: z.string()
  }),

  contact: contactSchema,
  counterPartyContact: contactSchema
});

export const DvpConfigurationPage: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const settlementId = params.get('settlementId');

  const [eodFormQuery, { data: eodData }] = useEodFormLazyQuery();
  const [createEodForm] = useCreateEodFormMutation();

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema),
    mode: 'onChange'
  });

  const { register, reset, formState: { errors, isSubmitting } } = form;

  React.useEffect(() => {
    if (settlementId) {
      eodFormQuery({
        variables: {
          input: {
            settlementId: settlementId
          }
        }
      });
    }
  }, [params]);

  React.useEffect(() => {
    if (eodData?.eodForm) {
      reset(eodData.eodForm);
    }
  }, [eodData]);

  const onRequest = form.handleSubmit(async (data) => {
    const response = await createEodForm({
      variables: {
        input: {
          settlementId: settlementId!,
          ...data
        }
      },
      refetchQueries: [
        'settlementsList'
      ]
    });
    window.open(response!.data?.createEODForm?.url!, '_blank');


    form.reset();

    snackbar.enqueueSnackbar('Operation successfully requested', {
      variant: 'success'
    });

    navigate('/financials/brokerage/settlements');
  });

  return (
    <Box>
      <PageHeading
        title="DVP Configuration"
        breadcrumbs={[{
          text: 'Brokerage'
        }, {
          text: 'DVP Configuration'
        }]}
      >
      </PageHeading>

      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }} mb={4} >
          <Typography variant='subtitle' mb={1}>Date of order</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>

            <Controller
              control={form.control}
              name="order.date"
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={{
                      flex: '0 0 50%'
                    }}
                    label="Date of order"
                    value={dayjs.utc(field.value)}
                    inputRef={field.ref}
                    timezone="UTC"
                    onChange={(date) => {
                      if (date) {
                        form.setValue('order.date', (date as any).$d.toISOString());
                      }
                    }}
                  />
                );
              }}
            />
          </Box>
        </Box>

        <Controller
          name="order.side"
          control={form.control}
          render={({ field }) => (
            <Box mb={4}>
              <Typography variant='subtitle' mb={1}>Trade</Typography>
              <RadioGroup
                sx={{
                  display: 'flex',
                  flexDirection: 'row',

                  marginLeft: '12px'

                }}
                {...field}
                value={field.value ?? OrderSide.Buy}
              >
                <FormControlLabel
                  key={OrderSide.Buy}
                  value={OrderSide.Buy}
                  label="Client buys - securities will be received (RVP)"
                  control={<Radio />}
                />

                <FormControlLabel
                  key={OrderSide.Sell}
                  value={OrderSide.Sell}
                  label="Client sells - securities will be delivered (DVP)"
                  control={<Radio />}
                />
              </RadioGroup>
            </Box>
          )}
        />
        <Box mb={4}>
          <Typography variant='subtitle' mb={1}>Instructions</Typography>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              label="Name of the ISIN"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.instructions?.name)}
              helperText={errors.instructions?.name?.message}
              {...register('instructions.name')}
            />
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              label="ISIN"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.instructions?.isin)}
              helperText={errors.instructions?.isin?.message}
              {...register('instructions.isin')}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              label="Quantity"
              disabled={true}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.instructions?.quantity)}
              helperText={errors.instructions?.quantity?.message}
              {...register('instructions.quantity')}
            />
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              select
              label="Currency"
              InputLabelProps={{ shrink: true }}
              {...register('instructions.currency')}
              error={Boolean(errors.instructions?.currency)}
              helperText={errors.instructions?.currency?.message}
            >
              <option selected disabled>
                Select Currency
              </option>

              {Object.values(FiatCurrency).map((currency) => (
                <option value={currency} key={currency}>
                  {currency}
                </option>
              ))}
            </TextField>
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }}>
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              label="Price"
              disabled={true}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.instructions?.price)}
              helperText={errors.instructions?.price?.message}
              {...register('instructions.price')}
            />
            <Controller
              control={form.control}
              name="instructions.settlement.date"
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={{
                      flex: '0 0 50%'
                    }}
                    label="Settlement date"
                    value={dayjs.utc(field.value)}
                    inputRef={field.ref}
                    timezone="UTC"
                    onChange={(date) => {
                      if (date) {
                        form.setValue('instructions.settlement.date', (date as any).$d.toISOString());
                      }
                    }}
                  />
                );
              }}
            />

          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px'
          }} mb={1}>
            <TextField
              sx={{
                flex: '0 0 50%'
              }}
              label="Settlement amount"
              disabled={true}
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.instructions?.settlement?.amount)}
              helperText={errors.instructions?.settlement?.amount?.message}
              {...register('instructions.settlement.amount')}
            />
            <Controller
              control={form.control}
              name="instructions.tradeDate"
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={{
                      flex: '0 0 50%'
                    }}
                    label="Trade date"
                    value={dayjs.utc(field.value)}
                    inputRef={field.ref}
                    timezone="UTC"
                    onChange={(date) => {
                      if (date) {
                        form.setValue('instructions.tradeDate', (date as any).$d.toISOString());
                      }
                    }}
                  />
                );
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant='subtitleSmall' mb={1}>SSI Counterparty</Typography>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap'
            }}>
              <TextField
                sx={{
                  flex: '0 50%',
                  marginRight: '1px'
                }}
                label="Institiution name"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.instructions?.ssiCounterparty?.name)}
                helperText={errors.instructions?.ssiCounterparty?.name?.message}
                {...register('instructions.ssiCounterparty.name')}
              />
              <TextField
                sx={{
                  flex: '0 50%',
                  marginRight: '1px'
                }}
                label="Institition BIC"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.instructions?.ssiCounterparty?.bic)}
                helperText={errors.instructions?.ssiCounterparty?.bic?.message}
                {...register('instructions.ssiCounterparty.bic')}
              />
              <TextField
                sx={{
                  flex: '0 50%',
                  marginRight: '1px'
                }}

                label="Depository code"
                InputLabelProps={{ shrink: true }}
                error={Boolean(errors.instructions?.ssiCounterparty?.code)}
                helperText={errors.instructions?.ssiCounterparty?.code?.message}
                {...register('instructions.ssiCounterparty.code')}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }} mb={4}>
          <Typography variant='subtitle' mb={1}>Client</Typography>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Name"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.client?.name)}
              helperText={errors.client?.name?.message}
              {...register('client.name')}
            />
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Deposit number"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.client?.depositNumber)}
              helperText={errors.client?.depositNumber?.message}
              {...register('client.depositNumber')}
            />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }} mb={4}>
          <Typography variant='subtitle' mb={1}>Contact</Typography>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Name"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.contact?.name)}
              helperText={errors.contact?.name?.message}
              {...register('contact.name')}
            />
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Phone number"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.contact?.phone)}
              helperText={errors.contact?.phone?.message}
              {...register('contact.phone')}
            />
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Email"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.contact?.email)}
              helperText={errors.contact?.email?.message}
              {...register('contact.email')}
            />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column'
        }} mb={4}>
          <Typography variant='subtitle' mb={1}>Contact counterparty</Typography>
          <Box sx={{
            display: 'flex',
            flexWrap: 'wrap'
          }}>

            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Name"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.counterPartyContact?.name)}
              helperText={errors.counterPartyContact?.name?.message}
              {...register('counterPartyContact.name')}
            />
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Phone number"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.counterPartyContact?.phone)}
              helperText={errors.counterPartyContact?.phone?.message}
              {...register('counterPartyContact.phone')}
            />
            <TextField
              sx={{
                flex: '0 50%',
                marginRight: '1px'
              }}
              label="Email"
              InputLabelProps={{ shrink: true }}
              error={Boolean(errors.counterPartyContact?.email)}
              helperText={errors.counterPartyContact?.email?.message}
              {...register('counterPartyContact.email')}
            />
          </Box>
          <Box
            sx={{
              pt: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >

            <LoadingButton
              loading={isSubmitting}
              disabled={!form.formState.isValid}
              onClick={onRequest}
            >
              Generate
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box >
  );
};
