const CHART_COLOR_PALETTE = [
    '#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de',
    '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#3355dd',
    '#33dd55', '#dd5533', '#55dd33', '#dd3355', '#5555dd',
    '#ddaa33', '#aadd33', '#33aadd', '#aa33dd', '#dd33aa',
    '#0077aa', '#00aa77', '#aa0077', '#aa7700', '#77aa00',
    '#aa5500', '#00aa55', '#aa0055', '#0055aa', '#55aa00',
    '#884411', '#448811', '#114488', '#881144', '#118844',
    '#772255', '#227755', '#552277', '#557722', '#772222',
    '#00ffff', '#ff00ff', '#ffff00', '#ff8800', '#00ff88',
    '#ff4400', '#0044ff', '#ff0044', '#44ff00', '#0044ff',
    '#99ee00', '#ee0099', '#00ee99', '#9900ee', '#99ee99',
    '#220088', '#882200', '#008822', '#228822', '#882288'
];

export const getTokenColor = (tokenName: string, allTokens: string[]): string => {
    const index = allTokens.indexOf(tokenName);
    if (index !== -1 && index < CHART_COLOR_PALETTE.length) {
        return CHART_COLOR_PALETTE[index];
    }
    // fallback color if token not found or exceeds palette length
    return '#000000';
};