import React from 'react';
import { Box, Step, StepContent, StepLabel, Stepper } from '@mui/material';
import { PageHeading, Title } from '@backed-fi/compound';

import { GeneralInformation } from './001-GeneralInformation';
import { InjectIncomingTransactions } from './002-IncomingTransactions';
import { ExternalWalletRegistration } from './003-ExternalWalletRegistration';
import { InjectOutgoingTransaction } from './004-OutgoingTransactions';
import { InjectOrder } from './006-Orders';
import { FeesAndInvoices } from './007-FeesAndInvoices';
import { InjectConfirmation } from './008-Confirm';

import { useInjectionContext } from '../../InjectionContext';
import { NavigationButtons } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/NavigationButtons';
import { InjectBankTransfers } from '@backed-fi/admin/src/app/domain/Interactions/Injections/pages/Steps/005-BankTransfers';


export const InjectInteractionPageDefinition: React.FC = () => {
  const context = useInjectionContext();

  const Steps = React.useMemo(() => (
    [
      {
        title: 'Client',
        subtitle: 'Select the client, for which you are injecting the interaction',
        Content: GeneralInformation
      },
      {
        title: 'Incoming Transaction',
        subtitle: 'Inject the incoming transactions of the interaction',
        Content: InjectIncomingTransactions
      },

      ...(context.incomingTransactions.some((x) => !x.client) ? [{
        title: 'External Wallet Registration',
        subtitle:
          'One or more of the incoming transaction came from unregistered ' +
          'external wallet. Before you can inject such transactions you ' +
          'must register the external wallet in the system',
        Content: ExternalWalletRegistration
      }] : []),

      {
        title: 'Outgoing Transactions',
        subtitle: 'Inject the outgoing transactions',
        Content: InjectOutgoingTransaction
      },
      {
        title: 'Bank Transfers',
        subtitle: 'Inject the bank transfers',
        Content: InjectBankTransfers
      },
      {
        title: 'Orders',
        subtitle: 'Inject all the orders, executed for the interaction',
        Content: InjectOrder
      },

      {
        title: 'Fees & Invoices',
        subtitle: 'Inject the fees and invoices of the interaction',
        Content: FeesAndInvoices
      }, {
        title: 'Confirm',
        subtitle: 'Confirm that everything looks okay',
        Content: InjectConfirmation
      }
    ]
  ), [context]);


  return (
    <Box>
      <PageHeading
        title="Inject Interaction"
        breadcrumbs={[
          {
            text: 'Interactions',
            link: '/interactions/all'
          }, {
            text: 'Inject'
          }
        ]}
      />

      <Box
        sx={{
          maxWidth: '800px'
        }}
      >
        <Stepper
          activeStep={context.currentStep}
          orientation="vertical"
          sx={{
            gap: '.25rem'
          }}
        >
          {Steps.map(({
            Content,
            ...step
          }) => (
            <Step key={step.title}>
              <StepLabel>
                <Title
                  small
                  title={step.title}
                  subtitle={step.subtitle}
                />
              </StepLabel>
              <StepContent>
                <Box
                  sx={{
                    ml: '8px',
                    mb: '1rem'
                  }}
                >
                  <Content />
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};
