import React from 'react';
import { InteractionsTable } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionsTable';
import { useParams } from 'react-router-dom';

export const TokenOperationsPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  return (
    <InteractionsTable
      tokenId={params.id}
    />
  );
};
