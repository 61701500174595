import React from 'react';
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { Title } from '@backed-fi/compound';
import { AdminPermission, InteractionFlag } from '@backed-fi/graphql';
import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';
import { InteractionRefundButton } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/components/InteractionRefundButton';
import { UpdateInteractionProductsButton } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/components/UpdateInteractionProductsButton';
import { useParams } from 'react-router-dom';

export const InteractionActionsPage: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  const adminContext = useAdminContext();

  return (
    <Box
      sx={{
        gap: '3rem',
        display: 'flex',
        flexFlow: 'column',

        '& .grid': {
          mt: '.5rem',
          maxWidth: '600px',
          border: '1px solid',
          borderRadius: '4px',
          borderBottom: 'unset',
          borderColor: 'utility.borderElement',

          display: 'grid',
          gridTemplateColumns: {
            xss: '1fr',
            md: '250px 1fr'
          },

          '> *': {
            padding: '1rem',
            borderBottom: '1px solid',
            borderColor: 'utility.borderElement'
          },

          '.content': {
            display: 'flex',
            justifyContent: 'flex-end'
          }
        }
      }}
    >
      {/* region Compliance */}

      <Box>
        <Title
          title="Compliance"
          subtitle="Actions for managing the compliance of the interaction"
        />

        <Box>
          <Box
            className="grid"
          >
            <Title
              boxed
              small
              title="Flags"
            />

            <Box>
              <FormGroup>
                {Object.values(InteractionFlag)
                  .map((flag) => (
                    <FormControlLabel
                      key={flag}
                      label={flag.replace(/([a-z])([A-Z])|([A-Z]+)([A-Z][a-z])/g, '$1$3 $2$4')}
                      control={<Checkbox />}

                      sx={{
                        mb: '.5rem'
                      }}
                    />
                  ))
                }
              </FormGroup>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* endregion */}

      {/* region Recalculation Zone */}

      <Box>
        <Title
          title="Updates Zone"
          subtitle="Ability to recalculate some of the interaction properties"
        />

        <Box className="grid">
          {/* region Related Products */}

          <Title
            boxed
            small
            title="Producs"
            subtitle="Update the interaction products"
          />

          <Box
            className="content"
          >
            <UpdateInteractionProductsButton
              interactionId={params.interactionId!}
            />
          </Box>

          {/* endregion */}
        </Box>
      </Box>

      {/* endregion */}

      {/* region Danger Zone */}

      <Box>
        <Title
          title="Danger Zone"
          subtitle="Potentially distributive actions"
        />

        {!adminContext.permissions.includes(AdminPermission.DeveloperManage) ? (
          <Box className="grid">
            {/* region Refund */}

            <Title
              boxed
              small
              title="Refund"
              subtitle="Cancel and refund the interaction"
            />

            <Box className="content">
              <InteractionRefundButton
                interactionId={params.interactionId!}
              />
            </Box>

            {/* endregion */}

            {/* region Cancel */}

            <Title
              boxed
              small
              title="Cancel"
              subtitle="Manually cancel the interaction"
            />

            <Box className="content">
              <Button
                color="error"
              >
                Cancel
              </Button>
            </Box>

            {/* endregion */}
          </Box>
        ) : (
          <Box>
            <Typography>
              You do not have permission to access this actions
            </Typography>
          </Box>
        )}
      </Box>

      {/* endregion */}
    </Box>
  );
};
