// region Form Schema
import React from 'react';
import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { z } from 'zod';
import { AdminPermission, Role } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const Schema = z.object({
  name: z.string()
    .nonempty(),

  description: z.string()
    .nonempty()
});

export type Schema = z.infer<typeof Schema>;


interface Props {
  onSubmit: (data: Schema, permissions: AdminPermission[]) => Promise<void>,
  action: string,
  role?: Pick<Role, 'name' | 'description' | 'permissions'>
}


// endregion

export const RoleForm: React.FC<Props> = ({ onSubmit, action, role }) => {
  // region State

  const [permissions, setPermissions] = React.useState<AdminPermission[]>(role?.permissions || []);

  // endregion

  // region Form Control

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
    ...(role ? {
      defaultValues: {
        name: role.name,
        description: role.description
      }
    } : {})

  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  const onPermissionToggle = (permission: AdminPermission) => (e: any, checked: boolean) => {
    if (checked) {
      setPermissions((prev) => ([
        ...prev,
        permission
      ]));
    } else {
      setPermissions((prev) => prev.filter((p) => p !== permission));
    }
  };

  // region Actions


  const onCreate = form.handleSubmit(async (data) => {
    await onSubmit(data, permissions);
  });


  return (
    <>
      <Box
        mt={3}
        mb={1}
      >
        <Typography
          variant='titleSmall'
        >
            General Information
        </Typography>

        <Typography
          variant='subtitleSmall'
        >
            Some basic information about the role
        </Typography>
      </Box>

      <TextField
        fullWidth
        label='Name'
        error={!!errors?.name}
        helperText={errors?.name?.message}
        {...form.register('name')}
      />

      <TextField
        fullWidth
        label='Description'
        error={!!errors?.description}
        helperText={errors?.description?.message}
        {...form.register('description')}
      />

      <Box
        mt={3}
        mb={1}
      >
        <Typography
          variant='titleSmall'
        >
            Permissions
        </Typography>

        <Typography
          variant='subtitleSmall'
        >
            The set of permissions available for the role
        </Typography>
      </Box>

      <FormGroup sx={{ marginLeft: '11px' }}>
        {Object.values(AdminPermission).map((permission) => (
          <FormControlLabel
            key={permission}
            label={permission}
            control={(
              <Checkbox
                checked={permissions.includes(permission)}
                onChange={onPermissionToggle(permission as AdminPermission)} />
            )}
          />
        ))}
      </FormGroup>


      <Box
        sx={{
          mt: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <LoadingButton
          loading={isSubmitting}
          onClick={onCreate}
        >
          {action}
        </LoadingButton>
      </Box>
    </>
  );
};
