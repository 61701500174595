import React from 'react';
import { gql } from '@apollo/client';
import { Box, Dialog, DialogContent } from '@mui/material';

import { CommandMenuItems } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuItems';
import { CommandMenuInput } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuInput';
import { CommandMenuTarget } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuTarget';
import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';

// region Graph

gql`
  query CommandMenu($filter: ClientsFilter) {
    clients(where: $filter, pageSize: 5) {
      nodes {
        id

        name
        contactEmail
      }
    }
  }
`;

// endregion

export const CommandMenuRoot = () => {
  const { toggle: dialog } = useCommandMenuContext();

  // region Effects

  // Listen for shortcuts
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'p' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();

        dialog.toggle();
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [dialog]);

  return (
    <Dialog
      {...dialog}
      fullWidth
      maxWidth="sm"
      sx={{
        backdropFilter: 'blur(2px)'
      }}
    >
      <DialogContent
        sx={{
          padding: '0',
          backdropFilter: 'blur(5px)'
        }}
      >
        <Box
          sx={({ palette }) => ({
            padding: '.5rem 1rem',
            paddingBottom: '0',
            borderBottom: `1px solid ${palette.utility.borderSubtle}`
          })}
        >
          <CommandMenuTarget />
          <CommandMenuInput />
        </Box>

        <CommandMenuItems />
      </DialogContent>
    </Dialog>
  );
};
