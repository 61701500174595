import React from 'react';
import { Box, TextField, Typography } from '@mui/material';
import { FormGroup, InfoLabel } from '@backed-fi/compound';
import { useNavigate, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { BlockchainNetwork, TokenSupportingStructuresQuery, useCreateTokenDeploymentMutation, useDeployTokenDataQuery, useTokenSupportingStructuresLazyQuery } from '@backed-fi/graphql';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';

const GraphQl = gql`
  query deployTokenData($tokenId: String!) {
    token(id: $tokenId) {
      id

      name
      symbol

      deployments {
        network
      }
    }
  }

  query tokenSupportingStructures {
    systemWallets {
      id
      title
      address
    }
  }

  mutation createTokenDeployment($input: CreateTokenDeploymentInput!) {
    createTokenDeployment(input: $input) {
      id
    }
  }
`;

const DeploySchema = z.object({
  network: z.string()
    .transform<BlockchainNetwork>(x => (x as BlockchainNetwork)),

  ownerAddress: z.string()
    .nonempty(),

  pauserAddress: z.string()
    .nonempty(),

  minterAddress: z.string()
    .nonempty(),

  burnerAddress: z.string()
    .nonempty(),

  sanctionListAddress: z.string()
    .nonempty(),

  systemWalletId: z.string()
    .nonempty()
});

export const DeployTokenPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- State ---- //
  const [confirmation, setConfirmation] = React.useState<boolean>(false);

  // ---- Networking ---- //
  const [loadTokenSupportingStructures, { data: tokenSupportingStructures }] = useTokenSupportingStructuresLazyQuery();
  const [createTokenDeployment, { loading: creating }] = useCreateTokenDeploymentMutation();
  const { data } = useDeployTokenDataQuery({
    variables: {
      tokenId: params.id!
    }
  });

  // ---- Form State ---- //
  const form = useForm<z.infer<typeof DeploySchema>>({ resolver: zodResolver(DeploySchema) });

  // ---- Actions ---- //
  const onDeploymentNetworkSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedNetworkName = e.target.value as BlockchainNetwork;

    form.setValue('network', selectedNetworkName);

    form.resetField('systemWalletId');

    await loadTokenSupportingStructures();
  };

  const onBeginDeployment = async () => {
    if (confirmation) {
      try {
        await createTokenDeployment({
          variables: {
            input: {
              tokenId: params.id!,
              ...form.getValues()
            }
          }
        });

        snackbar.enqueueSnackbar('Successfully submitted token for deployment');

        navigate(`/internal/tokens/details/${params.id}/networks`);
      } catch (e) {
        snackbar.enqueueSnackbar('An error occurred while submitting the token for development. Check the console for more information', {
          variant: 'error'
        });

        // eslint-disable-next-line no-console
        console.error(e);
      }
    } else {
      setConfirmation(true);
    }
  };

  // ---- Destructuring ---- //
  const { systemWallets } = (tokenSupportingStructures || {}) as TokenSupportingStructuresQuery;

  return (
    <Box>
      {/* // ---- Configuration Form ---- // */}
      {(data && !confirmation) && (
        <Box>
          <FormGroup
            noSeparator
            title='Network'
            subtitle='Select on which network you want to deploy the contract'
          >
            <TextField
              select
              label='Deployment Network'
              onChange={onDeploymentNetworkSelected}
              SelectProps={{
                native: true
              }}
            >
              <option disabled selected>
                Please select
              </option>

              {Object.keys(BlockchainNetwork).map((network) => {
                const exists = data.token.deployments.some(x => x.network === network);

                return (
                  <option key={network} value={network} disabled={exists}>
                    {network}

                    {exists && ' (Already Exists)'}
                  </option>
                );
              })}
            </TextField>
          </FormGroup>

          <FormGroup
            title='Contract Ownership'
            subtitle='The address of the accounts that will be set as the specified role on the deployed contract'
          >
            <TextField
              label='Owner Account'
              helperText='The address of the account that will be assigned as owner for the deployed contract'
              {...form.register('ownerAddress')}
            />

            <TextField
              label='Pauser Account'
              helperText='The address of the account that will be assigned as pauser for the deployed contract'
              {...form.register('pauserAddress')}
            />

            <TextField
              label='Minter Account'
              helperText='The address of the account that will be assigned as minter for the deployed contract'
              {...form.register('minterAddress')}
            />

            <TextField
              label='Burner Account'
              helperText='The address of the account that will be assigned as burner for the deployed contract'
              {...form.register('burnerAddress')}
            />

            <TextField
              label='Sanction List Address'
              helperText='The address of the smart contract that is going to be the oracle for sanctions screening'
              {...form.register('burnerAddress')}
            />
          </FormGroup>

          <FormGroup
            title='Token Operations'
            subtitle='Configure the operation details of the token in the system'
          >
            <TextField
              select
              fullWidth
              {...form.register('systemWalletId')}
              label='Operating System Wallet'
              helperText={
                'The system wallet that will pay for all actions, executed on this network ' +
                'for this token. It will also be used for storing the unpledged tokens'
              }
              SelectProps={{
                native: true
              }}
            >
              <option
                selected
                disabled
              >
                {data && 'Select Operating Wallet'}
                {!data && 'Please select the network first'}
              </option>


              {systemWallets?.map((systemWallet) => (
                  <option value={systemWallet.id} key={systemWallet.id}>
                    {systemWallet.title} ({systemWallet.address})
                  </option>
                )
              )}
            </TextField>
          </FormGroup>
        </Box>
      )}

      {/* // ---- Confirmation Prompt ---- // */}
      {
        (data && confirmation) && (
          <Box>
            <Typography variant='titleSmall'>
              Basic Deployment Information
            </Typography>

            <InfoLabel
              label='Network'
              content={form.getValues('network')}
            />

            {/*<InfoLabel*/}
            {/*  label="Factory Address"*/}
            {/*  content={network?.tokenFactoryAddress}*/}
            {/*/>*/}

            <Typography variant='titleSmall'>
              Contract Ownership Information
            </Typography>

            {/*<InfoLabel*/}
            {/*  label="Proxy Admin Owner"*/}
            {/*  content={network?.tokenFactoryProxyAdminAddress}*/}
            {/*/>*/}

            <InfoLabel
              label='Owner'
              content={form.getValues('ownerAddress')}
            />

            <InfoLabel
              label='Minter'
              content={form.getValues('minterAddress')}
            />

            <InfoLabel
              label='Pauser'
              content={form.getValues('pauserAddress')}
            />

            <InfoLabel
              label='Burner'
              content={form.getValues('burnerAddress')}
            />

            <Typography variant='titleSmall'>
              Operational Information
            </Typography>

            <InfoLabel
              label='Operator'
              content={form.getValues('systemWalletId')}
            />

          </Box>
        )
      }


      <LoadingButton
        loading={creating}
        onClick={form.handleSubmit(onBeginDeployment)}
        sx={{
          float: 'right'
        }}
      >
        {
          confirmation
            ? 'Begin Deployment'
            : 'Next'
        }
      </LoadingButton>
    </Box>
  );
};
