import React from 'react';
import { Box, Dialog, TextField } from '@mui/material';
import { gql } from '@apollo/client';
import { useRetryEventMutation } from '@backed-fi/graphql';
import { Title } from '@backed-fi/compound';
import { useSnackbar } from 'notistack';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';

// region Props

interface RetryEventDialogProps extends React.ComponentProps<typeof Dialog> {
  /**
   * The ID of the event to be retried
   */
  eventId: string;
}

// endregion

// region Graph

gql`
  mutation retryEvent($input: RetryEventMutationInput!) {
    retryEvent(input: $input) {
      id
    }
  }
`;

// endregion


const Schema = z.object({
  comment: z.string().min(1)
});

type Schema = z.infer<typeof Schema>;

export const RetryEventDialog: React.FC<RetryEventDialogProps> = ({
  eventId,
  ...props
}) => {
  const snackbar = useSnackbar();

  // region State

  const [loading, setLoading] = React.useState<boolean>(false);

  // endregion

  // region Form Control

  const {
    formState,
    register,
    ...form
  } = useForm<Schema>({
    resolver: zodResolver(Schema),
    mode: 'onChange'
  });

  const { errors, isValid } = formState;
  // endregion

  // region Networking


  const onRetryEvent = form.handleSubmit(async () => {
    setLoading(true);

    try {
      await retryEvent({
        variables: {
          input: {
            eventId,
            comment: form.getValues('comment')
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'eventDetails'
        ]
      });

      if (typeof (props as any).setFalse === 'function') {
        (props as any).setFalse();
      }

      snackbar.enqueueSnackbar('Successfully scheduled event for retry');
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while scheduling event retry', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  });
  const [retryEvent] = useRetryEventMutation();

  // endregion
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <Box
        sx={{
          p: '1rem 1.5rem'
        }}
      >
        <Title
          title="Schedule event handling retry"
          subtitle='Please provide a reason for retry'
        />

        <TextField
          fullWidth
          label="Comment"
          error={Boolean(errors.comment)}
          helperText={errors.comment?.message}
          {...register('comment')}
        />
        <LoadingButton
          disabled={!isValid}
          loading={loading}
          onClick={onRetryEvent}
          sx={{
            float: 'right'
          }}
        >
          Retry
        </LoadingButton>
      </Box>
    </Dialog>
  );
};
