import { join } from 'path';
import { IncomingTransactionScoreProvider } from '@backed-fi/graphql';

export const parseIncomingTransactionRedFlags =
  (scoreDetails: any, scoreProvider: IncomingTransactionScoreProvider): string[] => {
    const isScorechain = scoreProvider === IncomingTransactionScoreProvider.Scorechain;
    const highPriorityRisks =
      scoreDetails?.risks?.filter((risk: { type: number }) => isScorechain ? true : risk.type === 2);

    return highPriorityRisks?.flatMap((r: any) =>
      isScorechain ? (r.reference?.name ? [r.reference.name] : (r.causes?.map((x: any) => x.name) ?? [])) : [r.name]
    );
  };
