import React from 'react';
import { Chip } from '@mui/material';

import { PayoutMethod } from '@backed-fi/graphql';

import BankIcon from '@mui/icons-material/AccountBalanceRounded';
import CryptoIcon from '@mui/icons-material/CurrencyBitcoinRounded';

interface Props {
  method: PayoutMethod;
}

export const InteractionPayoutMethodChip: React.FC<Props> = ({ method }) => {
  return (
    <Chip
      label={method.replace(/([a-z])([A-Z])/g, '$1 $2')}

      icon={({
        [PayoutMethod.BankPayout]: <BankIcon />,
        [PayoutMethod.BlockchainPayout]: <CryptoIcon />
      } as const)[method]}

      color={({
        [PayoutMethod.BankPayout]: 'primary',
        [PayoutMethod.BlockchainPayout]: 'success'
      } as const)[method]}
    />
  );
};
