import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

import { useGetTokenStatisticsQuery } from '@backed-fi/graphql';
import { getTokenColor } from './colorUtil';

const transformData = (data: any): any => {
  if (!data?.[0]?.statistics?.tokens) {
    return {
      seriesData: []
    };
  }

  let stats = data[0].statistics;
  const allTokens = stats.tokens.map((token: any) => token.symbol).sort();
  let legendData: string[] = [];
  let yAxisData: string[] = [];
  let seriesData: any[] = [];

  stats.tokens.forEach((token: any) => {
    legendData.push(token.symbol);
    token.deployments.forEach((deployment: any) => {
      if (yAxisData.indexOf(deployment.network) === -1) {
        yAxisData.push(deployment.network);
      }
      let existingSeries = seriesData.find((series) => series.name === token.symbol);
      if (!existingSeries) {
        existingSeries = {
          name: token.symbol,
          type: 'bar',
          itemStyle: { color: getTokenColor(token.symbol, allTokens) },
          stack: 'total',
          label: { show: false },
          emphasis: { focus: 'series' },
          data: new Array(yAxisData.length).fill(0) // initially fill with 0
        };
        seriesData.push(existingSeries);
      }
      existingSeries.data[yAxisData.indexOf(deployment.network)] = deployment.circulatingSupply;
    });
  });

  return {
    legendData,
    yAxisData,
    seriesData
  };
};

const TokensPerNetworkChart: React.FC = () => {
  const [option, setOption] = useState<echarts.EChartsOption | null>(null);

  const query = useGetTokenStatisticsQuery({ variables: { isLatest: true } });

  const { loading, error } = query;
  const tokenStatistics = query.data?.tokenStatistics as { createdAt: string; statistics: string }[];

  useEffect(() => {
    if (tokenStatistics) {
      const transformedData = transformData(tokenStatistics);

      if (transformedData.seriesData.length === 0) {
        return;
      }

      setOption({
        title: {
          text: 'Circulating Tokens Per Network'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: { type: 'shadow' }
        },
        legend: {
          data: transformedData.legendData,
          top: 40
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: { type: 'value' },
        yAxis: {
          type: 'category',
          data: transformedData.yAxisData
        },
        series: transformedData.seriesData
      });
    }
  }, [tokenStatistics]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return option && <ReactEcharts option={option} style={{ height: '400px' }} />;
};

export default TokensPerNetworkChart;
