import { Box } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { SafeTxVerifier } from './components/SafeTxVerifier';

export const UtilsPage = () => {
  return (
    <Box>
      <PageHeading
        title='Utilities'
        breadcrumbs={[{
          text: 'Utilities'
        }]}
      />

      <SafeTxVerifier />
    </Box>
  );
};
