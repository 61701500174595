import { Box, Button, Dialog, DialogContent, TextField } from "@mui/material";
import React from "react";
import { gql } from "@apollo/client";
import {
  AdminPermission,
  useRegisterMultisignatureWalletMutation,
} from "@backed-fi/graphql";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useToggle } from "@backed-fi/hooks";
import { Protector, Title } from "@backed-fi/compound";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

// region Graph

gql`
  mutation registerMultisignatureWallet(
    $input: RegisterMultisignatureWalletInput!
  ) {
    registerMultisignatureWallet(input: $input) {
      id
    }
  }
`;

// endregion

// region Props

interface Props {}

// endregion

const Schema = z.object({
  address: z.string().nonempty(),
  title: z.string().nonempty(),
  description: z.string(),
});

type Schema = z.infer<typeof Schema>;

export const RegisterMultisignatureWalletDialog: React.FC<Props> = ({}) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const [registerMultisignatureWallet, { loading }] =
    useRegisterMultisignatureWalletMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
  });
  const { errors } = form.formState;

  // region Effect

  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(
    async ({ address, title }) => {
      try {
        await registerMultisignatureWallet({
          awaitRefetchQueries: true,
          refetchQueries: ["multisignatureWallets"],
          variables: {
            input: {
              address,
              title,
            },
          },
        });

        snackbar.enqueueSnackbar(
          "Successfully registered new multisignature wallet",
          {
            variant: "success",
          }
        );
      } catch (e: any) {
        snackbar.enqueueSnackbar(
          e.message ??
            "An error occurred while registering new multisignature wallet",
          {
            variant: "error",
          }
        );
      } finally {
        dialog.setFalse();
      }
    }
  );

  // endregion

  return (
    <Protector
      permissions={[
        AdminPermission.BlockchainManage,
        AdminPermission.SystemManage,
      ]}
    >
      <Button onClick={dialog.setTrue}>Register new wallet</Button>

      {dialog.open && (
        <Dialog {...dialog} fullWidth maxWidth="sm">
          <DialogContent>
            <Title
              title="Update Annual Management Fee"
              subtitle="Define annual management fee (in bips)"
            />

            <TextField
              size="small"
              label="Address of new multisignature wallet"
              type="string"
              sx={{
                marginRight: "16px",
                width: "100%",
              }}
              error={!!errors.address}
              helperText={errors.address?.message}
              {...form.register("address")}
            />

            <TextField
              size="small"
              label="Title of wallet"
              type="string"
              sx={{
                marginRight: "16px",
                width: "100%",
              }}
              error={!!errors.title}
              helperText={errors.title?.message}
              {...form.register("title")}
            />

            <TextField
              size="small"
              label="Description of wallet"
              type="string"
              sx={{
                marginRight: "16px",
                width: "100%",
              }}
              error={!!errors.description}
              helperText={errors.description?.message}
              {...form.register("description")}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: ".5rem",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={loading}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton loading={loading} onClick={onApprove}>
                Register
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Protector>
  );
};
