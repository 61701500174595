import { Route } from 'react-router-dom';
import { TradesListPage } from './Trades/TradesListPage';
import { ExecuteTradePage } from './Trades/ExecuteTradePage';

export const TradingEngineRoutes = (
  <Route path="trading-engine">
    <Route index path="trades/list" element={<TradesListPage />} />
    <Route index path="trades/execute" element={<ExecuteTradePage />} />
  </Route>
);
