import React from 'react';

import { DocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { DocumentFolderInternal } from '@backed-fi/admin/src/app/components/Documents/DocumentFolderInternal';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DeletionDialog } from '@backed-fi/admin/src/app/components/Documents/Deletion/DeletionDialog';
import { Box } from '@mui/material';

// region Props

interface Props {
  folderId: string;

  /**
   * Boolean, indicating if the folder header should be shown
   */
  hideParent?: boolean;

  name?: string;
  description?: string | null;

  /**
   * If set to true it will be the component that provides the DnD context
   */
  dndProvider?: boolean;
}

// endregion

export const DocumentFolder: React.FC<Props> = ({
  folderId,
  name,
  description,
  hideParent,
  dndProvider
}) => {
  const FolderContext = (
    <DocumentContext
      folderId={folderId}
      name={name}
      description={description!}
    >
      <DocumentFolderInternal hideParent={hideParent} />
    </DocumentContext>
  );

  return dndProvider
    ? (
      <Box>
        <DeletionDialog />

        <DndProvider backend={HTML5Backend}>
          {FolderContext}
        </DndProvider>
      </Box>
    ) : FolderContext;
};
