import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { EventStatus, EventType, useEventsListQueryQuery } from '@backed-fi/graphql';
import { PageHeading } from '@backed-fi/compound';
import { DataGrid } from '@mui/x-data-grid';
import DetailsIcon from '@mui/icons-material/RemoveRedEyeRounded';
import { useNavigate } from 'react-router-dom';
import { usePaginationModel } from '@backed-fi/hooks';
import { EventStatusBadge } from './components/EventStatusBadge';
import { Filters, filterToWhereQuery } from '@backed-fi/admin/src/app/components/filter/Filters';
import { DateTimeFormatter } from '@backed-fi/shared';

// region Graph

gql`
  query eventsListQuery(
    $page: Int,
    $pageSize: Int,
    $where: EventWhereInput
  ) {
    events (
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id
        createdAt

        type
        status
      }

      page {
        currentPage
        totalPages
        totalNodes
      }
    }
  }
`;

// endregion

export const EventsListPage: React.FC = () => {
  // region State

  const navigate = useNavigate();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();
  const [filters, setFilters] = useState<Record<string, any>>({});

  // endregion

  // region Networking

  const query = useEventsListQueryQuery({
    variables: {
      ...paginationVariables,
      where: filters
    }
  });

  const { events } = (query.data ?? {});

  // endregion

  return (
    <Box>
      <PageHeading
        title="Events"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Events'
          }
        ]}
      />
      <Box mb={1} gap={1} display="flex">
        <Filters
          onFiltersChanged={(filters) => setFilters(filterToWhereQuery(filters))}
          filters={[]}
          customFilters={{ EventStatus: {
            backendMap: 'status',
            inputType: 'select',
            possibleValues: Object.keys(EventStatus)
          },
          EventType: {
            backendMap: 'type',
            inputType: 'select',
            possibleValues: Object.keys(EventType)
          } }} />
      </Box>

      <DataGrid
        {...pagination}
        autoHeight
        rows={(events?.nodes ?? [])}
        rowCount={events?.page.totalNodes}
        columns={[
          {
            flex: 2,
            field: 'createdAt',
            headerName: 'Creation Date',
            renderCell: ({ value }) => (
              <Typography>
                {DateTimeFormatter.format(new Date(value))}
              </Typography>
            )
          },
          {
            flex: 2,
            minWidth: 250,
            field: 'type',
            headerName: 'Type'
          }, {
            width: 200,
            field: 'status',
            headerName: 'Status',
            renderCell: ({ value }) => (
              <EventStatusBadge status={value} />
            )
          }, {
            width: 200,
            field: 'id',
            headerName: '',
            renderCell: ({ value }) => (
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  onClick={() => {
                    navigate(`/internal/events/details/${value}`);
                  }}
                >
                  <DetailsIcon />
                </IconButton>
              </Box>
            )
          }
        ]}

      />

    </Box>
  );
};
