import React from 'react';
import { Box, Dialog, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import { EnumSelect } from '@backed-fi/compound';
import { BlockchainAddressType, useAddBlockchainAddressMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import * as z from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';

// region Schema


const schema = z.object({
  /**
   * The address that is being created
   */
  address: z.string(),

  /**
   * The type of the address that we are adding
   */
  addressType: z.nativeEnum(BlockchainAddressType),

  /**
   * Short description of the address being created
   */
  description: z.string()
});

// endregion

// region Graph

gql`
  mutation addBlockchainAddress($input: CreateBlockchainAddressInput!) {
    addBlockchainAddress: createBlockchainAddress(input: $input) {
      id
    }
  }
`;

// endregion

export const AddBlockchainAddressDialog: React.FC<DialogProps> = (props) => {
  const snackbar = useSnackbar();

  const [addBlockchainAddressMutation] = useAddBlockchainAddressMutation();

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const {
    isSubmitting,
    errors
  } = form.formState;

  // endregion

  // region Actions

  const onCreate = form.handleSubmit(async (data) => {
    await addBlockchainAddressMutation({
      refetchQueries: [
        'blockchainAddresses'
      ],
      awaitRefetchQueries: true,
      variables: {
        input: data
      }
    });

    snackbar.enqueueSnackbar('Blockchain address added successfully', {
      variant: 'success'
    });

    if (typeof props.onClose === 'function') {
      (props.onClose as any)();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogContent>
        <Typography variant="titleSmall">
          Add Blockchain Address
        </Typography>

        <TextField
          fullWidth
          label="Address"
          {...form.register('address')}
        />

        <EnumSelect
          fullWidth
          label="Address Type"
          enum={BlockchainAddressType}
          {...form.register('addressType')}
        />

        <TextField
          fullWidth
          multiline
          rows={3}
          label="Description"
          {...form.register('description')}
          error={!!errors.description}
        />

        <Box
          sx={{
            mt: '.5rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            onClick={onCreate}
            loading={isSubmitting}
          >
            Add Address
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
