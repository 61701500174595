import { Box, Typography } from '@mui/material';
import * as Base from '@radix-ui/react-context-menu';

import React from 'react';

// region Props

interface Props {
  name: string;
  onClick: () => any;

  Icon: React.FC;

  variant?: 'default' | 'danger';
}

// endregion

export const ContextMenuAction: React.FC<Props> = ({
  name,
  onClick,
  Icon,
  variant
}) => {
  return (
    <Base.Item asChild>
      <Box
        onClick={onClick}

        sx={({ palette }) => ({
          gap: '.5rem',
          display: 'flex',
          padding: '.25rem .5rem',
          cursor: 'pointer',
          borderRadius: '4px',
          border: '2px solid transparent',

          color: palette.utility.textLowContrast,

          '&:focus-visible': {
            outline: 'unset',

            ...((variant === 'default') && ({
              color: palette.primary.textHighContrast,
              backgroundColor: palette.utility.elementBackgroundActive
            })),

            ...((variant) === 'danger' && ({
              color: palette.error.textLowContrast,
              backgroundColor: palette.error.elementBackgroundActive
            }))
          }
        })}
      >
        <Icon />

        <Typography
          sx={{
            color: 'utility.textHighContrast',

            ...((variant) === 'danger' && ({
              color: 'inherit'
            }))
          }}
        >
          {name}
        </Typography>
      </Box>
    </Base.Item>
  );
};

ContextMenuAction.defaultProps = {
  variant: 'default'
};
