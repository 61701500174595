import React from 'react';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import { InteractionsTable } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionsTable';

export const ClientInteractionsPage: React.FC = () => {
  const params = useParams<{
    id: string
  }>();

  return (
    <Box>
      {params.id && (
        <InteractionsTable
          clientId={params.id}
        />
      )}
    </Box>
  );
};
