import React from 'react';
import { DocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { DocumentRowInternal } from '@backed-fi/admin/src/app/components/Documents/DocumentRowInternal';

// region Props

interface Props {
  documentId: string;

  name?: string;
  createdAt?: Date;
}

// endregion

export const DocumentRow: React.FC<Props> = ({ documentId, name }) => {
  return (
    <DocumentContext
      name={name}
      documentId={documentId}
    >
      <DocumentRowInternal
        documentId={documentId}
      />
    </DocumentContext>
  );
};
