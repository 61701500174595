import React from 'react';
import { Box, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { SupplyControlOperationType, useRequestSupplyControlOperationMutation, useSupplyControlDialogQuery, useSupplyControlTokenDeploymentLazyQuery } from '@backed-fi/graphql';
import { z } from 'zod';
import { ethers } from 'ethers';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EnumSelect } from '@backed-fi/compound';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

gql`
  query supplyControlDialog {
    tokens (includeUnpublished: true) {
      id
      name
      symbol
    }
  }

  query supplyControlTokenDeployment($tokenId: ID!) {
    tokenDeployments(
      where: {
        tokenId: $tokenId
      }
    ) {
      id
      network
    }
  }

  mutation requestSupplyControlOperation($input: RequestSupplyControlOperationInput!) {
    requestSupplyControlOperation(input: $input) {
      id
    }
  }
`;

const schema = z.object({
  type: z.nativeEnum(SupplyControlOperationType),

  deploymentId: z.string(),

  amount: z.string()
    .transform((amount) => {
      return ethers.utils
        .parseEther(amount || '0')
        .toString();
    })
});

export const RequestSupplyControlOperationDialog: React.FC<React.ComponentProps<typeof Dialog>> = (props) => {
  const snackbar = useSnackbar();

  // region Networking

  const { data } = useSupplyControlDialogQuery();
  const [tokenDeploymentsQuery, {
    data: deploymentsData,
    loading: fetchingDeployments
  }] = useSupplyControlTokenDeploymentLazyQuery();

  const [requestSupplyControlOperationMutation] = useRequestSupplyControlOperationMutation();

  // endregion

  // region From Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { isSubmitting } = form.formState;

  // endregion

  const onTokenSelected: React.ComponentProps<typeof TextField>['onChange'] = async (event) => {
    await tokenDeploymentsQuery({
      variables: {
        tokenId: event.target.value
      }
    });
  };

  const onRequest = form.handleSubmit(async (data) => {
    await requestSupplyControlOperationMutation({
      variables: {
        input: data
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'allSupplyControlOperations'
      ]
    });

    if (typeof (props as any).onClose === 'function') {
      await (props as any).onClose();
    }

    form.reset();

    snackbar.enqueueSnackbar('Operation successfully requested', {
      variant: 'success'
    });
  });


  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogContent>
        <Typography variant="title">
          Supply Control
        </Typography>

        {/* Token */}
        <TextField
          select
          fullWidth
          onChange={onTokenSelected}
        >
          <option selected disabled>
            {data ? 'Please select' : 'Loading...'}
          </option>

          {data?.tokens.map((token) => (
            <option
              key={token.id}
              value={token.id}
            >
              {token.name}
            </option>
          ))}
        </TextField>

        {/* Deployment */}
        {deploymentsData && (
          <TextField
            select
            fullWidth
            {...form.register('deploymentId')}
          >
            {!fetchingDeployments
              ? deploymentsData?.tokenDeployments.map((deployment) => (
                <option
                  key={deployment.id}
                  value={deployment.id}
                >
                  {deployment.network}
                </option>
              )) : (
                <option disabled>
                  Loading...
                </option>
              )}
          </TextField>
        )}


        {/* Amount */}
        <TextField
          fullWidth
          label="Operation Amount"
          {...form.register('amount')}
        />

        {/* Mint/Burn */}
        <EnumSelect
          fullWidth
          {...form.register('type')}
          enum={SupplyControlOperationType}
        />

        <Box
          sx={{
            pt: '1rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            loading={isSubmitting}
            onClick={onRequest}
          >
            Request Operation
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
