import React from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useTokenListQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { PageHeading } from '@backed-fi/compound';

const GraphQl = gql`
  query tokenList {
    tokens(includeUnpublished: true) {
      id

      name
      description

      isPublic

      deployments {
        network
        address
        stablecoinConfigs {
          symbol
        }
      }
    }
  }
`;

export const TokensListPage: React.FC = () => {
  const navigate = useNavigate();

  // ---- Networking ---- //
  const { data, loading } = useTokenListQuery();

  // ---- Actions ---- //
  const onCreateToken = () => {
    navigate('/internal/tokens/create');
  };

  const onImport = () => {
    navigate('/internal/tokens/deployments/import');
  };

  // ---- Destructuring ---- //
  const { tokens } = data || {};

  return (
    <Box
      sx={{
        height: 'calc(100vh - 4rem)'
      }}
    >
      <PageHeading
        title="Tokens"
        breadcrumbs={[
          {
            text: 'Tokens'
          }
        ]}
      >
        <Button
          sx={{
            mr: '1rem'
          }}
          onClick={onImport}
        >
          Import
        </Button>

        <Button onClick={onCreateToken}>Create New</Button>
      </PageHeading>

      <Box
        sx={{
          height: '100%'
        }}
      >
        <DataGrid
          loading={loading}
          rows={(tokens as any[]) || []}
          onRowClick={(row) => {
            navigate(`/internal/tokens/details/${row.id}/overview`);
          }}
          columns={[
            {
              flex: 2,
              field: 'name',
              minWidth: 300,
              headerName: 'Token Name',
              renderCell: ({ row }) => <Typography>{row.name}</Typography>
            },
            {
              field: 'address',
              minWidth: 435,
              headerName: 'Expected Address',
              renderCell: ({ row }) => (
                <Typography>
                  {row.deployments[0]?.address || 'No Address'}
                </Typography>
              )
            },
            {
              field: 'stablecoin',
              minWidth: 200,
              headerName: 'Stablecoin',
              renderCell: ({ row }) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexFlow: 'row'
                  }}
                >
                  {[...new Set(
                    row.deployments.flatMap(({ stablecoinConfigs }: any) => (
                      stablecoinConfigs.map((config: any) => config.symbol)
                    ))
                  )].map((symbol: any, index: number) => (
                    <Box key={index} sx={{ display: 'flex',
                      marginRight: '4px' }}>
                      <Box
                        flex={0}
                        mr={0.5}
                        src={`/assets/icons/assets/${symbol}.svg`}
                        component="img"
                        width={24}
                        height={24}
                      />
                      <Typography flex={1}>{symbol}</Typography>
                    </Box>
                  ))}
                </Box>
              )
            },
            {
              field: 'isPublic',
              width: 150,
              headerName: 'Visibility',
              renderCell: ({ row }) => (
                <Typography>{row.isPublic ? 'Public' : 'Internal'}</Typography>
              )
            },
            {
              flex: 2,
              field: 'deployments',
              headerName: 'Networks',
              renderCell: ({ row }) =>
                row.deployments.map(({ network }: any, key: number) => (
                  <Chip
                    key={key}
                    label={network}
                    color="info"
                    sx={{
                      mr: '.5rem'
                    }}
                  />
                ))
            }
          ]}
          sx={{
            backgroundColor: 'utility.backgroundSubtle',

            '.MuiDataGrid-row': {
              cursor: 'pointer'
            }
          }}
        />
      </Box>
    </Box>
  );
};
