import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { countries } from 'countries-list';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';

import OpenIcon from '@mui/icons-material/FileOpenRounded';
import RefreshIcon from '@mui/icons-material/RotateLeftRounded';
import MagnifyingGlassIcon from '@mui/icons-material/ManageSearchRounded';

import { PageHeading } from '@backed-fi/compound';
import { Country, useVerificationsListQuery, VerificationsListQuery } from '@backed-fi/graphql';

const GraphQL = gql`
  query verificationsList {
    verifications {
      id

      isArchived

      createdAt
      updatedAt

      tier
      vendor

      vendorApplicantId
      vendorVerificationId

      clientType
      status

      client {
        id

        name
        jurisdiction
      }
    }
  }
`;

export const VerificationsListPage = () => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- Networking ---- //
  const { data, loading, refetch } = useVerificationsListQuery();

  // ---- Destructing ---- //
  const { verifications } = (data || {}) as VerificationsListQuery;

  // ---- Actions ---- //
  const onCreateAssessment = (verificationId: string) => {
    return async () => {
      snackbar.enqueueSnackbar('Risk assessment creation was moved to the client compliance tab.');
    };
  };

  const onRefreshVerification = (verificationId: string) => {
    return async () => {
      snackbar.enqueueSnackbar('This function is temporally disabled');
    };
  };

  return (
    <Box>
      <PageHeading
        title='Verifications'
        breadcrumbs={[{
          text: 'Verifications'
        }]}
      />

      <Box
        sx={{
          width: '100%',
          height: '70vh'
        }}
      >
        <DataGrid
          hideFooter
          loading={loading}
          rows={verifications || []}
          sx={{
            backgroundColor: 'utility.backgroundSubtle'
          }}
          columns={[{
            field: 'status',
            headerName: 'Status',
            width: 150,
            renderCell: ({ row }) => {
              return (
                <Chip
                  label={row.status}
                />
              );
            }
          }, {
            field: 'principal',
            headerName: 'Client Type',
            flex: 1,
            minWidth: 150,
            renderCell: ({ value }) => {
              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {value}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: 'client',
            headerName: 'Client',
            flex: 6,
            renderCell: ({ row }) => {
              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {row.client?.name}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: 'jurisdiction',
            headerName: 'Jurisdiction',
            flex: 2,
            renderCell: ({ row }) => {
              const country = row.client?.jurisdiction as Country;

              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {country !== Country.Unknown ? (
                      `${countries[country].emoji} ${countries[country].name}`
                    ) : (
                      'Unknown'
                    )}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: 'tier',
            headerName: 'Verification Tier',
            flex: 1,
            minWidth: 350,
            renderCell: ({ row }) => {
              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {row.tier.match(/[A-Z][a-z]+/g)?.join(' ')}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: 'vendor',
            headerName: 'Vendor',
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => {
              return (
                <React.Fragment>
                  <Typography
                    sx={{
                      marginLeft: '5px'
                    }}
                  >
                    {row.vendor}
                  </Typography>
                </React.Fragment>
              );
            }
          }, {
            field: '',
            headerName: ' ',
            width: 120,
            disableColumnMenu: true,
            sortable: false,
            renderCell: ({ row }) => (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around'
                }}
              >
                <Tooltip title='Open in SumSub'>
                  <IconButton
                    component='a'
                    target='_blank'
                    href={`https://cockpit.sumsub.com/checkus#/applicant/${row.vendorApplicantId}/basicInfo`}
                  >
                    <OpenIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title='Start assessment based on that verification'>
                  <IconButton onClick={onCreateAssessment(row.id)}>
                    <MagnifyingGlassIcon />
                  </IconButton>
                </Tooltip>

                {!row.isArchived && (
                  <Tooltip title='Refresh the verification'>
                    <IconButton onClick={onRefreshVerification(row.id)}>
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )
          }]}
        />
      </Box>
    </Box>
  );
};
