import React from 'react';
import { Route } from 'react-router-dom';
import { AccountsListPage } from './Accounts/AccountsListPage';
import { CollateralisationOverviewPage } from '@backed-fi/admin/src/app/domain/FinOps/Collateralisation/CollateralisationOverviewPage';
import { WorkingCapitalPage } from '@backed-fi/admin/src/app/domain/FinOps/WorkingCapital/WorkingCapitalPage';
import { AccountDetailsPage } from './Accounts/AccountDetailsPage';

export const CustodyRoutes = (
  <Route path="finops">

    <Route
      path="accounts"
    >
      <Route
        index
        path="list"
        element={<AccountsListPage />}
      />
      <Route
        index
        path=":id"
        element={<AccountDetailsPage />}
      />
    </Route>

    <Route
      index
      path='collateralisation'
      element={<CollateralisationOverviewPage />}
    />


    <Route
      path='capital'
      element={<WorkingCapitalPage />}
    />
  </Route>
);
