import React from 'react';

import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Chip, Typography } from '@mui/material';

import { TokenDeploymentsQuery, TokenDeploymentStatus, useTokenDeploymentsQuery } from '@backed-fi/graphql';

const GraphQl = gql`
  query tokenDeployments($tokenId: ID!) {
    tokenDeployments(
      where: {
        tokenId: $tokenId
      }
    ) {
      id

      deploymentStatus

      address
      network
    }
  }
`;

export const TokenDeploymentsPage: React.FC = () => {
  // ---- Hooks ---- //
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  // ---- State ---- //
  const [poll, setPoll] = React.useState(false);

  // ---- Networking ---- //
  const { data, refetch, loading } = useTokenDeploymentsQuery({
    variables: {
      tokenId: params.id!
    },
    pollInterval: poll ? 5000 : undefined
  });

  // ---- Effects ---- //
  React.useEffect(() => {
    // Sometimes, after reload we get the cached version. In most
    // cases this is fine, but is problematic when we go here from
    // the creation page. So refresh it just in case, so that the
    // new network is present
    (async () => {
      await refetch();
    })();
  }, [refetch]);

  React.useEffect(() => {
    if (
      data &&
      data.tokenDeployments.some(x =>
        x.deploymentStatus === TokenDeploymentStatus.Created ||
        x.deploymentStatus === TokenDeploymentStatus.Deploying ||
        x.deploymentStatus === TokenDeploymentStatus.Validating
      )
    ) {
      setPoll(true);
    } else {
      setPoll(false);
    }
  }, [data]);

  // ---- Actions ---- //
  const onTokenDeploymentCreated = async () => {
    await refetch();
  };

  // ---- Destructuring ---- //
  const { tokenDeployments } = (data || {}) as TokenDeploymentsQuery;

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      <Box>
        <Button
          sx={{
            margin: '1rem 0',
            float: 'right'
          }}
          onClick={() => {
            navigate(location.pathname + '/deploy');
          }}
        >
          Deploy on new network
        </Button>
      </Box>

      <Box
        sx={{
          flex: 1
        }}
      >
        <DataGrid
          hideFooter
          loading={loading}
          rows={tokenDeployments || []}
          columns={[{
            field: 'address',
            headerName: 'Address',
            flex: 4,
            renderCell: ({ row }) => (
              <Typography>
                {row.address || 'There is no contract address'}
              </Typography>
            )
          }, {
            field: 'network',
            headerName: 'Network',
            flex: 1
          }, {
            field: 'deploymentStatus',
            headerName: 'Deployment Status',
            flex: 1,
            renderCell: ({ row }) => {
              return (
                <Chip
                  label={row.deploymentStatus}
                  color={
                    row.deploymentStatus === TokenDeploymentStatus.Unsuccessful
                      ? 'error'
                      : 'primary'
                  }
                />
              );
            }

          }]}
          componentsProps={{
            toolbar: {
              tokenId: params.id,
              onTokenDeploymentCreated
            }
          }}

          sx={{
            backgroundColor: 'utility.backgroundSubtle'
          }}
        />
      </Box>
    </Box>
  );
};
