import React from 'react';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { useFunMode } from '@backed-fi/hooks';
import { PageHeading } from '@backed-fi/compound';
import { useDarkMode } from '@backed-fi/shared/hooks/useDarkMode';

export const ExperimentalSettings: React.FC = () => {
  const funMode = useFunMode();
  const darkMode = useDarkMode();

  return (
    <Box>
      <PageHeading
        title="Experimental Settings"
      />

      <Box>
        <FormControlLabel
          label="Fun Mode"
          control={
            <Switch
              checked={funMode.enabled ?? false}
              onChange={funMode.toggle}
            />
          }
        />
      </Box>

      <Box>
        <FormControlLabel
          label="Dark Mode"
          control={
            <Switch
              checked={darkMode.enabled ?? false}
              onChange={darkMode.toggle}
            />
          }
        />
      </Box>
    </Box>
  );
};
