import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tooltip, Typography } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { useGenerateSystemWalletMutation, useSystemWalletsListQuery } from '@backed-fi/graphql';

gql`
  query systemWalletsList {
    systemWallets {
      id

      address

      title
      description
    }
  }

  mutation generateSystemWallet {
    createSystemWallet {
      id
    }
  }
`;

export const SystemWalletsListPage: React.FC = () => {
  const snackbar = useSnackbar();

  // ---- State ---- //
  const [generating, setGenerating] = React.useState(false);

  // ---- Networking ---- //
  const {
    data,
    loading,
    refetch
  } = useSystemWalletsListQuery();
  const [generateSystemWallet] = useGenerateSystemWalletMutation();

  // ---- Actions ---- //
  const generateWallet = async () => {
    setGenerating(true);

    await generateSystemWallet();

    snackbar.enqueueSnackbar('Wallet was successfully generated', {
      variant: 'success'
    });

    await refetch();

    setGenerating(false);
  };


  // ---- Data destructuring ---- //
  const { systemWallets } = data || {};

  return (
    <Box
      sx={{
        height: 'calc(100vh - 3rem)',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          flex: 1,
          mb: '2rem',
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <PageHeading
          title="System Wallets"
          breadcrumbs={[{
            text: 'System Wallets'
          }]}
        >
          <Box>
            <LoadingButton
              loading={generating}
              onClick={generateWallet}
            >
              Create System Wallet
            </LoadingButton>
          </Box>
        </PageHeading>

        <Box
          sx={{
            flex: 1
          }}
        >
          <DataGrid
            loading={loading}
            rows={systemWallets || []}
            columns={[{
              flex: 2,
              field: 'title',
              headerName: 'Title',
              renderCell: ({ row }) => (
                <Typography>
                  {row.title}
                </Typography>
              )
            }, {
              width: 400,
              field: 'address',
              headerName: 'Address'
            }]}
            sx={{
              backgroundColor: 'utility.backgroundSubtle'
            }}
          />
        </Box>
      </Box>
    </Box>
  );

};
