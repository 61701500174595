import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { z } from 'zod';
import { Box, Checkbox, Chip, FormControlLabel, TextField, Typography } from '@mui/material';
import { Page } from '@backed-fi/compound';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';
import { RiskLevel, useDetailedRiskAssessmentQuery, useFileRiskAssessmentMutation, useUpdateRiskAssessmentMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { HiddenRiskLevels } from '@backed-fi/admin/src/app/domain/Compliance/Assessments/_configuration';
import { ClientClassificationData } from './components/ClientClassificationData';

const schema = z.object({
  notes: z.string()
    .optional(),

  isBusinessActivityInNonServicedCountry: z.boolean()
    .optional(),
  isBusinessActivityInNonServicedCountryNotes: z.string()
    .optional(),
  isDomicileNonServicedCountryBased: z.boolean()
    .optional(),
  isDomicileNonServicedCountryBasedNotes: z.string()
    .optional(),

  isPoliticallyExposed: z.boolean()
    .optional(),
  isPoliticallyExposedNotes: z.string()
    .optional(),
  isDomiciledInMonitoredTerritory: z.boolean()
    .optional(),
  isDomiciledInMonitoredTerritoryNotes: z.string()
    .optional(),
  isUnderInvestigation: z.boolean()
    .optional(),
  isUnderInvestigationNotes: z.string()
    .optional(),
  isHighRiskForOtherReasons: z.boolean()
    .optional(),
  isHighRiskForOtherReasonsNotes: z.string()
    .optional(),

  isDomiciledInHighRiskCountry: z.boolean()
    .optional(),
  isDomiciledInHighRiskCountryNotes: z.string()
    .optional(),
  isBusinessActivityInHighRiskCountry: z.boolean()
    .optional(),
  isBusinessActivityInHighRiskCountryNotes: z.string()
    .optional(),
  isBusinessActivityInHighRiskSector: z.boolean()
    .optional(),
  isBusinessActivityInHighRiskSectorNotes: z.string()
    .optional(),
  isBusinessActivityStructureComplex: z.boolean()
    .optional(),
  isBusinessActivityStructureComplexNotes: z.string()
    .optional(),
  isFrequentTransactor: z.boolean()
    .optional(),
  isFrequentTransactorNotes: z.string()
    .optional(),
  isHighWealth: z.boolean()
    .optional(),
  isHighWealthNotes: z.string()
    .optional(),

  concludedRiskLevel: z.nativeEnum(RiskLevel)
});

const GetDetailedRiskAssessment = gql`
  query DetailedRiskAssessment($id: ID!) {
    riskAssessment(id: $id) {
      id

      notes

      isBusinessActivityInNonServicedCountry
      isBusinessActivityInNonServicedCountryNotes

      isDomicileNonServicedCountryBased
      isDomicileNonServicedCountryBasedNotes



      isPoliticallyExposed
      isPoliticallyExposedNotes

      isDomiciledInMonitoredTerritory
      isDomiciledInMonitoredTerritoryNotes

      isUnderInvestigation
      isUnderInvestigationNotes

      isHighRiskForOtherReasons
      isHighRiskForOtherReasonsNotes

      isDomiciledInHighRiskCountry
      isDomiciledInHighRiskCountryNotes

      isBusinessActivityInHighRiskCountry
      isBusinessActivityInHighRiskCountryNotes

      isBusinessActivityInHighRiskSector
      isBusinessActivityInHighRiskSectorNotes

      isBusinessActivityStructureComplex
      isBusinessActivityStructureComplexNotes

      isFrequentTransactor
      isFrequentTransactorNotes

      isHighWealth
      isHighWealthNotes


      availableChecks {
        name
        reject
        displayName
        description
        riskFactor
      }

      verification {
        clientType

        vendorApplicantId

        client {
          id
          name
        }
      }
    }
  }
`;

const UpdateRiskAssessmentMutation = gql`
  mutation UpdateRiskAssessment($input: UpdateRiskAssessmentInput!) {
    updateRiskAssessment(input: $input) {
      id
    }
  }
`;

const FileAssessmentMutation = gql`
  mutation FileRiskAssessment($id: ID!, $concludedRiskLevel: RiskLevel!) {
    fileRiskAssessment(id: $id, concludedRiskLevel: $concludedRiskLevel) {
      status
    }
  }
`;


export const FileRiskAssessmentPage: React.FC = () => {
  // ---- General hooks ---- //
  const params = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- State ---- //
  const riskAssesmentId = params['assessmentId'] as string;
  const [action, setAction] = React.useState<'saving' | 'filling' | 'rejecting' | undefined>();
  // ---- Networking ---- //

  const [fileAssessment] = useFileRiskAssessmentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'clientCompliance'
    ]
  });

  const [updateAssessment] = useUpdateRiskAssessmentMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'clientCompliance'
    ]
  });


  const {
    data,
    loading
  } = useDetailedRiskAssessmentQuery({
    variables: {
      id: riskAssesmentId
    }
  });

  // ---- Form management ---- //

  const {
    register,
    ...form
  } = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  // ---- Effects ---- //

  React.useEffect(() => {
    if (data) {
      const reset = {};

      data.riskAssessment.availableChecks.map((check) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reset[check.name] = data.riskAssessment[check.name];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        reset[`${check.name}Notes`] = data.riskAssessment[`${check.name}Notes`];
      });

      form.reset(reset);
    }
  }, [data]);

  // ---- Actions ---- //

  const onSaveAssessmentChanges = async () => {
    setAction('saving');

    try {
      const {
        concludedRiskLevel,
        ...formData
      } = form.getValues();

      await updateAssessment({
        variables: {
          input: {
            ...formData,
            id: params['assessmentId'] as string
          }
        }
      });

      snackbar.enqueueSnackbar('Assessment successfully saved!');
    } catch (e: any) {
      const reference = e.graphQLErrors[0]?.extensions?.reference || '';

      snackbar.enqueueSnackbar(`An error occurred saving the assessment. ${reference}`, {
        variant: 'error'
      });
    }

    setAction(undefined);
  };

  const onFileAssessment = (reject = false) => {
    return async () => {
      setAction(reject ? 'rejecting' : 'filling');

      try {
        const {
          concludedRiskLevel,
          ...formData
        } = form.getValues();

        // Make sure that the assessment is up-to-date
        await updateAssessment({
          variables: {
            input: {
              ...formData,
              id: params['assessmentId'] as string
            }
          }
        });

        // The assessment data is up-to-date, file it
        await fileAssessment({
          variables: {
            id: params['assessmentId'] as string,
            concludedRiskLevel
          }
        });

        snackbar.enqueueSnackbar('Assessment successfully filled!', {
          variant: 'success'
        });

        // Go to the previous page
        navigate(-1);
      } catch (e: any) {
        const reference = e.graphQLErrors[0]?.extensions?.reference || '';

        snackbar.enqueueSnackbar(`An error occurred filling the assessment. ${reference}`, {
          variant: 'error'
        });
      }

      setAction(undefined);
    };
  };

  return (
    <Page>
      <Box
        sx={{
          margin: '2rem 0'
        }}
      >
        <Typography variant="title">
          File risk assessment for{' '}

          <Typography
            variant="title"
            sx={{
              cursor: 'pointer',
              display: 'inline',
              color: 'primary.textLowContrast'
            }}
            onClick={() => {
              navigate(`/clients/${data?.riskAssessment?.verification?.client?.id}/details/compliance`);
            }}
          >
            {data?.riskAssessment?.verification?.client?.name}
          </Typography>
        </Typography>
      </Box>

      {/* Form */}

      {loading && (
        <div>
          Loading
        </div>
      )}

      {data && (
        <React.Fragment>
          <ClientClassificationData
            riskAssessmentId={riskAssesmentId}
          />

          {data?.riskAssessment && (
            [...data.riskAssessment.availableChecks]
              .sort((a, b) => b.riskFactor + (b.reject ? 10000 : 0) - (a.riskFactor + (a.reject ? 10000 : 0)))
              .map((check) => (
                <Box
                  key={check.name}
                  sx={{
                    margin: '1em 0'
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex'
                    }}
                  >
                    <Typography
                      variant="sectionTitle"
                      sx={{
                        marginBottom: 0,
                        textTransform: 'capitalize'
                      }}
                    >
                      {check.displayName}
                    </Typography>

                    <Chip
                      sx={{
                        marginLeft: '1rem'
                      }}

                      color={
                        check.reject
                          ? 'secondary'
                          : check.riskFactor >= 51
                            ? 'error'
                            : 'warning'
                      }

                      label={
                        check.reject
                          ? 'Excludes'
                          : check.riskFactor >= 51
                            ? 'Red Card'
                            : 'Yellow card'
                      }
                    />
                  </Box>

                  <FormControlLabel
                    sx={{
                      marginLeft: '0',
                      '.MuiFormControlLabel-label': {
                        paddingLeft: '8px'
                      }
                    }}
                    control={
                      <Checkbox
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        defaultChecked={data.riskAssessment[check.name]}
                        {...register(check.name as any)}
                      />
                    }
                    label={
                      check.description
                        ? check.description
                        : `Do you consider the principal of being ${check.displayName.toLocaleLowerCase()}?`
                    }
                  />

                  <Typography
                    children="Notes"
                  />

                  <TextField
                    rows={3}
                    multiline
                    fullWidth
                    placeholder="Any notes you may have should live here."
                    {...register(`${check.name}Notes` as any)}
                  />
                </Box>
              ))
          )}

          <Typography
            mb="0"
            mt="2rem"
            variant="sectionTitle"
          >
            Concluded Risk Level
          </Typography>

          <TextField
            select
            fullWidth
            SelectProps={{
              native: true
            }}
            {...register('concludedRiskLevel')}
          >
            {Object.keys(RiskLevel)
              .map((riskLevel) => (
                HiddenRiskLevels.includes(riskLevel as RiskLevel)
                  ? (
                    <React.Fragment />
                  ) : (
                    <option value={riskLevel} key={riskLevel}>
                      {riskLevel.replace(/(?=[A-Z])/g, ' ')}
                    </option>
                  )
              ))
            }
          </TextField>

          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '2em 0'
            }}
          >
            <LoadingButton
              color="secondary"
              disabled={!!action}
              loading={action === 'saving'}
              onClick={onSaveAssessmentChanges}
            >
              Save progress
            </LoadingButton>

            <LoadingButton
              disabled={!!action}
              onClick={onFileAssessment(false)}
              loading={action === 'filling'}
              sx={{
                marginLeft: '1em'
              }}
            >
              File assessment
            </LoadingButton>
          </Box>
        </React.Fragment>
      )}
    </Page>
  );
};
