import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';

export const useDownloadCurrentDocument = () => {
  const documentContext = useDocumentContext();

  return async () => {
    if (!documentContext.document) {
      throw new Error('No document present');
    }

    if (!documentContext.document.fetched) {
      await documentContext.document.fetch();
    }

    // This is only to fix the types downwards
    if (!documentContext.document.fetched) {
      return;
    }

    const link = document.createElement('a');

    link.href = documentContext.document.url!;

    link.click();
  };
};
