import React from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useClientUsersQuery, useRemoveUserPasswordMutation } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { AddClientUserDialog } from '@backed-fi/admin/src/app/domain/Clients/pages/components/AddClientUserDialog';
import { useToggle } from '@backed-fi/hooks';
import RemovePasswordIcon from '@mui/icons-material/KeyOffOutlined';
import { useSnackbar } from 'notistack';

// region Graph

gql`
  query clientUsers($clientId: ID!) {
    client(clientId: $clientId) {
      users {
        id

        firstName
        lastName

        email

        hasPasswordSet
      }
    }
  }

  mutation removeUserPassword($input: RemoveUserPasswordInput!) {
    removeUserPassword(input: $input)
  }
`;

// endregion

export const ClientUsersPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const addUserDialog = useToggle();
  const snackbar = useSnackbar();

  // region Networking

  const [removeUserPasswordMutation, { loading }] = useRemoveUserPasswordMutation();
  const query = useClientUsersQuery({
    variables: {
      clientId: params.id!
    }
  });

  // endregion

  // region Actions

  const onRemovePassword = (userId: string) => async () => {
    await removeUserPasswordMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'clientUsers'
      ],
      variables: {
        input: {
          userId
        }
      }
    });

    snackbar.enqueueSnackbar('User password successfully removed', {
      variant: 'success'
    });
  };

  // endregion

  return (
    <Box>
      <AddClientUserDialog
        {...addUserDialog}
        onCreatedRefetch={[
          'clientUsers'
        ]}
      />

      <Box
        sx={{
          mb: '.5rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button onClick={addUserDialog.setTrue}>
          Add User
        </Button>
      </Box>

      <DataGrid
        autoHeight
        rows={query.data?.client?.users || []}
        columns={[{
          flex: 3,
          field: 'firstName',
          headerName: 'Full Name',
          renderCell: ({ row }) => (
            <Typography>
              {row.firstName} {row.lastName}
            </Typography>
          )
        }, {
          flex: 5,
          field: 'email',
          headerName: 'User Email',
          renderCell: ({ row }) => (
            <Typography>
              {row.email}
            </Typography>
          )
        }, {
          width: 100,
          field: 'id',
          headerName: '',
          renderCell: ({ row }) => (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {row.hasPasswordSet && (
                <Tooltip title="Remove the password of that user">
                  <IconButton
                    disabled={loading}
                    onClick={onRemovePassword(row.id)}
                  >
                    <RemovePasswordIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          )
        }]}
      />
    </Box>
  );
};
