import { ethers } from 'ethers';

export enum OperationType {
  Call,
  DelegateCall
}

export interface MetaTransactionData {
  to: string;
  value: string;
  data: string;
}

export interface SafeTransactionData extends MetaTransactionData {
  operation: OperationType;
  safeTxGas: string;
  baseGas: string;
  gasPrice: string;
  gasToken: string;
  refundReceiver: string;
  nonce: number;
}

interface Payload {
  safeAddress: string;
  chainId: any;
  safeTransactionData: SafeTransactionData;
}

export const signTransaction = async (
  signer: ethers.providers.JsonRpcSigner,
  payload: Payload
): Promise<string> => {
  const {
    safeAddress,
    chainId,
    safeTransactionData
  } = payload;

  const EIP712_DOMAIN = [
    {
      type: 'uint256',
      name: 'chainId'
    },
    {
      type: 'address',
      name: 'verifyingContract'
    }
  ];

  const EIP712_TYPES = {
    EIP712Domain: EIP712_DOMAIN,
    SafeTx: [
      {
        type: 'address',
        name: 'to'
      },
      {
        type: 'uint256',
        name: 'value'
      },
      {
        type: 'bytes',
        name: 'data'
      },
      {
        type: 'uint8',
        name: 'operation'
      },
      {
        type: 'uint256',
        name: 'safeTxGas'
      },
      {
        type: 'uint256',
        name: 'baseGas'
      },
      {
        type: 'uint256',
        name: 'gasPrice'
      },
      {
        type: 'address',
        name: 'gasToken'
      },
      {
        type: 'address',
        name: 'refundReceiver'
      },
      {
        type: 'uint256',
        name: 'nonce'
      }
    ]
  };


  const typedData = {
    types: EIP712_TYPES,
    domain: {
      chainId,
      verifyingContract: safeAddress
    },
    primaryType: 'SafeTx',
    message: safeTransactionData
  };

  const signature = await (signer.provider as ethers.providers.JsonRpcProvider)!.send('eth_signTypedData_v4', [
    (await signer.getAddress()).toLowerCase(),
    JSON.stringify(typedData)
  ]);

  const ETHEREUM_V_VALUES = [0, 1, 27, 28];
  const MIN_VALID_V_VALUE_FOR_SAFE_ECDSA = 27;
  let signatureV = parseInt(signature.slice(-2), 16);

  if (!ETHEREUM_V_VALUES.includes(signatureV)) {
    throw new Error('Invalid signature');
  }

  if (signatureV < MIN_VALID_V_VALUE_FOR_SAFE_ECDSA) {
    signatureV += MIN_VALID_V_VALUE_FOR_SAFE_ECDSA;
  }

  const adjustedSignature = signature.slice(0, -2) + signatureV.toString(16);

  return adjustedSignature;
};
