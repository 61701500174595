import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { useCreateFolderMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { useConfetti } from '@backed-fi/hooks';

export const useCreateNewFolder = () => {
  const snackbar = useSnackbar();
  const confetti = useConfetti();
  const { folder } = useDocumentContext();

  const [createNewFolderMutation] = useCreateFolderMutation();

  return async () => {
    try {
      await createNewFolderMutation({
        variables: {
          input: {
            parentId: folder?.id!,
            path: 'New Folder'
          }
        }
      });

      await folder?.fetch();

      snackbar.enqueueSnackbar('The folder was successfully created', {
        variant: 'success'
      });

      await confetti.emitConfetti();
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred creating the new folder', {
        variant: 'error'
      });
    }
  };
};
