import { useDeleteFolderMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { useConfetti } from '@backed-fi/hooks';

export const useDeleteFolder = () => {
  const snackbar = useSnackbar();
  const confetti = useConfetti();
  const mutation = useDeleteFolderMutation();
  const { folderId } = useDocumentContext();

  return async () => {
    if (!folderId) {
      throw new Error('Not in folder context');
    }

    try {
      await mutation[0]({
        awaitRefetchQueries: true,
        refetchQueries: [
          'folder'
        ],
        variables: {
          input: {
            folderId
          }
        }
      });

      snackbar.enqueueSnackbar('Folder deleted', {
        variant: 'success'
      });

      await confetti.emitConfetti({
        emojis: [
          '😭',
          '💔'
        ],
        emojiSize: 48
      });
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred', {
        variant: 'error'
      });
    }
  };
};
