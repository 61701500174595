import React from 'react';

import { z } from 'zod';
import { Title } from '@backed-fi/compound';
import { Box, Dialog, DialogContent, DialogProps, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useRegisterFactoryMutation } from '@backed-fi/graphql';

// region Schema

const Schema = z.object({
  address: z.string()
    .regex(/^0x[a-fA-F0-9]{40}$/)
    .transform<string>((address) => address.toLowerCase()),

  description: z.string()
    .min(10)
});

// endregion

// region Graph

gql`
  mutation registerFactory($input: RegisterContractFactoryInput!) {
    registerContractFactory(input: $input) {
      id
    }
  }
`;

// endregion

export const RegisterFactoryDialog: React.FC<DialogProps> = (props) => {
  const snackbar = useSnackbar();

  // region Networking

  const [registerFactoryMutation] = useRegisterFactoryMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'factoriesList'
    ]
  });

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  // endregion

  // region Actions

  const onRegisterFactory = form.handleSubmit(async (data) => {
    try {
      await registerFactoryMutation({
        variables: {
          input: data
        }
      });

      // Reset the state
      form.reset();

      // Dismiss the modal and give feedback
      (props as any).onClose?.();
      snackbar.enqueueSnackbar('Successfully registered factory', {
        variant: 'success'
      });
    } catch (e) {
      console.error(e);
      snackbar.enqueueSnackbar('An error occurred while trying to register the factory', {
        variant: 'error'
      });
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogContent>
        <Title
          title="Register Factory"
          subtitle="Register factory on all system supported networks"
        />

        <Box
          sx={{
            mt: '1rem',
            mb: '2rem'
          }}
        >
          <TextField
            fullWidth
            label="Factory Address"
            error={!!errors?.address}
            helperText={
              errors?.address?.message ??
              'The address will be checked on all networks for existence of the factory contract'
            }
            {...form.register('address')}

          />

          <TextField
            fullWidth
            multiline
            rows={4}
            label="Factory Description"
            error={!!errors?.description}
            helperText={errors?.description?.message}
            {...form.register('description')}
          />

        </Box>

        <LoadingButton
          loading={isSubmitting}
          onClick={onRegisterFactory}
          sx={{
            float: 'right'
          }}
        >
          Register
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};
