import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { Protector } from '@backed-fi/compound';
import { AdminPermission, BlockchainNetwork, useRegisterBridgeMutation } from '@backed-fi/graphql';

gql`
  mutation RegisterBridge($input: RegisterBridgeInput!) {
    registerBridge(input: $input) {
      id
    }
  }
`;

const Schema = z.object({
  address: z.string(),
  network: z.string()
    .transform<BlockchainNetwork>(x => (x as BlockchainNetwork)),
});

export const RegisterBridgeDialog: React.FC = () => {
  const registerBridgeDialogToggle = useToggle();
  const snackbar = useSnackbar();

  const [registerBridgeMutation] = useRegisterBridgeMutation();

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    isSubmitting
  } = form.formState;

  const onRegister = async (input: z.infer<typeof Schema>) => {
    try {
      await registerBridgeMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'bridges',
        ],
        variables: {
          input
        }
      });
      snackbar.enqueueSnackbar('Successfully registered bridge', { variant: 'success' });

      form.reset();
      registerBridgeDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to register bridge', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Protector permissions={[AdminPermission.BlockchainManage]}>
          <Button
            onClick={() => {
              registerBridgeDialogToggle.toggle();
            }}
          >
            Register bridge
          </Button>
        </Protector>
      </Box>
      {registerBridgeDialogToggle.open && (
        <Dialog
          {...registerBridgeDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Typography variant='title'>
              Register Bridge
            </Typography>
            <TextField
              select
              label='Network'
              {...form.register('network')}
              SelectProps={{
                native: true
              }}
              fullWidth
            >
              <option disabled selected>
                Please select
              </option>

              {Object.keys(BlockchainNetwork).map((network) => {
                return (
                  <option key={network} value={network}>
                    {network}
                  </option>
                );
              })}
            </TextField>

            <TextField
              fullWidth
              label="Address"
              {...form.register('address')}
            />

            <LoadingButton
              loading={isSubmitting}
              onClick={form.handleSubmit(onRegister)}
              sx={{
                mt: '.25rem',
                float: 'right'
              }}
            >
              Register
            </LoadingButton>

          </DialogContent>
        </Dialog>)}
    </>
  );
};
