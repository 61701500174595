import { Box, TextField, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { UsageLimitTier, useGetUsageAndLimitsOfClientQuery, useUpdateClientUsageLimitsTierMutation } from '@backed-fi/graphql';
import React from 'react';

const Graph = gql`
  query GetUsageAndLimitsOfClient($clientId: ID!) {
    client(clientId: $clientId) {
      limits {
        id

        dailyTransactionLimit
        monthlyTransactionLimit

        tier
      }

      usage {
        usages {
          value
          usageOf
        }
      }
    }
  }

  mutation UpdateClientUsageLimitsTier($input: UpdateClientUsageLimitsInput!) {
    updateClientUsageLimits(input: $input) {
      id
    }
  }
`;

export const ClientUsageAndLimitsPage = () => {
  const params = useParams<{ id: string }>();

  const [updateTier, { loading: updating }] = useUpdateClientUsageLimitsTierMutation();
  const { data } = useGetUsageAndLimitsOfClientQuery({
    variables: {
      clientId: params.id!
    }
  });

  // region Actions

  const onUpdateTier = async (event: React.ChangeEvent<HTMLInputElement>) => {
    await updateTier({
      variables: {
        input: {
          clientId: params.id!,
          usageTier: event.target.value as any
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'GetUsageAndLimitsOfClient'
      ]
    });
  };

  // endregion

  return (
    <Box>
      {data?.client && (
        <TextField
          select
          disabled={updating}
          value={data?.client.limits.tier}
          onChange={onUpdateTier}
          label='Current Limits Tier'
          SelectProps={{
            native: true
          }}
        >
          {Object.keys(UsageLimitTier)
            .map((tier) => (
              <option value={tier} key={tier}>
                {tier}
              </option>
            ))
          }
        </TextField>
      )}


      <Box>
        <Typography variant='code'>
          {JSON.stringify(data ?? {}, null, 4)}
        </Typography>
      </Box>
    </Box>
  );
};
