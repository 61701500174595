import React from 'react';
import { Box } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { IncomingTransactionsTable } from '../components/IncomingTransactionsTable';

export const IncomingTransactionsListPage = () => {
  return (
    <Box
      sx={{
        height: '500px'
      }}
    >
      <PageHeading
        title='Incoming Transactions'
        breadcrumbs={[{
          text: 'Blockchain'
        }, {
          text: 'Incoming Transactions'
        }]}
      />

      <IncomingTransactionsTable />
    </Box>
  );
};
