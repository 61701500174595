import React from 'react';
import { Box, Button } from '@mui/material';
import { DeploymentsTable } from '@backed-fi/admin/src/app/domain/Deployment/components/DeploymentsTable';
import { useNavigate, useParams } from 'react-router-dom';

export const TokenDeploymentsListPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  return (
    <Box>
      <Box
        sx={{
          mb: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          onClick={() => {
            navigate(`/internal/deployments/create?tokenId=${params.id}`);
          }}
        >
          Start new deployment
        </Button>
      </Box>

      <DeploymentsTable
        where={{
          tokenId: params.id
        }}
      />
    </Box>
  );
};
