import React from 'react';
import { Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { CustodyAccountFacetType, useAccountsListPageQuery, useCreateCustodyAccountFacetMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
}

// endregion

// region Form Schema

const Schema = z.object({
  accountId: z.string(),
  type: z.enum([CustodyAccountFacetType.Fiat, CustodyAccountFacetType.Security]),
  label: z.string(),
  symbol: z.string()
});

// endregion

// region Graph

gql`
  mutation createCustodyAccountFacet($input: CreateCustodyAccountFacetInput!) {
    createCustodyAccountFacet(input: $input) {
      id
    }
  }
`;

// endregion

export const CreateCustodyAccountFacetDialog: React.FC<Props> = ({ ...props }) => {
  // region Networking

  const [createCustodyAccountFacetMutation] = useCreateCustodyAccountFacetMutation();
  const { data } = useAccountsListPageQuery();

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onRegister = form.handleSubmit(async (data) => {
    await createCustodyAccountFacetMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'AccountsListPage'
      ],
      variables: {
        input: {
          ...data
        }
      }
    });

    form.reset();

    if (typeof props.onClose === 'function') {
      (props as any).onClose();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Create new custody account facet
        </Typography>

        <Typography variant='subtitleSmall' mb='1rem'>
          Assign new custody account facet to given incore account
        </Typography>

        <TextField
          fullWidth
          label='Symbol'
          {...form.register('symbol')}
        />

        <TextField
          fullWidth
          label='Label'
          {...form.register('label')}
        />

        <TextField
          select
          fullWidth
          label='Type'
          {...form.register('type')}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {Object.values(CustodyAccountFacetType).map((x) => {
            return (
              <option key={x} value={x}>
                {x}
              </option>
            );
          })}
        </TextField>
        <TextField
          select
          fullWidth
          label='Account'
          {...form.register('accountId')}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {data?.custodyAccounts.map((x) => {
            return (
              <option key={x.id} value={x.id}>
                {x.externalId}
              </option>
            );
          })}
        </TextField>

        <LoadingButton
          loading={isSubmitting}
          onClick={onRegister}
          sx={{
            mt: '.25rem',
            float: 'right'
          }}
        >
          Register
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};
