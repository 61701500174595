import React from 'react';
import { Box } from '@mui/material';

import { _export } from '@backed-fi/admin/src/app/components/CommandMenu/targets/_export';
import { MenuCommandSearchResult } from '@backed-fi/admin/src/app/components/CommandMenu/types';
import { CommandMenuItem } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuItem';
import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';
import { useCommandMenuData } from '@backed-fi/admin/src/app/components/CommandMenu/hooks/useCommandMenuData';

// region Props

interface Props {
}

// endregion

export const CommandMenuItems: React.FC<Props> = () => {
  const context = useCommandMenuContext();
  const searchData = useCommandMenuData();

  const [items, setItems] = React.useState<MenuCommandSearchResult[]>([]);

  // region Actions

  const onMoveFocusedItem = (direction: 'forwards' | 'backwards' | 'reset' = 'forwards') => {
    context.setFocusedItem((currentItem) => {
      if (!currentItem || direction === 'reset') {
        return items[0];
      } else {
        if (!currentItem) {
          console.warn('Cannot move focused item');

          return undefined;
        }

        const currentIndex = items.findIndex((x) => x.id === currentItem.id) ?? 0;

        let nextIndex = direction === 'forwards'
          ? currentIndex + 1
          : currentIndex - 1;

        // Negative overflow
        if (nextIndex < 0) {
          nextIndex = items.length - 1;
        }

        // Positive overflow
        if (nextIndex > (items.length - 1)) {
          nextIndex = 0;
        }

        return items[nextIndex];
      }
    });
  };


  // endregion

  // Key Listeners
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === 'ArrowDown') {
        e.preventDefault();

        onMoveFocusedItem('forwards');
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();

        onMoveFocusedItem('backwards');
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [items]);

  React.useEffect(() => {
    if (!context.currentTarget) {
      setItems(
        _export
          .filter((value) => {
            return value.keywords
              .some((keyword) => {
                return keyword.includes(context.filter.toLowerCase());
              });
          })
          .map((target) => ({
            id: target.name,
            Icon: target.Icon,
            title: target.name,
            subtitle: target.description,
            onClick: () => {
              context.setCurrentTarget(target);

              return true;
            }
          }))
      );
    } else {
      if (searchData.formattedData) {
        setItems(searchData.formattedData);
      } else {
        setItems([]);
      }
    }
  }, [context.currentTarget, context.results, context.filter, searchData]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        gap: '.5rem',
        padding: '1rem'
      }}
    >
      {/* region Nothing Found State */}

      {(!searchData.fetching && searchData.fetched && !searchData.formattedData?.length) && (
        <Box>
          Nothing to see here
        </Box>
      )}

      {/* endregion */}

      {items.map((item) => (
        <CommandMenuItem
          key={item.id}
          result={item}
        />
      ))}
    </Box>
  );
};
