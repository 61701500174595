import React from 'react';
import { useToggle } from '@backed-fi/hooks';
import { Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { Title } from '@backed-fi/compound';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';

// region Props

interface Props {
  type: string;
  factoryDeployments: {
    ownership: string,
    network: string
  }[];
}

// endregion

export const OwnershipDetailsDialog: React.FC<Props> = ({ type, factoryDeployments }) => {
  const toggle = useToggle();

  return (
    <React.Fragment>
      <IconButton onClick={toggle.setTrue}>
        <Tooltip title='View ownership details'>
          <PersonSearchRoundedIcon />
        </Tooltip>
      </IconButton>

      <Dialog
        fullWidth
        maxWidth='md'
        {...toggle}
      >
        <DialogContent>
          <Title
            title='Factory Ownership'
            subtitle={`Ownership details for factory ${type}`}
          />

          <Typography variant='code'>
            {JSON.stringify(Object.fromEntries(factoryDeployments.map(x => [x.network, x.ownership])), null, 4)}
          </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  )
}
