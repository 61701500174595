import React from 'react';
import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material';
import { PageHeading, Title } from '@backed-fi/compound';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { RoleDetailsQuery, useRoleDetailsQuery, AdminPermission, useUnassignRoleMutation } from '@backed-fi/graphql';

import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CrossIcon from '@mui/icons-material/HighlightOffRounded';
import RemovePersonIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import { useToggle } from '@backed-fi/hooks';
import { AssignRoleDialog } from '@backed-fi/admin/src/app/domain/Internal/Roles/pages/components/AssignRoleDialog';
import { useSnackbar } from 'notistack';

// region Graph

gql`
  query roleDetails($where: RoleWhereUniqueInput!) {
    role(where: $where) {
      id

      name
      description

      permissions

      admins {
        id

        firstName
        lastName
        email
      }
    }
  }

  mutation unassignRole($input: ToggleRoleInput!) {
    toggleRole(input: $input)
  }
`;

// endregion

export const RoleDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const assignToggle = useToggle();

  // region Networking

  const [unassignRole, { loading: unassigning }] = useUnassignRoleMutation();

  const { data } = useRoleDetailsQuery({
    variables: {
      where: {
        id: params.id!
      }
    }
  });

  const { role } = (data ?? {}) as RoleDetailsQuery;

  // endregion

  // region Actions

  const onunassignRole = (adminId: string) => async () => {
    const { data } = await unassignRole({
      awaitRefetchQueries: true,
      refetchQueries: [
        'roleDetails',
        'adminsForRole'
      ],
      variables: {
        input: {
          adminId,
          roleId: params.id!
        }
      }
    });

    snackbar.enqueueSnackbar(`Successfully ${data?.toggleRole ? 'assigned' : 'unsigned'} role`, {
      variant: 'success'
    });
  };

  // endregion


  return (
    <Box>
      <PageHeading
        title="Role Details"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Roles',
            link: '/internal/roles'
          }, {
            text: 'Details'
          }
        ]}
      />

      {role && (
        <React.Fragment>
          <AssignRoleDialog
            {...assignToggle}
            roleId={role.id}
            roleName={role.name}
            filterAdmins={role.admins}
          />

          <Title
            title="Permissions"
            subtitle="The permissions that this role bears"
          />

          <Box
            sx={{
              m: '1rem 2rem'
            }}
          >
            {Object.values(AdminPermission)
              .map((permission) => (
                <Box
                  key={permission}
                  sx={{
                    my: '.5rem',
                    maxWidth: '225px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <Typography>
                    {permission.replace('_', ' ')}
                  </Typography>

                  {role.permissions.includes(permission as any) ? (
                    <CheckIcon
                      color="success"
                    />
                  ) : (
                    <CrossIcon
                      color="error"
                    />
                  )}
                </Box>
              ))
            }
          </Box>

          <Box
            sx={{
              mt: '2rem',
              mb: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Title
                title="Admins"
                subtitle="List of admin users that have this role "
              />
            </Box>

            <Box>
              <Tooltip title="Assign the role to another admin user">
                <Button onClick={assignToggle.setTrue}>
                  Assign
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <DataGrid
            autoHeight
            rows={role.admins}
            columns={[
              {
                flex: 1,
                field: 'firstName',
                minWidth: 300,
                headerName: 'Name',
                renderCell: ({ row }) => (
                  <Box>
                    <Typography>
                      {row.firstName} {row.lastName}
                    </Typography>
                  </Box>
                )
              }, {
                width: 100,
                field: 'id',
                headerName: '',
                renderCell: ({ value }) => (
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Tooltip title="Remove admin from role">
                      <IconButton
                        disabled={unassigning}
                        onClick={onunassignRole(value)}
                      >
                        <RemovePersonIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                )

              }
            ]}
          />

        </React.Fragment>
      )}
    </Box>
  );
};
