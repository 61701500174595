import React from 'react';
import { Route } from 'react-router-dom';

import { AdminsListPage } from '@backed-fi/admin/src/app/domain/Internal/Admins/AdminsListPage';
import { FeesConfigurationOverviewPage } from '@backed-fi/admin/src/app/domain/Internal/Fees/FeesConfigurationOverviewPage';
import { DeploymentPages } from '@backed-fi/admin/src/app/domain/Deployment/DeploymentPages';
import { RolesPages } from '@backed-fi/admin/src/app/domain/Internal/Roles/RolesPages';
import { EventsPages } from '@backed-fi/admin/src/app/domain/Internal/Events/EventsPages';

export const InternalPages = (
  <Route path='internal'>
    <Route
      path='admins/all'
      element={<AdminsListPage />}
    />

    <Route
      path='fees/overview'
      element={<FeesConfigurationOverviewPage />}
    />

    {DeploymentPages}
    {RolesPages}
    {EventsPages}
  </Route>
);
