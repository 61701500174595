import React from 'react';
import { Route } from 'react-router-dom';

import { EventsListPage } from './pages/EventsListPage';
import { EventDetailsPage } from './pages/EventDetailsPage';

export const EventsPages = (
  <Route path='events'>
    <Route
      index
      element={
        <EventsListPage />
      }
    />

    <Route
      path='details/:id'
      element={
        <EventDetailsPage />
      }
    />
  </Route>
);
