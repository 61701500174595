import React from 'react';
import { Avatar, Box, Typography } from '@mui/material';

interface Props {
  contactEmail: string
  name?: string
}

export const ClientInfo: React.FC<Props> = ({ contactEmail, name: clientName }) => {
  const [name, surname] = clientName?.split(' ') || [];

  return (
    <Box
      sx={{
        flex: 1,
        gap: '1rem',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Avatar>
        {name && name[0]}{surname && surname[0]}
      </Avatar>

      <Box
        sx={{
          flex: 1
        }}
      >
        <Typography>
          {name}
        </Typography>

        <Typography variant="label">
          {contactEmail}
        </Typography>
      </Box>
    </Box>
  );
};
