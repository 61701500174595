import React from 'react';
import { Box } from '@mui/material';
import { NavigationButtons } from '../../components/NavigationButtons';
import { BankTransferInjectionDefinition } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/BankTransferInjectionDefinition';

export const InjectBankTransfers: React.FC = () => {
  return (
    <Box>
      <Box>
        <BankTransferInjectionDefinition transactionType="incoming" />
        <BankTransferInjectionDefinition transactionType="outgoing" />
      </Box>

      <Box
        sx={{
          my: '.5rem',
          gap: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <NavigationButtons />
      </Box>
    </Box>
  );
};
