import React from 'react';

import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { useToggle } from '@backed-fi/hooks';
import { Title } from '@backed-fi/compound';
import { z } from 'zod';
import { FiatCurrency } from '@backed-fi/graphql';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useInjectionContext } from '../InjectionContext';
import { BankTransferInjectionSchema } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionSchema';
import { CapitaliseFormatter, CentsFormatter } from '@backed-fi/shared';

// region Props

interface Props {
  transactionType: 'outgoing' | 'incoming';
}

// endregion

export const BankTransferInjectionDefinition: React.FC<Props> = ({ transactionType }) => {
  const toggle = useToggle();
  const context = useInjectionContext();

  const formattedTransactionType = CapitaliseFormatter(transactionType);

  // region From Control

  const baseForm = useForm<z.infer<typeof BankTransferInjectionSchema>>({
    resolver: zodResolver(BankTransferInjectionSchema)
  });

  const { isSubmitting } = baseForm.formState;

  // endregion

  // region Actions

  const onAddTransfer = baseForm.handleSubmit(async (data) => {
    context.setInjectionState((p) => ({
      ...p,
      [transactionType + 'BankTransfers']: [
        ...(p as any)[transactionType + 'BankTransfers'],
        data
      ]
    }));

    toggle.setFalse();
    baseForm.reset({});
  });

  // endregion

  return (
    <Box>
      <Dialog
        {...toggle}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Title
            title="Inject Bank Transfer"
            subtitle="Define new bank transfer for injection"
          />

          <Box
            sx={{
              mt: '1rem',
              gap: '.5rem',
              display: 'flex',
              flexFlow: 'column'
            }}
          >
            <TextField
              fullWidth
              label="Amount"
              {...baseForm.register('amount')}
            />

            <TextField
              select
              label="Currency"
              {...baseForm.register('currency')}
            >
              <option selected disabled>
                Select Currency
              </option>

              {Object.values(FiatCurrency).map((currency) => (
                <option value={currency} key={currency}>
                  {currency}
                </option>
              ))}
            </TextField>

            <LoadingButton
              loading={isSubmitting}
              onClick={onAddTransfer}
            >
              Add Bank Transfer
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>

      <Title
        small
        title={`${formattedTransactionType} Bank Transfers`}
        subtitle={
          'Define all the bank transfers that were sent ' +
          `${
            transactionType === 'incoming'
              ? 'from the client to Backed'
              : 'from Backed to the client'
          }`
        }
      />

      <Box
        sx={{
          mt: '1rem',
          mb: '1rem',
          pb: '1rem',
          borderBottom: '1px solid',
          borderColor: 'utility.borderSubtle'
        }}
      >
        {context[`${transactionType}BankTransfers`].length ? (
          context[`${transactionType}BankTransfers`].map((transfer, index) => (
            <Typography key={index}>
              {formattedTransactionType} Transfer for {CentsFormatter.format(transfer.amount, transfer.currency)}
            </Typography>
          ))
        ) : (
          <Typography>
            No {formattedTransactionType} Bank Transfers Defined
          </Typography>
        )}

        <Box
          sx={{
            mt: '1rem'
          }}
        >
          <Button onClick={toggle.setTrue}>
            Inject {formattedTransactionType} Transfer
          </Button>
        </Box>
      </Box>

    </Box>
  );
};
