import { Box, Dialog, DialogContent, DialogProps, Input, TextField } from '@mui/material';
import React from 'react';
import { Title } from '@backed-fi/compound';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { LoadingButton } from '@mui/lab';
import { useInputState } from '@backed-fi/hooks';
import { useUpdateDocumentMutation, useUpdateFolderMutation } from '@backed-fi/graphql';

// region Props

interface Props extends DialogProps {
}

// endregion

export const RenameDialog: React.FC<Props> = (props) => {
  const documentContext = useDocumentContext();

  const [updateFolderMutation] = useUpdateFolderMutation();
  const [updateDocumentMutation] = useUpdateDocumentMutation();

  const inputRef = React.useRef<HTMLInputElement>();

  const {
    value: name,
    inputProps
  } = useInputState(
    documentContext.isFolder
      ? documentContext.folder?.name
      : documentContext.document?.name
  );

  const {
    value: description,
    inputProps: descriptionInputProps
  } = useInputState(documentContext.folder?.description!);

  // region Actions

  const onRename = async () => {
    try {
      if (documentContext.isFolder) {
        await updateFolderMutation({
          variables: {
            input: {
              folderId: documentContext.folderId!,

              changeNameTo: name,
              changeDescriptionTo: description
            }
          }
        });

        await documentContext.fetchFolder();
      }

      if (documentContext.isDocument) {
        await updateDocumentMutation({
          variables: {
            input: {
              documentId: documentContext.documentId!,

              changeNameTo: name
            }
          }
        });

        await documentContext.document?.fetch();
      }
    } finally {
      if (typeof props.onClose === 'function') {
        (props.onClose as any)();
      }
    }
  };


  // endregion

  // region Effects

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current?.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  // Listen for shortcuts
  React.useEffect(() => {
    const down = async (e: KeyboardEvent) => {
      if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();

        await onRename();
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [onRename]);


  // endregion

  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Title
          title={`Rename ${(documentContext.isFolder ? 'Folder' : 'Document')}`}
        />

        <TextField
          fullWidth
          size="small"
          inputRef={inputRef}
          label={documentContext.isFolder ? 'Name' : undefined}
          {...inputProps}
        />

        {documentContext.isFolder && (
          <TextField
            fullWidth
            multiline
            rows={3}
            label="Description"
            {...descriptionInputProps}
          />
        )}

        <Box
          sx={{
            mt: '.5rem',
            gap: '.5rem',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton color="secondary" onClick={props.onClose as any}>
            Cancel
          </LoadingButton>

          <LoadingButton onClick={onRename}>
            Rename
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
