import React from 'react';

import { z } from 'zod';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Breadcrumbs, Link, TextField, Typography, useTheme } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { NavigationButton, Protector } from '@backed-fi/compound';
import { useAvailableCollateralQuery, useCreateCollateralMutation, useCreateTokenMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

const AssetSchema = z.object({
  symbol: z.string()
    .nonempty(),
  ISINName: z.string()
    .nonempty(),
  ISINNumber: z.string()
    .nonempty(),
  incoreClientDepositNumber: z.string()
    .nonempty(),
  tradingViewId: z.string()
    .nonempty()
});

const TokenSchema = z.object({
  collateralId: z.string()
    .optional(),

  name: z.string()
    .nonempty(),

  description: z.string()
    .nonempty()
    .min(10)
    .max(128),

  tokenSymbol: z.string()
    .nonempty(),

  constantRatio: z.boolean(),

  accrualSchedule: z.preprocess(value => Number(value), z.number().int()).nullable().optional(),

  annualFee: z.preprocess(value => Number(value), z.number().int()).nullable().optional(),

  initialFeeAccrualTimestamp: z.date().nullable().optional()
});

const GraphQL = gql`
  # // ---- Queries ---- // #
  query AvailableCollateral {
    collaterals {
      id

      symbol
    }
  }

  # // ---- Mutations ---- // #
  mutation CreateCollateral($input: CreateCollateralInput!) {
    createCollateral(input: $input) {
      id
    }
  }

  mutation CreateToken($input: CreateTokenInput!) {
    createToken(input: $input) {
      id
    }
  }
`;

export const CreateTokenPage: React.FC = () => {
  const { palette } = useTheme();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- State ---- //
  const [creating, setCreating] = React.useState<boolean>(false);
  const [shouldCreateCollateral, setShouldCreateCollateral] = React.useState<boolean>(false);
  const [canConfigureManagementFee, setCanConfigureManagementFee] = React.useState<boolean>(false);

  // ---- Form state ---- //
  const tokenForm = useForm<z.infer<typeof TokenSchema>>({ resolver: zodResolver(TokenSchema) });
  const collateralsForm = useForm<z.infer<typeof AssetSchema>>({ resolver: zodResolver(AssetSchema) });

  // ---- Networking ---- //
  const [createToken] = useCreateTokenMutation();
  const [createCollateral] = useCreateCollateralMutation();
  const { data: collaterals, ...collateralsQuery } = useAvailableCollateralQuery();

  // ---- Actions ---- //
  const onSelectAsset = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === 'create') {
      setShouldCreateCollateral(true);
    } else {
      setShouldCreateCollateral(false);

      if (value) {
        tokenForm.setValue('collateralId', value);
      }
    }
  };

  const onConstantRatio = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value === 'true') {
      setCanConfigureManagementFee(false);
    } else {
      setCanConfigureManagementFee(true);

      if (value) {
        tokenForm.setValue('constantRatio', value === 'true');
      }
    }
  };

  const onCreateToken = tokenForm.handleSubmit(async (tokenData) => {
    setCreating(true);

    try {
      // Check if we have to create collateral and create it if so
      if (shouldCreateCollateral) {
        // Validate the collaterals form
        await collateralsForm.trigger();

        // Create the collateral
        const collateral = await createCollateral({
          variables: {
            input: collateralsForm.getValues()
          }
        });

        // Update the collaterals
        await collateralsQuery.refetch();

        // Update the dropdown
        tokenForm.setValue('collateralId', collateral.data?.createCollateral?.id || '');
        tokenData.collateralId = collateral.data?.createCollateral?.id || '';
        setShouldCreateCollateral(false);
      }

      // Create the token
      const { data: token } = await createToken({
        variables: {
          input: {
            ...tokenData,
            collateralId: tokenData.collateralId!
          }
        },
        refetchQueries: [
          'tokenList'
        ]
      });

      snackbar.enqueueSnackbar('Token successfully created');

      navigate(`/internal/deployments/create?${new URLSearchParams({ tokenId: token?.createToken?.id! }).toString()}`);
    } finally {
      setCreating(false);
    }
  });

  const initialFeeAccrualTimestampHandler = tokenForm.register('initialFeeAccrualTimestamp');
  return (
    <Protector>
      <Box>
        <Box
          sx={{
            mb: '2rem'
          }}
        >
          <Typography variant="title">
            Create Token
          </Typography>

          <Breadcrumbs>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              Dashboard
            </Link>

            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/internal/tokens/all"
            >
              Tokens
            </Link>

            <Typography color="primary.textLowContrast">
              Create
            </Typography>
          </Breadcrumbs>
        </Box>

        {/* // ---- Create form ---- // */}
        <Box>
          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <Box
              sx={{
                minWidth: '20vw'
              }}
            >
              <Typography variant="titleSmall">
                Basic information
              </Typography>

              <Typography variant="subtitleSmall">
                Most common details about the token
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: '30vw',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <TextField
                label="Token Name"
                {...tokenForm.register('name')}
              />

              <TextField
                label="Token Symbol"
                {...tokenForm.register('tokenSymbol')}
              />
            </Box>
          </Box>

          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <Box
              sx={{
                minWidth: '20vw',
                maxWidth: '20vw'
              }}
            >
              <Typography variant="titleSmall">
                Description
              </Typography>

              <Typography variant="subtitleSmall">
                A small description about the token and the underlying collateral
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: '30vw',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <TextField
                multiline
                rows={5}
                label="Description"
                {...tokenForm.register('description')}
              />
            </Box>
          </Box>

          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <Box
              sx={{
                minWidth: '20vw',
                maxWidth: '20vw'
              }}
            >
              <Typography variant="titleSmall">
                Contract Configuration
              </Typography>

              <Typography variant="subtitleSmall">
                Describes whether token is constant balance one, or is it expect to handle management fee, dividends, splits or any other type of balance changing events. Can't be changed later on.
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: '30vw',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <TextField
                select
                label="Is constant balance"
                onChange={onConstantRatio}
                SelectProps={{
                  native: true
                }}
              >
                <option disabled selected>
                  Please select
                </option>

                <option value="true">Yes</option>
                <option value="false">No</option>

              </TextField>
            </Box>
          </Box>


          {canConfigureManagementFee && (

            <Box
              sx={{
                py: '1rem',
                display: 'flex',
                borderTop: `1px solid ${palette.utility.borderElement}`
              }}
            >
              <Box
                sx={{
                  minWidth: '20vw',
                  maxWidth: '20vw'
                }}
              >
                <Typography variant="titleSmall">
                  Management Fee
                </Typography>

                <Typography variant="subtitleSmall">
                  Defines management fee configuration
                </Typography>
              </Box>

              <Box
                sx={{
                  minWidth: '30vw',
                  display: 'flex',
                  flexFlow: 'column'
                }}
              >
                <TextField
                  type="number"
                  label="Annual Fee (bps)"
                  helperText="Fee accrued in one year"
                  {...tokenForm.register('annualFee')}
                />
                <TextField
                  type="number"
                  label="Accrual period"
                  helperText="Defines how frequently fee is accrued"
                  {...tokenForm.register('accrualSchedule')}
                />
                <TimePicker
                  label="Accrual Start Time"
                  {...initialFeeAccrualTimestampHandler}
                  onChange={(e) => tokenForm.setValue('initialFeeAccrualTimestamp', (e as any).toDate())} />
              </Box>
            </Box>
          )}

          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <Box
              sx={{
                minWidth: '20vw',
                maxWidth: '20vw'
              }}
            >
              <Typography variant="titleSmall">
                Operational
              </Typography>

              <Typography variant="subtitleSmall">
                Configuration of how the token works
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: '30vw',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <TextField
                select
                label="Asset"
                onChange={onSelectAsset}
                SelectProps={{
                  native: true
                }}
              >
                <option disabled selected>
                  Please select
                </option>

                {collaterals && (
                  collaterals.collaterals.map((collateral) => (
                    <option key={collateral.id} value={collateral.id}>
                      {collateral.symbol}
                    </option>
                  ))
                )}

                <option value="create">
                  Create New
                </option>
              </TextField>

              {shouldCreateCollateral && (
                <React.Fragment>
                  <TextField
                    label="Symbol"
                    helperText="The stock symbol of the collateral"
                    {...collateralsForm.register('symbol')}
                  />

                  <TextField
                    label="ISIN Name"
                    helperText="The ISIN Name of the collateral"
                    {...collateralsForm.register('ISINName')}
                  />
                  <TextField
                    label="ISIN Number"
                    helperText="The ISIN number of the collateral"
                    {...collateralsForm.register('ISINNumber')}
                  />
                  <TextField
                    label="TradingView symbol"
                    helperText="The symbol of the collateral in the TradingView"
                    {...collateralsForm.register('tradingViewId')}
                  />
                  <TextField
                    label="Client Deposit Number In Incore"
                    helperText="The deposit account number of the collateral in Incore"
                    {...collateralsForm.register('incoreClientDepositNumber')}
                  />
                  <TextField
                    label="Broker link"
                    helperText="The way to link the collateral to system of the broker. Placeholder"
                  />
                </React.Fragment>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <LoadingButton
              loading={creating}
              onClick={onCreateToken}
            >
              Create token
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Protector>
  );
};
