import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Chip, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';

import DetailsIcon from '@mui/icons-material/InfoRounded';
import RefreshIcon from '@mui/icons-material/RotateRightRounded';

import { useToggle } from '@backed-fi/hooks';
import { OracleType, TokenCollateralQuery, useTokenCollateralQuery, useUpdateOracleAnswerMutation } from '@backed-fi/graphql';
import { CreateOracleDialog } from '@backed-fi/admin/src/app/domain/Internal/Tokens/pages/Details/components/CreateOracleDialog';
import { AppleOracleAnswerUpdateDialog } from '@backed-fi/admin/src/app/domain/Internal/Tokens/pages/Details/components/AppleOracleAnswerUpdateDialog';
import { RegisterOracleForwarderDialog } from '../components/RegisterOracleForwarderDialog';

// region Graph

gql`
  query tokenCollateral($tokenId: String!) {
    collateral(tokenId: $tokenId) {
      id
      symbol

      oracles {
        id

        updateSchedule
        isActive

        network
        address
        type

        forwarder {
          address
        }

        answerUpdates(
          onlyPending: true
        ) {
          id

          newAnswer
          previousAnswer
        }
      }
    }
  }
`;

// endregion

export const TokenOraclesPage = () => {
  const params = useParams<{ id: string }>();
  const createToggle = useToggle();
  const registerForwarderToggle = useToggle();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [activePriceUpdate, setActivePriceUpdate] = React.useState<TokenCollateralQuery['collateral']['oracles'][number]['answerUpdates'][number]>();

  // region Networking

  const [updateOracleAnswerMutation, { loading: refreshing }] = useUpdateOracleAnswerMutation();
  const { data } = useTokenCollateralQuery({
    variables: {
      tokenId: params.id!
    }
  });

  const { collateral } = (data || {}) as TokenCollateralQuery;

  // endregion

  // region Actions

  const onRefreshOracleAnswer = (oracleId: string) => async () => {
    await updateOracleAnswerMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'tokenCollateral'
      ],
      variables: {
        input: {
          oracleId
        }
      }
    });

    snackbar.enqueueSnackbar('Oracle price successfully requested');
  };

  const onApplyOracleAnswerUpdate = (priceUpdate: typeof activePriceUpdate) => () => {
    setActivePriceUpdate(priceUpdate);
  };

  // endregion

  return (
    <Box>
      {/* region Price Update Dialog */}

      {activePriceUpdate && (
        <AppleOracleAnswerUpdateDialog
          open={!!activePriceUpdate}
          onClose={() => setActivePriceUpdate(undefined)}

          priceUpdateId={activePriceUpdate.id}
          newAnswer={activePriceUpdate.newAnswer}
          previousAnswer={activePriceUpdate.previousAnswer}
        />
      )}


      {/* endregion */}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="titleSmall">
            Blockchain Oracles
          </Typography>

          <Typography variant="subtitleSmall">
            List of Backed managed oracles that provide the asset data on-chain
          </Typography>
        </Box>
      </Box>

      {[OracleType.Price].map(oracleType => (<>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <Box>
            <Typography variant="sectionTitle">
              {oracleType} Oracles
            </Typography>
          </Box>
          <Box>
            {collateral && oracleType == OracleType.Price && (
              <React.Fragment>
                <CreateOracleDialog
                  {...createToggle}
                  collateralId={collateral.id}
                  collateralSymbol={collateral.symbol}
                />
                <RegisterOracleForwarderDialog
                  {...registerForwarderToggle}
                  oracles={collateral.oracles.filter(z => z.type === oracleType)}
                />

                <Button sx={{ marginRight: '16px' }} onClick={createToggle.setTrue}>
                  Register Oracle
                </Button>

                <Button onClick={registerForwarderToggle.setTrue}>
                  Register Oracle Forwarder
                </Button>
              </React.Fragment>
            )}
          </Box>
        </Box>
        <DataGrid
          autoHeight
          rows={collateral?.oracles.filter(z => z.type === oracleType) ?? []}
          sx={{
            mt: '1rem',
            backgroundColor: 'utility.backgroundSubtle'
          }}
          columns={[
            {
              flex: 1,
              field: 'network',
              headerName: 'Network',
              renderCell: ({ value }) => (
                <Chip
                  label={value}
                />
              )
            },
            {
              minWidth: 450,
              field: 'address',
              headerName: 'Address',
              renderCell: ({ value }) => (
                <Typography>
                  {value}
                </Typography>
              )
            },
            {
              minWidth: 450,
              field: 'forwarder',
              headerName: 'Forwarder',
              renderCell: ({ value }) => (
                <Typography>
                  {value?.address}
                </Typography>

              )
            }, {
              minWidth: 150,
              field: 'updateSchedule',
              headerName: 'Update Schedule',
              renderCell: ({ value }) => (
                <Typography>
                  {value}
                </Typography>
              )
            }, {
              minWidth: 100,
              field: 'isActive',
              headerName: 'Active',
              renderCell: ({ value }) => (
                <Typography>
                  {value ? 'Yes' : 'No'}
                </Typography>
              )
            }, {
              minWidth: 300,
              field: 'id',
              headerName: '',
              renderCell: ({
                value,
                row
              }) => (
                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                >
                  {row.answerUpdates[0] && (
                    <Tooltip title="Pending price update. Click to review">
                      <Button
                        onClick={onApplyOracleAnswerUpdate(row.answerUpdates[0])}
                      >
                        Apply Answer Update
                      </Button>
                    </Tooltip>
                  )}

                  <Tooltip title="Oracle Details">
                    <IconButton
                      onClick={() => {
                        navigate(`/blockchain/oracles/${row.id}/details`);
                      }}
                    >
                      <DetailsIcon />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Trigger manual refresh">
                    <IconButton
                      disabled={refreshing}
                      onClick={onRefreshOracleAnswer(value)}
                    >
                      {refreshing ? (
                        <CircularProgress
                          size={16}
                        />
                      ) : (
                        <RefreshIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )
            }
          ]}
        /></>))}
    </Box>
  );
};
