import { useEffect, useMemo } from 'react';
import {
  Box,
  TextField,
  Typography
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  AvailableCustodyAccountsQuery,
  useAvailableCustodyAccountsQuery,
  useTokenCollateralCustodyQuery,
  TokenCollateralCustodyQuery,
  useUpdateCollateralCustodyAccountMutation,
  CustodyAccountFacetType,
  FiatCurrency
} from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';

import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ConfigureClearanceAccountDialog } from '../components/ConfigureClearanceAccountDialog';

// region Graph

const Graph = gql`
  query tokenCollateralCustody($tokenId: String!) {
    collateral(tokenId: $tokenId) {
      id
      symbol
      priceCurrency
      clearanceAccounts {
        id
        broker {
          id
          name
        }
        institutionName
        depositoryCode
        institutionBic
        updatedAt
      }
    }
  }

  query availableCustodyAccounts {
    custodyAccounts {
      externalId
      label
      facets {
        id
        type
        asset
        collateral {
          id
        }
      }
    }
  }

  mutation updateCollateralCustodyAccount(
    $input: UpdateCollateralCustodyAccountInput!
  ) {
    updateCollateralCustodyAccount(input: $input) {
      id
    }
  }
`;

// endregion

const Schema = z.object({
  accounts: z.string().nullable().array()
});

type Schema = z.infer<typeof Schema>;

const expectedAccounts = (currency: FiatCurrency) => ([
  {
    type: CustodyAccountFacetType.Security
  },
  {
    type: CustodyAccountFacetType.Fiat,
    asset: currency
  }
]);

export const TokenCollateralCustodyPage = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { isSubmitting } = form.formState;

  // region Networking

  const [updateCollateralCustodyAccount, { loading: refreshing }] =
    useUpdateCollateralCustodyAccountMutation();
  const { data: collateralData } = useTokenCollateralCustodyQuery({
    variables: {
      tokenId: params.id!
    }
  });
  const { data } = useAvailableCustodyAccountsQuery();

  const { collateral } = (collateralData || {}) as TokenCollateralCustodyQuery;
  const { custodyAccounts } = (data || {}) as AvailableCustodyAccountsQuery;

  // endregion

  const accountsToSet = useMemo(() => {
    if (collateral && custodyAccounts) {
      return expectedAccounts(collateral.priceCurrency).map((x) => ({
        ...x,
        options: custodyAccounts.flatMap((z) => z.facets.map((y) => ({
          ...y,
          externalId: z.externalId
        }))).filter((z) => z?.type === x.type && (!x.asset || z.asset === x.asset) && (
          !z.collateral || z.collateral.id === collateral.id
        ))
      }));
    }
    return [];
  }, [custodyAccounts, collateral]);

  useEffect(() => {
    if (accountsToSet && collateral) {
      form.setValue('accounts', accountsToSet.map((z) => z.options.find((x) => x.collateral?.id === collateral.id)?.id ?? null));
    }
  }, [accountsToSet, collateral]);

  // region Actions

  const onUpdate = form.handleSubmit(async (data) => {
    await updateCollateralCustodyAccount({
      awaitRefetchQueries: true,
      refetchQueries: ['tokenCollateral'],
      variables: {
        input: {
          collateralId: collateral.id,
          accounts: data.accounts.filter((x): x is string => !!x)
        }
      }
    });

    snackbar.enqueueSnackbar('Fiat custody account successfully updated');
  });
  // endregion

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Box>


          {accountsToSet.map((item, index) => {
            return (
              <div key={index}>
                <Typography variant="titleSmall">{item.type} {item.asset} Custody Account</Typography>

                <Typography variant="subtitleSmall" mb="1rem">
                  Set {item.type} {item.asset} Custody Account for Collateral
                </Typography>

                {item.options && (
                  <TextField
                    select
                    fullWidth
                    label={`${item.type} Custody Account`}
                    {...form.register(`accounts.${index}`)}
                    SelectProps={{
                      native: true
                    }}
                  >
                    <option disabled selected>
                      Please select
                    </option>

                    {item.options.map((x) => {
                      return (
                        <option key={x?.id} value={x?.id}>
                          {x?.asset} - {x.externalId}
                        </option>
                      );
                    })}
                  </TextField>
                )}
              </div>
            );
          })}

          <LoadingButton
            loading={isSubmitting}
            onClick={onUpdate}
            sx={{
              mt: '.25rem',
              float: 'right'
            }}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem'
        }}
      >
        <Typography
          variant="titleSmall"
        >
          Clearance Accounts
        </Typography>

        {collateral && (<>
          <DataGrid
            autoHeight
            rows={collateral.clearanceAccounts || []}
            rowHeight={70}
            sx={{
              backgroundColor: 'white'
            }}
            columns={[
              {
                flex: 10,
                field: 'broker.name',
                headerName: 'Broker',
                renderCell: ({ row }) => {
                  return <Typography>{row.broker.name ?? '-/-'}</Typography>;
                }
              },
              {
                flex: 10,
                field: 'institutionName',
                headerName: 'Institution Name',
                renderCell: ({ value }) => {
                  return <Typography>{value ?? '-/-'}</Typography>;
                }
              },
              {
                flex: 10,
                field: 'institutionBic',
                headerName: 'BIC',
                renderCell: ({ value }) => {
                  return <Typography>{value ?? '-/-'}</Typography>;
                }
              },
              {
                flex: 10,
                field: 'depositoryCode',
                headerName: 'Depository Code',
                renderCell: ({ value }) => {
                  return <Typography>{value ?? '-/-'}</Typography>;
                }
              },
              {
                flex: 10,
                field: 'actions',
                headerName: 'Actions',
                renderCell: ({ row }) => {
                  return <><ConfigureClearanceAccountDialog
                  collateralId={collateral.id}
                  clearanceAccountId={row.id}
                /></>;
                }
              }
            ]}
          /><ConfigureClearanceAccountDialog
          collateralId={collateral.id}
        />
        </>)}
      </Box>
    </Box>
  );
};
