import React from 'react';
import { Route } from 'react-router-dom';

import { InteractionsListPage } from './pages/InteractionsListPage';

import { InteractionDetailsLayout } from './pages/Details/_Layout';
import { InteractionDetailsOverview } from './pages/Details/Overview';
import { InteractionOrdersPage } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/Orders';
import { InteractionInvoicesPage } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/Invoice';
import { InteractionTransactionsPage } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/Transactions';
import { InteractionActionsPage } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/Actions';
import { MonitoringListPage } from './pages/Monitoring/MonitoringListPage';
import { InteractionTransfersPage } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/Transfers';

export const InteractionPages = (
  <React.Fragment>
    <Route
      path="all"
      element={
        <InteractionsListPage />
      }
    />
    <Route
      path="monitoring"
      element={
        <MonitoringListPage />
      }
    />

    <Route
      path="details/:interactionId"
      element={
        <InteractionDetailsLayout />
      }
    >
      <Route
        path="overview"
        element={<InteractionDetailsOverview />}
      />

      <Route
        path="transactions"
        element={<InteractionTransactionsPage />}
      />

      <Route
        path="transfers"
        element={<InteractionTransfersPage />}
      />

      <Route
        path="orders"
        element={<InteractionOrdersPage />}
      />

      <Route
        path="invoice"
        element={<InteractionInvoicesPage />}
      />

      <Route
        path="actions"
        element={<InteractionActionsPage />}
      />
    </Route>
  </React.Fragment>
);
