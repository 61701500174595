import React from 'react';
import { z } from 'zod';
import { InjectionPayloadSchema } from './InjectionSchema';
import { gql } from '@apollo/client';
import { InteractionType } from '@backed-fi/graphql';

// region Types

interface InjectionContext extends z.infer<typeof InjectionPayloadSchema> {
  currentStep: number;
}

interface InjectionFunctions {
  setInjectionState: React.Dispatch<React.SetStateAction<InjectionContext>>;

  nextStep: () => Promise<void>;
  previousStep: () => void;

  getCurrentState: () => InjectionContext;
}

// endregion

const defaultContext: InjectionContext = {
  type: InteractionType.Custom,
  currentStep: 0,
  clientId: '',

  fee: {
    shouldCollect: false,
    shouldGenerateInvoice: false
  },

  orders: [],
  walletsToRegister: [],
  outgoingTransactions: [],
  incomingTransactions: [],
  incomingBankTransfers: [],
  outgoingBankTransfers: []
};

const Context = React.createContext<InjectionContext & InjectionFunctions>(defaultContext as any);

// region Networking

gql`
  mutation upsertInjection($input: UpsertInjectionInput!) {
    upsertInjection(input: $input) {
      id
    }
  }
`;

// endregion

export const InjectionContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [injectionState, setInjectionState] = React.useState<InjectionContext>(defaultContext);

  const nextStep = async () => {
    const newState = {
      ...injectionState,
      currentStep: ++injectionState.currentStep
    };

    setInjectionState(newState);
  };

  const previousStep = () => setInjectionState((p) => ({
    ...p,
    currentStep: (p.currentStep - 1) < 0
      ? 0
      : p.currentStep - 1
  }));

  return (
    <Context.Provider
      value={{
        ...injectionState,

        getCurrentState: () => injectionState,

        nextStep,
        previousStep,
        setInjectionState
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useInjectionContext = () => React.useContext(Context);
