import React from 'react';
import { Box, Typography } from '@mui/material';
import { NavigationButtons } from '../../components/NavigationButtons';
import { InjectTransactionButton } from '../../components/InjectTransactionButton';
import formatRelative from 'date-fns/formatRelative';
import { ethers } from 'ethers';
import { useInjectionContext } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionContext';

export const InjectOutgoingTransaction: React.FC = () => {
  const context = useInjectionContext();

  return (
    <Box>
      <Box>
        {context.outgoingTransactions.length ? (
          context.outgoingTransactions.map((tx, index) => (
            <Box key={index}>
              <Typography>
                Outgoing transaction by {tx.client ? tx.client.name : 'unknown client'}{' '}
                executed {formatRelative(new Date(tx.blockTimestamp * 1000), new Date())}{' '}
                for {ethers.utils.formatUnits(tx.amount, tx.decimals)} {tx.symbol}
              </Typography>
            </Box>
          ))
        ) : (
          <Box>
            <Typography>
              No outgoin transaction defined
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          my: '.5rem',
          gap: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <InjectTransactionButton
          transactionType="outgoing"
        />

        <NavigationButtons />
      </Box>
    </Box>
  );
};
