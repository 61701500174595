import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import React from 'react';
import { Box, Dialog, DialogContent, DialogProps } from '@mui/material';
import { Title } from '@backed-fi/compound';

export const DocumentInfoDialog: React.FC<DialogProps> = (props) => {
  const { document } = useDocumentContext();

  // region Fetch if not fetched

  React.useEffect(() => {
    if (!document?.fetched) {
      document!.fetch()
    }
  }, [])

  // endregion

  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Title
          title="Document Details"
        />

        {!document?.fetched ? (
          <React.Fragment>
            Loading
          </React.Fragment>
        ) : (
          <Box
            sx={{
              mt: '1rem',
              gap: '.5rem',
              display: 'flex',
              flexFlow: 'column'
            }}
          >
            <Title
              small
              boxed
              title="Created At"
              subtitle={document.createdAt}
            />

            <Title
              small
              boxed
              title="Last Updated At"
              subtitle={document.updatedAt}
            />

            <Title
              small
              boxed
              title="Created for"
              subtitle={`${document.client.classification} client ${document.client.name}`}
            />

            {document.uploader && (
              <Title
                small
                boxed
                title="Created by"
                subtitle={`${document.uploader.firstName} ${document.uploader.lastName}`}
              />
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
