import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

import { useGetTokenStatisticsQuery } from '@backed-fi/graphql';

// Utility function to generate a random color
const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const transformData = (data: any): any => {
  if (!data?.[0]?.statistics?.tokens) {
    return {
      seriesData: []
    };
  }

  let stats = data[0].statistics;

  // Prepare data for echarts
  let processedData: { [key: string]: number } = {};

  stats.tokens.forEach((token: any) => {
    token.deployments.forEach((deployment: any) => {
      if (processedData[deployment.network]) {
        processedData[deployment.network] += deployment.aum;
      } else {
        processedData[deployment.network] = deployment.aum;
      }
    });
  });

  let seriesData = Object.keys(processedData).map((key) => {
    return {
      value: processedData[key],
      name: key
    };
  });

  return {
    seriesData
  };
};

const AumPerNetworkChart: React.FC = () => {
  const [option, setOption] = useState<echarts.EChartsOption | null>(null);

  const query = useGetTokenStatisticsQuery({ variables: { isLatest: true } });

  const { loading, error } = query;
  const tokenStatistics = query.data?.tokenStatistics as { createdAt: string; statistics: string }[];

  useEffect(() => {
    if (tokenStatistics) {
      const transformedData = transformData(tokenStatistics);

      if (transformedData.seriesData.length === 0) {
        return;
      }

      const colors = transformedData.seriesData.map(() => getRandomColor());

      // Prepare echarts options
      let options: echarts.EChartsOption = {
        title: {
          text: 'AUM per Network',
          left: 'left'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'horizontal',
          left: 'center',
          top: 40
        },
        color: colors,
        series: [
          {
            name: 'AUM',
            type: 'pie',
            radius: '50%',
            data: transformedData.seriesData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };

      setOption(options);
    }
  }, [tokenStatistics]);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error! {error.message}</div>;
  }

  return (
    option && <ReactEcharts option={option} style={{ height: '400px' }} />
  );
};

export default AumPerNetworkChart;
