import { BlockchainNetwork, FiatCurrency, InteractionType, OrderSide } from '@backed-fi/graphql';
import * as z from 'zod';

export const InjectedTransaction = z.object({
  hash: z.string(),
  network: z.nativeEnum(BlockchainNetwork),

  benefactorAddress: z.string(),
  beneficiaryAddress: z.string(),

  amount: z.string(),
  symbol: z.string(),
  decimals: z.string(),
  blockTimestamp: z.number(),

  client: z.any()
});

export const OrderInjectSchema = z.object({
  side: z.nativeEnum(OrderSide),
  symbol: z.string(),
  executedAt: z.coerce.date(),
  totalShares: z.coerce.number(),
  pricePerShare: z.coerce.number()
    .transform((x) => x * 100),

  /// The exchange rate from USD to the underlying currency
  exchangeRate: z.coerce.number(),

  reference: z.string(),
  brokerId: z.string()
});

export const FeeInjectionSchema = z.object({
  shouldCollect: z.boolean(),
  shouldGenerateInvoice: z.boolean(),

  amount: z.string()
    .optional(),

  value: z.coerce.number()
    .optional()
    .transform((x) => x ? x * 100 : x),

  network: z.nativeEnum(BlockchainNetwork)
    .optional()
});

export const BankTransferInjectionSchema = z.object({
  currency: z.nativeEnum(FiatCurrency),
  amount: z.coerce.number()
    .transform((x) => x * 100),

  references: z.string()
    .optional()
});

export const InjectionPayloadSchema = z.object({
  type: z.nativeEnum(InteractionType),

  clientId: z.string(),
  currentStep: z.number(),

  walletsToRegister: z.array(
    z.string()
  ),

  fee: FeeInjectionSchema,

  orders: z.array(OrderInjectSchema),

  incomingTransactions: z.array(InjectedTransaction),
  outgoingTransactions: z.array(InjectedTransaction),

  incomingBankTransfers: z.array(BankTransferInjectionSchema),
  outgoingBankTransfers: z.array(BankTransferInjectionSchema)
});
