import React from 'react';
import { Dialog, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import { BlockchainNetwork, OracleType, OracleUpdateSchedule, useCreateOracleMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';

// region Props

interface Props extends DialogProps {
  collateralId: string;

  collateralSymbol: string;
}

// endregion

// region Form Schema

const Schema = z.object({
  address: z.string(),
  forwarderAddress: z.string().optional().nullable(),
  network: z.nativeEnum(BlockchainNetwork),
  updateSchedule: z.nativeEnum(OracleUpdateSchedule)
});

// endregion

// region Graph

gql`
  mutation createOracle($input: CreateOracleInput!) {
    createOracle(input: $input) {
      id
    }
  }
`;

// endregion

export const CreateOracleDialog: React.FC<Props> = ({
  collateralId,
  collateralSymbol,
  ...props
}) => {
  // region Networking

  const [createOracleMutation] = useCreateOracleMutation();

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  // endregion

  // region Actions

  const onRegister = form.handleSubmit(async (data) => {
    await createOracleMutation({
      awaitRefetchQueries: true,
      refetchQueries: [
        'tokenCollateral'
      ],
      variables: {
        input: {
          ...data,
          collateralId
        }
      }
    });

    form.reset();

    if (typeof props.onClose === 'function') {
      (props.onClose as any)();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogContent>
        <Typography variant="titleSmall">
          Register Oracle
        </Typography>

        <Typography variant="subtitleSmall" mb="1rem">
          Register oracle in the system. The registered oracle will be for {collateralSymbol} and{' '}
          will be updated with the price for it
        </Typography>

        <TextField
          fullWidth
          label="Contract Address"
          {...form.register('address')}
        />

        <TextField
          fullWidth
          label="Forwarder Address (Optional)"
          {...form.register('forwarderAddress')}
        />

        <TextField
          select
          fullWidth
          label="Deployment Network"
          {...form.register('network')}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {Object.keys(BlockchainNetwork).map((network) => {
            return (
              <option key={network} value={network}>
                {network}
              </option>
            );
          })}
        </TextField>

        <TextField
          select
          fullWidth
          label="Update Schedule"
          {...form.register('updateSchedule')}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {Object.keys(OracleUpdateSchedule).map((schedule) => {
            return (
              <option key={schedule} value={schedule}>
                {schedule}
              </option>
            );
          })}
        </TextField>

        <LoadingButton
          loading={isSubmitting}
          onClick={onRegister}
          sx={{
            mt: '.25rem',
            float: 'right'
          }}
        >
          Register
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};
