import React from 'react';
import { useApolloClient } from '@apollo/client';
import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';

export const useCommandMenuData = () => {
  const {
    filter,
    currentTarget
  } = useCommandMenuContext();

  const apollo = useApolloClient();

  const [state, setState] = React.useState<{
    fetching: boolean;
    fetched: boolean;
    rawData: any;
    formattedData: any;
  }>({} as any);

  React.useEffect(() => {
    if (currentTarget) {
      new Promise(async () => {
        try {
          setState((prev) => ({
            ...prev,
            fetching: true
          }));

          const { data } = await apollo.query({
            query: currentTarget.query,
            variables: currentTarget.variables(filter ?? ''),
            fetchPolicy: 'network-only'
          });

          setState({
            fetching: false,
            fetched: true,
            rawData: data,
            formattedData: currentTarget.formatData(data)
          });
        } catch (e) {
          console.error('An error occurred while attempting to fetch search data', e);
        } finally {
          setState((prevState) => ({
            ...prevState,
            fetching: false
          }));
        }
      });
    } else {
      setState((prev) => ({
        ...prev,
        fetched: false
      }));
    }
  }, [apollo, filter, currentTarget]);

  return state;
};
