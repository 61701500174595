import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';

import { ControlledCheckbox, PageHeading, Protector } from '@backed-fi/compound';
import { AdminPermission, ClientFeature, ComplianceReviewStatus, useComplianceReviewQuery, useSubmitComplianceReviewMutation, useUpdateComplianceReviewMutation } from '@backed-fi/graphql';
import { Alert, Box, FormControl, FormControlLabel, FormGroup, FormHelperText, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

const Graph = gql`
  query complianceReview($id: ID!) {
    complianceReview(complianceReviewId: $id) {
      id

      createdAt
      updatedAt

      notes
      isProfessional
      featuresAllowed

      client {
        id
        name
      }
    }
  }

  mutation updateComplianceReview($input: UpdateComplianceReviewInput!) {
    updateComplianceReview(input: $input) {
      id
    }
  }

  mutation submitComplianceReview($input: SubmitComplianceReviewInput!) {
    submitComplianceReview(input: $input) {
      id
    }
  }
`;

const FormSchema = z.object({
  notes: z.string()
    .nonempty("Please describe your decision with at least 10 characters")
    .min(10, "Please describe your decision with at least 10 characters"),

  isProfessional: z.boolean()
    .default(false),

  featuresAllowed: z.array(
    z.nativeEnum(ClientFeature)
  ).default([])
});

type FormSchemaType = z.infer<typeof FormSchema>;

export const SubmitComplianceReviewPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  // region Networking

  const { data, loading } = useComplianceReviewQuery({
    variables: {
      id: params.id!
    }
  });

  const [updateReview] = useUpdateComplianceReviewMutation();
  const [submitReview] = useSubmitComplianceReviewMutation();

  // endregion

  // region Form Control

  const form = useForm<FormSchemaType>({
    resolver: zodResolver(FormSchema)
  });

  const { errors } = form.formState;

  // endregion

  // region Actions

  const onSubmitReview = (action: 'reject' | 'approve') => form.handleSubmit(async (data) => {
    // Make sure that the assessment is up-to-date
    await updateReview({
      variables: {
        input: {
          complianceReviewId: params.id!,
          ...data
        }
      }
    });

    // Submit it
    await submitReview({
      variables: {
        input: {
          complianceReviewId: params.id!,
          status: action === 'reject'
            ? ComplianceReviewStatus.Rejected
            : ComplianceReviewStatus.Approved
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'dashboardData',
        'clientCompliance'
      ]
    });

    // Give the user feedback
    snackbar.enqueueSnackbar('Successfully submitted the compliance review');

    // And redirect them
    navigate(-1);
  });

  // endregion

  return (
    <Protector permissions={[AdminPermission.ComplianceManage]}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center'
        }}
      >
        <Box
          sx={{
            width: '100%',
            maxWidth: '900px'
          }}
        >
          <PageHeading
            title={(
              <Typography variant='title'>
                Compliance Review for{' '}

                <Typography
                  variant='title'
                  sx={{
                    cursor: 'pointer',
                    display: 'inline',
                    color: 'primary.textLowContrast'
                  }}
                  onClick={() => {
                    navigate(`/clients/${data?.complianceReview?.client?.id}/details/compliance`);
                  }}
                >
                  {data?.complianceReview?.client?.name}
                </Typography>
              </Typography>
            )}
            breadcrumbs={[{
              text: 'Compliance'
            }, {
              text: 'Reviews'
            }, {
              text: 'Submit'
            }]}
          />

          <Alert severity='warning'>
            Do not forget to submit the compliance review because it is not automatically submitted
            and if you lose this page it is going to be hard getting back
          </Alert>

          <Typography
            mt={3}
            variant='subtitle'
          >
            Professional investor
          </Typography>

          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <ControlledCheckbox
                    name='isProfessional'
                    control={form.control}
                  />
                }
                label='Is Professional Investor'
              />
            </FormGroup>
            <FormHelperText>
              Marking the client as professional client will enable
              the issuance feature for them
            </FormHelperText>
          </FormControl>

          <Typography
            mb='8px'
            mt='20px'
            variant='subtitle'
          >
            Notes
          </Typography>

          <TextField
            rows={5}
            fullWidth
            multiline
            label='Compliance Decision'
            error={Boolean(errors.notes)}
            helperText={errors.notes?.message}
            {...form.register('notes')}
          />

          <Box
            sx={{
              mt: '12px',
              gap: '12px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              color='error'
              onClick={onSubmitReview('reject')}
            >
              Reject
            </LoadingButton>

            <LoadingButton
              onClick={onSubmitReview('approve')}
            >
              Approve
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Protector>
  );
};
