import { OrderStatus } from '@backed-fi/graphql';

export const OrderDisplayStatuses =
  ['Created', 'Failed', 'Batched', 'Skipped execution', 'To be executed', 'In execution', 'Completed'] as const;
export type OrderDisplayStatus = typeof OrderDisplayStatuses[number];

const statusMap: { [key in OrderStatus]: OrderDisplayStatus } = {
  [OrderStatus.Created]: 'Created',
  [OrderStatus.Failed]: 'Failed',
  [OrderStatus.Batched]: 'Batched',
  [OrderStatus.SkippedExecution]: 'Skipped execution',
  [OrderStatus.RequirementsCheckedSuccesfully]: 'To be executed',
  [OrderStatus.MarkedForManualExecution]: 'To be executed',
  [OrderStatus.ReadyForExecution]: 'To be executed',
  [OrderStatus.SubmittedManually]: 'In execution',
  [OrderStatus.Executed]: 'Completed',
  [OrderStatus.Submitted]: 'Completed'
};

export const getUnderlyingStatuses = (status: OrderDisplayStatus): OrderStatus[] => {
  return Object.entries(statusMap).filter(([key, value]) => value === status).map(([key, value]) => key as OrderStatus);
};

export const simplifyStatus = (status: OrderStatus): (OrderDisplayStatus | 'Unknown') => {
  return statusMap[status] ?? 'Unknown';
};
