import React from 'react';
import { Route } from 'react-router-dom';
import { DocumentsListPage } from './pages/DocumentsListPage';

export const DocumentRoutes = (
  <Route path='compliance/documents'>
    <Route
      path='all'
      element={<DocumentsListPage />}
    />
  </Route>
);
