import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { gql } from '@apollo/client';
import { useAdminsQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { AddAdminDialog } from '@backed-fi/admin/src/app/domain/Internal/Admins/components/AddAdminDialog';
import { usePaginationModel, useToggle } from '@backed-fi/hooks';
import { AdminPermissions } from './components/AdminPermissions';

// region Graph

gql`
  query admins(
    $page: Int,
    $pageSize: Int
  ) {
    admins(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        createdAt
        updatedAt

        firstName
        lastName

        email
        permissions
      }

      page {
        currentPage
        totalNodes
        totalPages
      }
    }
  }
`;

// endregion

export const AdminsListPage: React.FC = () => {
  const addDialogToggle = useToggle();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region Networking

  const query = useAdminsQuery({
    variables: paginationVariables
  });

  // endregion

  return (
    <Box>
      {/* region Dialogs */}

      <AddAdminDialog {...addDialogToggle} />
      {/* endregion */}

      <PageHeading
        title="System Admins"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'System Admins'
          }
        ]}
      />

      <Box
        sx={{
          my: '1rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button onClick={addDialogToggle.setTrue}>
          Add Admin
        </Button>
      </Box>

      <DataGrid
        {...pagination}
        autoHeight
        loading={query.loading}
        rows={query.data?.admins?.nodes || []}
        rowCount={query.data?.admins?.page.totalNodes}
        columns={[{
          flex: 1,
          minWidth: 300,
          field: 'name',
          headerName: 'Name',
          renderCell: ({ row }) => (
            <Typography>
              {row.firstName} {row.lastName}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 300,
          field: 'email',
          headerName: 'Email',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        }, {
          flex: 1,
          minWidth: 300,
          field: 'permissions',
          headerName: 'Permissions',
          renderCell: ({ row }) => (
            <AdminPermissions admin={row}></AdminPermissions>
          )
        }]}
      />
    </Box>
  );
};
