import React from 'react';
import { gql } from '@apollo/client';

import EyeIcon from '@mui/icons-material/VisibilityRounded';

import { PageHeading, Protector } from '@backed-fi/compound';
import { AdminPermission, DocumentsListQuery, useDocumentsListQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, IconButton, Tooltip, Typography } from '@mui/material';
import { DocumentDetailsModal } from '../components/DocumentDetailsModal';

const Graph = gql`
  query documentsList {
    documents {
      id

      type

      client {
        id
        name
        type
      }
    }
  }
`;

export const DocumentsListPage: React.FC = () => {
  // ---- State ---- //
  const [documentId, setDocumentId] = React.useState();

  // ---- Networking ---- //
  const { data, loading } = useDocumentsListQuery();

  // ---- Destructuring ---- //
  const { documents } = (data || {}) as DocumentsListQuery;

  return (
    <Protector permissions={[AdminPermission.ComplianceRead]}>
      <DocumentDetailsModal
        documentId={documentId!}
        open={!!documentId}
        onClose={() => setDocumentId(undefined)}
      />

      <Box
        sx={{
          height: 'calc(100vh - 3rem)',
          display: 'flex',
          flexFlow: 'column',
          pb: '2rem'
        }}
      >
        <PageHeading
          title='Documents'
          breadcrumbs={[{
            text: 'Documents'
          }]}
        />

        <DataGrid
          loading={loading}
          rows={documents || []}
          sx={{
            backgroundColor: 'utility.backgroundSubtle'
          }}
          columns={[{
            flex: 5,
            field: 'client',
            headerName: 'Client',
            renderCell: ({ row }) => (
              <Typography>
                {row.client.name}
              </Typography>
            )
          }, {
            flex: 3,
            field: 'type',
            headerName: 'Document Type',
            renderCell: ({ row }) => (
              <Typography>
                {row.type}
              </Typography>
            )
          }, {
            flex: 2,
            field: 'id',
            headerName: 'Actions',
            renderCell: ({ value }) => (
              <Tooltip title='Preview the document'>
                <IconButton onClick={() => setDocumentId(value)}>
                  <EyeIcon />
                </IconButton>
              </Tooltip>
            )
          }]}
        />
      </Box>
    </Protector>
  );
};
