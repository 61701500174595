import { useParams } from 'react-router-dom';
import { Box, Grid, LinearProgress, Typography } from '@mui/material';
import { gql } from '@apollo/client';

import { CryptoFormatter, DateTimeFormatter, PercentageFormatter } from '@backed-fi/shared';
import { InfoLabelCard, LoaderSection } from '@backed-fi/compound';
import { TokenMultiplierChangeStatus, useTokenCollateralizationQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import Decimal from 'decimal.js';
import { UpdateMultiplierDialog } from '../components/UpdateMultiplierDialog';
import { TokenMultiplierChangesTable } from '../components/TokenMultiplierChangesTable';
import { UpdateManagementFeeDialog } from '../components/UpdateManagementFeeDialog';


gql`
  query tokenCollateralization($tokenId: String) {
    token(id: $tokenId) {
      symbol
      collateral {
        symbol
      }

      lastMultiplierUpdateTimestamp
      managementFee {
        amount
        feeAccruals {
          id
          createdAt
          nominalFeeCollected
        }
      }
      deployments {
        network
        currentMultiplier
      }
    }
  }
`;

export const TokenCollateralizationPage = () => {
  const params = useParams<{ id: string }>();

  // region Networking
  const { data, loading } = useTokenCollateralizationQuery({
    variables: {
      tokenId: params.id!
    }
  });

  const token = data?.token;

  return (
    <Box sx={{ marginRight: '3em' }}>
      <Box>
        {loading && (
          <LoaderSection
            description='Fetching current collateralization'
          />
        )}
        {!loading && token && (
          <Box>
            <Box>
              <Grid sx={{ marginBottom: '32px' }} container spacing={2}>
                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Current Multiplier</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      {token.deployments.map(deployment => (<InfoLabelCard
                      key={deployment.network}
                        label={`${deployment.network} ${token.symbol} Multiplier`}
                        sx={{ paddingX: '10px' }}
                        value={`${new Decimal(deployment.currentMultiplier).div(Decimal.pow(10, 18))}`}
                      />))}
                    </Grid>
                    <Grid item xs={4}>
                      <UpdateMultiplierDialog tokenId={params.id!} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={6}>
                  <Typography marginRight={1} variant="title">Fees</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <InfoLabelCard
                        label='Annual Management Fee'
                        sx={{ paddingX: '10px' }}
                        // Maybe should be shown in bips instead?
                        value={PercentageFormatter.format((token.managementFee?.amount ?? 0) / 10000)}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <UpdateManagementFeeDialog tokenId={params.id!} />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: '16px' }} variant="title">Pending Multiplier Changes</Typography>
              <TokenMultiplierChangesTable tokenId={params.id!} status={[
                TokenMultiplierChangeStatus.PendingApproval,
                TokenMultiplierChangeStatus.Processing,
                TokenMultiplierChangeStatus.Failed
              ]}/>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: '16px' }} variant="title">Executed Multiplier Changes</Typography>
              <TokenMultiplierChangesTable tokenId={params.id!} status={[TokenMultiplierChangeStatus.Executed]}/>
            </Box>
            <Box>
              <Typography sx={{ marginBottom: '16px' }} variant="title">Fee Accruals</Typography>
              <DataGrid
                autoHeight
                loading={loading}
                rows={token?.managementFee?.feeAccruals ?? []}
                rowCount={0}
                getRowId={(row) => row.id}
                sx={{
                  backgroundColor: 'white'
                }}
                components={{
                  LoadingOverlay: LinearProgress
                }}
                initialState={{
                  sorting: {
                    sortModel: [{
                      field: 'createdAt',
                      sort: 'desc'
                    }]
                  }
                }}
                columns={[
                  {
                    flex: 2,
                    field: 'nominalFeeCollected',
                    headerName: 'Fee collected',
                    renderCell: ({ value }) => (
                      <Typography
                        sx={{
                          height: '21px',
                          gap: '0.25rem',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 14
                        }}
                      >
                        {CryptoFormatter.format(value, 18)} {token.collateral.symbol}
                      </Typography>
                    )
                  },
                  {
                    flex: 2,
                    field: 'createdAt',
                    headerName: 'Date',
                    renderCell: ({ value }) => (
                      <Typography
                        sx={{
                          height: '21px',
                          gap: '0.25rem',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: 14
                        }}
                      >
                        {DateTimeFormatter.format(new Date(value))}
                      </Typography>
                    )
                  }]}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>);
};
