import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';

import { CreateCommentField } from './CreateCommentField';
import { useSnackbar } from 'notistack';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  onDismiss: () => void,

  interactionId: string,
  refreshQueries: string[]
}

// endregion


export const CreateCommentDialog: React.FC<Props> = ({ interactionId, refreshQueries, ...props }) => {
  const snackbar = useSnackbar();

  const onCommentCreate = async () => {
    snackbar.enqueueSnackbar('Compliance comment created successfully.', { variant: 'success' });

    props.onDismiss();
  };

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Create Compliance Comment
        </Typography>

        <CreateCommentField interactionId={interactionId} refreshQueries={refreshQueries} onCreate={onCommentCreate}/>
      </DialogContent>
    </Dialog>
  );
};
