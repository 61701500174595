import { MenuCommand } from '@backed-fi/admin/src/app/components/CommandMenu/types';
import TransactionIcon from '@mui/icons-material/ReceiptLongRounded';
import { gql } from '@apollo/client';
import { BlockchainNetwork, CommandMenuTransactionsSearchQuery, CommandMenuTransactionsSearchQueryVariables, FilteringMode } from '@backed-fi/graphql';

import IncomingTransactionIcon from '@mui/icons-material/MoveToInboxRounded';
import { Chip } from '@mui/material';

export const TransactionsSearchMenuCommand: MenuCommand<
  CommandMenuTransactionsSearchQueryVariables,
  CommandMenuTransactionsSearchQuery
> = {
  Icon: TransactionIcon,
  name: 'Transactions',
  description: 'Search for any transaction',
  keywords: [
    'tx',
    'transaction',
    'transactions',
    'hash',
    'incoming',
    'outgoing'
  ],
  query: gql`
    query commandMenuTransactionsSearch($filter: IncomingTransactionWhereInput) {
      incomingTransactions(where: $filter, pageSize: 3) {
        nodes {
          id

          hash
          network

          interaction {
            id
          }

          client {
            id
            name
          }
        }
      }
    }
  `,
  variables: (filter) => ({
    filter: {
      OR: [
        {
          hash: {
            contains: filter,
            mode: FilteringMode.Insensitive
          }
        }, {
          tokenSymbol: {
            search: filter,
            mode: FilteringMode.Insensitive
          }
        }
      ]
    }
  }),
  formatData: (data) => {
    return data.incomingTransactions.nodes.map((node) => ({
      id: node.id,
      Icon: IncomingTransactionIcon,
      title: 'Incoming Transaction',
      subtitle: `Incoming transaction for ${node.client.name}`,
      endAdornment: (
        <Chip
          label={node.network}
          color={({
            [BlockchainNetwork.Ethereum]: 'primary',
            [BlockchainNetwork.Polygon]: 'secondary',
            [BlockchainNetwork.Avalanche]: 'error',
            [BlockchainNetwork.Arbitrum]: 'info'
          } as any)[node.network] || 'warning'}
        />
      ),
      onClick: ({ navigate, snackbar }) => {
        if (node.interaction) {
          navigate(`/interactions/${node.interaction.id}/details/transaction/incoming`);
        } else {
          snackbar.enqueueSnackbar(
            'Transaction is not part of interaction. There ' +
            'is no screen yet for viewing such transactions'
          );
        }
      }
    }));
  }
};
