import React from 'react';

import { useDrag } from 'react-dnd';
import { useSearchParams } from 'react-router-dom';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';

import formatDistance from 'date-fns/formatDistance';
import GenericFileIcon from '@mui/icons-material/Description';
import CommentsIcon from '@mui/icons-material/InsertCommentRounded';
import PendingDeletionIcon from '@mui/icons-material/AutoDeleteRounded';

import { Title } from '@backed-fi/compound';
import { DocumentContextMenu } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/DocumentContextMenu';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';

// region Props

interface Props {
  documentId: string;

  name?: string;
  createdAt?: Date;
}

// endregion

export const DocumentRowInternal: React.FC<Props> = ({
  documentId,
  ...props
}) => {
  const [params, setParams] = useSearchParams();
  const { document } = useDocumentContext();

  // region Drag & Drop

  const [, drag] = useDrag<{ documentId: string }, any, { isDragging: boolean }>(() => ({
    type: 'Document',
    item: {
      documentId
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  // endregion

  const name = React.useMemo(() => (
    document?.name || props.name || 'Loading...'
  ), [document, props.name]);

  // region Actions

  const onFileClick = async () => {
    if (!document?.fetched) {
      await document?.fetch();
    }

    // Here the document for sure is fetched and this if
    // is only to correct the types
    if (document?.fetched) {
      window.open(document.url!, '_blank');
    }
  };

  const onOpenDeletionRequestInfo = (event: any) => {
    setParams({
      documentId
    });

    event.stopPropagation();
  };

  // endregion

  // region Effects

  React.useEffect(() => {
    if (!document?.fetched) {
      document?.fetch();
    }
  }, []);

  // endregion

  return (
    <DocumentContextMenu documentId={documentId}>
      <Box
        ref={drag}
        onClick={onFileClick}
        sx={{
          pl: '1rem',
          py: '.5rem',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          borderRadius: '4px',

          '&:hover': {
            backgroundColor: 'utility.elementBackgroundHovered'
          }
        }}
      >
        <GenericFileIcon />

        <Box
          sx={{
            ml: '1rem',
            gap: '1.5rem',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Title
            small
            boxed
            loading
            title={name}
            subtitle={
              !!document?.fetched
                ? 'Created ' + formatDistance(
                new Date(document.createdAt),
                new Date(),
                {
                  addSuffix: true
                }
              ) : undefined
            }
          />

          {(document?.fetched && document.isPendingDeletion) && (
            <Tooltip title="The document has pending deletion request. Click for more details">
              <IconButton
                onClick={onOpenDeletionRequestInfo}
              >
                <PendingDeletionIcon />
              </IconButton>
            </Tooltip>
          )}

          {(document?.fetched && !!document.commentsCount) && (
            <Box
              sx={{
                gap: '.5rem',
                display: 'flex'
              }}
            >
              <CommentsIcon />

              <Typography variant="small">
                {document.commentsCount} Comment{document.commentsCount !== 1 ? 's' : ''}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </DocumentContextMenu>
  );
};
