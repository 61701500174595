import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { useValueVerificationIncomingTransactionLazyQuery, useVerifyIncomingTransactionValueMutation, useValueVerificationOutgoingTransactionLazyQuery, useVerifyOutgoingTransactionValueMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Title } from '@backed-fi/compound';
import { ethers } from 'ethers';
import { useSearchParams } from 'react-router-dom';
import { CentsFormatter } from '@backed-fi/shared';
import Decimal from 'decimal.js';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

// region Graph

gql`
  query valueVerificationOutgoingTransaction($transactionId: String!) {
    outgoingTransaction(transactionId: $transactionId) {
      id

      network
      amount
      decimals
      tokenSymbol
      value
      valueCurrency
      isDepegged
    }
  }

  mutation verifyOutgoingTransactionValue($input: VerifyOutgoingTransactionValueInput!) {
    verifyOutgoingTransactionValue(input: $input)
  }

  query valueVerificationIncomingTransaction($transactionId: String!) {
    incomingTransaction(transactionId: $transactionId) {
      id

      network
      amount
      decimals
      tokenSymbol
      value
      valueCurrency
      isDepegged
    }
  }

  mutation verifyIncomingTransactionValue($input: VerifyIncomingTransactionValueInput!) {
    verifyIncomingTransactionValue(input: $input)
  }
`;

// endregion

// region Form Schema

const schema = z.object({
  exchangeRate: z.string()
    .refine((x) => new Decimal(x), 'The value must be a valid decimal number')
    .transform((x) => new Decimal(x).toString()),

})

// endregion

// region Props

interface Props {
  transactionId: string;
  transactionType: 'Incoming' | 'Outgoing';
  onVerified?: () => any;
}

// endregion

export const TransactionValueVerification: React.FC<Props> = ({ transactionId, transactionType, onVerified }) => {
  const isForIncomingTransaction = transactionType === 'Incoming';
  const dialog = useToggle();
  const isApproving = useToggle();
  const snackbar = useSnackbar();

  const [searchParams] = useSearchParams();


  const [verifyMutation] = isForIncomingTransaction ? useVerifyIncomingTransactionValueMutation() : useVerifyOutgoingTransactionValueMutation();
  const [fetchData, { data: dataRaw }] = isForIncomingTransaction ? useValueVerificationIncomingTransactionLazyQuery() : useValueVerificationOutgoingTransactionLazyQuery();
  const data = dataRaw! ?? {};
  const transaction = 'incomingTransaction' in data ? data.incomingTransaction : data.outgoingTransaction;

  // region Form Control

  const form = useForm<z.infer<typeof schema>>({
    resolver: zodResolver(schema)
  });

  const { errors } = form.formState;

  // endregion
  
  // region Effect

  React.useEffect(() => {
    const searchParamsId = searchParams.get(`verifyValue${transactionType}TransactionId`);

    if (searchParamsId && searchParamsId === transactionId) {
      dialog.setTrue();
    }
  }, []);

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          transactionId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(async (data) => {
    try {
      isApproving.setTrue();

      await verifyMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          `${transactionType.toLowerCase()}Transactions`,
          'interactionDetailsOverview'
        ],
        variables: {
          input: {
            transactionId,
            exchangeRate: data.exchangeRate
          }
        }
      });

      if(transactionType === 'Outgoing') {
        // Wait for outgoing transaction to be marked for approval
        await new Promise(res => setTimeout(res, 2500));
      }

      if(onVerified)
        onVerified();

      dialog.setFalse();

      snackbar.enqueueSnackbar(`Successfully verified the ${transactionType.toLowerCase()} transaction value`, {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar(`An error occurred while verifying the ${transactionType.toLowerCase()} transaction value`, {
        variant: 'error'
      });
    } finally {
      isApproving.setFalse();
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>
        Verify Value
      </Button>

      {dialog.open && transaction && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Verify Transaction Value"
              subtitle="Provide current stablecoin exchange rate"
            />

            {/* region Labels */}


            <InfoLabel
              label="Value estimated by system"
              tooltip="Transaction value estimated by system at the time of receiving it"
              content={
                transaction
                  ? CentsFormatter.format(transaction.value!, transaction.valueCurrency!)
                  : undefined
              }
            />

            <InfoLabel
              label="Network"
              content={transaction?.network}
            />

            <InfoLabel
              label="Token Symbol"
              content={transaction?.tokenSymbol}
            />

            <InfoLabel
              label="Token Amount"
              content={
                transaction
                  ? Number(ethers.utils.formatUnits(transaction.amount, transaction.decimals)).toFixed(6)
                  : undefined
              }
            />

            <TextField
              fullWidth
              label="Exchange Rate"
              error={!!errors?.exchangeRate}
              helperText={errors?.exchangeRate?.message ?? 'The fiat to stablecoin exchange rate'}
              {...form.register('exchangeRate')}

              InputProps={{
                endAdornment: (
                  <Typography>
                    {transaction.valueCurrency}/{transaction.tokenSymbol}
                  </Typography>
                )
              }}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={isApproving.value}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={isApproving.value}
                onClick={onApprove}
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
