import React from 'react';
import { useParams } from 'react-router-dom';

import { CollateralisationGraph } from '../components/CollaterisationGraph';

export const TokenReconciliationPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  return (
    <CollateralisationGraph
      tokenId={params.id!}
    />
  );
};
