import { Alert, Box, Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { ApproveOutgoingTransactionQueryQuery, useApproveOutgoingTransactionMutation, useApproveOutgoingTransactionQueryLazyQuery, useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Title } from '@backed-fi/compound';
import { ethers } from 'ethers';
import { useSearchParams } from 'react-router-dom';

// region Graph

gql`
  query approveOutgoingTransactionQuery($transactionId: String!) {
    outgoingTransaction(transactionId: $transactionId) {
      id

      network
      amount
      decimals
      tokenSymbol

      receiverAddress
      contractAddress

      interaction {
        type
      }
    }
  }

  query CanSystemWalletSatisfyOutgoingTransactionExecution($input: CanSystemWalletSatisfyOutgoingTransactionExecutionInput!) {
    canSystemWalletSatisfyOutgoingTransactionExecution(input: $input) {
      gas,
      tokens
    }
  }

  mutation approveOutgoingTransaction($input: ApproveOutgoingTransactionInput!) {
    approveOutgoingTransaction(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  transactionId: string;
}

// endregion

export const OutgoingTransactionApproval: React.FC<Props> = ({ transactionId }) => {
  const dialog = useToggle();
  const isApproving = useToggle();
  const snackbar = useSnackbar();

  const [searchParams] = useSearchParams();


  const [approveMutation] = useApproveOutgoingTransactionMutation();
  const [fetchData, { data }] = useApproveOutgoingTransactionQueryLazyQuery();

  const { outgoingTransaction } = (data ?? {}) as ApproveOutgoingTransactionQueryQuery;


  const {
    data: systemWalletSatisfy,
    loading: isVerificatingSystemWallet,
    error: isVerificatingSystemWalletError
  } =
    useCanSystemWalletSatisfyOutgoingTransactionExecutionQuery({
      variables: {
        input: {
          outgoingTransactionId: transactionId
        }
      }
    });

  const isSystemWalletReady =
    systemWalletSatisfy?.canSystemWalletSatisfyOutgoingTransactionExecution?.tokens &&
    systemWalletSatisfy?.canSystemWalletSatisfyOutgoingTransactionExecution?.gas;
  const systemWalletErrors = [
    ...!systemWalletSatisfy?.canSystemWalletSatisfyOutgoingTransactionExecution?.gas ? ['gas'] : [],
    ...!systemWalletSatisfy?.canSystemWalletSatisfyOutgoingTransactionExecution?.tokens ? ['funds'] : []
  ];


  // region Effect

  React.useEffect(() => {
    const searchParamsId = searchParams.get('approveOutgoingTransactionId');

    if (searchParamsId && searchParamsId === transactionId) {
      dialog.setTrue();
    }
  }, []);

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          transactionId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Approve

  const onApprove = async () => {
    try {
      isApproving.setTrue();

      await approveMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'outgoingTransactions'
        ],
        variables: {
          input: {
            transactionId
          }
        }
      });

      dialog.setFalse();

      snackbar.enqueueSnackbar('Successfully approved the outgoing transaction', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred while approving the outgoing transaction', {
        variant: 'error'
      });
    } finally {
      isApproving.setFalse();
    }
  };

  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>
        Approve
      </Button>

      <Dialog
        {...dialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Title
            title="Approve Transaction"
            subtitle="Confirm and authorize the outgoing transaction"
          />

          {/* region Labels */}

          <InfoLabel
            label="Network"
            content={outgoingTransaction?.network}
          />

          <InfoLabel
            label="Interaction Type"
            content={outgoingTransaction?.interaction.type}
          />

          <InfoLabel
            label="Token Symbol"
            content={outgoingTransaction?.tokenSymbol}
          />

          <InfoLabel
            label="Token Amount"
            content={
              outgoingTransaction
                ? Number(ethers.utils.formatUnits(outgoingTransaction.amount, outgoingTransaction.decimals)).toFixed(6)
                : undefined
            }
          />

          <InfoLabel
            label="Raw Amount"
            content={outgoingTransaction?.amount}
          />

          <InfoLabel
            label="Decimals"
            content={outgoingTransaction?.decimals}
          />

          <InfoLabel
            copy
            label="Receiver Address"
            content={outgoingTransaction?.receiverAddress}
          />

          <InfoLabel
            copy
            label="Token Contract Address"
            content={outgoingTransaction?.contractAddress}
          />


          {
            !isVerificatingSystemWallet && (

              <React.Fragment>
                {isSystemWalletReady && (<Alert
                  severity="success"
                  sx={{
                    mt: '.5rem'
                  }}>
                  System wallet has enough gas and funds to cover the transaction
                </Alert>)
                }
                {!isVerificatingSystemWalletError && systemWalletErrors.length > 0 && (<Box><Alert
                  severity="error"
                  sx={{
                    mt: '.5rem'
                  }}
                >
                  Not enough <b>{systemWalletErrors.join(', ').concat(' ')}</b>
                  to cover the transaction, please prepare the account for the order execution.
                </Alert></Box>)
                }
                {isVerificatingSystemWalletError && (<Alert
                  severity="warning"
                  sx={{
                    mt: '.5rem'
                  }}
                >
                  Could not verify system wallet, before you execute the outgoing transaction make sure that there are
                  enough tokens and gas to cover the transaction that will follow
                </Alert>)

                }
              </React.Fragment>)
          }
          {/* endregion */}

          <Box
            sx={{
              gap: '.5rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton
              color="secondary"
              disabled={isApproving.value}
              onClick={dialog.setFalse}
            >
              Later
            </LoadingButton>

            <LoadingButton
              disabled={!isVerificatingSystemWalletError && systemWalletErrors.length > 0}
              loading={isApproving.value}
              onClick={onApprove}
            >
              Approve
            </LoadingButton>
          </Box>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};
