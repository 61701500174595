import React from 'react';
import { InteractionStatus } from '@backed-fi/graphql';
import { Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from '@mui/material';

import CompletedIcon from '@mui/icons-material/EmojiEventsRounded';
import FailedIcon from '@mui/icons-material/ErrorOutlineRounded';

// region Steps

const steps = [
  {
    stepName: 'Initialised',
    description:
      'The client has send funds to their sweeping wallet and ' +
      'the system has detected them',
    status: []
  }, {
    stepName: 'Pre-Processing',
    description:
      'The system is doing pre-processing on the incoming transactions. That ' +
      'includes limit checks, transaction screening and more',
    status: [
      InteractionStatus.PendingIncomingTransactionProcessing,
      InteractionStatus.PendingInternalFundsMovement,
      InteractionStatus.PendingLimitsCheck
    ]
  }, {
    stepName: 'Pending Wallet Registration',
    description:
      'The wallet of the client was not registered. In order for the transaction ' +
      'to continue executing the client must register their wallet',
    status: [
      InteractionStatus.PendingWalletRegistration
    ],
    hide: true
  }, {
    stepName: 'Order Processing',
    description:
      'The interaction is waiting for brokerage order to be executed',
    status: [
      InteractionStatus.PendingOrdersExecution
    ]
  }, {
    stepName: 'Pending Manual Action',
    description:
      'The transaction is at state, in which it cannot be handled ' +
      'automatically and needs help from an admin',
    status: [
      InteractionStatus.ManualActionRequired
    ],
    hide: true
  }, {
    stepName: 'Pending Payout',
    description:
      'Tokens are currently being sent to the client. The outgoing transaction ' +
      'may require admin approval',
    status: [
      InteractionStatus.PendingPayout
    ],
  }, {
    stepName: 'Completed',
    description:
      'The interaction was successfully completed',
    status: [
      InteractionStatus.Completed
    ],
    hide: [
      InteractionStatus.Failed
    ],
    icon: <CompletedIcon />
  }, {
    stepName: 'Failed',
    description:
      'Something went wrong when executing the interaction. A refund was/should be issued',
    status: [
      InteractionStatus.Failed
    ],
    hide: true,
    icon: <FailedIcon />
  }
];


// endregion

// region Props

interface Props {
  status: InteractionStatus;
}

// endregion

export const InteractionProgress: React.FC<Props> = ({ status }) => {
  const theme = useTheme();
  const shouldUseVerticalStepper = useMediaQuery(theme.breakpoints.down('md'));

  const availableSteps = React.useMemo(() => {
    // If no status is present, return an empty array
    if (!status) {
      return [];
    }

    return steps.filter((step) => {
      if (step.hide) {
        if (
          Array.isArray(step.hide)
            // If it is array hide it if we are currently in any of the array statuses
            ? step.hide.includes(status)

            // If it is boolean hide it unless we are currently at that status
            : !step.status.includes(status)
        ) {
          return null;
        }
      }

      return step;
    });
  }, [status]);

  return (
    <Stepper
      orientation={shouldUseVerticalStepper ? 'vertical' : 'horizontal'}
      activeStep={availableSteps.findIndex((x) => x.status.includes(status))}
      alternativeLabel
      sx={{
        alignItems: 'stretch'
      }}
    >
      {availableSteps.map((step) => {
        return (
          <Step key={step.stepName}>
            <StepLabel
              icon={step.icon}
            >
              <Typography>
                {step.stepName}
              </Typography>

              <Typography variant='small'>
                {step.description}
              </Typography>
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};
