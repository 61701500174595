import React from 'react';
import { Chip } from '@mui/material';

import IssuanceIcon from '@mui/icons-material/FileDownloadRounded';
import RedemptionIcon from '@mui/icons-material/FileUploadRounded';

import { InteractionType } from '@backed-fi/graphql';

interface Props {
  type: InteractionType
}

export const InteractionTypeBadge: React.FC<Props> = ({ type }) => {
  return (
    <Chip
      label={type}

      icon={({
        Issuance: <IssuanceIcon />,
        Redemption: <RedemptionIcon />
      } as any)[type]}

      color={({
        Issuance: 'primary',
        Redemption: 'secondary',
        Unknown: 'error',
        Custom: 'warning'
      } as any)[type] ?? 'primary'}
    />
  );
};
