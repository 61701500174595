import React from 'react';
import { Box, Dialog, TextField, CircularProgress, TextFieldProps, Tooltip, IconButton } from '@mui/material';
import { gql } from '@apollo/client';
import { AdminsForRoleQuery, useAdminsForRoleLazyQuery, useAssignRoleMutation } from '@backed-fi/graphql';
import { Title } from '@backed-fi/compound';
import { useDebounce } from '@backed-fi/hooks';

import AssignIcon from '@mui/icons-material/PersonAddAltRounded';
import NotFoundIcon from '@mui/icons-material/NotListedLocationRounded';
import { useSnackbar } from 'notistack';

// region Props

interface AssignRoleDialogProps extends React.ComponentProps<typeof Dialog> {
  /**
   * The ID of the role that will be assigned
   */
  roleId: string;

  /**
   * The name of the role that will be assigned
   */
  roleName: string;

  /**
   * List of IDs of admin users that will be hidden from
   * the search view. Used to hide admins that are already
   * in that role
   */
  filterAdmins?: { id: string }[];
}

// endregion

// region Graph

gql`
  query adminsForRole($where: AdminsWhereInput) {
    admins(where: $where) {
      nodes {
        id

        firstName
        lastName

        email
      }
    }
  }

  mutation assignRole($input: ToggleRoleInput!) {
    toggleRole(input: $input)
  }
`;

// endregion


export const AssignRoleDialog: React.FC<AssignRoleDialogProps> = ({
  roleId,
  roleName,
  filterAdmins,
  ...props
}) => {
  const snackbar = useSnackbar();

  // region State

  const [filter, setFilter] = React.useState<string>();
  const [availableAdmins, setAvailableAdmins] = React.useState<NonNullable<AdminsForRoleQuery['admins']>['nodes']>([]);

  const debouncedFilter = useDebounce(filter, 500);

  // endregion

  // region Networking

  const [assignRole] = useAssignRoleMutation();
  const [fetchAdmins, { loading: fetchingData }] = useAdminsForRoleLazyQuery();

  // endregion

  // region Effects

  React.useEffect(() => {
    const formattedFilter = {
      contains: debouncedFilter
    };

    (async () => {
      const { data } = await fetchAdmins({
        variables: {
          where: debouncedFilter ? {
            OR: [
              {
                email: formattedFilter
              }, {
                firstName: formattedFilter
              }, {
                lastName: formattedFilter
              }
            ]
          } : {}
        }
      });

      if (data) {
        setAvailableAdmins(
          data.admins?.nodes
            .filter((admin) => !(filterAdmins ?? []).some((x) => x.id === admin.id)) ?? []
        );
      }
    })();
  }, [debouncedFilter, filterAdmins, fetchAdmins]);

  // endregion

  // region Actions

  const onTextFieldChange: TextFieldProps['onChange'] = (event) => {
    setFilter(
      event.target.value
    );
  };

  const onAssignRole = (adminId: string) => async () => {
    const { data } = await assignRole({
      awaitRefetchQueries: true,
      refetchQueries: [
        'admins',
        'roleDetails',
        'adminsForRole'
      ],
      variables: {
        input: {
          roleId,
          adminId
        }
      }
    });

    if (typeof (props as any).setFalse === 'function') {
      (props as any).setFalse();
    }

    snackbar.enqueueSnackbar(`Successfully ${data?.toggleRole ? 'assigned' : 'unsigned'} role`, {
      variant: 'success'
    });
  };

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <Box
        sx={{
          p: '1rem 1.5rem'
        }}
      >
        <Title
          title="Assign Role"
          subtitle={`Assign ${roleName} role to admin`}
        />

        <TextField
          fullWidth
          label="Search"
          onChange={onTextFieldChange}
          spellCheck="false"
          autoCorrect="off"
          autoComplete="off"
          sx={{
            mt: '1rem'
          }}
          InputProps={{
            endAdornment: fetchingData && (
              <CircularProgress
                size={24}
              />
            )
          }}
        />

        {(availableAdmins.length && !fetchingData) ? (
          availableAdmins.map((admin) => (
            <Box
              key={admin.id}
              sx={{
                p: '.5rem',
                my: '.5rem',
                borderRadius: '4px',
                border: '1px solid',
                borderColor: 'utility.borderSubtle',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Box>
                <Title
                  small
                  title={`${admin.firstName} ${admin.lastName}`}
                  subtitle={admin.email}
                />
              </Box>

              <Tooltip title={`Assign ${roleName} to ${admin.firstName}`}>
                <IconButton onClick={onAssignRole(admin.id)}>
                  <AssignIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              my: '3rem',
              display: 'flex',
              flexFlow: 'column',
              alignItems: 'center'
            }}
          >
            <NotFoundIcon
              sx={{
                mb: '.25rem',
                fontSize: '3rem'
              }}
            />

            <Title
              small
              title="No admins found"
              subtitle="Maybe all of them went to get ice cream"
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};
