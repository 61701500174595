import React from 'react';

import { gql } from "@apollo/client";
import { Button, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { usePaginationModel } from '@backed-fi/hooks';
import { AddressTile, PageHeading } from '@backed-fi/compound';
import { BridgesQuery, useBridgesQuery } from '@backed-fi/graphql';
import { DateTimeFormatter } from '@backed-fi/shared';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { useNavigate } from 'react-router-dom';
import { RegisterBridgeDialog } from './components/RegisterBridgeDialog';

gql`
  query bridges(
    $page: Int,
    $pageSize: Int
  ) {
    bridges(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        createdAt
        address
        network
        explorerUrl
      }

      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }

    }
  }
`;

export const BridgesListPage: React.FC = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  const navigate = useNavigate();

  const { data, loading } = useBridgesQuery();

  const { bridges } = (data || {}) as BridgesQuery;

  return <>
    <PageHeading
      title="Bridges"
      breadcrumbs={[
        {
          text: 'Bridges'
        }
      ]}
    >
      <RegisterBridgeDialog />
    </PageHeading>

    <DataGrid
      {...pagination}
      autoHeight
      loading={loading}
      rows={bridges?.nodes ?? []}
      rowCount={bridges?.page.totalNodes}
      rowHeight={70}
      columns={[
        {
          width: 300,
          field: 'createdAt',
          headerName: 'Timestamp',
          renderCell: ({
            value,
            row
          }) => {
            return (
              <Typography
                onClick={() => {
                  navigate(`/bridges/${row.id}`)
                }}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }
                }}
              >
                {DateTimeFormatter.format(new Date(value))}
              </Typography >
            );
          }
        }, {
          width: 300,
          flex: 1,
          field: 'address',
          headerName: 'Address',
          renderCell: ({
            value,
            row
          }) => {
            return (
              <AddressTile address={value} explorerUrl={row.explorerUrl} />
            )
          }
        },
        {
          width: 300,
          field: 'network',
          headerName: 'Network',
          renderCell: ({
            value
          }) => {
            return (<BlockchainNetworkLabel
              label={`${value} Network`}
              network={value}
              size={24}
            />)
          }
        },
      ]}
    />
  </>;
}