import { Box, Dialog, DialogContent, Typography } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { RefundDetailsQuery, useExecuteRefundMutation, useRefundDetailsQuery } from '@backed-fi/graphql';
import { InfoLabel } from '@backed-fi/compound';
import { LoadingButton } from '@mui/lab';

import isPast from 'date-fns/isPast';
import { useSnackbar } from 'notistack';


// region Props

interface Props {
  /**
   * The ID of the refund that will be executed
   */
  refundId: string;

  onClose: () => Promise<void>;
}

// endregion

// region Graph

gql`
  query refundDetails($refundId: String!) {
    refund(
      where: {
        refundId: $refundId
      }
    ) {
      id

      status

      reason
      creationReason

      refundDefinition

      incomingTransactions {
        id

        hash

        benefactorAddress
        beneficiaryAddress

        amount
        network
        problems
        tokenSymbol
        decimals
        displayAmount
        contractAddress
      }

      client {
        id
        name

        frozenUntil

        verificationProfile {
          riskLevel
          currentTier
        }
      }
    }
  }

  mutation executeRefund($input: ExecuteRefundInput!) {
    executeRefund(input: $input)
  }
`;

// endregion

export const ExecuteRefundDialog: React.FC<Props> = ({ refundId, ...props }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [executeRefund, { loading: submitting }] = useExecuteRefundMutation();
  const { data, loading } = useRefundDetailsQuery({
    variables: {
      refundId
    }
  });

  // endregion

  // region Destructuring

  const { refund } = (data || {}) as RefundDetailsQuery;

  // endregion

  // region Actions

  const onExecuteRefund = async () => {
    await executeRefund({
      variables: {
        input: {
          refundId
        }
      }
    });

    await props.onClose();

    snackbar.enqueueSnackbar('Refund successfully submitted for execution', {
      variant: 'success'
    });
  };

  // endregion

  return (
    <Dialog
      open
      fullWidth
      maxWidth='md'
      onClose={props.onClose}
    >
      <DialogContent>
        <Typography variant='heading'>
          Execute Refund
        </Typography>

        {/* region Loaded State - Execution Overview */}

        {(!loading && refund) && (
          <React.Fragment>
            {/* region Client Details */}

            <Typography
              mt='2rem'
              variant='titleSmall'
            >
              Client
            </Typography>

            <Typography variant='subtitleSmall'>
              Details about the client that we are refunding
            </Typography>

            <Box
              sx={{
                mt: '1rem',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr'
              }}
            >
              <InfoLabel
                label='Name'
                content={refund.client.name}
              />

              <InfoLabel
                label='Refund Count'
                content="Unknown" // @todo
              />

              <InfoLabel
                label='Frozen'
                content={
                  (!refund.client.frozenUntil || isPast(refund.client.frozenUntil))
                    ? 'No, the client is not frozen'
                    : `Yes, client frozen until ${refund.client.frozenUntil}`
                }
              />


              <InfoLabel
                label='Risk Level'
                content={refund.client.verificationProfile?.riskLevel}
              />

              <InfoLabel
                label='Current Verification Tier'
                content={refund.client.verificationProfile?.currentTier}
              />

            </Box>

            {/* endregion */}

            {/* region Incoming Transaction Details */}

            <Typography
              mt='1rem'
              variant='titleSmall'
            >
              Incoming Transaction
            </Typography>

            <Typography
              variant='subtitleSmall'
            >
              Details about the incoming transaction for which this refund is made
            </Typography>

            {refund.incomingTransactions.map((tx) => (
              <Box
                key={tx.id}
                sx={{
                  mt: '1rem',
                  display: 'grid',
                  gridTemplateColumns: '1fr'
                }}
              >
                <InfoLabel
                  label='Asset'
                  content={tx.tokenSymbol}
                />

                <InfoLabel
                  label='Refund Reason'
                  content={refund.creationReason}
                />

                <InfoLabel
                  copy
                  label='Transaction Hash'
                  content={tx.hash}
                />

                <InfoLabel
                  label='Token Amount'
                  content={tx.displayAmount}
                />

                <InfoLabel
                  label='Blockchain Network'
                  content={tx.network}
                />

                <InfoLabel
                  label='Transaction Problems'
                  content={
                    !tx.problems.length
                      ? 'No, no problems were present with the transaction'
                      : `Yes, ${tx.problems.join(', ')}`
                  }
                />
              </Box>
            ))}

            {/* endregion */}

            {/* region Refund Details */}

            <Typography
              mt='1rem'
              variant='titleSmall'
            >
              Refund Details
            </Typography>

            <Typography
              mb='1rem'
              variant='subtitleSmall'
            >
              Execution details and options for the refund
            </Typography>

            <Typography
              variant="code"
            >
              {JSON.stringify(refund.refundDefinition, null, 4)}
            </Typography>


            {/* endregion */}

            <Box>
              <LoadingButton
                loading={submitting}
                onClick={onExecuteRefund}
                sx={{
                  float: 'right'
                }}
              >
                Execute Refund
              </LoadingButton>
            </Box>
          </React.Fragment>
        )}

        {/* endregion */}
      </DialogContent>
    </Dialog>
  );
};
