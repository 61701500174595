import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { gql } from '@apollo/client';
import { useInvitationsQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';

import UnclaimedIcon from '@mui/icons-material/HighlightOffRounded';
import ClaimedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { InviteClientDialog } from '../components/InviteClientDialog';
import { usePaginationModel } from '@backed-fi/hooks';
import { DateTimeFormatter } from '@backed-fi/shared';

// region GraphQL

gql`
  query invitations($page: Int, $pageSize: Int) {
    invitations(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        createdAt
        updatedAt

        email

        isClaimed
        claimedAt
      }

      page {
        totalNodes
      }
    }
  }
`;

// endregion

export const InvitationsListPage: React.FC = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region Networking

  const query = useInvitationsQuery({
    variables: {
      ...paginationVariables
    }
  });

  // endregion

  // region State

  const [inviteDialogOpen, setInviteDialogOpen] = React.useState<boolean>(false);

  // endregion

  return (
    <Box>
      {/* region Dialogs */}

      {inviteDialogOpen && (
        <InviteClientDialog
          onDismiss={() => {
            setInviteDialogOpen(false);
          }}
        />
      )}

      {/* endregion */}

      <PageHeading
        title="Client Invitations"
        breadcrumbs={[
          {
            text: 'Clients'
          }, {
            text: 'Invitations'
          }
        ]}
      />

      <Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mb: '1rem'
          }}
        >
          <Button
            onClick={() => setInviteDialogOpen(true)}
          >
            Invite Client
          </Button>
        </Box>

        <DataGrid
          {...pagination}
          autoHeight
          loading={query.loading}
          rows={query.data?.invitations?.nodes || []}
          rowCount={query.data?.invitations?.page.totalNodes}
          sx={{
            backgroundColor: 'utility.backgroundSubtle'
          }}
          columns={[{
            field: 'email',
            headerName: 'Email',
            minWidth: 300,
            flex: 1
          }, {
            field: 'createdAt',
            headerName: 'Invited At',
            width: 300,
            renderCell: ({ value }) => (
              <Typography>
                {DateTimeFormatter.format(value)}
              </Typography>
            )
          }, {
            field: 'isClaimed',
            headerName: 'Invitation Claimed',
            width: 200,
            renderCell: ({ value }) => (
              <Box>
                {value ? (
                  <ClaimedIcon />
                ) : (
                  <UnclaimedIcon />
                )}
              </Box>
            )
          }]}
        />
      </Box>
    </Box>
  );
};
