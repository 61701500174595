import React from 'react';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { useMultisignatureWalletsListQuery } from '@backed-fi/graphql';
import { useNavigate } from 'react-router-dom';
import { RegisterMultisignatureWalletDialog } from './components/RegisterMultisignatureWalletDialog';

gql`
  query multisignatureWalletsList {
    multisignatureWallets {
      id
      address
      networks
      title
      description
    }
  }
`;

export const MultisignatureWalletsListPage: React.FC = () => {
  const navigate = useNavigate();

  // ---- State ---- //

  // ---- Networking ---- //
  const {
    data,
    loading
  } = useMultisignatureWalletsListQuery();

  // ---- Actions ---- //

  // ---- Data destructuring ---- //
  const { multisignatureWallets } = data || {};

  return (
    <Box
      sx={{
        height: 'calc(100vh - 3rem)',
        display: 'flex'
      }}
    >
      <Box
        sx={{
          flex: 1,
          mb: '2rem',
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        <PageHeading
          title="Multisignature Wallets"
          breadcrumbs={[{
            text: 'Multisignature Wallets'
          }]}
        >
          <Box>
            <RegisterMultisignatureWalletDialog />
          </Box>
        </PageHeading>

        <Box
          sx={{
            flex: 1
          }}
        >
          <DataGrid
            loading={loading}
            rows={multisignatureWallets || []}
            onRowClick={(row) => {
              navigate(`/internal/multisignature/details/${row.id}/overview`);
            }}
            columns={[{
              flex: 2,
              field: 'title',
              headerName: 'Title',
              renderCell: ({ row }) => (
                <Typography>
                  {row.title}
                </Typography>
              )
            }, {
              width: 400,
              field: 'address',
              headerName: 'Address'
            }]}
            sx={{
              backgroundColor: 'utility.backgroundSubtle'
            }}
          />
        </Box>
      </Box>
    </Box>
  );

};
