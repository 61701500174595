import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useInjectionDetailsQuery } from '@backed-fi/graphql';
import React from 'react';
import { Box } from '@mui/material';

// region Graph

gql`
  query injectionDetails($injectionId: String!) {
    injection(id: $injectionId) {
      id

      status
      payload
    }
  }
`;

// endregion

export const InjectionDetailsPage = () => {
  const params = useParams<{ id: string }>();

  const { data } = useInjectionDetailsQuery({
    variables: {
      injectionId: params.id!
    }
  });

  return (
    <Box>
      {JSON.stringify(data)}
    </Box>
  );
};
