import { Box } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { AdminPermission, InteractionDetailsOverviewQuery, useInteractionDetailsOverviewQuery } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { InfoCard, InfoLabel, Protector, Title } from '@backed-fi/compound';
import { CentsFormatter } from '@backed-fi/shared';
import { InteractionProgress } from '@backed-fi/admin/src/app/domain/Interactions/Interactions/pages/Details/components/InteractionProgress';
import { countries } from 'countries-list';
import { Comments } from '@backed-fi/admin/src/app/components/Comments';
import { compareAsc } from 'date-fns';


// region Graph

gql`
  query interactionDetailsOverview($interactionId: String!) {
    interaction(
      where: {
        id: $interactionId
      }
    ) {
      id

      createdAt
      updatedAt

      type
      status
      failureReason

      flags
      isInjected

      currency
      actualTotalValue
      expectedTotalValue

      incomingTransactions {
        createdAt
      }

      orders {
        createdAt
      }

      outgoingTransactions {
        createdAt
      }

      fee {
        id
        fee
        principal
      }

      client {
        id
        humanId

        name
        jurisdiction
        isProfessional
        contactEmail
        classification

        usage {
          issuanceDailyUsage
          issuanceMonthlyUsage

          redemptionDailyUsage
          redemptionMonthlyUsage
        }
      }

      events {
        id
        createdAt

        type
        payload
      }
    }
  }
`;


// endregion


export const InteractionDetailsOverview: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  // region Networking

  const { data } = useInteractionDetailsOverviewQuery({
    pollInterval: 30_000,
    variables: {
      interactionId: params.interactionId!
    }
  });

  const { interaction } = (data || {}) as InteractionDetailsOverviewQuery;
  const initiatedAt = React.useMemo(() => {
    if (interaction) {
      if (interaction.isInjected) {
        if (interaction.incomingTransactions.length > 0) {
          return interaction.incomingTransactions.map(i => i.createdAt).sort(compareAsc)[0];
        }
        if (interaction.orders.length > 0) {
          return interaction.orders.map(o => o.createdAt).sort(compareAsc)[0];
        }
        if (interaction.outgoingTransactions.length > 0) {
          return interaction.outgoingTransactions.map(o => o.createdAt).sort(compareAsc)[0];
        }
      }

      return interaction.createdAt;
    }
  }, [interaction])
  // endregion

  return interaction
    ? (
      <Box>
        <Box
          sx={{
            my: '2rem'
          }}
        >
          <InteractionProgress
            status={interaction.status}
          />
        </Box>

        <Box
          sx={{
            mt: '1rem',
            gap: '3rem',
            display: 'grid',
            gridTemplateColumns: {
              xxs: '1fr',
              xl: '5fr 2fr'
            }
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexFlow: 'column',
              gap: {
                xss: '1rem',
                md: '3rem'
              }
            }}
          >
            <Box>
              <Title
                title="Interaction Details"
                subtitle="Get a quick overview of the important details for this interaction"
              />

              {/* region Interaction Tiles */}

              <Box
                sx={{
                  mt: '1rem',
                  gap: '1rem',
                  display: 'grid',
                  gridTemplateColumns: {
                    xxs: '1fr',
                    lg: '1fr 1fr 1fr'
                  }
                }}
              >
                <InfoCard
                  title="Total Value"
                  data={
                    CentsFormatter.format(
                      Number(interaction.actualTotalValue) || interaction.expectedTotalValue,
                      interaction.currency
                    )
                  }
                />

                <InfoCard
                  title="Fee"
                  data={
                    CentsFormatter.format(
                      Number(interaction.fee?.fee),
                      interaction.currency
                    )
                  }
                />

                <InfoCard
                  title="Type"
                  data={interaction.type}
                />
              </Box>

              {/* endregion */}

              {/* region Interaction Label */}

              <Box
                sx={{
                  maxWidth: '800px',
                  display: 'grid',
                  gridTemplateColumns: {
                    xss: '1fr',
                    md: '1fr 1fr'
                  }
                }}
              >
                <InfoLabel
                  copy
                  label="Interaction ID"
                  content={interaction.id}
                />

                <InfoLabel
                  copy
                  label="Fee Basis Points"
                  content={Math.round((interaction.fee?.fee / interaction.fee?.principal) * 10000).toString()}
                />

                <InfoLabel
                  label="Status"
                  content={interaction.status}
                />

                <InfoLabel
                  label="Initiated At"
                  content={initiatedAt}
                />

                <InfoLabel
                  label="Last Update At"
                  content={interaction.updatedAt}
                />

                <InfoLabel
                  label="Flags"
                  content={
                    interaction.flags.length
                      ? `Yes, ${interaction.flags.join(', ')}`
                      : 'No, the interaction does not have any flags'
                  }
                />

                <InfoLabel
                  label="Is injected"
                  content={interaction.isInjected ? 'Yes' : 'No'}
                />
              </Box>

              {/* endregion */}
            </Box>

            <Box>
              <Title
                title="Client Details"
                subtitle="Get a quick overview of the important details for the client"
              />

              {/* region Client Tiles */}

              <Box
                sx={{
                  mt: '1rem',
                  gap: '1rem',
                  display: 'grid',
                  gridTemplateColumns: {
                    xxs: '1fr',
                    lg: '1fr 1fr 1fr'
                  }
                }}
              >
                <InfoCard
                  title="Client Name"
                  data={interaction.client.name}
                />

                <InfoCard
                  title="Jurisdiction"
                  data={
                    interaction.client.jurisdiction !== 'Unknown'
                      ? countries[interaction.client.jurisdiction as keyof typeof countries]?.name
                      : 'Unknown'
                  }
                />

                <InfoCard
                  title="Classification"
                  data={interaction.client.classification}
                />
              </Box>


              {/* endregion */}

              {/* region Client Labels */}

              <Box
                sx={{
                  maxWidth: '800px',
                  display: 'grid',
                  gridTemplateColumns: {
                    xss: '1fr',
                    md: '1fr 1fr'
                  }
                }}
              >
                <InfoLabel
                  copy
                  label="Human ID"
                  content={interaction.client.humanId}
                />

                <InfoLabel
                  label="Client Name"
                  content={interaction.client.name}
                />

                <InfoLabel
                  copy
                  label="Client Contact Email"
                  content={interaction.client.contactEmail}
                />

                <InfoLabel
                  label="Jurisdiction"
                  content={interaction.client.jurisdiction}
                />

                <InfoLabel
                  label="Professional Status"
                  content={
                    interaction.client.isProfessional
                      ? 'Is professional investor'
                      : 'Is not professional investor'
                  }
                />

                <InfoLabel
                  label="Classification"
                  content={interaction.client.classification}
                />
              </Box>

              {/* endregion */}
            </Box>

          </Box>

          <Box>
            <Protector permissions={[AdminPermission.ComplianceRead]}>
              <Title
                title="Discussion"
                subtitle="The discussion for the given interaction"
              />

              <Box>
                <Comments
                  interactionId={params.interactionId!}
                />
              </Box>
            </Protector>


            {/*{*/}
            {/*  [...interaction.events]*/}
            {/*    .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())*/}
            {/*    .map((event) => (*/}
            {/*      <Box*/}
            {/*        key={event.id}*/}
            {/*        sx={{*/}
            {/*          p: '.5rem',*/}
            {/*          mb: '1rem',*/}
            {/*          border: '1px solid',*/}
            {/*          borderRadius: '4px',*/}
            {/*          borderColor: 'utility.borderElement',*/}
            {/*          backgroundColor: 'utility.elementBackground'*/}
            {/*        }}*/}
            {/*      >*/}
            {/*        <Typography*/}
            {/*          variant="titleSmall"*/}
            {/*          sx={{*/}
            {/*            fontSize: '18px'*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {event.type}*/}
            {/*        </Typography>*/}

            {/*        <Typography*/}
            {/*          variant="subtitleSmall"*/}
            {/*          sx={{*/}
            {/*            textTransform: 'capitalize'*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {formatRelative(new Date(event.createdAt), new Date())}*/}
            {/*        </Typography>*/}
            {/*      </Box>*/}
            {/*    ))*/}
            {/*}*/}
          </Box>
        </Box>
      </Box>
    )
    :
    null;
};
