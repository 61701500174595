import React from 'react';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { OrderSide, useBrokersQuery } from '@backed-fi/graphql';
import { EnumSelect, Title } from '@backed-fi/compound';
import { useInjectionContext } from '../../InjectionContext';
import { OrderInjectSchema } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionSchema';
import { CollateralSearch } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/CollateralSearch';
import { NavigationButtons } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/NavigationButtons';

export const InjectOrder: React.FC = () => {
  const toggle = useToggle();
  const context = useInjectionContext();

  const { data: brokers } = useBrokersQuery();

  // region Form Control

  const form = useForm<z.infer<typeof OrderInjectSchema>>({
    resolver: zodResolver(OrderInjectSchema)
  });

  const { errors } = form.formState;

  // endregion

  React.useEffect(() => {
    if (toggle.open) {
      form.reset({});
    }
  }, [toggle.open]);


  const onInject = form.handleSubmit((data) => {
    context.setInjectionState((p) => ({
      ...p,
      orders: [
        ...p.orders,
        data
      ]
    }));

    toggle.setFalse();
  });

  return (
    <Box
      sx={{
        mb: '1rem'
      }}
    >
      <Dialog
        {...toggle}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Title title="Inject Order" />

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr'
            }}
          >
            <Typography sx={{
              mt: '1rem',
              mb: '.5rem'
            }}>
              Order details
            </Typography>

            <EnumSelect
              enum={OrderSide}
              label="Order Side"
              error={!!errors.side}
              helperText={errors.side?.message}
              {...form.register('side')}
            />

            <CollateralSearch
              onChange={async (e, v: any) => {
                form.setValue('symbol', v.symbol);
              }}
            />

            <DateTimePicker
              label="Executed At"
              onChange={(date) => {
                if (date) {
                  form.setValue('executedAt', new Date((date as any).$d));
                }
              }}
            />

            <TextField
              label="Total Shares"
              error={!!errors.totalShares}
              helperText={errors.totalShares?.message}
              {...form.register('totalShares')}
            />

            <TextField
              label="Price Per Share (USD)"
              error={!!errors.pricePerShare}
              helperText={errors.pricePerShare?.message}
              {...form.register('pricePerShare')}
            />

            <TextField
              label="Exchange Rate"
              error={!!errors.exchangeRate}
              helperText={errors.exchangeRate?.message ?? 'The exchange rate from USD to the underlying currency'}
              {...form.register('exchangeRate')}
            />

            <Typography sx={{
              mt: '1rem',
              mb: '.5rem'
            }}>
              Broker details
            </Typography>

            <TextField
              select
              fullWidth
              label="Broker"
              error={Boolean(errors.brokerId)}
              helperText={errors.brokerId?.message}
              {...form.register('brokerId')}
            >
              {
                brokers?.brokers.nodes
                  .map((value) => (
                    <option key={value.id} value={value.id}>
                      {value.name}
                    </option>
                  ))
              }
            </TextField>

            <TextField
              label="Reference"
              error={!!errors.reference}
              helperText={errors.reference?.message}
              {...form.register('reference')}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button onClick={onInject}>
              Inject Order
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      <Box>
        {context.orders.map((order, index) => (
          <Typography key={index}>
            {order.side} order for {order.totalShares} {order.symbol}
          </Typography>
        ))}
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '.5rem',
          justifyContent: 'flex-end'
        }}
      >
        <Button onClick={toggle.setTrue}>
          Inject Order
        </Button>

        <NavigationButtons />
      </Box>
    </Box>
  );
};
