import React from 'react';
import { InjectionContextProvider } from '../InjectionContext';
import { InjectInteractionPageDefinition } from './Steps/PageDefintion';

export const InjectInteractionPage: React.FC = () => {
  return (
    <InjectionContextProvider>
      <InjectInteractionPageDefinition />
    </InjectionContextProvider>
  );
};
