import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { Box, Button, Card, Dialog, DialogContent, FormControlLabel, Switch, TextField, Typography } from '@mui/material';

import { AdminPermission, ClientFeature, ClientFeaturesQuery, useClientFeaturesQuery, useUpdateClientFeaturesMutation } from '@backed-fi/graphql';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { DateTimeFormatter } from '@backed-fi/shared';

import AddedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import SwapedIcon from '@mui/icons-material/SwapHorizRounded';
import RemovedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import { useAdminContext } from '../../../context/AdminContext';

const Graph = gql`
  query clientFeatures($clientId: ID!) {
    client(clientId: $clientId) {
      id

      name
      features
      isProfessional

      verificationProfile {
        approved
      }

      securityLogs(
        where: {
          type: FeatureToggled
        }
      ) {
        id
        createdAt
        payload
        message
        actorType

        actor {
          id
          firstName
          lastName
        }
      }
    }
  }

  mutation updateClientFeatures($input: UpdateClientFeaturesInput!) {
    updateClientFeatures(input: $input) {
      id
    }
  }
`;

// region Form Support

const FormSchema = z.object({
  message: z.string()
    .nonempty()
});

type FormSchema = z.infer<typeof FormSchema>;

// endregion

export const ClientFeaturesPage = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();
  const adminContext = useAdminContext();

  // region State

  const [modal, setModal] = React.useState<ClientFeature>();
  const [updating, setUpdating] = React.useState<ClientFeature[]>([]);
  const [canChangeFeature, setCanChangeFeatures] = React.useState<boolean>(false);

  // endregion

  // region Form Control

  const form = useForm<FormSchema>({
    resolver: zodResolver(FormSchema)
  });

  // endregion

  // region Networking

  const [updateFeatures] = useUpdateClientFeaturesMutation({
    refetchQueries: ['clientFeatures'],
    awaitRefetchQueries: true
  });

  const { data } = useClientFeaturesQuery({
    variables: {
      clientId: params.id!
    }
  });

  const { client } = (data || {}) as ClientFeaturesQuery;

  // endregion

  // region Effects

  React.useEffect(() => {
    setCanChangeFeatures(client?.verificationProfile?.approved ?? false);
  }, [client]);

  // endregion

  // region Actions

  const onOpenModal = (feature: ClientFeature) => {
    return () => {
      if (adminContext.permissions.includes(AdminPermission.ComplianceManage)) {

        setModal(feature);
      } else {
        snackbar.enqueueSnackbar('You don\'t have the permission to change client features', {
          variant: 'error'
        });
      }
    };
  };

  const onDismissModal = () => {
    setModal(undefined);
  };

  const onUpdateFeature = (feature: ClientFeature) => {
    return async ({ message }: FormSchema) => {
      setUpdating((prev) => [
        ...prev,
        feature
      ]);

      try {
        await updateFeatures({
          variables: {
            input: {
              message,
              clientId: params.id!,


              ...(data?.client?.features?.includes(feature) ? {
                featuresToRemove: [
                  feature
                ]
              } : {
                featuresToAdd: [
                  feature
                ]
              })
            }
          }
        });

        // Remove the message for following feature toggles
        form.reset();

        snackbar.enqueueSnackbar(`Successfully changes ${feature} feature`, {
          variant: 'success'
        });
      } finally {
        setUpdating((updating) => {
          return updating.filter((f) => f !== feature);
        });

        onDismissModal();
      }
    };
  };

  // endregion

  return (
    <Box>
      {/* region Change Feature Dialog */}

      {modal && (
        <Dialog
          open={!!modal}
          onClose={onDismissModal}
        >

          <DialogContent>
            <Typography variant='titleSmall'>
              Update Feature
            </Typography>

            <Typography variant='subtitleSmall'>
              Are you sure that you want to update the {modal} feature for {client?.name}? A record of you
              doing that will be saved to the database.
            </Typography>


            <Box my={2}>
              <Typography>
                Current: {client.features.includes(modal!) ? 'Enabled' : 'Disabled'}
              </Typography>

              <Typography>
                After Update: {client.features.includes(modal!) ? 'Disabled' : 'Enabled'}
              </Typography>
            </Box>

            <TextField
              fullWidth
              multiline
              rows={3}
              label='Change Reasoning (Required)'
              {...form.register('message')}
            />

            <Box
              sx={{
                gap: '8px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button onClick={onDismissModal} color='secondary'>
                Dismiss
              </Button>

              <Button onClick={form.handleSubmit(onUpdateFeature(modal))}>
                Update
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {/* endregion */}

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'column'
        }}
      >
        {data?.client && (
          <Box>
            {canChangeFeature ? (
              <Box
                sx={{
                  display: 'flex',
                  flexFlow: 'column'
                }}
              >
                {Object.keys(ClientFeature)
                  .map((feature) => {
                    if (feature === ClientFeature.Issuance && !client.isProfessional) {
                      return null;
                    }

                    return (
                      <FormControlLabel
                        control={
                          <Switch
                            onChange={onOpenModal(feature as ClientFeature)}
                            disabled={updating.includes(feature as ClientFeature)}
                            checked={data?.client?.features.includes(feature as ClientFeature)}
                          />
                        }
                        label={feature}
                      />
                    );
                  })}
              </Box>
            ) : (
              <Box>
                <Typography>
                  Changing features of client is not allowed until they are compliance approved
                </Typography>
              </Box>
            )}
          </Box>
        )}

        {!!client?.securityLogs?.length && (
          <Box
            sx={{
              my: '24px'
            }}
          >
            <Typography variant='title'>
              History
            </Typography>

            {client.securityLogs.map((log) => (
              <Card
                key={log.id}
                sx={{
                  p: '12px',
                  mt: '12px'
                }}
              >
                <Box
                  sx={{
                    mb: '12px',
                    width: '32px',
                    height: '32px',
                    borderRadius: '32px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'primary.textLowContrast',
                    backgroundColor: 'primary.elementBackground'
                  }}
                >
                  {!!(log.payload.featuresRemoved?.length && !log.payload.featuresAdded?.length) && (
                    <RemovedIcon />
                  )}

                  {!!(log.payload.featuresRemoved?.length && log.payload.featuresAdded?.length) && (
                    <SwapedIcon />
                  )}

                  {!!(!log.payload.featuresRemoved?.length && log.payload.featuresAdded?.length) && (
                    <AddedIcon />
                  )}
                </Box>

                <Typography
                  sx={{
                    color: 'utility.textLowContrast'
                  }}
                >
                  On {DateTimeFormatter.format(new Date(log.createdAt))}{' '}

                  <Typography display='inline' color='utility.textHighContrast'>
                    {log.actor ? (
                      `${log.actor.firstName} ${log.actor.lastName}`
                    ) : (
                      'the system'
                    )}
                  </Typography>
                  {' '}

                  {!!log.payload.featuresAdded?.length && (
                    <React.Fragment>
                      added feature/s{' '}
                      <Typography display='inline' color='utility.textHighContrast'>
                        {log.payload.featuresAdded.join(', ')}
                      </Typography>
                    </React.Fragment>
                  )}

                  {!!log.payload.featuresRemoved?.length && (
                    <React.Fragment>
                      removed feature/s{' '}
                      <Typography display='inline' color='utility.textHighContrast'>
                        {log.payload.featuresRemoved.join(', ')}
                      </Typography>
                    </React.Fragment>
                  )}
                </Typography>

                {log.message && (
                  <Typography ml={2}>
                    {log.message}
                  </Typography>
                )}
              </Card>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
};
