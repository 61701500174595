import { Box, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useProvideMultisignatureWalletTransactionSignatureMutation, useMultisignatureWalletTransactionSignaturePayloadQuery } from '@backed-fi/graphql';
import { signTransaction } from '@backed-fi/admin/src/app/domain/Blockchain/supply/helpers/signTransaction';
import { useWeb3Context } from '@backed-fi/context';
import { ConnectWeb3Button } from '@backed-fi/compound';

// region Graph

gql`
  query multisignatureWalletTransactionSignaturePayload($transactionId: String!) {
    multisignatureWalletTransaction(id: $transactionId) {
      id

      eip712Domain
      possibleSigners
      signatures {
        signerAddress
      }
      wallet {
        requiredSignatures
      }
    }
  }

  mutation ProvideMultisignatureWalletTransactionSignature($input: ProvideMultisignatureWalletTransactionSignatureInput!) {
    provideMultisignatureWalletTransactionSignature(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  transactionId: string;
}

// endregion

export const AddSignature: React.FC<Props> = ({ transactionId }) => {
  const snackbar = useSnackbar();
  const web3Context = useWeb3Context();

  const [provideSignatureMutation] = useProvideMultisignatureWalletTransactionSignatureMutation();
  const { data } = useMultisignatureWalletTransactionSignaturePayloadQuery({
    variables: {
      transactionId
    }
  });

  const [loading, setLoading] = React.useState(false);

  const canSign = useMemo(() => {
    if(!data || !web3Context.account)
      return false;
    return (data!.multisignatureWalletTransaction.possibleSigners)
    .includes(web3Context.account!.toLowerCase()) && !data!.multisignatureWalletTransaction.signatures.some(
      x => x.signerAddress === web3Context.account!.toLowerCase()
    )
  }, [data, web3Context.account])

  const onSign = async () => {
    try {
      setLoading(true);

      if (!window.ethereum) {
        throw new Error('No ethereum provider detected');
      }

      // That should never be the case, doing it only to fix the types
      if (!data) {
        throw new Error('Data not loaded');
      }

      const operation = data.multisignatureWalletTransaction;

      // Get the account
      const signer = web3Context.signer!;

      // Check if the address of the signer can sign the payload
      const signerAddress = await signer.getAddress();

      if (!operation.possibleSigners.includes(signerAddress.toLowerCase())) {
        const message = 'Cannot use that wallet to sign the transaction. Please connect another one!';

        throw new Error(message);
      }


      // Sign the payload
      const signature = await signTransaction(signer, data.multisignatureWalletTransaction.eip712Domain);

      // Send the signature to the backend
      await provideSignatureMutation({
        awaitRefetchQueries: true,
        variables: {
          input: {
            signature,
            address: signerAddress,
            transactionId: data.multisignatureWalletTransaction.id
          }
        },
        refetchQueries: [
          'multisignatureWalletTransactions'
        ]
      });
    } catch (e: any) {
      console.error(e);

      snackbar.enqueueSnackbar(`An error occurred: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box>
      {!web3Context.connected
        ? (
          <ConnectWeb3Button/>
        ) : (
          <Tooltip
            title={canSign ? "" : "Account already signed or unable to sign"}
            >
              <LoadingButton
                onClick={onSign}
                loading={loading || !data}
                disabled={!canSign}
              >
                Sign
              </LoadingButton>
          </Tooltip>
        )}
    </Box>
  );
};
