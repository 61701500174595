import React from 'react';
import { z } from 'zod';
import { gql } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { useActivateExternalWalletManuallyMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  externalWalletId: string
}

// endregion

// region Networking

const Graph = gql`
  mutation activateExternalWalletManually($input: ActivateExternalWalletManuallyInput!) {
    activateExternalWalletManually(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Schema

const FormSchema = z.object({
  comment: z.string()
    .optional()
});

type FormSchema = z.infer<typeof FormSchema>

// endregion

export const ActivateExternalWalletDialog: React.FC<Props> = ({ externalWalletId, ...props }) => {
  const snackbar = useSnackbar();

  const [activateExternalWalletManually] = useActivateExternalWalletManuallyMutation();

  // region Form Control

  const form = useForm<FormSchema>({
    resolver: zodResolver(FormSchema)
  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onActivateWallet = form.handleSubmit(async (data) => {
    await activateExternalWalletManually({
      variables: {
        input: {
          ...data,
          externalWalletId
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        'clientBankAccountsAndWallets'
      ]
    });

    form.reset({});

    snackbar.enqueueSnackbar('External wallet successfully activated');

    if (props.onClose) {
      props.onClose({}, 'escapeKeyDown');
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Activate External Wallet
        </Typography>

        <Typography variant='subtitleSmall'>
          Manually activate external wallet
        </Typography>

        <Box my={1}>
          <TextField
            fullWidth
            multiline
            rows='3'
            label='Comments'
            error={!!errors?.comment}
            helperText={errors?.comment?.message}
            {...form.register('comment')}
          />
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            onClick={onActivateWallet}
            loading={isSubmitting}
          >
            Activate Wallet
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
