import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { Box, Chip, LinearProgress, Typography } from '@mui/material';

import { ExportButton } from '@backed-fi/admin/src/app/components/ExportButton';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';

import { usePaginationModel } from '@backed-fi/hooks';
import { IncomingTransactionStatus, RefundStatus, useIncomingTransactionsQuery } from '@backed-fi/graphql';
import { Filters, filterToWhereQuery, FilterType } from '@backed-fi/admin/src/app/components/filter/Filters';

import { IncomingTransactionApproval } from '../../internal/components/IncomingTransactionApproval';
import { DateTimeFormatter } from '@backed-fi/shared';
import { HashTile } from '@backed-fi/compound';
import { TransactionValueVerification } from '../../internal/components/TransactionValueVerification';


gql`
  query incomingTransactions(
    $where: IncomingTransactionWhereInput
    $page: Int
    $pageSize: Int
  ) {
    incomingTransactions(
      where: $where,
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id
        createdAt

        hash
        explorerUrl
        index

        amount
        status
        type
        displayAmount
        tokenSymbol

        network
        score
        scoreProvider

        problems

        client {
          id
          name
        }

        tokenDeployment {
          id
          network

          token {
            name
            symbol
          }
        }

        refund {
          id
          status
        }

        interaction {
          id
          status
          failureReason
        }
      }

      page {
        currentPage
        totalNodes
        totalPages
      }
    }
  }

  mutation retriggerIncomingTransactionConfirmation($transactionId: String!) {
    retriggerIncomingTransactionConfirmation(
      input: {
        transactionId: $transactionId
      }
    )
  }
`;

// region Props

interface Props {
  hideFilters?: boolean;

  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  clientId?: string;
  interactionId?: string;
}

// endregion

export const IncomingTransactionsTable: React.FC<Props> = ({
  disablePagination,
  hideFilters,
  interactionId,
  clientId
}) => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(disablePagination);

  const navigate = useNavigate();

  // region State

  const [filters, setFilters] = useState<Record<string, any>>({});
  const queryFilters = useMemo(() => {
    return {
      clientId,
      interactionId,
      ...filters
    };
  }, [filters, clientId, interactionId]);

  // endregion

  // region Networking

  const query = useIncomingTransactionsQuery({
    notifyOnNetworkStatusChange: true,
    variables: {
      ...paginationVariables,

      where: queryFilters
    }
  });

  const {
    data,
    loading
  } = query;

  // endregion

  // region Effects

  React.useEffect(() => {
    (async () => {
      if (!pagination.isDisabled) {
        await query.refetch(paginationVariables);
      }
    })();
  }, [paginationVariables]);

  // endregion

  return (
    <Box
      sx={{
        height: '100%'
      }}
    >
      {!hideFilters && (
        <Box
          mb={1}
          gap={1}
          display="flex"
        >
          {/* region Filters */}

          <Filters
            onFiltersChanged={(filters) => setFilters(filterToWhereQuery(filters))}
            filters={[
              FilterType.IncomingTransactionStatus,
              FilterType.IncomingTransactionType,
              FilterType.ClientType,
              FilterType.ClientName,
              FilterType.Network,
              FilterType.TokenSymbol
            ]}
          />

          {/* endregion */}

          {/* region Export */}

          <ExportButton
            csv
            json
            exportName="incoming-transactions"
            data={data?.incomingTransactions.nodes ?? []}
          >
            Export Current Page
          </ExportButton>

          {/* endregion */}
        </Box>
      )}

      <Box
        sx={{
          overflowX: 'scroll'
        }}
      >
        <DataGrid
          {...pagination}
          autoHeight
          slots={{
            loadingOverlay: LinearProgress
          }}
          loading={loading}
          hideFooter={!!disablePagination}
          rows={data?.incomingTransactions?.nodes || []}
          rowCount={data?.incomingTransactions.page.totalNodes}
          columns={[
            {
              width: 200,
              field: 'createdAt',
              headerName: 'Timestamp',
              renderCell: ({
                value,
                row
              }) => {
                return (
                  <Typography
                    sx={{
                      '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline'
                      }
                    }}
                    onClick={() => {
                      navigate(`/blockchain/transactions/incoming/${row['id']}/details`);
                    }}
                  >
                    {DateTimeFormatter.format(new Date(value))}
                  </Typography>
                );
              }
            }, {
              flex: 2,
              minWidth: 200,
              maxWidth: 450,
              field: 'client',
              headerName: 'Client Associated',
              renderCell: ({ value }) => <Typography>{value.name}</Typography>
            }, {
              flex: 1,
              minWidth: 150,
              field: 'tokenDeployment.symbol',
              headerName: 'Asset',
              renderCell: ({
                row
              }) => (
                <Typography>
                  {row.tokenDeployment
                    ? `${row.tokenDeployment?.token.symbol}`
                    : row.type}
                </Typography>
              )
            }, {
              width: 200,
              field: 'network',
              headerName: 'Network',
              renderCell: ({ row }) => {
                return <BlockchainNetworkLabel network={row.network!} />;
              }
            }, {
              flex: 1,
              minWidth: 200,
              field: 'type',
              headerName: 'Operation',
              renderCell: ({ value }) => (
                <Typography>
                  {value === 'Stablecoin' ? 'Issuance' : 'Redemption'}
                </Typography>
              )
            }, {
              width: 200,
              field: 'amount',
              headerName: 'Amount',
              renderCell: ({ row }) => (
                <Typography>{row.displayAmount}</Typography>
              )
            }, {
              width: 200,
              field: 'score',
              headerName: 'Score',
              renderCell: ({ row }) => (
                <Typography>
                  {row.scoreProvider} ({row.score ?? 'Not Present'})
                </Typography>
              )
            }, {
              width: 200,
              field: 'tokenDeployment',
              headerName: 'Asset',
              renderCell: ({ row }) => {
                const tokenDeployment =
                  'tokenDeployment' in row ? row.tokenDeployment : null;
                const token = tokenDeployment ? tokenDeployment.token : null;

                return (
                  <Typography>
                    {tokenDeployment
                      ? `${token?.symbol} (${tokenDeployment.network})`
                      : row.tokenSymbol}
                  </Typography>
                );
              }
            }, {
              flex: 2,
              minWidth: 150,
              field: 'opsErrors',
              headerName: 'Operational Errors',
              renderCell: ({ row }) => {
                let label = 'None';

                if (row.interaction?.status === 'Failed') {
                  label = `Interaction Failed: ${row.interaction.failureReason}`;
                } else if (!row.interaction) {
                  label = 'No Linked Interaction';
                }

                return (
                  <Chip
                    label={label}
                    color={label === 'None' ? 'success' : 'error'}
                  />
                );
              }
            }, {
              flex: 2,
              minWidth: 150,
              field: 'problems',
              headerName: 'TX Errors',
              renderCell: ({ value }) => {
                // TODO: render multiple chips for multiple errors (e.g ["ExternalWalletNotRegistered","UnsupportedAsset"])
                return (
                  <Chip
                    label={value.length ? value : 'none'}
                    color={value.length ? 'error' : 'success'}
                  />
                );
              }
            }, {
              flex: 1,
              minWidth: 150,
              field: 'refundable',
              headerName: 'Refundable',
              renderCell: ({ row }) => (
                <Box>
                  {row.refund ? (
                    <Box>
                      {row.refund.status === RefundStatus.Available
                        ? 'Refundable'
                        : `Non-refundable. Refund: ${row.refund.status}`}
                    </Box>
                  ) : (
                    <Box>Non-refundable</Box>
                  )}
                </Box>
              )
            }, {
              flex: 1,
              minWidth: 150,
              field: 'status',
              headerName: 'Transaction Status',
              renderCell: ({ value }) => {
                return (
                  <Chip
                    label={value}
                    color={
                      {
                        Received: 'secondary',
                        Confirmed: 'primary',
                        Refunded: 'secondary',
                        Approved: 'primary',
                        PendingValueVerification: 'warning',
                        PendingCollection: 'secondary',
                        PendingApproval: 'warning',
                        Collected: 'success'
                      }[value as IncomingTransactionStatus] as any
                    }
                  />
                );
              }
            }, {
              width: 200,
              field: 'hash',
              headerName: 'Tx Hash',
              renderCell: ({
                value,
                row
              }) => (
                <HashTile
                  hash={value}
                  explorerUrl={row.explorerUrl}
                />
              )
            }, {
              width: 150,
              field: 'actions',
              headerName: '',
              renderCell: ({ row }) => (
                <Box>
                {row.status === IncomingTransactionStatus.PendingApproval && (
                  <IncomingTransactionApproval transactionId={row.id} />
                )}
                {row.status === IncomingTransactionStatus.PendingValueVerification && (
                  <TransactionValueVerification transactionId={row.id} transactionType='Incoming' />
                )}
                </Box>
              )
            }
          ]}
        />
      </Box>
    </Box>
  );
};
