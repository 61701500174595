import { useUpdateDocumentMutation, useUpdateFolderMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

export const useMoveItem = () => {
  const snackbar = useSnackbar();

  const [updateFolderMutation] = useUpdateFolderMutation();
  const [updateDocumentMutation] = useUpdateDocumentMutation();

  return {
    moveFolder: async (folderId: string, newParentFolderId: string) => {
      await updateFolderMutation({
        variables: {
          input: {
            folderId: folderId,
            changeParentTo: newParentFolderId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'folder'
        ]
      });

      snackbar.enqueueSnackbar('Folder moved', {
        variant: 'success'
      });
    },
    moveDocument: async (documentId: string, newFolderId: string) => {
      await updateDocumentMutation({
        variables: {
          input: {
            documentId,
            changeFolderTo: newFolderId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'folder'
        ]
      });

      snackbar.enqueueSnackbar('Document moved', {
        variant: 'success'
      });
    }
  };
};
