import React from 'react';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, Chip, Dialog, DialogContent, FormControlLabel, FormGroup, Tooltip, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { AdminPermission, useUpdateAdminMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

gql`
  mutation UpdateAdmin($input: UpdateAdminInput!) {
    updateAdmin(input: $input) {
      id
    }
  }
`;

interface Props {
  admin: {
    id: string,
    email: string,

    permissions: AdminPermission[]
  }
}

export const AdminPermissions: React.FC<Props> = ({ admin }) => {
  const updateRolesDialogToggle = useToggle();
  const snackbar = useSnackbar();

  // region Networking

  const [updateAdminMutation, { loading }] = useUpdateAdminMutation();

  // endregion

  // region State

  const [permissions, setPermissions] = React.useState<AdminPermission[]>(admin.permissions);

  // endregion


  const onPermissionToggle = (permission: AdminPermission) => (e: any, checked: boolean) => {
    if (checked) {
      setPermissions((prev) => ([
        ...prev,
        permission
      ]));
    } else {
      setPermissions((prev) => prev.filter((p) => p !== permission));
    }
  };

  const onUpdate = async () => {
    try {
      await updateAdminMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'admins'
        ],
        variables: {
          input: {
            adminId: admin.id,
            update: {
              permissions
            }
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully updated admin permissions', { variant: 'success' });

      updateRolesDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to update admin permissions', { variant: 'error' });
    }
  };

  return (
    <>
      <Tooltip title={admin.permissions.join(', ')}>
        <Chip
          onClick={updateRolesDialogToggle.setTrue}
          label={`${admin.permissions.length} permission${admin.permissions.length !== 1 ? 's' : ''}`}
        />
      </Tooltip>
      { updateRolesDialogToggle.open && (
        <Dialog
          {...updateRolesDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Typography variant='title'>
              Permissions
            </Typography>

            <Box mb={1}>
              <Typography variant='subtitleSmall'>
                The set of permissions that {admin.email} posses:
              </Typography>
            </Box>

            <FormGroup sx={{ marginLeft: '11px' }}>
              {Object.values(AdminPermission).map((permission) => (
                <FormControlLabel
                  key={permission}
                  label={permission}
                  control={(
                    <Checkbox
                      checked={permissions.includes(permission)}
                      onChange={onPermissionToggle(permission as AdminPermission)} />
                  )}
                />
              ))}
            </FormGroup>

            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                loading={loading}
                onClick={onUpdate}
              >
                Update
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
