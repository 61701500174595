import React from 'react';
import { Box, Checkbox, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import AddCommentIcon from '@mui/icons-material/AddComment';

import { ClientInfo } from '@backed-fi/admin/src/app/domain/Clients/components/ClientInfo';
import { InteractionTypeBadge } from '../../../components/InteractionTypeBadge';
import { InteractionStatusBadge } from '../../../components/InteractionStatusBadge';
import { CreateInteractionReportDialog } from './components/CreateInteractionReportDialog';

import {
  Interaction,
  InteractionFlag,
  IncomingTransactionScoreProvider,
  InteractionReport,
  InteractionReportType,
  useInteractionsMonitoringQuery,
  InteractionAutomatedCheck
} from '@backed-fi/graphql';
import { PageHeading } from '@backed-fi/compound';
import { CentsFormatter, DateFormatter } from '@backed-fi/shared';
import { usePaginationModel, useToggle } from '@backed-fi/hooks';
import { InteractionAssetsList } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionAssetsList';
import { CreateCommentDialog } from '@backed-fi/admin/src/app/components/CreateCommentDialog';
import { RemoveInteractionReportDialog } from './components/RemoveInteractionReportDialog';
import { ToggleInteractionAsHighRiskDialog } from './components/ToggleInteractionAsHighRiskDialog';
import { parseIncomingTransactionRedFlags } from '../../../../Blockchain/transactions/incoming/helpers/parseIncomingTransactionRedFlags';

const query = gql`query interactionsMonitoring(
  $page: Int,
  $pageSize: Int
) {
  interactions(
    page: $page,
    pageSize: $pageSize
  ) {
    nodes {
      id
      createdAt

      type

      status
      currency

      flags
      automatedChecks

      actualTotalValue
      expectedTotalValue

      comments {
        comment
      }

      reports {
        id
        type
        comments {
          comment
        }
      }

      client {
        id

        name
        contactEmail
      }

      incomingTransactions {
        id

        hash
        network
        amount
        value
        decimals
        explorerUrl
        type

        score
        scoreDetails
        scoreProvider
        isHighRiskScore
        problemsChecked

        tokenSymbol
      }

      outgoingTransactions {
        id
        hash
        network
        explorerUrl
        createdAt
        tokenSymbol
        amount
        type

        displayAmount
        value
        decimals
      }

      fee {
        fee
        currency
      }
    }

    page {
      totalNodes
      totalPages
      currentPage

      hasNextPage
      hasPreviousPage
    }
  }
}
`;

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--HighRisk': {
    backgroundColor: theme.palette.error.elementBackgroundActive,
    '&:hover': {
      backgroundColor:  theme.palette.error.elementBackgroundHovered
    },
    '&.Mui-selected': {
      backgroundColor:  theme.palette.error.elementBackground,
      '&:hover': {
        backgroundColor:  theme.palette.error.backgroundSubtle
      }
    }
  }
}));

export const MonitoringListPage: React.FC = () => {
  const createInteractionReportDialogToggle = useToggle();
  const removeInteractionReportDialogToggle = useToggle();
  const markInteractionAsHighRiskDialogToggle = useToggle();
  const createComplianceCommentDialogToggle = useToggle();

  const [interaction, setInteraction] = React.useState<Interaction>();
  const [report, setReport] = React.useState<Pick<InteractionReport, 'id' | 'type' | 'comments'>>();
  // region Pagination

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // endregion

  // region Networking

  const {
    data,
    previousData,
    loading
  } = useInteractionsMonitoringQuery({
  // If we are on the first page - poll, otherwise - don't
    pollInterval: paginationVariables.page
      ? undefined
      : 5000,
    variables: {
      ...paginationVariables
    }
  });

  // endregion

  const finalData = React.useMemo(() => {
    const d = data || previousData;

    return {
      rows: (d?.interactions?.nodes || []).map((n) => ({
        ...n,
        kyt: n.incomingTransactions.map((i) => ({
          score: i.score,
          scoreProvider: i.scoreProvider,
          redFlags:   parseIncomingTransactionRedFlags(i.scoreDetails, i.scoreProvider!)
        })),
        automatedChecks: {
          kyt: n.incomingTransactions.every((i) => i.problemsChecked),
          checks: Object.entries(InteractionAutomatedCheck).map(([key, value]) => ({ name: value,
            value: n.automatedChecks.some((ac) => ac === value) })),
          amlCrossed: n.flags.some((f) => f === InteractionFlag.AmlLimitThresholdCrossed)
        },
        highRisk: n.flags.some((i) => i === InteractionFlag.HighRisk) || n.incomingTransactions.some((s) => s.isHighRiskScore),
        internalReport: n.reports.find((r) => r.type === InteractionReportType.Internal),
        mrosReport: n.reports.find((r) => r.type === InteractionReportType.Mros),
        comments: n.comments.map((c) => c?.comment)
      })),
      totalNodes: d?.interactions?.page.totalNodes
    };
  }, [data, previousData]);

  React.useEffect(() => {
    if (report) {
      if (report.id) {
        removeInteractionReportDialogToggle.setTrue();
      } else {
        createInteractionReportDialogToggle.setTrue();
      }
    }
  }, [report]);

  return <Box>
    {
      interaction && <ToggleInteractionAsHighRiskDialog
        {...markInteractionAsHighRiskDialogToggle}
        onDismiss={markInteractionAsHighRiskDialogToggle.setFalse}
        interactionId={interaction.id}
        flags={interaction.flags}/>
    } {
      interaction && report?.type && <CreateInteractionReportDialog
        {...createInteractionReportDialogToggle}
        onDismiss={createInteractionReportDialogToggle.setFalse}
        interactionId={interaction.id}
        reportType={report.type!}/>
    }
    {
      report?.id && <RemoveInteractionReportDialog
        {...removeInteractionReportDialogToggle}
        onDismiss={removeInteractionReportDialogToggle.setFalse}
        interactionReport={report}/>
    }
    {
      interaction && <CreateCommentDialog
        {...createComplianceCommentDialogToggle}
        interactionId={interaction.id}
        onDismiss={createComplianceCommentDialogToggle.setFalse}
        refreshQueries={['interactionsMonitoring']}/>
    }
    <PageHeading
      title="Monitoring"
      breadcrumbs={[
        {
          text: 'Interactions'
        }
      ]}
    />
    <StyledDataGrid
      {...pagination}
      getRowClassName={(params) => `super-app-theme--${params.row.highRisk ? 'HighRisk' : ''}`}
      getRowHeight={() => 'auto'}
      loading={loading}
      rows={finalData.rows}
      rowCount={finalData.totalNodes}
      onRowClick={({ row }) => {setInteraction(row);}}
      columns={[
        {
          width: 200,
          field: 'createdAt',
          headerName: 'Timestamp',
          renderCell: ({
            value
          }) => {
            return (
              <Typography sx={{ alignSelf: 'center' }}>
                {DateFormatter.format(new Date(value))}
              </Typography>
            );
          }
        },
        {
          width: 250,
          field: 'client',
          headerName: 'Client',

          renderCell: ({ value }) => (
            <Box sx={{ paddingY: '16px',
              overflowX: 'hidden' }}>
              <ClientInfo name={value.name} contactEmail={value.contactEmail}/>
            </Box>
          )
        },
        {
          width: 150,
          field: 'type',
          headerName: 'Interaction Type',

          renderCell: ({ value }) => (
            <InteractionTypeBadge type={value}/>
          )
        },
        {
          width: 250,
          field: 'status',
          headerName: 'Status',

          renderCell: ({ value }) => (
            <InteractionStatusBadge status={value}/>
          )
        }, {
          width: 150,
          field: 'expectedTotalValue',
          headerName: 'Expected Value',

          renderCell: ({ row }) => (
            <Typography sx={{ alignSelf: 'center' }}>
              {CentsFormatter.format(row.expectedTotalValue, row.currency)}
            </Typography>
          )
        },
        {
          width: 150,
          field: 'fee.fee',
          headerName: 'Fee',

          renderCell: ({ row }) => (
            <Typography sx={{ alignSelf: 'center' }}>
              {CentsFormatter.format(row.fee?.fee, row.fee?.currency)}
            </Typography>
          )
        }, {
          width: 300,
          field: 'incomingTransactions',
          headerName: 'Incoming Assets',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              <InteractionAssetsList
                small
                assets={[...value.map((x: any) => ({
                  ...x,
                  type: 'Blockchain' as const
                }))] ?? []}
              />
            </Box>
          )
        }, {
          width: 300,
          field: 'outgoingTransactions',
          headerName: 'Outgoing Assets',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >

              <InteractionAssetsList
                small
                assets={[...value.map((x: any) => ({
                  ...x,
                  type: 'Blockchain' as const
                }))] ?? []}
              />
            </Box>
          )
        }, {
          width: 300,
          field: 'automatedChecks',
          headerName: 'Automated Checks',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              {value.kyt && <Typography>KYT</Typography>}
              {value.checks.filter((check: any) => check.value).map((check: any, i: number) => (
                <Typography key={i}>{check.name}</Typography>
              ))}
              {value.amlCrossed && <Typography>(AML Limit Crossed)</Typography>}
            </Box>
          )
        }, {
          width: 250,
          field: 'kyt',
          headerName: 'KYT Score',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              {value.map((v: {score: number, scoreProvider: IncomingTransactionScoreProvider, redFlags: string[]}, i: number) => (
                <>
                  <Typography key={i}>{v.score ?
                    `${v.score} (Provider: ${v.scoreProvider})` : 'Not Present'}
                  </Typography>
                  {v.redFlags?.map((flag: string, j) => (
                    <Typography key={j}>{`- ${flag}`}</Typography>
                  ))}
                </>
              ))}
            </Box>
          )
        }, {
          width: 150,
          field: 'highRisk',
          headerName: 'High risk',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              <Checkbox checked={value} onClick={markInteractionAsHighRiskDialogToggle.setTrue}/>
            </Box>
          )
        }, {
          width: 150,
          field: 'internalReport',
          headerName: 'Internal Report',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              <Checkbox checked={!!value?.id} onClick={() => {
                setReport((value ?? { type: InteractionReportType.Internal }));
              }}/>
            </Box>
          )
        }, {
          width: 150,
          field: 'mrosReport',
          headerName: 'MROS Report',

          renderCell: ({ value }) => (
            <Box
              sx={{
                alignSelf: 'center'
              }}
            >
              <Checkbox checked={!!value?.id} onClick={() => {
                setReport(value ?? { type: InteractionReportType.Mros });
              }}/>
            </Box>
          )
        }, {
          width: 350,
          field: 'comments',
          headerName: 'Comments',


          renderCell: ({ value }) => (
            <Box
              sx={{
                display: 'flex',
                flexGrow: '1',
                alignSelf: 'center'
              }}
            >
              {!!value.length && <ul>
                {value.map((v: string, i: number) => (
                  <li key={i}>
                    <Typography sx={{ whiteSpace: 'break-spaces' }}>{v}</Typography>
                  </li>
                ))}
              </ul>
              }

              <Box sx={{ display: 'flex',
                flexGrow: '1',
                justifyContent: 'end',
                alignItems: 'center' }}>
                <Tooltip
                  title='Add comment'
                >
                  <IconButton
                    sx={{
                      height: '40px'
                    }}
                  >
                    <AddCommentIcon sx={{ cursor: 'pointer' }} onClick={createComplianceCommentDialogToggle.setTrue}/>
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          )
        }
      ]}
    />
  </Box>;
};
