import React from 'react';

import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { InternalTransactionsTable } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/internal/components/InternalTransactionsTable';
import { BankTransfersTable } from '@backed-fi/admin/src/app/domain/Banking/tranfers/components/BankTransfersTable';

export const InteractionTransfersPage: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  return (
    <Box>
      <Box>
        <Box
          sx={{
            marginBottom: '1rem'
          }}
        >
          <Typography
            variant="titleSmall"
          >
            Incoming Transfers
          </Typography>

          <Typography
            variant="subtitleSmall"
          >
            List of all the incoming transfers created for that interaction
          </Typography>
        </Box>

        <BankTransfersTable
          disablePagination
          paymentForInteractionId={params.interactionId!}
        />
      </Box>

      <Box>
        <Box
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem'
          }}
        >
          <Typography
            variant="titleSmall"
          >
            Outgoing Transfers
          </Typography>

          <Typography
            variant="subtitleSmall"
          >
            List of all the outgoing transfers created for that interaction
          </Typography>
        </Box>

        <BankTransfersTable
          disablePagination
          payoutForInteractionId={params.interactionId!}
        />
      </Box>
    </Box>
  );
};
