import React from 'react';
import { Alert, Box, Divider, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { AdminNotificationsQuery, useAdminNotificationsQuery, useDashboardDataQuery } from '@backed-fi/graphql';
import { useAdminContext } from '../../context/AdminContext';
import { SystemWalletBalances } from '@backed-fi/admin/src/app/components/SystemWalletBalances';
import CurrentAumChart from './components/CurrentAumChart';
import AumOverTimeChart from './components/AumOverTimeChart';
import TokensPerNetworkChart from './components/TokensPerNetworkChart';
import AumPerNetworkChart from './components/AumPerNetworkChart';

const Graph = gql`
  query adminNotifications {
    notifications(
      where: {
        domain: System,
        isAcknowledged: false
      }
    ) {
      id

      title
      type
    }
  }

  query dashboardData {
    riskAssessments (
      where: {
        status: Active
      }
    ) {
      id

      client {
        id
        name
      }
    }
  }
`;

export const HomePage: React.FC = () => {
  const adminContext = useAdminContext();
  const { data } = useDashboardDataQuery();
  const { data: notificationData } = useAdminNotificationsQuery();

  // ---- Destructuring ---- //
  const { notifications } = (notificationData || {}) as AdminNotificationsQuery;

  return (
    <Box>
      <Typography variant='title'>
        Welcome {adminContext?.firstName},
      </Typography>

      <Typography variant='subtitle'>
        {notifications?.length
          ? `There ${notifications.length > 1 ? 'are' : 'is'} ${notifications.length} notification${notifications.length !== 1 ? 's' : ''} pending`
          : 'There are no pending notifications 🌱'
        }
      </Typography>

      {!!data?.riskAssessments?.length && (
        <Box my='8px'>
          <Alert severity='info'>
            There are risk assessments that are pending filling
          </Alert>
        </Box>
      )}

      <Divider variant="middle" />
      
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '16px', my: '8px' }}>
        <CurrentAumChart />
        <AumOverTimeChart />
        <TokensPerNetworkChart />
        <AumPerNetworkChart />
      </Box>
    </Box>
  );
};
