import { DomainRestricted } from '@backed-fi/primitives';
import { Alert } from '@mui/material';
import { config } from '@backed-fi/config';
import React from 'react';

export const EnvironmentLabel = () => (
  <DomainRestricted dev local stage sandbox>
    <Alert
      variant="outlined"
      severity="warning"
      sx={{
        width: '100%'
      }}
    >
      {config.isDev && 'Development Environment'}
      {config.isStage && 'Staging Environment'}
    </Alert>
  </DomainRestricted>
);
