import { Box, Menu, MenuItem, TextField, Typography } from '@mui/material';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import React from 'react';
import { Filter } from './Filters';

import debounce from 'lodash/debounce';

interface Props {
  filter: Filter;

  onFilterRemoved: () => void;
  onFilterValueChange: (values: any) => void;
}

export const AppliedFilterTile: React.FC<Props> = ({ filter, ...actions }) => {
  const filterConfig = filter.definition;

  const tileRef = React.useRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // region State

  const [textValue, setTextValue] = React.useState<string>(filter.value || '');

  // endregion

  // region Effects

  React.useEffect(() => {
    // Open the select menu on first render
    tileRef.current?.click();
  }, []);

  // endregion

  // region Actions

  const onRemoveFilter = (event: any) => {
    event.stopPropagation();
    actions.onFilterRemoved();
  };

  const onOpenFilterMenu = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseFilter = () => {
    setAnchorEl(null);
  };

  const onUpdateFilterDebounced = React.useCallback(
    debounce((value) => {
      actions.onFilterValueChange(value || undefined);
    }, 1000), []
  );

  const onTextValueChange = (event: any) => {
    setTextValue(event.target.value);

    onUpdateFilterDebounced(event.target.value);
  };


  // endregion

  return (
    <React.Fragment>
      <Box
        ref={tileRef}
        onClick={onOpenFilterMenu}
        sx={{
          padding: '0px 8px',
          borderRadius: '8px',
          display: 'inline-flex',
          border: '1px solid',
          borderColor: 'utility.borderElement',
          backgroundColor: 'utility.backgroundSubtle',
          textTransform: 'capitalize',
          gap: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
      >
        <Typography variant='small'>
          <Typography component='span'>
            {filter.type.replace(/(?=[A-Z])/g, ' ')}
          </Typography>

          {filter.value && (
            <React.Fragment>
              {' '}is{' '}
              <Typography component='span'>
                {Array.isArray(filter.value)
                  ? filter.value.join(', ')
                  : filter.value
                }
              </Typography>
            </React.Fragment>
          )}
        </Typography>


        <ClearRoundedIcon
          onClick={onRemoveFilter}
          sx={{
            padding: '4px',
            fontSize: '24px',
            borderRadius: '12px',

            color: 'utility.textLowContrast',

            '&:hover': {
              color: 'utility.highContrast',
              backgroundColor: 'utility.elementBackgroundActive'
            }
          }}
        />
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={onCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          marginTop: '.5rem'
        }}
      >
        <Box
          sx={{
            minWidth: '200px'
          }}
        >
          {/* region Select Filter */}

          {filterConfig.inputType === 'select' && (
            filterConfig.possibleValues
              .map((value, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    onCloseFilter();
                    actions.onFilterValueChange(value);
                  }}
                >
                  {value}
                </MenuItem>
              ))
          )}

          {filterConfig.inputType === 'text' && (
            <Box
              sx={{
                margin: '0 8px'
              }}
            >
              <Typography>
                {filter.type.replace(/(?=[A-Z])/g, ' ')}
              </Typography>

              <TextField
                autoFocus
                size='small'
                label='Enter a value'
                value={textValue}
                onChange={onTextValueChange}
              />
            </Box>
          )}

          {/* endregion */}
        </Box>
      </Menu>
    </React.Fragment>
  );
};
