import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';
import React from 'react';
import { useToggle } from '@backed-fi/hooks';
import { Box, CircularProgress, Dialog, DialogContent, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { gql } from '@apollo/client';
import QRCode from 'react-qr-code';
import { useActivateToptLayerMutation, useCreateToptLayerMutation } from '@backed-fi/graphql';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { useSnackbar } from 'notistack';

// region Graph

const Graph = gql`
  mutation createTOPTLayer {
    createTOPTLayer
  }

  mutation activateTOPTLayer($input: ActivateTOPTLayerInput!) {
    activateTOPTLayer(input: $input)
  }
`;

// endregion

export const MFADialog: React.FC = () => {
  const context = useAdminContext();
  const snackbar = useSnackbar();

  const toggle = useToggle(!context.isMFAEnabled);
  const qrCodeToggle = useToggle(true);

  const [createTOPTLayerMutation, {
    data,
    loading
  }] = useCreateToptLayerMutation();

  const [activateTOPTLayerMutation, {
    loading: activatingTOPT
  }] = useActivateToptLayerMutation();

  // region Actions

  const onFieldDataChange: StandardInputProps['onChange'] = async(e) => {
    const value = e.target.value;

    if (value?.length === 6) {
      const result = await activateTOPTLayerMutation({
        variables: {
          input: {
            code: value
          }
        }
      });

      if (result.data?.activateTOPTLayer) {
        toggle.setFalse();

        snackbar.enqueueSnackbar('MFA successfully enabled', {
          variant: 'success'
        });
      } else {
        snackbar.enqueueSnackbar('The provided code is not valid', {
          variant: 'error'
        });
      }
    }
  };

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...toggle}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Enable Multi-Factor Authentication
        </Typography>

        <Typography variant='subtitleSmall'>
          To increase the security of your account please enable MFA
        </Typography>


        {data ? (
          <Box>
            <Tooltip
              title={
                qrCodeToggle.open
                  ? 'Click the QR code if you cannot scan it'
                  : ''
              }
            >
              <Box
                onClick={qrCodeToggle.setFalse}
                sx={{
                  my: '2rem',
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                {qrCodeToggle.open ? (
                  <QRCode
                    value={`otpauth://totp/${context.email}?secret=${data.createTOPTLayer}&issuer=BackedFinance`}
                  />
                ) : (
                  <Box>
                    <Typography>
                      Email: {context.email}
                    </Typography>

                    <Typography>
                      Secret: {data.createTOPTLayer}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Tooltip>


            <TextField
              fullWidth
              label='Verification Code'
              helperText='The 6 digit code from your authenticator app. Once provided it will automatically verify'
              onChange={onFieldDataChange}
              disabled={activatingTOPT}
              inputProps={{ maxLength: 6 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    {activatingTOPT ? (
                      <CircularProgress
                        size={20}
                      />
                    ) : (
                      <KeyRoundedIcon />
                    )}
                  </InputAdornment>
                )
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              mt: '1rem',
              gap: '1rem',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <LoadingButton onClick={toggle.setFalse}>
              Skip
            </LoadingButton>

            <LoadingButton
              loading={loading}
              onClick={() => createTOPTLayerMutation()}
            >
              Do it now
            </LoadingButton>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};
