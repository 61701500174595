import React from 'react';

import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { PageHeading } from '@backed-fi/compound';
import { Box, Button, Chip, List, ListItem, ListItemText, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { ContractFactoryType, FactoriesListQuery, useFactoriesListQuery } from '@backed-fi/graphql';
import { RegisterFactoryDialog } from '@backed-fi/admin/src/app/domain/Internal/Factories/components/RegisterFactoryDialog';
import { OwnershipDetailsDialog } from '@backed-fi/admin/src/app/domain/Internal/Factories/components/OwnershipDetailsDialog';

const Graph = gql`
  query factoriesList {
    factories {
      id

      type
      description

      factoryDeployments {
        address
        network
        ownership
      }
    }
  }
`;

export const FactoriesListPage = () => {
  const registerToggle = useToggle();

  // region Networking

  const {
    data,
    loading
  } = useFactoriesListQuery();
  const { factories } = (data || {}) as FactoriesListQuery;

  // endregion

  return (
    <Box>
      <RegisterFactoryDialog
        {...registerToggle}
      />

      <PageHeading
        title='Factories'
        breadcrumbs={[{
          text: 'Factories'
        }]}
      >
        <Button onClick={registerToggle.setTrue}>
          Register new factory
        </Button>
      </PageHeading>

      <DataGrid
        autoHeight
        loading={loading}
        rows={factories || []}
        getRowHeight={({ model }) => 52 * (model.factoryDeployments?.length ?? 1)}
        columns={[{
          width: 450,
          field: 'factoryDeployments',
          headerName: 'Factory Address',
          renderCell: ({ row }) => (
            <List>
              {row.factoryDeployments.map((x) => (
                <ListItem key={x.network} disablePadding>
                    <ListItemText primary={`${x.network}: ${x.address || 'No address'}`} />
                </ListItem>
              ))}
            </List>)
        }, {
          width: 150,
          field: 'type',
          headerName: 'Factory Type',
          renderCell: ({ value }) => (
            <Chip
              label={value}
              color={({
                Unknown: 'error',
                TokenFactory: 'warning',
                OracleFactory: 'primary'
              } as any)[value] as any}
            />
          )
        }, {
          flex: 3,
          field: 'description',
          headerName: 'Description',
          renderCell: ({ value }) => (
            <Typography>
              {value}
            </Typography>
          )
        }, {
          width: 100,
          field: 'id',
          headerName: '',
          renderCell: ({ row }) => (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <OwnershipDetailsDialog
                {...row}
              />
            </Box>
          )
        }]}
      />
    </Box>
  );
};
