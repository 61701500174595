import { Box, Typography } from '@mui/material';
import React from 'react';
import { ethers } from 'ethers';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { useProvideSupplyControlOperationSignatureMutation, useSupplyControlOperationSignaturePayloadQuery } from '@backed-fi/graphql';
import { signTransaction } from '@backed-fi/admin/src/app/domain/Blockchain/supply/helpers/signTransaction';
import { useParams } from 'react-router-dom';

// region Graph

gql`
  query supplyControlOperationSignaturePayload($operationId: String!) {
    supplyControlOperation(
      where: {
        id: $operationId
      }
    ) {
      id

      eip712Domain
      possibleSigners
    }
  }

  mutation ProvideSupplyControlOperationSignature($input: ProvideSupplyControlOperationSignatureInput!) {
    provideSupplyControlOperationSignature(input: $input)
  }
`;

// endregion

export const AddSignature = () => {
  const params = useParams<{ id: string }>();
  const snackbar = useSnackbar();

  const [provideSignatureMutation] = useProvideSupplyControlOperationSignatureMutation();
  const { data } = useSupplyControlOperationSignaturePayloadQuery({
    variables: {
      operationId: params.id!
    }
  });

  const hasWallet = React.useMemo(() => !!(window as any).ethereum, []);

  const [loading, setLoading] = React.useState(false);

  const onSign = async () => {
    try {
      setLoading(true);

      if (!window.ethereum) {
        throw new Error('No ethereum provider detected');
      }

      // That should never be the case, doing it only to fix the types
      if (!data) {
        throw new Error('Data not loaded');
      }

      const operation = data.supplyControlOperation;

      // Get the account
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      // Request permission from the Web3 Provider
      await provider.send('eth_requestAccounts', []);

      // Get the provider
      const signer = provider.getSigner();

      // Check if the address of the signer can sign the payload
      const signerAddress = await signer.getAddress();

      if (!operation.possibleSigners.includes(signerAddress.toLowerCase())) {
        const message = 'Cannot use that wallet to sign the transaction. Please connect another one!';

        snackbar.enqueueSnackbar(message, {
          variant: 'error'
        });

        throw new Error(message);
      }


      // Sign the payload
      const signature = await signTransaction(signer, data.supplyControlOperation.eip712Domain);

      // Send the signature to the backend
      await provideSignatureMutation({
        awaitRefetchQueries: true,
        variables: {
          input: {
            signature,
            address: signerAddress,
            operationId: data.supplyControlOperation.id
          }
        },
        refetchQueries: [
          'supplyControlOperationDetails'
        ]
      });
    } catch (e: any) {
      console.error(e);

      snackbar.enqueueSnackbar(`An error occurred: ${e.message}`, {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box>
      {!hasWallet
        ? (
          <Typography>
            Cannot detect your wallet provider
          </Typography>
        ) : (
          <LoadingButton
            onClick={onSign}
            loading={loading || !data}
          >
            Sign Mint
          </LoadingButton>
        )}
    </Box>
  );
};
