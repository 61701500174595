 import React from 'react';
import { Route } from 'react-router-dom';

import { InjectInteractionPage } from './pages/InjectionCreatePage';
import { InjectionDetailsPage } from '@backed-fi/admin/src/app/domain/Interactions/Injections/pages/InjectionDetailsPage';

export const InjectionPages = (
  <Route path="injections">
    <Route
      path="new"
      element={
        <InjectInteractionPage />
      }
    />

    <Route
      path="details/:id"
      element={
        <InjectionDetailsPage />
      }
    />
  </Route>
);
