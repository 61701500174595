import React from 'react';
import { Route } from 'react-router-dom';
import { IncomingTransactionsListPage } from './transactions/incoming/pages/IncomingTransactionsListPage';
import { IncomingTransactionDetailsPage } from './transactions/incoming/pages/IncomingTransactionDetailsPage';
import { OutgoingTransactionsListPage } from './transactions/outgoing/pages/OutgoingTransactionsListPage';
import { BlockchainAddressesListPage } from '@backed-fi/admin/src/app/domain/Blockchain/addresses/BlockchainAddressesListPage';
import { SupplyControlOperationsListPage } from '@backed-fi/admin/src/app/domain/Blockchain/supply/SupplyControlOperationsListPage';
import { SupplyControlOperationDetailsPage } from '@backed-fi/admin/src/app/domain/Blockchain/supply/SupplyControlOperationDetailsPage';
import { InternalTransactionsListPage } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/internal/pages/InternalTransactionsListPage';

import { OracleRoutes } from '@backed-fi/admin/src/app/domain/Blockchain/oracles/Routes';
import { BlockchainOwnershipPage } from '@backed-fi/admin/src/app/domain/Blockchain/ownership/BlockchainOwnershipPage';

export const BlockchainRoutes = (
  <Route path="blockchain">
    {OracleRoutes}

    <Route
      path="addresses"
      element={
        <BlockchainAddressesListPage />
      }
    />

    <Route
      path="ownership"
      element={
        <BlockchainOwnershipPage />
      }
    />

    <Route
      path="supply"
    >
      <Route
        path="operations"
        element={
          <SupplyControlOperationsListPage />
        }
      />

      <Route
        path="operation"
      >
        <Route
          path="details/:id"
          element={<SupplyControlOperationDetailsPage />}
        />
      </Route>
    </Route>

    <Route path="transactions">
      <Route
        path="incoming"
      >
        <Route
          path="all"
          element={<IncomingTransactionsListPage />}
        />

        <Route
          path=":id/details"
          element={<IncomingTransactionDetailsPage />}
        />
      </Route>

      <Route path="outgoing">
        <Route
          path="all"
          element={<OutgoingTransactionsListPage />}
        />
      </Route>

      <Route path="internal">
        <Route
          path="all"
          element={<InternalTransactionsListPage />}
        />
      </Route>
    </Route>
  </Route>
);
