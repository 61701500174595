import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Typography } from '@mui/material';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';
import { DeploymentsHomeQuery, DeploymentsWhereInput, useDeploymentsHomeQuery } from '@backed-fi/graphql';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { usePaginationModel } from '@backed-fi/hooks';

// region Graph

gql`
  query deploymentsHome(
    $page: Int,
    $pageSize: Int,
    $where: DeploymentsWhereInput
  ) {
    deployments(
      page: $page,
      pageSize: $pageSize,

      where: $where
    ) {
      nodes {
        id

        createdAt
        status

        admin {
          id
          firstName
          lastName
        }

        token {
          id
          name
          symbol
        }
      }

      page {
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

// endregion

export const DeploymentsTable: React.FC<{
  where?: DeploymentsWhereInput
}> = ({ where }) => {
  const navigate = useNavigate();
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();


  // region Networking

  const query = useDeploymentsHomeQuery({
    variables: {
      where,
      ...paginationVariables
    }
  });

  const { nodes: deployments, page } = (query.data?.deployments || {}) as NonNullable<DeploymentsHomeQuery['deployments']>;

  // endregion


  return (
    <React.Fragment>
      <DataGrid
        {...pagination}
        autoHeight
        rows={deployments || []}
        loading={query.loading}
        rowCount={page?.totalNodes}
        onRowClick={({ row }) => {
          navigate(`/internal/deployments/details/${row.id}`);
        }}
        columns={[
          {
            flex: 2,
            headerName: 'Token',
            field: 'token',
            renderCell: ({ value }) => (
              <Box
                sx={{
                  gap: '16px',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: 'pointer',

                  '&:hover': {
                    textColor: 'utility.textHighContrast'
                  }
                }}
              >
                <AssetIcon symbol={value.symbol} />

                <Typography>
                  {value.name}
                </Typography>
              </Box>
            )
          }, {
            flex: 3,
            headerName: 'Admin',
            field: 'admin',
            renderCell: ({ value }) => (
              <Box
                sx={{
                  gap: '16px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Typography>
                  {value.firstName} {value.lastName}
                </Typography>
              </Box>
            )
          }, {
            flex: 1,
            headerName: 'Status',
            field: 'status',
            renderCell: ({ value }) => (
              <Chip
                label={value}
              />
            )
          }
        ]}
      />
    </React.Fragment>
  );
};
