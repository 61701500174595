import { Box, Button, Dialog, DialogContent, Tooltip } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { AdminPermission, BlockchainNetwork, useExecuteTokenMultiplierUpdateMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Protector, Title } from '@backed-fi/compound';
import Decimal from 'decimal.js';
import { useAdminContext } from '@backed-fi/admin/src/app/context/AdminContext';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';

// region Graph

gql`
  mutation executeTokenMultiplierUpdate($input: ExecuteTokenMultiplierUpdateInput!) {
    executeTokenMultiplierUpdate(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  multiplierChangeId: string;
  initiatorId: string;
  previousMultiplier: string;
  newMultiplier: string;
  network: BlockchainNetwork;
}

// endregion

export const ExecuteMultiplierUpdateDialog: React.FC<Props> = ({ multiplierChangeId, initiatorId, previousMultiplier, newMultiplier, network }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();
  const admin = useAdminContext();

  const [executeTokenMultiplierUpdate, { loading }] = useExecuteTokenMultiplierUpdateMutation();

  // region Effect

  // endregion

  // region On Approve

  const onApprove = async () => {
    try {
      await executeTokenMultiplierUpdate({
        awaitRefetchQueries: true,
        refetchQueries: [
          'tokenMultiplierChanges'
        ],
        variables: {
          input: {
            multiplierChangeId
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully applied new multiplier', {
        variant: 'success'
      });
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred while applying new multiplier', {
        variant: 'error'
      });
    } finally {
      dialog.setFalse();
    }
  };

  // endregion

  return (
    <Protector permissions={[AdminPermission.BlockchainManage, AdminPermission.SystemManage]}>
      <Tooltip title={admin.id === initiatorId ? "Update can be only executed by different admin than initiating one" : "Execute Multiplier Update"}>
        <Button onClick={dialog.setTrue} disabled={admin.id === initiatorId}>
          Execute Multiplier Update
        </Button>
      </Tooltip>

      {dialog.open && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Execute Multiplier Update"
              subtitle="Executes updates on given network"
            />

            {/* region Labels */}
            <InfoLabel
              label={'Network'}
              content={<BlockchainNetworkLabel network={network}/>}
            />
            <InfoLabel
              label={'Current multiplier'}
              content={new Decimal(previousMultiplier).div(Decimal.pow(10, 18)).toString()}
            />
            <InfoLabel
              label={`New multiplier`}
              content={new Decimal(newMultiplier).div(Decimal.pow(10, 18)).toString()}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={loading}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={loading}
                onClick={onApprove}
              >
                Apply
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </Protector>
  );
};
