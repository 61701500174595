import React from 'react';
import { Box } from '@mui/material';

export const TokenDetailsOverviewPage: React.FC = () => {
  return (
    <Box>
      Details Overview
    </Box>
  );
};
