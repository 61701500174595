import React from 'react';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { InteractionReportType, useRemoveInteractionReportMutation } from '@backed-fi/graphql';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  onDismiss: () => void,

  interactionReport: {
    id: string,
    type: InteractionReportType,
    comments: {comment: string}[]
  }
}

// endregion

// region Networking

const Graph = gql`
  mutation removeInteractionReport($input: RemoveInteractionReportInput!) {
    removeInteractionReport(input: $input)
  }
`;

// endregion

export const RemoveInteractionReportDialog: React.FC<Props> = ({ interactionReport, ...props }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [removeInteractionReport, { loading }] = useRemoveInteractionReportMutation();

  // endregion

  // region Actions
  const onRemove = async () => {
    try {
      await removeInteractionReport({
        variables: {
          input: {
            interactionReportId: interactionReport.id
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'interactionsMonitoring'
        ]
      });

      snackbar.enqueueSnackbar('Interaction report successfully removed');

      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown');
      }
    } catch (e) {
      snackbar.enqueueSnackbar('Interaction report removal failed');
    }
  };

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Remove Interaction {interactionReport.type} report
        </Typography>

        <Typography variant='subtitleSmall'>
          Are you sure you want to remove interaction {interactionReport.type} report?
        </Typography>

        <Box
          sx={{
            mt: '8px',
            gap: '8px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="secondary"
            onClick={props.onDismiss}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onRemove}
            loading={loading}
          >
            Remove
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
