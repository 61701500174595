import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { IncomingTransactionDetails } from '../components/IncomingTransactionDetails'

export const IncomingTransactionDetailsPage = () => {
  const params = useParams<{ id: string }>();

  return (
    <Box>
      <PageHeading
        title='Transaction Details'
        breadcrumbs={[{
          text: 'Blockchain'
        }, {
          text: 'Transactions'
        }, {
          text: `[${params.id}]`
        }, {
          text: 'Details'
        }]}
      />
      <IncomingTransactionDetails incomingTransactionId={params.id!}></IncomingTransactionDetails>

    </Box>
  );
};
