import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { InfoLabel, PageHeading, Title } from '@backed-fi/compound';
import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { DataGrid } from '@mui/x-data-grid';
import { EventDetailsQuery, useEventDetailsQuery, EventStatus } from '@backed-fi/graphql';

import { useToggle } from '@backed-fi/hooks';
import { DateTimeFormatter } from '@backed-fi/shared';
import { RetryEventDialog } from './components/RetryEventDialog';

// region Graph

gql`
  query eventDetails($where: EventWhereUniqueInput!) {
    event(where: $where) {
      id
      createdAt

      type
      payload
      status
      failures
      retryAttempts {
        id
        createdAt
        comment
        forFailures
      }
    }
  }
`;

// endregion

export const EventDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const assignToggle = useToggle();

  // region Networking
  const { data } = useEventDetailsQuery({
    variables: {
      where: {
        id: params.id!
      }
    }
  });

  const { event } = (data ?? {}) as EventDetailsQuery;

  // endregion

  return (
    <Box>
      <PageHeading
        title="Event Details"
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Events',
            link: '/internal/events'
          }, {
            text: 'Details'
          }
        ]}
      />

      {event && (
        <React.Fragment>
          <RetryEventDialog
            {...assignToggle}
            eventId={event.id}
          />

          <Title
            title="Details"
            subtitle="Details of the event"
          />

          <Box
            sx={{
              maxWidth: '800px',
              display: 'grid',
              gridTemplateColumns: {
                xss: '1fr',
                md: '1fr 1fr'
              }
            }}
          >
            <InfoLabel
              copy
              label="Event ID"
              content={event.id}
            />
            <InfoLabel
              copy
              label="Type"
              content={event.type}
            />
            <InfoLabel
              label="Status"
              content={event.status}
            />
            <InfoLabel
              label="Created At"
              content={DateTimeFormatter.format(new Date(event.createdAt))}
            />
            <InfoLabel
              copy
              label="Payload"
              sx={{ 'white-space': 'break-spaces' }}
              content={JSON.stringify(event.payload, undefined, 4)}
            />
            <InfoLabel
              copy
              label="Failures"
              sx={{ 'white-space': 'break-spaces' }}
              content={JSON.stringify(event.failures, undefined, 4)}
            />
          </Box>


          <Box
            sx={{
              mt: '2rem',
              mb: '1rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Box>
              <Title
                title="Retries"
                subtitle="List of past retries of handling this event"
              />
            </Box>

            <Box>
              <Tooltip title="Retry this event">
                <Button disabled={event.status !== EventStatus.FailedFinal} onClick={assignToggle.setTrue}>
                  Retry
                </Button>
              </Tooltip>
            </Box>
          </Box>

          <DataGrid
            autoHeight
            getRowHeight={() => 'auto'}
            rows={event.retryAttempts as any}
            columns={[
              {
                flex: 1,
                field: 'createdAt',
                headerName: 'Creation Date',
                renderCell: ({ value }) => (
                  <Typography>
                    {DateTimeFormatter.format(new Date(value))}
                  </Typography>
                )
              },
              {
                flex: 2,
                field: 'comment',
                minWidth: 300,
                headerName: 'Comment',
                renderCell: ({ value }) => (
                  <Box>
                    <Typography>
                      {value}
                    </Typography>
                  </Box>
                )
              },
              {
                flex: 2,
                field: 'forFailures',
                minWidth: 300,
                headerName: 'Failures at the time of retry',
                renderCell: ({ value }) => (
                  <Box
                    sx={{
                      alignSelf: 'flex-start'
                    }}>
                    <Typography sx={{ 'white-space': 'break-spaces' }}>
                      {JSON.stringify(value, null, 4)}
                    </Typography>
                  </Box>
                )
              }
            ]}
          />

        </React.Fragment>
      )}
    </Box>
  );
};
