import React from 'react';
import { Box } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';

import { OutgoingTransactionsTable } from '../components/OutgoingTransactionsTable';

export const OutgoingTransactionsListPage = () => {
  return (
    <Box>
      <PageHeading
        title="Outgoing Transactions"
        breadcrumbs={[{
          text: 'Blockchain'
        }, {
          text: 'Outgoing Transactions'
        }]}
      />

      <OutgoingTransactionsTable />
    </Box>
  );
};
