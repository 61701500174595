import React from 'react';

import { AdminPages } from './Pages';

import { BackedProvider } from '@backed-fi/providers';


export const App: React.FC = () => {
  return (
    <React.Fragment>
      <BackedProvider
        pages={AdminPages}
        application="BackedFi.Admin"
      />
    </React.Fragment>
  );
};

