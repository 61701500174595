import { Box } from '@mui/material';
import React from 'react';
import { PageHeading } from '@backed-fi/compound';
import { InternalTransactionsTable } from '../components/InternalTransactionsTable';

export const InternalTransactionsListPage: React.FC = () => {
  return (
    <Box
      sx={{
        height: '500px'
      }}
    >
      <PageHeading
        title='Internal Transactions'
        breadcrumbs={[{
          text: 'Blockchain'
        }, {
          text: 'Internal Transactions'
        }]}
      />

      <InternalTransactionsTable />
    </Box>
  );
};
