import React, { useState } from 'react';

import { ethers } from 'ethers';
import { gql } from '@apollo/client';
import { Box, TextField, Typography } from '@mui/material';

import { Flex } from '@backed-fi/primitives';
import { PageHeading } from '@backed-fi/compound';
import { useWorkingCapitalQuery } from '@backed-fi/graphql';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';

// region GraphQL

const Graph = gql`
  query workingCapital {
    systemWallets {
      id
      title
      description

      nativeBalances {
        address
        balance
        network
      }

      tokenBalances {
        balance {
          network
          balance
          contractAddress
          walletAddress
        }

        token {
          id
          name
          symbol
          isPublic
        }
      }
    }
  }
`;

// endregion

export const WorkingCapitalPage = () => {
  const {
    data,
    loading
  } = useWorkingCapitalQuery();

  // region State

  const [filter, setFilter] = useState<string>();

  // endregion

  return (
    <Box>
      <PageHeading
        title='Working Capital'
        breadcrumbs={[
          {
            text: 'Financial Operations'
          }, {
            text: 'Working Capital'
          }
        ]}
      />

      {loading && (
        <Typography>
          Loading
        </Typography>
      )}

      {data && data.systemWallets.map((systemWallet) => (
        <Box key={systemWallet.id}>
          <Box my={2}>
            <Typography variant='titleSmall'>
              Native Balances
            </Typography>

            <Typography variant='subtitleSmall'>
              The native balance of {systemWallet.title} on all supported chains
            </Typography>

            <Box my={1}>
              {systemWallet.nativeBalances
                .map((balance) => (
                  <Typography
                    key={balance.network}
                    sx={{
                      gap: '.5rem',
                      display: 'flex'
                    }}
                  >
                    <BlockchainNetworkLabel network={balance.network} />{' '}

                    ~{Number(ethers.utils.formatEther(balance.balance)).toFixed(4)}
                  </Typography>
                ))
              }
            </Box>
          </Box>

          <Box my={1}>


            <Flex justifyContent='space-between'>
              <Box>
                <Typography variant='titleSmall'>
                  Token Balance
                </Typography>

                <Typography variant='subtitleSmall'>
                  The {'token'} balance for {systemWallet.title} on all supported chains
                </Typography>
              </Box>

              <Box>
                <TextField
                  select
                  size='small'
                  onChange={({ target }) => {
                    setFilter(
                      target.value === 'all'
                        ? undefined
                        : target.value
                    );
                  }}
                >
                  <option value='all'>
                    All tokens
                  </option>

                  {systemWallet.tokenBalances
                    .map(({ token }) => (
                      <option
                        key={token.id}
                        value={token.id}
                      >
                        {token.name}
                      </option>
                    ))
                  }
                </TextField>
              </Box>
            </Flex>


            {systemWallet.tokenBalances
              .filter((tokenBalance) => {
                if (!filter) {
                  return true;
                }

                return tokenBalance.token.id === filter;
              })
              .map(({
                token,
                balance
              }) => (
                <Box>
                  <Typography
                    variant='titleSmall'
                    sx={{
                      mt: '1rem',
                      gap: '1rem',
                      display: 'flex'
                    }}
                  >
                    <AssetIcon symbol={token.symbol} />

                    {token.name}
                  </Typography>

                  <Box
                    sx={{
                      ml: '2rem'
                    }}
                  >
                    {balance.map((networkBalance) => (
                      <Typography
                        sx={{
                          my: '.5rem',
                          display: 'flex'
                        }}
                      >
                        <BlockchainNetworkLabel network={networkBalance.network} />: {' '}
                        ~{Number(ethers.utils.formatEther(networkBalance.balance)).toFixed(4)}{' '}
                        {token.symbol}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              ))
            }
          </Box>
        </Box>
      ))}
    </Box>
  );
};
