import React from 'react';
import { Box } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { gql } from '@apollo/client';
import { BankTransfersTable } from '@backed-fi/admin/src/app/domain/Banking/tranfers/components/BankTransfersTable';


export const BankTransfersListPage: React.FC = () => {
  return (
    <Box>
      <PageHeading
        title="Bank Transfers"
        breadcrumbs={[{
          text: 'Banking'
        }, {
          text: 'Transfers'
        }]}
      />

      <BankTransfersTable />
    </Box>
  );
};
