import React from 'react';
import { Box, Typography } from '@mui/material';

// region Props

interface Props {
  symbol: string;
  size?: number;
  name?: string;
}

// endregion

export const AssetIcon: React.FC<Props> = ({ symbol, size, name }) => {
  return (
    <Box
      gap={1}
      display='flex'
      alignItems='center'
    >
      <Box
        component='img'
        src={`/assets/icons/assets/${symbol}.svg`}
        sx={{
          width: size ?? '32px'
        }}
      />

      {name && (
        <Typography>
          {name}
        </Typography>
      )}
    </Box>
  );
};
