import React from 'react';
import { json2csv } from 'json-2-csv';
import { Button } from '@mui/material';
import { SplitButton } from './SplitButton';

interface Props extends React.ComponentProps<typeof Button> {
  data: any;
  exportName?: string;

  csv?: boolean;
  json?: boolean;
}

// region Helpers

interface NestedObject {
  [key: string]: any | NestedObject;
}

const createCSVFromArray = (data: any[]): Promise<string> => {
  return json2csv(data);
};


// endregion

export const ExportButton: React.FC<Props> = ({ data, csv, exportName, json, ...buttonProps }) => {

  // region Actions

  const exportFile = (fileContent: string, format: 'json' | 'csv') => {
    const link = document.createElement('a');

    link.href = fileContent;
    link.download = `${exportName ?? 'backedfi-dataexport'}.${format}`;

    link.click();
  };

  const onExportJson = () => {
    if (data) {
      const jsonString = `data:text/json;charset=utf-8,${encodeURIComponent(
        JSON.stringify(data)
      )}`;

      exportFile(jsonString, 'json');
    }
  };

  const onExportCSV = async () => {
    if (data) {
      const csvString = `data:text/csv;charset=utf-8,${encodeURIComponent(
        await createCSVFromArray(data)
      )}`;

      exportFile(csvString, 'csv');
    }
  };

  // endregion

  return (
    <SplitButton
      {...buttonProps}

      options={[
        ...(csv ? [
          {
            title: buttonProps.children ? `${buttonProps.children} as CSV` : 'Export as CSV',
            action: onExportCSV
          }
        ] : []),

        ...(json ? [
          {
            title: buttonProps.children ? `${buttonProps.children} as JSON` : 'Export as JSON',
            action: onExportJson
          }
        ] : [])
      ]}
    >
      {buttonProps.children || 'Export Data'}
    </SplitButton>
  );
};
