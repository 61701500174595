import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  TextField,
  Typography
} from '@mui/material';
import EditIcon from '@mui/icons-material/SaveAsRounded';
import DoneIcon from '@mui/icons-material/Done';
import { gql } from '@apollo/client';

import { PageHeading } from '@backed-fi/compound';
import {
  AccountsListPageQuery,
  CustodyAccountFacet,
  useAccountsListPageQuery,
  usePatchCustodyAccountFacetMutation
} from '@backed-fi/graphql';
import { DataGrid, GridCellEditStartParams, GridRenderCellParams, MuiEvent } from '@mui/x-data-grid';
import {
  Filter,
  Filters
} from '@backed-fi/admin/src/app/components/filter/Filters';

import {
  CentimesFormatter,
  CentsFormatter,
  EuroCentsFormatter,
  IntAmountFormatter
} from '@backed-fi/shared';
import { CreateCustodyAccountFacetDialog } from '../../Internal/Tokens/pages/Details/components/CreateCustodyAccountFacetDialog';
import { useToggle } from '@backed-fi/hooks';
import { get, groupBy, sortBy } from 'lodash';
import { useNavigate } from 'react-router-dom';

gql`
  query AccountsListPage {
    custodyAccounts {
      id
      externalId

      facets {
        id
        balance
        label
        type
        asset
        isWorkingCapital
        collateral {
          symbol
        }
      }
    }
  }

  mutation patchCustodyAccountFacet(
    $input: PatchCustodyAccountFacetMutationInput!
  ) {
    patchCustodyAccountFacet(input: $input) {
      id
    }
  }
`;

const formatters = {
  EUR: EuroCentsFormatter,
  USD: CentsFormatter,
  CHF: CentimesFormatter
} as { [key: string]: typeof CentimesFormatter };

export const AccountsListPage = () => {
  const navigate = useNavigate();
  // region State

  const createCustodyAccountFacetDialog = useToggle(false);
  const [filters, setFilters] = useState<Filter[]>([]);

  // endregion


  // ---- Networking ---- //
  const { data, loading, refetch } = useAccountsListPageQuery();
  const [patchCustodyAccountFacet] = usePatchCustodyAccountFacetMutation();

  // ---- Actions ---- //
  const onWorkingCapitalSwitch = (account: CustodyAccountFacet) => async () => {
    await patchCustodyAccountFacet({
      variables: {
        input: {
          accountFacetId: account.id,
          isWorkingCapital: !account.isWorkingCapital
        }
      }
    });
    await refetch();
  };

  // ---- Destructuring ---- //
  const { custodyAccounts } = (data || {}) as AccountsListPageQuery;
  const custodyAccountsFacets = useMemo(() => (custodyAccounts || [])
    .flatMap((x) => x.facets.map((z) => ({
      id: z?.id,
      label: z?.label,
      accountNumber: x.externalId,
      accountId: x.id,
      symbol: z?.asset!,
      balance: z?.balance!,
      type: z?.type,
      isWorkingCapital: z?.isWorkingCapital!,
      forSecurity: z?.collateral?.symbol
    }))
    ), [custodyAccounts]);
  const custodyAccountsFiltered = useMemo(() =>
    custodyAccountsFacets?.filter((x) => {
      return filters.every((z) => !z.value || (
        z.definition.inputType === 'select' ? get(x, z.definition.backendMap) === z.value :
          get(x, z.definition.backendMap)?.toLowerCase().includes(z.value?.toLowerCase())));
    }),
  [custodyAccountsFacets, filters]);

  const custodyAccountsData = useMemo(() => {
    if (!custodyAccountsFiltered) {
      return [];
    }

    const accounts = sortBy(custodyAccountsFiltered, ['type', 'symbol'], ['desc', 'asc']);

    const groupedAccounts = groupBy(accounts, (x) => x.forSecurity ?? 'General Accounts');

    return Object.entries(groupedAccounts);
  }, [custodyAccountsFiltered]);

  return (
    <Box>
      <CreateCustodyAccountFacetDialog
        {...createCustodyAccountFacetDialog}
      />

      <PageHeading
        title="Accounts"
        breadcrumbs={[
          {
            text: 'Accounts'
          }
        ]}
      >
        <Button onClick={createCustodyAccountFacetDialog.setTrue}>Create New</Button>
      </PageHeading>
      <Box mb={1} gap={1} display="flex">
        <Filters
          onFiltersChanged={setFilters}
          filters={[]}
          customFilters={{ AccountLabel: {
            backendMap: 'label',
            inputType: 'text',
            possibleValues: []
          },
          CollateralSymbol: {
            backendMap: 'forSecurity',
            inputType: 'select',
            possibleValues: [...new Set((custodyAccounts || []).flatMap((z) => z.facets.map((x) => x?.collateral?.symbol)))]
          } }} />
      </Box>
      {custodyAccountsData.map(([groupLabel, custodyAccountsData]) => (
        <Box key={groupLabel}>
          <Box
            sx={{
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
          >
            <Typography
              variant="titleSmall"
            >
              {groupLabel}
            </Typography>
          </Box>
          <DataGrid
            autoHeight
            loading={loading}
            rows={custodyAccountsData || []}
            rowHeight={70}
            onCellEditStart={(params: GridCellEditStartParams, event: MuiEvent) => {
              event.defaultMuiPrevented = true;
            }}
            sx={{
              backgroundColor: 'white'
            }}
            columns={[
              {
                flex: 10,
                field: 'accountNumber',
                headerName: 'Account no.',
                renderCell: ({ value, row }) => {
                  return (
                    <Typography
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                          textDecoration: 'underline'
                        }
                      }}
                      onClick={() => {
                        navigate(`/finops/accounts/${row['accountId']}`);
                      }}
                    >
                      {value}
                    </Typography>);
                }
              },
              {
                flex: 10,
                field: 'label',
                headerName: 'Account label',
                editable: true,
                renderCell: ({ value, id, field, api }) => {
                  return <Typography sx={{
                    width: '100%'
                  }}>{value ?? '-/-'}<IconButton
                      onClick={() => api.startCellEditMode({ id,
                        field })}
                      sx={{
                        pt: '0px',
                        pb: '0px',
                        float: 'right'
                      }}><EditIcon></EditIcon></IconButton></Typography>;
                },
                renderEditCell: (props) => <CustodyAccountFacetLabelInput {...props} onUpdate={() => refetch()}/>
              },
              {
                flex: 10,
                field: 'symbol',
                headerName: 'Symbol',
                renderCell: ({ value }) => {
                  return <Typography>{value ?? '-/-'}</Typography>;
                }
              },
              {
                flex: 10,
                field: 'balance',
                headerName: 'Balance',
                renderCell: ({ value, row }) => {
                  return (
                    <Typography>
                      {(formatters[row.symbol] ?? IntAmountFormatter).format(value)}
                    </Typography>
                  );
                }
              },
              {
                flex: 10,
                field: 'isWorkingCapital',
                headerName: 'Is Working Capital',
                renderCell: ({ row, value }) => {
                  return (
                    <Checkbox
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      checked={value}
                      onChange={onWorkingCapitalSwitch(row as any)}
                    />
                  );
                }
              }
            ]}
          />
        </Box>))}
    </Box>
  );
};
const CustodyAccountFacetLabelInput = (props: { onUpdate: () => {}} & GridRenderCellParams) => {
  const { api, id, field, value } = props;
  const [label, setLabel] = useState<string>(value);
  const [patchCustodyAccountFacet] = usePatchCustodyAccountFacetMutation();

  const handleChange = async () => {
    await patchCustodyAccountFacet({
      variables: {
        input: {
          accountFacetId: props.row.id,
          label
        }
      }
    });
    await api.setEditCellValue({
      id,
      field,
      value: label
    });
    api.stopCellEditMode({
      id,
      field
    });
    await props.onUpdate();
  };
  return <Box sx={{
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center'
  }}>
    <TextField
      value={label}
      size='small'
      sx={{
        display: 'flex',
        flexGrow: 1
      }}
      onChange={(event) => setLabel(event.target.value)} />
    <IconButton
      onClick={handleChange}
    >
      <DoneIcon/>
    </IconButton>
  </Box>;
};
