import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import React from 'react'; import Paper from '@mui/material/Paper';
import { Box, Button, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { AdminPermission, ExternalWalletScoreProvider, ExternalWalletStatus, useClientBankAccountsAndWalletsQuery } from '@backed-fi/graphql';
import { CreateExternalWalletDialog } from '@backed-fi/admin/src/app/domain/Clients/pages/components/CreateExternalWalletDialog';
import { ActivateExternalWalletDialog } from './components/ActivateExternalWalletDialog';
import { useAdminContext } from '../../../context/AdminContext';

const Graph = gql`
  query clientBankAccountsAndWallets($clientId: ID!, $withScore: Boolean!) {
    bankAccounts(where: { clientId: $clientId }) {
      id

      createdAt
      updatedAt

      iban

      verificationStatus
    }

    externalWallets(where: { clientId: $clientId }) {
      id

      createdAt
      updatedAt

      scores @include(if: $withScore) {
        id
        score
        provider
        network
        payload
      }

      address
      status

      reports {
        id
        createdAt
        updatedAt

        reportId
        reportPayload

        reportFiles
      }
    }
  }
`;

interface WalletTableRow {
  id: string;
  date: string;
  address: string;
  status: ExternalWalletStatus;
  highestScore: {
    value: number;
    provider: ExternalWalletScoreProvider;
  }
  lowestScore: {
    value: number;
    provider: ExternalWalletScoreProvider
  },
  flags: string[];
  reports: {
    name: string;
    accessUrl: string;
  }[];
}

export const ClientBankAccountsAndWalletsPage = () => {
  const params = useParams<{ id: string }>();
  const admin = useAdminContext();
  const includeCompliance = admin.permissions.some(p =>
      [AdminPermission.ComplianceRead, AdminPermission.ComplianceReadSensitive, AdminPermission.ComplianceManage].includes(p))
  const snackbar = useSnackbar();

  const addWalletToggle = useToggle();
  const activateWalletToggle = useToggle();

  const [externalWalletId, setExternalWalletId] = React.useState<string>();

  // ---- Networking ---- //
  const { data } = useClientBankAccountsAndWalletsQuery({
    variables: {
      clientId: params.id!,
      withScore: includeCompliance
    }
  });

  const wallets = React.useMemo<Array<WalletTableRow>>(() => {
    if (!data) {
      return [];
    }

    return data.externalWallets.map((d) => {
      const scores = d.scores ? d.scores.map((score) => {
        const provider = score.provider!;
        // Calculate delta for different providers. 100 - good score, 0 - bad score
        const value = provider === ExternalWalletScoreProvider.Scorechain ? (score.score) : (100 - score.score);

        return {
          value,
          provider
        };
      }): [];

      const flags = d.scores ? d.scores.reduce((prev, acc) => {
        if (acc.provider === ExternalWalletScoreProvider.Coinfirm) {
          return prev.concat(acc.payload.cscore_info.map((r: any) => r.name));
        } else {
          return prev.concat(
            acc.payload.risks.map((r: any) => r.reference?.name).filter((x: string) => x),
            acc.payload.risks.flatMap((r: any) => r.causes?.map((x: any) => x.name) ?? []).filter((x: string) => x)
          );
        }
      }, []): [];

      const reportFiles = d.reports.reduce((prev, acc) => prev.concat(acc?.reportFiles),
        [] as {name: string, accessUrl: string}[]);

      return {
        id: d.id,
        address: d.address,
        status: d.status,
        date: new Date(d.createdAt).toLocaleString(),
        highestScore: scores.reduce((highest, score) => highest.value > score.value ? highest : score, { provider: null as any,
          value: -1 }),
        lowestScore: scores.reduce((lowest, score) => lowest.value < score.value ? lowest : score, { provider: null as any,
          value: 100 }),
        flags: [...new Set(flags)],
        reports: reportFiles.map((r, index) => {
          return { name: `Report file - ${index + 1}`,
            accessUrl: r.accessUrl };
        })
      };
    });
  }, [data]);

  return (
    <Box>
      <CreateExternalWalletDialog {...addWalletToggle} />
      <ActivateExternalWalletDialog {...activateWalletToggle} externalWalletId={externalWalletId!} />

      {/* region Wallets  */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="titleSmall">Wallets</Typography>

          <Typography variant="subtitleSmall">
            List of all the wallets registered in the system by the client
          </Typography>
        </Box>

        <Button onClick={addWalletToggle.setTrue}>Add manually</Button>
      </Box>
      <TableContainer
        component={Paper}
        sx={
          { my: '16px' }
        }
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width='15%'>Date and Time Connected</TableCell>
              <TableCell width='20%'>Wallet Address</TableCell>
              <TableCell width='10%'>Status</TableCell>
              {includeCompliance && <>
                <TableCell width='10%'>Max incoming score</TableCell>
                <TableCell width='10%'>Min incoming score</TableCell>
                <TableCell width='15%'>Flags</TableCell>
                <TableCell width='15%'>Report</TableCell>
              </>}
              <TableCell width='10%'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            {!!wallets.length && wallets.map((wallet) => (
              <TableRow key={wallet.id}>
                <TableCell>
                  {wallet.date}
                </TableCell>
                <TableCell>
                  <Typography
                    variant='code'
                    sx={{
                      height: '21px',
                      gap: '0.25rem',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: 14
                    }}
                  >
                    {wallet.address}

                    <IconButton
                      color='inherit'
                      onClick={() => {
                        navigator.clipboard.writeText(wallet.address).then(() => {
                          snackbar.enqueueSnackbar('Copied wallet address to clipboard', {
                            variant: 'success'
                          });
                        });
                      }}
                    >
                      <ContentCopyIcon fontSize='small' />
                    </IconButton>
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography>{wallet.status}
                  </Typography>
                </TableCell>
                { includeCompliance && <>
                  <TableCell>
                  <Typography>{wallet.highestScore.provider ?
                    `${wallet.highestScore.value} (Provider: ${wallet.highestScore.provider})` : '-'}
                  </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{wallet.lowestScore.provider ?
                      `${wallet.lowestScore.value} (Provider: ${wallet.lowestScore.provider})` : '-'}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{ wallet.flags.join(', ')}</Typography>
                  </TableCell>
                  <TableCell>
                    {
                      wallet.reports.map((report) => (
                        <Typography key={report.name} target="_blank"
                          component="a"
                          href={report.accessUrl}>{report.name}</Typography>
                      ))
                    }
                  </TableCell>
                </>}
                <TableCell>
                  {
                    wallet.status !== ExternalWalletStatus.Active &&
                    <Button onClick={() => {
                      setExternalWalletId(wallet.id);
                      activateWalletToggle.setTrue();
                    }}>Activate</Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* endregion */}

      {/* region Bank Accounts  */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Box>
          <Typography variant="titleSmall">Bank Accounts</Typography>

          <Typography variant="subtitleSmall">
            List of all the bank accounts registered in the system by the client
          </Typography>
        </Box>
      </Box>

      <Typography variant="code">
        {JSON.stringify(data?.bankAccounts || {}, null, 2)}
      </Typography>

      {/* endregion */}
    </Box>
  );
};
