import React from 'react';

import { useToggle } from '@backed-fi/hooks';
import { MenuCommand, MenuCommandSearchResult } from '@backed-fi/admin/src/app/components/CommandMenu/types';

interface CommandMenuContext {
  toggle: ReturnType<typeof useToggle>;

  /**
   * Function for resetting the state of the command menu
   *
   * @param shouldClose - Optional boolean, indicating if the context
   *                      menu dialog should be closed
   */
  reset: (shouldClose?: boolean) => void;

  filter: string;
  setFilter: React.Dispatch<React.SetStateAction<string>>;

  results: MenuCommandSearchResult[];
  setResults: React.Dispatch<React.SetStateAction<MenuCommandSearchResult[]>>;

  currentTarget?: MenuCommand;
  setCurrentTarget: (target: MenuCommand | undefined) => void;


  focusedItem?: MenuCommandSearchResult;
  setFocusedItem: React.Dispatch<React.SetStateAction<MenuCommandSearchResult | undefined>>;
}


export const CommandMenuContext = React.createContext<CommandMenuContext>({
  results: [] as MenuCommandSearchResult[]
} as CommandMenuContext);


export const CommandMenuContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const toggle = useToggle();

  // region State

  const [filter, setFilter] = React.useState<string>('');
  const [focusedItem, setFocusedItem] = React.useState<MenuCommandSearchResult>();
  const [currentTarget, setCurrentTarget] = React.useState<MenuCommand>();
  const [results, setResults] = React.useState<MenuCommandSearchResult[]>([]);

  // endregion

  // region Actions

  const reset = (shouldClose: boolean = false) => {
    if (shouldClose) {
      toggle.setFalse();
    }

    setFilter('');
    setFocusedItem(undefined);
    setCurrentTarget(undefined);
    setResults([]);
  };

  // endregion

  return (
    <CommandMenuContext.Provider
      value={{
        toggle,
        reset,

        filter,
        setFilter,

        focusedItem,
        setFocusedItem,

        currentTarget,
        setCurrentTarget: (target) => {
          setFilter('');
          setCurrentTarget(target);
        },

        results,
        setResults
      }}
    >
      {children}
    </CommandMenuContext.Provider>
  );
};

export const useCommandMenuContext = () => React.useContext(CommandMenuContext);
