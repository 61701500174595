import { gql } from '@apollo/client';
import React, { useState } from 'react';
import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { InfoLabel, Protector } from '@backed-fi/compound';
import {
  AdminPermission,
  IncomingTransactionDetailsQuery,
  IncomingTransactionScoreProvider,
  useIncomingTransactionDetailsQuery
} from '@backed-fi/graphql';
import { Comments } from '../../../../../components/Comments';
import { parseIncomingTransactionRedFlags } from '../helpers/parseIncomingTransactionRedFlags';

interface Props {
  incomingTransactionId: string;
}

const Graph = gql`
  query incomingTransactionDetails($id: String!) {
    incomingTransaction(transactionId: $id) {
      id

      hash
      index
      network

      type
      amount

      type
      status
      contractAddress
      benefactorAddress
      beneficiaryAddress

      problems
      problemsChecked

      score
      scoreDetails
      scoreProvider

      report {
        id
        reportId
        reportFiles
        reportPayload
      }
    }
  }
`;

export const IncomingTransactionDetails: React.FC<Props> = ({
  incomingTransactionId
}) => {
  // ---- Networking ---- //
  const { data, ...query } = useIncomingTransactionDetailsQuery({
    variables: {
      id: incomingTransactionId
    }
  });
  const [scoreOpen, setScoreOpen] = useState(false);
  const [reportOpen, setReportOpen] = useState(false);
  const [incomingTransactionRedFlags, setIncomingTransactionRedFlags] =
    useState([] as string[]);

  // region Effects
  React.useEffect(() => {
    if (data && data.incomingTransaction) {
      const incomingTransactionRedFlags =
        parseIncomingTransactionRedFlags(data.incomingTransaction.scoreDetails, data.incomingTransaction.scoreProvider!);

      setIncomingTransactionRedFlags(incomingTransactionRedFlags);
    }
  }, [data]);
  // endregion

  // region Destructuring
  const { incomingTransaction } = (data ||
    {}) as IncomingTransactionDetailsQuery;
  const { report } = (incomingTransaction ||
    {}) as IncomingTransactionDetailsQuery['incomingTransaction'];
  // endregion

  return (
    <>
      <Box
        sx={{
          gap: '1rem',
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            xl: '1fr 1fr 1fr'
          }
        }}
      >
        <Box>
          <Typography variant="titleSmall">Transaction Information</Typography>

          <InfoLabel
            label="Transaction Type"
            content={data?.incomingTransaction?.type}
          />

          <InfoLabel
            label="Transaction Status"
            content={data?.incomingTransaction?.status}
          />

          <InfoLabel
            copy
            label="Contract Address"
            tooltip="The address with which the benefactor interacted"
            content={data?.incomingTransaction?.contractAddress}
          />

          <InfoLabel
            copy
            label="Benefactor Address"
            tooltip="The address with which the benefactor interacted"
            content={data?.incomingTransaction?.benefactorAddress}
          />

          <InfoLabel
            copy
            label="Beneficiary Address"
            tooltip="The address with which the benefactor interacted"
            content={data?.incomingTransaction?.beneficiaryAddress}
          />
        </Box>

        <Box>
          <Typography variant="titleSmall">Chain Information</Typography>

          <InfoLabel
            copy
            label="Transaction ID"
            tooltip="The ID of the transaction in our system"
            content={data?.incomingTransaction?.id}
          />

          <InfoLabel
            copy
            label="Transaction Hash"
            tooltip="The hash of the transaction. Useful to find transaction on explorer"
            content={data?.incomingTransaction?.hash}
          />

          <InfoLabel
            label="Transaction Log Index"
            tooltip="The index of the log that created this transaction entity"
            content={data?.incomingTransaction?.index}
          />

          <InfoLabel
            label="Transaction Network"
            tooltip="The network on which the transaction occurred"
            content={data?.incomingTransaction?.network}
          />
        </Box>

        <Box>
          <Typography variant="titleSmall">Detected Problems</Typography>

          <Typography>
            {data?.incomingTransaction?.problemsChecked
              ? `The transaction was checked for problems. During the check ${data?.incomingTransaction?.problems?.length
                ? 'some problems were found.'
                : 'no problems were found'
              }`
              : 'The transaction is not yet checked for problems'}

            {(data?.incomingTransaction?.problems?.length || 0) > 0 &&
              JSON.stringify(data?.incomingTransaction?.problems)}
          </Typography>
        </Box>
      </Box>

      <Box mt={4}>
        <Typography
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          variant="titleSmall"
        >
          Compliance Information
          {incomingTransactionRedFlags?.length > 0 && (
            <ErrorOutlineIcon sx={{ marginLeft: '4px' }} color="error" />
          )}
        </Typography>

        <InfoLabel
          label="Score Provider"
          tooltip={'Provider that has performed analysis on the transaction'}
          content={data?.incomingTransaction?.scoreProvider}
        />

        <InfoLabel
          label={`Score ${data?.incomingTransaction?.scoreProvider
            ? data?.incomingTransaction?.scoreProvider ===
              IncomingTransactionScoreProvider.Scorechain
              ? '(Low score = High risk)'
              : '(High score = High risk)'
            : ''
            }`}
          tooltip={
            'The incoming score that was given to the ' +
            'transaction if the transaction happened on ' +
            'supported by network'
          }
          content={data?.incomingTransaction?.score}
        />

        <InfoLabel
          label={'Red flag'}
          tooltip={'List of risks associated with the transaction that has been identified by score provider'}
          content={
            incomingTransactionRedFlags?.length > 0 ? (
              <Box>
                {incomingTransactionRedFlags.map((flag) => (
                  <Box key={flag}>{flag}</Box>
                ))}
              </Box>
            ) : (
              'Not identified'
            )
          }
        />

        <InfoLabel
          label="Raw score details"
          content={
            <Box onClick={() => setScoreOpen(!scoreOpen)}>
              Click to expand
              <IconButton aria-label="expand" size="small">
                {scoreOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
              <Collapse in={scoreOpen} timeout="auto" unmountOnExit>
                <span style={{ whiteSpace: 'pre' }}>
                  {JSON.stringify(
                    data?.incomingTransaction?.scoreDetails,
                    null,
                    4
                  )}
                </span>
              </Collapse>
            </Box>
          }
        />
      </Box>

      {report && (
        <Box>
          <Typography variant="titleSmall">Compliance Report</Typography>

          <InfoLabel label="Report Id" content={report.reportId} />

          <InfoLabel
            label="Raw report details"
            content={
              <Box onClick={() => setReportOpen(!reportOpen)}>
                Click to expand
                <IconButton aria-label="expand" size="small">
                  {reportOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
                <Collapse in={reportOpen} timeout="auto" unmountOnExit>
                  <Typography
                    variant="code"
                    children={JSON.stringify(report.reportPayload, null, 3)}
                  />
                </Collapse>
              </Box>
            }
          />

          <InfoLabel
            label="Report files"
            content={
              <React.Fragment>
                {(report.reportFiles as any[]).map((file, index) => (
                  <Typography
                    key={index}
                    target="_blank"
                    component="a"
                    href={file.accessUrl}
                  >
                    File {index + 1}
                  </Typography>
                ))}
              </React.Fragment>
            }
          />
        </Box>
      )}

      <Box>
        <Divider />

        <Protector permissions={[AdminPermission.ComplianceRead]}>
          <Comments incomingTransactionId={incomingTransactionId} />
        </Protector>
      </Box>
    </>
  );
};
