import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Title } from '@backed-fi/compound';
import { ethers } from 'ethers';
import { useSearchParams } from 'react-router-dom';
import { CentsFormatter } from '@backed-fi/shared';
import Decimal from 'decimal.js';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useClearanceAccountDetailsLazyQuery, useBrokersClearanceAccountsQuery, useUpsertClearanceAccountMutation, useCollateralCustodyQuery } from '@backed-fi/graphql';
import { uniq } from 'lodash';

// region Graph

gql`
  query clearanceAccountDetails($input: ClearanceAccountWhereUniqueInput!) {
    clearanceAccount(where: $input) {

      id
      broker {
        id
        name
      }
      institutionName
      depositoryCode
      institutionBic
    }
  }

  query collateralCustody($collateralId: String!) {
    collateral(collateralId: $collateralId) {
      id
      symbol
      clearanceAccounts {
        id
        broker {
          id
          name
        }
        institutionName
        depositoryCode
        institutionBic
      }
    }
  }

  query brokersClearanceAccounts {
    brokers {
      nodes {
        id
        name
        clearanceAccounts {
          id

          institutionName
          institutionBic
          depositoryCode
        }
      }
    }
  }

  mutation upsertClearanceAccount($input: UpsertClearanceAccountInput!) {
    upsertClearanceAccount(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Schema

const Schema = z.object({
  clearanceAccountId: z.string()
    .optional(),

  institutionName: z.string()
    .optional(),

  institutionBic: z.string()
    .optional(),

  depositoryCode: z.string()
    .optional(),

  brokerId: z.string()
    .optional()
});
type Schema = z.infer<typeof Schema>;

// endregion

// region Props

interface Props {
  collateralId: string;
  clearanceAccountId?: string;
  onVerified?: () => any;
}

// endregion

export const ConfigureClearanceAccountDialog: React.FC<Props> = ({ collateralId, clearanceAccountId: requestedClearanceAccountId, onVerified }) => {
  const dialog = useToggle();
  const isApproving = useToggle();
  const snackbar = useSnackbar();

  // region Form Control

  const {
    formState,
    register,
    ...form
  } = useForm<Schema>({
    resolver: zodResolver(Schema),
    defaultValues: {
      clearanceAccountId: requestedClearanceAccountId ?? '-/-'
    }
  });

  const { errors } = formState;
  const brokerId = form.watch('brokerId');
  const clearanceAccountId = form.watch('clearanceAccountId');


  // endregion

  const [upsertAccount] = useUpsertClearanceAccountMutation();
  const [fetchClearanceAccount, { data: clearanceAccountData }] = useClearanceAccountDetailsLazyQuery();
  const { data: dataRaw } = useBrokersClearanceAccountsQuery();
  const { data: collateralData } = useCollateralCustodyQuery({
    variables: {
      collateralId
    }
  });
  const data = React.useMemo(() => dataRaw?.brokers.nodes.filter(x => !collateralData?.collateral.clearanceAccounts.some(z => z.broker.id === x.id) ?? true) ?? [], [dataRaw, collateralData]);
  const clearanceAccounts = React.useMemo(() => data.find(x => x.id === brokerId!)?.clearanceAccounts ?? [], [data, brokerId])

  // region Effect

  useEffect(() => {
    if (requestedClearanceAccountId) {
      fetchClearanceAccount({
        variables: {
          input: {
            id: requestedClearanceAccountId
          }
        }
      })
    } else {
      form.setValue('brokerId', data[0]?.id)
    }
  }, [requestedClearanceAccountId, data]);
  useEffect(() => {
    form.setValue('clearanceAccountId', clearanceAccountData?.clearanceAccount?.id)
    form.setValue('brokerId', clearanceAccountData?.clearanceAccount?.broker.id)
    form.setValue('institutionBic', clearanceAccountData?.clearanceAccount?.institutionBic!)
    form.setValue('institutionName', clearanceAccountData?.clearanceAccount?.institutionName!)
    form.setValue('depositoryCode', clearanceAccountData?.clearanceAccount?.depositoryCode!)
  }, [clearanceAccountData])
  // endregion

  // region On Approve

  const onApprove = form.handleSubmit(async (data) => {
    try {
      isApproving.setTrue();

      const { clearanceAccountId, ...values } = form.getValues();
      await upsertAccount({
        awaitRefetchQueries: true,
        refetchQueries: [
          'tokenCollateralCustody'
        ],
        variables: {
          input: {
            collateralId,
            ...values,
            ...(clearanceAccountId !== '-/-' && {
              clearanceAccountId
            })
          }
        }
      });

      if (onVerified) {
        onVerified();
      }

      dialog.setFalse();

      snackbar.enqueueSnackbar(`Successfully configured clearance account`, {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar(`An error occurred while configuring clearance account`, {
        variant: 'error'
      });
    } finally {
      isApproving.setFalse();
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>
        {requestedClearanceAccountId ? 'Modify' : 'Add'} Account
      </Button>

      {dialog.open && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Configure clearance account"
              subtitle="Set clearance account details"
            />

            {/* region Labels */}

            {!requestedClearanceAccountId && <TextField
              select
              size="small"
              fullWidth
              label="Broker"
              error={Boolean(errors.brokerId)}
              helperText={errors.brokerId?.message}
              {...register('brokerId')}
            >
              {data.map((value) => (
                <option key={value.id} value={value.id}>
                  {value.name}
                </option>
              ))}
            </TextField>}
            {brokerId && !requestedClearanceAccountId && (
              <TextField
                select
                size="small"
                fullWidth
                label="Clearance Account"
                error={Boolean(errors.clearanceAccountId)}
                helperText={errors.clearanceAccountId?.message}
                {...register('clearanceAccountId')}
              >
                <option value={'-/-'}>
                  Create new
                </option>
                {clearanceAccounts.map((value) => (
                  <option key={value.id} value={value.id}>
                    {value.institutionName} | {value.institutionBic}  | {value.depositoryCode}
                  </option>
                ))}
              </TextField>)}
            {(clearanceAccountId === '-/-' || requestedClearanceAccountId) && (
              <>

                <TextField
                  fullWidth
                  label="Institution Name"
                  error={!!errors?.institutionName}
                  helperText={errors?.institutionName?.message}
                  {...register('institutionName')}
                />
                <TextField
                  fullWidth
                  label="Institution BIC"
                  error={!!errors?.institutionBic}
                  helperText={errors?.institutionBic?.message}
                  {...register('institutionBic')}
                />
                <TextField
                  fullWidth
                  label="Depository Code"
                  error={!!errors?.depositoryCode}
                  helperText={errors?.depositoryCode?.message}
                  {...register('depositoryCode')}
                />
              </>)}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={isApproving.value}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={isApproving.value}
                onClick={onApprove}
              >
                Submit
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};