import { useCreateFolderMutation, useUploadDocumentMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { useParams } from 'react-router-dom';
import { useConfetti } from '@backed-fi/hooks';


export const useUploadDocuments = () => {
  const snackbar = useSnackbar();
  const confetti = useConfetti();
  const params = useParams<{ id: string; }>();
  const { folder } = useDocumentContext();


  const [createFolderMutation] = useCreateFolderMutation();
  const [uploadDocumentMutation] = useUploadDocumentMutation();

  return async (files: File[]) => {
    let snackbarId = snackbar.enqueueSnackbar('Uploading documents', {
      persist: true
    });

    const formattedFiles: {
      file: File;
      folderId: string;
    }[] = [];

    for (const file of files) {
      const folderId = await (async () => {
        return !file.webkitRelativePath || file.webkitRelativePath === ''
          ? folder?.id!
          : (
            await createFolderMutation({
              variables: {
                input: {
                  path: file.webkitRelativePath.replace(file.name, ''),
                  parentId: folder?.id!
                }
              }
            })
          ).data!.createDocumentFolder.id;
      })();

      formattedFiles.push({
        folderId,
        file
      });
    }

    try {
      const uploadResults = await Promise.allSettled(
        formattedFiles.map(async ({
          folderId,
          file
        }) => {
          await uploadDocumentMutation({
            variables: {
              input: {
                folderId,
                document: file,
                name: file.name,
                clientId: params.id!
              }
            }
          });
        })
      );
      const maybeError = uploadResults.filter((x): x is PromiseRejectedResult => x.status === 'rejected');
      if (maybeError.length > 0) {
        console.error(maybeError);

        snackbar.enqueueSnackbar(`An error occurred with upload of some of the files:\n ${maybeError
          .flatMap((x) => x.reason?.graphQLErrors)
          .flatMap((x) => x.extensions?.validationErrors ?? [])
          .map((x) => x?.message).join('; \n')}`, {
          variant: 'error'
        });
      } else {
        await folder?.fetch();

        snackbar.enqueueSnackbar('Successfully uploaded file', {
          variant: 'success'
        });

        await confetti.emitConfetti();
      }
    } catch (e) {
      console.error(e);

      snackbar.enqueueSnackbar('An error occurred with the file upload', {
        variant: 'error'
      });
    } finally {
      snackbar.closeSnackbar(snackbarId);
    }
  };
};
