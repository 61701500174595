import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { useCollateralAutocompleteSearchQuery } from '@backed-fi/graphql';

// region Graph

gql`
  query collateralAutocompleteSearch {
    collaterals {
      id
      symbol
    }
  }
`;

// endregion

export const CollateralSearch: React.FC<Partial<React.ComponentProps<typeof Autocomplete>>> = (props) => {
  const { data } = useCollateralAutocompleteSearchQuery();

  return (
    <Autocomplete
      disabled={!data}
      options={data?.collaterals ?? []}
      getOptionLabel={(option: any) => option.symbol}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Collateral"
        />
      )}
      {...props}
    />
  );
};
