import { Route } from 'react-router-dom';

import { BridgeTransfersListPage } from './transfers/BridgeTransfersListPage';
import { BridgesListPage } from './BridgesListPage';
import { BridgeDetailsPage } from './BridgeDetailsPage';

export const BridgePages = (
  <Route path='bridges'>
    <Route path=':id'
      element={<BridgeDetailsPage />}
    />
    <Route path='all'>
      <Route index
        element={
          <BridgesListPage />
        }
      />
    </Route>
    <Route path='transfers'>
      <Route
        index
        element={
          <BridgeTransfersListPage />
        }
      />
    </Route>
  </Route >
)
