import { Route } from 'react-router-dom';

import { BankTransfersListPage } from './tranfers/pages/BankTransfersListPage';

export const BankingPages = (
  <Route path='banking'>
    <Route path='transfers'>
      <Route
        index
        element={
          <BankTransfersListPage />
        }
      />
    </Route>
  </Route>
)
