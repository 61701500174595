import React from 'react';
import { Box, Chip } from '@mui/material';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';

export const CommandMenuTarget: React.FC = () => {
  const {
    filter,
    currentTarget,
    setCurrentTarget
  } = useCommandMenuContext();

  // region Key Listeners

  // Listen for shortcuts
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (!!currentTarget) {
        if (e.key === 'Backspace' && !filter) {
          e.preventDefault();

          setCurrentTarget(undefined);
        }
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [filter]);

  // endregion

  return (
    <Box
      sx={{
        mb: '.25rem',
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Chip
        label="Home"
        size="small"
        sx={{
          borderRadius: '4px'
        }}
      />

      {currentTarget && (
        <React.Fragment>
          <ChevronRightRoundedIcon
            sx={{
              fontSize: '18px',
              color: 'utility.textLowContrast'
            }}
          />

          <Chip
            label={currentTarget.name}
            size="small"
            sx={{
              borderRadius: '4px'
            }}
          />
        </React.Fragment>
      )}
    </Box>
  );
};
