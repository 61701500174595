import React from 'react';

import { Box } from '@mui/material';
import { ClientFeesConfiguration } from '@backed-fi/admin/src/app/domain/Clients/pages/Fees/components/ClientFeesConfiguration';

export const ClientFeesPage: React.FC = () => {
  return (
    <Box>
      <ClientFeesConfiguration />
    </Box>
  );
};
