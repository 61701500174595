import React from 'react';
import { Box, CircularProgress, Grid, Tooltip as InfoTooltip, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { gql } from '@apollo/client';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList
} from 'recharts';
import { useParams } from 'react-router-dom';
import { useTokenCollateralizationDetailsQuery } from '@backed-fi/graphql';
import Decimal from 'decimal.js';
import { InfoLabel } from '@backed-fi/compound';
import {
  CryptoDecimalAwareFormatter,
  CryptoFormatter
} from '@backed-fi/shared';

const Graph = gql`
  query TokenCollateralizationDetails($tokenId: String!) {
    token(id: $tokenId) {
      id
      symbol
      collateral {
        symbol
        price
        balance
        usedBalance
        pendingBalance
        tokens {
          id
          pendingBalance
          deployments {
            circulatingSupply
            totalSupply
          }
        }
      }
      pendingBalance
    }
  }
`;

type TokenCollateralizationInfo = {
  name: string;
  value: number;
  pendingValue?: number;
  color?: string;
};

const CustomTooltip = ({
  active,
  payload,
  label
}: any) => {
  if (active && payload && payload.length) {
    const item = payload[0].payload as TokenCollateralizationInfo;
    return (
      <Box
        sx={{
          flexFlow: 'column',
          display: 'flex',
          gap: '1rem',
          maxWidth: '200px',
          margin: 0,
          lineHeight: '24px',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          padding: '10px'
        }}
      >
        <Typography>
          Total: ${CryptoDecimalAwareFormatter.format(item.value + (item.pendingValue ?? 0))}
        </Typography>

        <Typography>
          Current: ${CryptoDecimalAwareFormatter.format(item.value)}
        </Typography>

        <Typography>
          Pending: ${CryptoDecimalAwareFormatter.format(item.pendingValue ?? 0)}
        </Typography>
      </Box>
    );
  }

  return null;
};

const labelToTooltip = {
  Collateral: 'Securities in custody',
  'Circulating Supply': 'Amount of tokens that are circulating in the markets and are in public hands. In Backed case this specifically refer to pledged tokens that were distributed to customers and are not in the overcollateral buffer. This is the value that we will export externally and will be matched against Collateral bought to validate the Proof of Reserves.',
  Overcollateral: 'Delta between Collateral and Token distributed - it represents tokens collateralised but not distributed to customers',

  'Total Supply': 'Tokens in existence, specifically the number of tokens minted minus tokens burnt. This means that the total supply is the sum of the circulating supply + unpledged tokens (in Backed wallets: system and treasury)'
} as { [key: string]: string };

const CustomLabel = ({
  active,
  payload,
  x,
  y
}: any) => {
  return (
    <foreignObject
      transform={`translate(${x - 90},${y})`}
      width='180'
      height='24'
    >
      <div style={{ textAlign: 'center' }}>
        <span style={{ display: 'inline-block' }}>{payload.value}</span>
        <InfoTooltip title={labelToTooltip[payload.value]}>
          <InfoOutlinedIcon fontSize='small' />
        </InfoTooltip>
      </div>
    </foreignObject>
  );
};

const CustomYAxisTick = ({
  active,
  payload,
  x,
  y
}: any) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} fontSize='16' textAnchor='end' fill='#666'>
        {CryptoDecimalAwareFormatter.format(payload.value)}
      </text>
    </g>
  );
};

const renderCustomizedLabel = ({
  x,
  y,
  width,
  height,
  value
}: any) => {
  return (
    value && (
      <g>
        <text
          x={x + width / 2}
          y={y + height / 2}
          fill='#000'
          fontWeight='bold'
          fontSize='larger'
          textAnchor='middle'
          dominantBaseline='middle'
        >
          {CryptoDecimalAwareFormatter.format(value)}
        </text>
      </g>
    )
  );
};

// region Props

interface Props {
  tokenId: string;
}

// endregion

export const CollateralisationGraph: React.FC<Props> = ({ tokenId }) => {
  const {
    data,
    loading
  } = useTokenCollateralizationDetailsQuery({
    variables: {
      tokenId
    }
  });

  const dataseries = React.useMemo<Array<TokenCollateralizationInfo[]>>(() => {
    if (!data) {
      return [];
    }

    const circulatingSupply = data?.token.collateral.tokens
      .flatMap((x) => x.deployments)
      .reduce((prev, curr) => {
        return prev.add(
          new Decimal(curr.circulatingSupply)
            .div(new Decimal(10)
              .pow(18))
        );
      }, new Decimal(0));


    const totalSupply = data?.token.collateral.tokens
      .flatMap((x) => x.deployments)
      .reduce((prev, curr) => {
        return prev.add(
          new Decimal(curr.totalSupply)
            .div(new Decimal(10)
              .pow(18))
        );
      }, new Decimal(0));

    return [
      [
        {
          name: 'Collateral',
          value: data?.token.collateral.balance,
          pendingValue: data?.token.collateral.pendingBalance
        }
      ],
      [
        {
          name: 'Circulating Supply',
          value: circulatingSupply.toNumber(),
          pendingValue: new Decimal(data?.token.pendingBalance)
            .div(1e18)
            .toNumber()
        }
      ],
      [
        {
          name: 'Overcollateral',
          value: new Decimal(data?.token.collateral.balance)
            .sub(new Decimal(data?.token.collateral.usedBalance).div(1e18))
            .toNumber(),
          pendingValue: new Decimal(data?.token.collateral.pendingBalance)
            .sub(
              data?.token.collateral.tokens
                .reduce(
                  (acc, token) => acc.plus(token.pendingBalance),
                  new Decimal(0)
                )
                .div(1e18)
            )
            .toNumber(),
          color: '#FEDC56'
        }
      ],
      [
        {
          name: 'Total Supply',
          value: totalSupply.toNumber()
        }
      ]
    ];
  }, [data]);

  return (
    <Box>
      {/* region Loading State */}

      {loading && (
        <Box
          sx={{
            my: '4rem',
            gap: '1rem',
            display: 'flex',
            flexFlow: 'column',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <CircularProgress />

          <Box>
            <Typography variant='titleSmall'>
              Loading
            </Typography>

            <Typography variant='subtitleSmall'>
              Fetching collateralisation data
            </Typography>
          </Box>
        </Box>
      )}

      {/* endregion */}

      {!loading && (
        <React.Fragment>
          <Grid container spacing={3}>
            {dataseries.map((data) => (
              <Grid item xs='auto' key={data[0].name}>
                <BarChart
                  width={250 * data.length}
                  height={300}
                  data={data}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  <XAxis dataKey='name' tick={<CustomLabel />} />
                  <YAxis
                    type='number'
                    tick={<CustomYAxisTick />}
                    domain={([dataMin, dataMax]) => {
                      return [
                        dataMin,
                        Math.abs(dataMax) < 5
                          ? dataMax * 2
                          : Math.ceil(dataMax)
                      ];
                    }}
                  />
                  <Tooltip content={<CustomTooltip />} />

                  <Legend />

                  <Bar dataKey='value' name='Current' stackId='a' fill={data[0].color ?? '#8884d8'}>
                    <LabelList dataKey='value' content={renderCustomizedLabel} />
                  </Bar>

                  {'pendingValue' in data[0] && (
                    <>
                      <pattern
                        id='diagonalHatch'
                        width='10'
                        height='10'
                        patternTransform='rotate(45 0 0)'
                        patternUnits='userSpaceOnUse'
                      >
                        <line
                          x1='0'
                          y1='0'
                          x2='0'
                          y2='10'
                          style={{
                            stroke: 'black',
                            strokeOpacity: '50%',
                            strokeWidth: 4
                          }}
                        />
                      </pattern>

                      <Bar
                        dataKey='pendingValue'
                        name='Pending'
                        stackId='a'
                        fill='url(#diagonalHatch)'
                        stroke='red'
                      >
                        <LabelList
                          dataKey='pendingValue'
                          content={renderCustomizedLabel}
                        />
                      </Bar>
                    </>
                  )}
                </BarChart>
              </Grid>
            ))}
          </Grid>

          <Typography
            align='center'
            variant='subtitleSmall'
          >
            Please mouse over the bars to display the exact amounts
          </Typography>
        </React.Fragment>
      )}
    </Box>
  );
};
