import React from 'react';
import { Box, Chip } from '@mui/material';
import { NavigationButton, PageHeading } from '@backed-fi/compound';
import { Outlet, useParams } from 'react-router-dom';
import { gql } from '@apollo/client';
import { useInteractionDetailsLayoutQuery } from '@backed-fi/graphql';

// region Graph

gql`
  query interactionDetailsLayout($interactionId: String!) {
    interaction(
      where: {
        id: $interactionId
      }
    ) {
      id

      createdAt
      updatedAt

      type
      status
      flags
    }
  }
`;

// endregion

export const InteractionDetailsLayout: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  // region Networking

  const { data } = useInteractionDetailsLayoutQuery({
    variables: {
      interactionId: params.interactionId!
    }
  });

  // endregion

  // region Helpers

  const getUrl = (section: string) => `/interactions/details/${params.interactionId}/${section}`;

  // endregion

  return (
    <Box
      sx={{
        userSelect: 'none'
      }}
    >
      <PageHeading
        noBottomMargin
        loading={!data}
        title="Interaction Details"
        breadcrumbs={[
          {
            text: 'Interactions',
            link: '/interactions/all'
          }, {
            text: `[${params.interactionId}]`
          }, {
            text: 'Details'
          }
        ]}
      />

      <Box
        sx={{
          display: 'flex',
          mt: '.5rem',
          mb: '2rem',
          gap: '.5rem'
        }}
      >
        <Chip
          color="info"
          size="small"
          label={data?.interaction?.status.replace(/([a-z])([A-Z])/g, '$1 $2')}
        />

        {data?.interaction?.flags.map((flag, index) => (
          <Chip
            key={index}
            color="warning"
            size="small"
            label={flag.replace(/([a-z])([A-Z])|([A-Z]+)([A-Z][a-z])/g, '$1$3 $2$4')}
          />
        ))}
      </Box>


      <Box>
        <NavigationButton first to={getUrl('overview')}>
          Overview
        </NavigationButton>

        <NavigationButton to={getUrl('transactions')}>
          Transactions
        </NavigationButton>

        <NavigationButton to={getUrl('transfers')}>
          Bank Transfers
        </NavigationButton>

        <NavigationButton to={getUrl('orders')}>
          Broker Orders
        </NavigationButton>

        <NavigationButton to={getUrl('invoice')}>
          Invoice
        </NavigationButton>

        <NavigationButton to={getUrl('actions')}>
          Actions
        </NavigationButton>
      </Box>

      <Box
        sx={{
          mt: '2rem'
        }}
      >
        <Outlet />
      </Box>

    </Box>
  );
};
