import React from 'react';
import { Box, Dialog, DialogContent, DialogProps } from '@mui/material';
import { Comments } from '@backed-fi/admin/src/app/components/Comments';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { Title } from '@backed-fi/compound';

export const DocumentDiscussionDialog: React.FC<DialogProps> = (props) => {
  const { document } = useDocumentContext();

  return (
    <Dialog
      {...props}
      fullWidth
      maxWidth="xs"
      onClose={async () => {
        try {
          document?.fetch();
        } finally {
          if (typeof props.onClose === 'function') {
            (props.onClose as any)();
          }
        }
      }}
    >
      <DialogContent>
        <Title
          title="Document Discussion"
        />

        <Comments
          maxHeight="300px"
          documentId={document?.id}
        />
      </DialogContent>
    </Dialog>
  );
};
