import React from 'react';
import { Box } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { DeploymentsTable } from '@backed-fi/admin/src/app/domain/Deployment/components/DeploymentsTable';


export const DeploymentsHomePage: React.FC = () => {
  return (
    <Box>
      <PageHeading
        title='Deployments'
        breadcrumbs={[
          {
            text: 'Internal'
          }, {
            text: 'Deployments'
          }
        ]}
      />

      <DeploymentsTable />
    </Box>
  );
};
