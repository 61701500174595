import React from 'react';

import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

import { OutgoingTransactionsTable } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/outgoing/components/OutgoingTransactionsTable';
import { IncomingTransactionsTable } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/incoming/components/IncomingTransactionsTable';
import { InternalTransactionsTable } from '@backed-fi/admin/src/app/domain/Blockchain/transactions/internal/components/InternalTransactionsTable';

export const InteractionTransactionsPage: React.FC = () => {
  const params = useParams<{ interactionId: string }>();

  return (
    <Box>
      <Box>
        <Box
          sx={{
            marginBottom: '1rem'
          }}
        >
          <Typography
            variant="titleSmall"
          >
            Incoming Transactions
          </Typography>

          <Typography
            variant="subtitleSmall"
          >
            List of all the incoming transactions created for that interaction
          </Typography>
        </Box>

        <IncomingTransactionsTable
          hideFilters
          disablePagination
          interactionId={params.interactionId!}
        />
      </Box>

      <Box>
        <Box
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem'
          }}
        >
          <Typography
            variant="titleSmall"
          >
            Internal Transactions
          </Typography>

          <Typography
            variant="subtitleSmall"
          >
            List of all the internal transactions created for that interaction
          </Typography>
        </Box>

        <InternalTransactionsTable
          disablePagination
          interactionId={params.interactionId!}
        />
      </Box>

      <Box>
        <Box
          sx={{
            marginTop: '2rem',
            marginBottom: '1rem'
          }}
        >
          <Typography
            variant="titleSmall"
          >
            Outgoing Transactions
          </Typography>

          <Typography
            variant="subtitleSmall"
          >
            List of all the outgoing transactions created for that interaction
          </Typography>
        </Box>

        <OutgoingTransactionsTable
          hideFilters
          disablePagination
          interactionId={params.interactionId!}
        />
      </Box>
    </Box>
  );
};
