import React from 'react';
import { z } from 'zod';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { InteractionReportType, useCreateInteractionReportMutation } from '@backed-fi/graphql';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  onDismiss: () => void,

  interactionId: string,
  reportType: InteractionReportType
}

// endregion

// region Networking

const Graph = gql`
  mutation createInteractionReport($input: CreateInteractionReportInput!) {
    createInteractionReport(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Schema

const FormSchema = z.object({
  comment: z.string()
    .optional()
});

type FormSchema = z.infer<typeof FormSchema>

// endregion

export const CreateInteractionReportDialog: React.FC<Props> = ({ interactionId, reportType, ...props }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [createInteractionReport] = useCreateInteractionReportMutation();

  // endregion

  // region Form Control

  const form = useForm<FormSchema>({
    resolver: zodResolver(FormSchema)
  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onCreate = form.handleSubmit(async (data) => {
    try {
      await createInteractionReport({
        variables: {
          input: {
            ...data,
            type: reportType,
            interactionId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'interactionsMonitoring'
        ]
      });

      form.reset({ comment: '' });

      snackbar.enqueueSnackbar('Interaction report successfully created');

      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown');
      }
    } catch (e) {
      snackbar.enqueueSnackbar('Interaction report creation failed');
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Create Interaction {reportType} Report
        </Typography>

        <Typography variant='subtitleSmall'>
          Manually create {reportType} report:
        </Typography>

        <Box my={1}>
          <TextField
            fullWidth
            multiline
            rows='3'
            label='Comment'
            error={!!errors?.comment}
            helperText={errors?.comment?.message}
            {...form.register('comment')}
          />
        </Box>

        <Box
          sx={{
            mt: '8px',
            gap: '8px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="secondary"
            onClick={props.onDismiss}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onCreate}
            loading={isSubmitting}
          >
            Create
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
