import { Box, Button } from '@mui/material';
import React from 'react';
import { useInjectionContext } from '../InjectionContext';

// region Props

interface Props {
  hideNext?: boolean;
  hidePrevious?: boolean;

  validateBeforeNext?: () => boolean | Promise<boolean>;
}

// endregion

export const NavigationButtons: React.FC<Props> = ({
  hideNext,
  hidePrevious,
  validateBeforeNext
}) => {
  const context = useInjectionContext();

  const onGoToNextStep = async () => {
    if (typeof validateBeforeNext === 'function') {
      if (!(await validateBeforeNext())) {
        return;
      }
    }
    context.nextStep();
  };

  return (
    <Box
      sx={{
        display: 'inline-flex',
        gap: '.5rem'
      }}
    >
      {!hidePrevious && (
        <Button onClick={context.previousStep}>
          Previous Step
        </Button>
      )}

      {!hideNext && (
        <Button onClick={onGoToNextStep}>
          Next Step
        </Button>
      )}
    </Box>
  );
};
