import React from 'react';
import { isBefore } from 'date-fns';
import { Chip, Tooltip } from '@mui/material';
import { ClientStatus } from '@backed-fi/graphql';
import { DateTimeFormatter } from '@backed-fi/shared';
import { useToggle } from '@backed-fi/hooks';
import { UnfreezeClientDialog } from '../pages/components/UnfreezeClientDialog';

interface Props {
  clientId: string,
  status: ClientStatus,
  frozenUntil?: Date
}

export const ClientStatusChip: React.FC<Props> = ({ clientId, status, frozenUntil }) => {
  const toggle = useToggle();

  const isClientFrozen = frozenUntil ? isBefore(new Date(), frozenUntil) : false;

  return (
    <>
      <UnfreezeClientDialog {...toggle} onDismiss={toggle.setFalse} clientId={clientId}></UnfreezeClientDialog>

      <Tooltip title={isClientFrozen ? `Until ${DateTimeFormatter.format(frozenUntil!)}. Click here to unfreeze.` : ''}>
        <Chip
          onClick={() => !!isClientFrozen && toggle.setTrue()}
          label={isClientFrozen ? 'Frozen' : status}
          color={{
            Onboarding: 'secondary',
            Active: 'success',
            Rejected: 'error',
            Closed: 'error',
            Frozen: 'primary'
          }[isClientFrozen ? 'Frozen' : (status as ClientStatus)] as React.ComponentProps<typeof Chip>['color']}/>
      </Tooltip>
    </>


  );
};
