import React from 'react';
import { Route } from 'react-router-dom';
import { VerificationsListPage } from './VerificationsListPage';

export const VerificationPages = (
  <Route path='verifications'>
    <Route
      path='all'
      element={<VerificationsListPage />}
    />
  </Route>
);
