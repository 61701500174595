import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { gql } from '@apollo/client';

import { PageHeading } from '@backed-fi/compound';
import { TradesListPageQuery, useTradesListPageQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';

import { DateFormatter } from '@backed-fi/shared';
import { useSnackbar } from 'notistack';

const Graph = gql`
  query TradesListPage {
    trades {
      id

      createdAt
      
      baseAsset {
        name
      }
      amount
      quoteAsset {
        name
      }
      side
      providerTradeId
      provider
      exchangeRate
      status
    }
  }
`;

export const TradesListPage = () => {
  // ---- Networking ---- //
  const { data, loading } = useTradesListPageQuery();

  // ---- Destructuring ---- //
  const { trades } = (data || {}) as TradesListPageQuery;

  // ---- Actions ---- //

  return (
    <Box>
      <PageHeading
        title='Trades'
        breadcrumbs={[{
          text: 'Trades'
        }]}
      />

      <DataGrid
        autoHeight
        loading={loading}
        rows={trades || []}
        rowHeight={70}
        columns={[{
          flex: 4,
          field: 'baseAsset',
          headerName: 'Base Asset',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value?.name ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'quoteAsset',
          headerName: 'Quote Asset',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value?.name ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'amount',
          headerName: 'Amount',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'side',
          headerName: 'Side',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'status',
          headerName: 'Status',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'exchangeRate',
          headerName: 'Rate',
          renderCell: ({ value }) => {
            return (
              <Typography>
                {value ?? '-/-'}
              </Typography>
            );
          }
        }, {
          flex: 4,
          field: 'createdAt',
          headerName: 'Creation Date',
          renderCell: ({ row }) => (
            <Typography>
              {DateFormatter.format(new Date(row.createdAt))}
            </Typography>
          )
        }]}
      />
    </Box>
  );
};
