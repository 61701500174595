import React from 'react';
import { Box, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCreateClientInvitationMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import { useToggle } from '@backed-fi/hooks';

// region Props

interface Props {
  onDismiss: () => void;
}

// endregion

// region Graph

const Graph = gql`
  mutation createClientInvitation($input: CreateInvitationInput!) {
    createInvitation(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Control

const Schema = z.object({
  email: z.string()
    .email()
    .nonempty()
});

type Schema = z.infer<typeof Schema>;

// endregion

export const InviteClientDialog: React.FC<Props> = ({ onDismiss }) => {
  const snackbar = useSnackbar();
  const alreadyInvited = useToggle();

  const [createClientInvitation, { loading }] = useCreateClientInvitationMutation({
    awaitRefetchQueries: true,
    refetchQueries: [
      'invitations'
    ]
  });

  // region Form Control

  const {
    formState,
    register,
    ...form
  } = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors } = formState;

  // endregion

  // region Effects

  React.useEffect(() => {
    return () => {
      // Clean the form state when the component unmounts
      form.reset();
    };
  }, []);

  // endregion

  // region Actions

  const onCreateInvitation = form.handleSubmit(async (data) => {
    try {
      await createClientInvitation({
        variables: {
          input: {
            ...data,
            resend: alreadyInvited.value
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully created client invitation', {
        variant: 'success'
      });

      // Finally call the dismiss function
      await onDismiss();
    } catch (e) {
      alreadyInvited.setTrue();
    }
  });

  // endregion

  return (
    <Dialog
      open
      fullWidth
    >
      <DialogContent>
        <Typography variant="titleSmall">
          {alreadyInvited.value
            ? 'Resend Invitation'
            : 'Invite Client'
          }
        </Typography>

        <Typography variant="subtitleSmall">
          {
            alreadyInvited.value
              ? 'The client is already invited into the system. You resend the invitation'
              : 'Invited clients will be able to onboard into the system'
          }
        </Typography>

        <Box
          sx={{
            my: '16px'
          }}
        >
          {!alreadyInvited.value && (
            <TextField
              fullWidth
              label="Client Email"
              error={Boolean(errors.email)}
              helperText={errors.email?.message}
              {...register('email')}
            />
          )}
        </Box>

        <Box
          sx={{
            mt: '8px',
            gap: '8px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <LoadingButton
            color="secondary"
            onClick={onDismiss}
          >
            Cancel
          </LoadingButton>

          <LoadingButton
            onClick={onCreateInvitation}
            loading={loading}
          >
            {
              alreadyInvited.value
                ? 'Resend Invitation'
                : 'Invite Client'
            }
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
