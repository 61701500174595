import { Box } from '@mui/material';
import React from 'react';
import { PageHeading } from '@backed-fi/compound';
import { gql } from '@apollo/client';

interface Props {
  hideHeader?: boolean;
}

const GraphQl = gql`
  query verificationProfileDetails($profileId: ID!) {
    verificationProfile(id: $profileId) {
      id
    }
  }
`;

export const VerificationProfileDetailsPage: React.FC<Props> = ({ hideHeader }) => {
  return (
    <Box>
      {!hideHeader && (
        <PageHeading
          title='Verification Profile'
          breadcrumbs={[{
            text: 'Verification Profiles',
            link: '/compliance/profiles/all'
          }, {
            text: 'Details'
          }]}
        />
      )}
    </Box>
  );
};
