import React from 'react';
import { Route } from 'react-router-dom';

import { OrdersListPage } from './Orders/pages/OrdersListPage';
import { OrderDetailsPage } from './Orders/pages/OrderDetailsPage';
import { SettlementsListPage } from './Settlements/pages/SettlementsListPage';
import { DvpConfigurationPage } from './Settlements/pages/DvpConfigurationPage';

export const BrokeragePages = (
  <Route path='brokerage'>
    <Route path='orders'>
      <Route
        index
        element={<OrdersListPage />}
      />

      <Route
        path=':id'
        element={<OrderDetailsPage />}
      />
    </Route>

    <Route path='settlements'>
      <Route
        index
        element={
          <SettlementsListPage />
        }
      />
    </Route>

    <Route path='dvp'>
      <Route
        index
        element={
          <DvpConfigurationPage />
        }
      />
    </Route>
  </Route>
);
