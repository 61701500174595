import React from 'react';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';
import { InteractionFlag, useUpdateInteractionMutation } from '@backed-fi/graphql';


// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  onDismiss: () => void,

  interactionId: string;
  flags: InteractionFlag[];
}

// endregion

// region Networking

const Graph = gql`
  mutation updateInteraction($data: UpdateInteractionDataInput!, $where: InteractionWhereUniqueInput!) {
    updateInteraction(data: $data, where: $where) {
      id
    }
  }
`;

// endregion

export const ToggleInteractionAsHighRiskDialog: React.FC<Props> = ({ interactionId, flags, ...props }) => {
  const snackbar = useSnackbar();
  const isMarked = flags.some((f) => f === InteractionFlag.HighRisk);

  // region Networking

  const [updateInteraction, { loading }] = useUpdateInteractionMutation();

  // endregion

  // region Actions
  const onToggleAsHighRisk = async () => {
    try {
      await updateInteraction({
        variables: {
          data: {
            flags: {
              set: isMarked ? flags.filter((f) => f !== InteractionFlag.HighRisk) : [...flags, InteractionFlag.HighRisk]
            }
          },
          where: {
            id: interactionId
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'interactionsMonitoring'
        ]
      });

      snackbar.enqueueSnackbar('Interaction high risk status updated');

      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown');
      }
    } catch (e) {
      snackbar.enqueueSnackbar('Interaction high risk status update has failed');
    }
  };

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Update interaction high risk status
        </Typography>

        <Typography variant='subtitleSmall'>
          Are you sure you want to update interaction high risk status?
        </Typography>

        <Box
          sx={{
            mt: '8px',
            gap: '8px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            color="secondary"
            onClick={props.onDismiss}
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onToggleAsHighRisk}
            loading={loading}
          >
            {isMarked ? 'Unmark' : 'Mark'}
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
