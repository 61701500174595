import React from 'react';

import { gql } from "@apollo/client";
import ReactEcharts from 'echarts-for-react';

import { InfoLabel, PageHeading, Title } from '@backed-fi/compound';
import { BridgeQuery, useBridgeQuery } from '@backed-fi/graphql';
import { useParams } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Decimal from 'decimal.js';

gql`
  query bridge(
    $where: BridgeWhereInput!,
  ) {
    bridge(
      where: $where,
    ) {
      id

      createdAt

      address
      network
      sourceNetworks
      destinationNetworks

      custodyWallet
      assets {
        deployment {
          token {
            symbol
          }
        }
        allowance
        balanceOf
      }
    }
  }
`;

export const BridgeDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();


  const { data } = useBridgeQuery({
    variables: {
      where: {
        bridgeId: params.id!
      }
    }
  });

  const { bridge } = (data || {}) as BridgeQuery;

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: { type: 'shadow' }
    },
    legend: {
      data: ['allowance', 'balanceOf'],
      top: 0
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: { type: 'value' },
    yAxis: {
      type: 'category',
      data: bridge ? bridge.assets.map((asset) => asset.deployment.token.symbol) : []
    },
    series: bridge ? [{
      name: 'balanceOf',
      type: 'bar',
      barWidth: '20%',
      itemStyle: { color: '#C8C8C8' },
      data: bridge.assets.map(x => (new Decimal(x.balanceOf)).div(1e18).toString())
    },

    {
      name: 'allowance',
      type: 'bar',
      barGap: '-100%',
      barCategoryGap: '80%',
      barWidth: '20%',
      itemStyle: { color: '#008000' },
      data: bridge.assets.map(x => (new Decimal(x.allowance)).div(1e18).toString())
    }
    ] : []
  }

  return <>
    <PageHeading
      title="Bridge"
      breadcrumbs={[
        {
          text: 'Bridge'
        }
      ]}
    >
    </PageHeading>

    {bridge && <Box>
      <Title
        title="Details"
      />
      <Box display='flex' flexDirection='row' gap={4}>
        <Box display='flex' flexDirection='column'>
          <InfoLabel label='Address' content={bridge.address} />
          <InfoLabel label='Network' content={bridge.network} />
        </Box>
        <Box>
          <InfoLabel label='Custody Wallet' content={bridge.custodyWallet} />
        </Box>
      </Box>
      <Title
        title="Available lanes"
      />
      <Box display='flex' flexDirection='row' gap={4} mb={2}>
        <Box>
          <Typography variant='label' py={1}>Outgoing</Typography>
          {bridge.destinationNetworks.map(n => (
            <Box display='flex' flexDirection='row' gap={2}>
              <BlockchainNetworkLabel network={bridge.network} />
              <ArrowForwardIcon fontSize="small" />
              <BlockchainNetworkLabel network={n} />
            </Box>
          ))
          }
          {!bridge.destinationNetworks.length && "No outgoing lanes available"}
        </Box>
        <Box>
          <Typography variant='label' py={1}>Incoming</Typography>
          {bridge.sourceNetworks.map(n =>
            <Box display='flex' flexDirection='row' gap={2}>
              <BlockchainNetworkLabel network={n} />
              <ArrowForwardIcon fontSize="small" />
              <BlockchainNetworkLabel network={bridge.network} />
            </Box>
          )}
          {!bridge.sourceNetworks.length && "No incoming lanes available"}
        </Box>
      </Box>

      <Title
        title="Available assets"
      />
      <ReactEcharts option={option} style={{ height: '400px' }} />
    </Box>
    }
  </>;
}