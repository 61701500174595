import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { OracleDetailsQuery, useOracleDetailsLazyQuery, useUpdateOracleAnswerMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Title } from '@backed-fi/compound';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { CryptoFormatter } from '@backed-fi/shared';

// region Graph

gql`
  mutation updateOracleAnswer($input: UpdateOracleAnswerInput!) {
    updateOracleAnswer(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  oracleId: string;
}

// endregion

const Schema = z.object({
  value: z.preprocess(
    (v) => Number(v || '0'),
    z.number().nonnegative()
  )
});

type Schema = z.infer<typeof Schema>;

export const RequestAnswerUpdate: React.FC<Props> = ({ oracleId }) => {
  const dialog = useToggle();
  const snackbar = useSnackbar();

  const form = useForm<Schema>({
    resolver: zodResolver(Schema),
    defaultValues: {
      value: 0
    }
  });
  const {
    errors
  } = form.formState;


  // region Networking

  const [updateOracleAnswerMutation, { loading: requestingUpdate }] = useUpdateOracleAnswerMutation();
  const [fetchData, { data }] = useOracleDetailsLazyQuery({
    variables: {
      oracleId
    }
  });

  const { oracle } = (data || {}) as OracleDetailsQuery;

  // endregion

  // region Effect

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          oracleId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Requested


  const onRequested = form.handleSubmit(async ({ value }) => {
    try {
      await updateOracleAnswerMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'oracleDetails'
        ],
        variables: {
          input: {
            oracleId,
            ...(value && {
              value: CryptoFormatter.format(value, 8) //Assumes standard 8 decimals oracle
            })
          }
        }
      });

      snackbar.enqueueSnackbar('Oracle answer update successfully requested');
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred while requesting oracle answer update', {
        variant: 'error'
      });
    } finally {
      dialog.setFalse();
    }
  });

  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>
        Request Answer Update
      </Button>

      {dialog.open && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Request Answer Update"
              subtitle="Please provide custom value or put 0 to fetch price automatically. Update will be limited by oracle configured change threshold."
            />

            {/* region Labels */}

            <InfoLabel
              label="Symbol"
              content={oracle?.symbol}
            />

            <InfoLabel
              label="Network"
              content={oracle?.network}
            />

            <TextField
              size="small"
              label="New oracle update answer value (in full units, ex. 1.2)"
              type="number"
              sx={{
                marginRight: '16px',
                width: '100%'
              }}

              error={!!errors.value}
              helperText={errors.value?.message}
              {...form.register('value')}
            >

            </TextField>
            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={requestingUpdate}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={requestingUpdate}
                onClick={onRequested}
              >
                Request
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
