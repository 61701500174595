import React from 'react';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';

import { useToggle } from '@backed-fi/hooks';
import { useDeleteRoleMutation } from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Title } from '@backed-fi/compound';

gql`
  mutation DeleteRole($input: DeleteRoleInput!) {
    deleteRole(input: $input)
  }
`;

interface Props {
  adminRole: {
    id: string,
    name: string,
    admins: { email: string, firstName: string, lastName: string }[]
  }
}

export const DeleteRoleDialog: React.FC<Props> = ({ adminRole }) => {
  const deleteRoleDialogToggle = useToggle();
  const snackbar = useSnackbar();

  // region Networking

  const [deleteRoleMutation, { loading }] = useDeleteRoleMutation();

  // endregion

  const onUpdate = async () => {
    try {
      await deleteRoleMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'admins',
          'rolesListQuery'
        ],
        variables: {
          input: {
            roleId: adminRole.id
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully deleted role', { variant: 'success' });

      deleteRoleDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to delete role', { variant: 'error' });
    }
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Tooltip title="Remove role">
          <IconButton
            onClick={() => {
              deleteRoleDialogToggle.toggle();
            }}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>

      </Box>
      { deleteRoleDialogToggle.open && (
        <Dialog
          {...deleteRoleDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Box mb={1}>
              <Typography variant='subtitle'>
                Are you sure you want to delete {adminRole.name} role?
              </Typography>
            </Box>

            {adminRole.admins.length > 0 && <Box mb={1}>
              <Typography variant='subtitleSmall'>
                Admins listed below will have permissions related to this role revoked:
              </Typography>
              {adminRole.admins.map((admin, index) => (
                <Box
                  key={index}
                  sx={{
                    p: '.5rem',
                    my: '.5rem',
                    borderRadius: '4px',
                    border: '1px solid',
                    borderColor: 'utility.borderSubtle',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                >
                  <Box>
                    <Title
                      small
                      title={`${admin.firstName} ${admin.lastName}`}
                      subtitle={admin.email}
                    />
                  </Box>
                </Box>
              ))}
            </Box>}

            {!(adminRole.admins.length > 0) && <Box mb={1}>
              <Typography variant='subtitleSmall'>
                No admins will be affected by this change
              </Typography></Box>
            }

            <Box
              sx={{
                mt: 1,
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Box mr={1}>
                <Button
                  onClick={deleteRoleDialogToggle.onClose}
                >
                Cancel
                </Button>
              </Box>
              <LoadingButton
                loading={loading}
                onClick={onUpdate}
              >
                Delete
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};
