import React from 'react';
import { Route } from 'react-router-dom';
import { InvitationsListPage } from './pages/InvitationsListPage';

export const InvitationPages = (
  <React.Fragment>
    <Route path='clients/invitations'>
      <Route
        path='all'
        element={
          <InvitationsListPage />
        }
      />
    </Route>
  </React.Fragment>
);
