import { Route } from 'react-router-dom';
import { UsersListPage } from './Users/UsersListPage';
import React from 'react';

export const UserPages = (
  <Route path='clients/users'>
    <Route
      index
      element={<UsersListPage />}
    />
  </Route>
);
