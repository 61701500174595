import { Box, Button, Dialog, DialogContent } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { ApproveIncomingTransactionQueryQuery, useApproveIncomingTransactionMutation, useApproveIncomingTransactionQueryLazyQuery } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { useToggle } from '@backed-fi/hooks';
import { InfoLabel, Title } from '@backed-fi/compound';
import { ethers } from 'ethers';
import { useSearchParams } from 'react-router-dom';
import { CentsFormatter } from '@backed-fi/shared';

// region Graph

gql`
  query approveIncomingTransactionQuery($transactionId: String!) {
    incomingTransaction(transactionId: $transactionId) {
      id

      network
      amount
      decimals
      tokenSymbol
      value
      valueCurrency

      client {
        name
        jurisdiction
      }
    }
  }

  mutation approveIncomingTransaction($input: ApproveIncomingTransactionInput!) {
    approveIncomingTransaction(input: $input)
  }
`;

// endregion

// region Props

interface Props {
  transactionId: string;
}

// endregion

export const IncomingTransactionApproval: React.FC<Props> = ({ transactionId }) => {
  const dialog = useToggle();
  const isApproving = useToggle();
  const snackbar = useSnackbar();

  const [searchParams] = useSearchParams();


  const [approveMutation] = useApproveIncomingTransactionMutation();
  const [fetchData, { data }] = useApproveIncomingTransactionQueryLazyQuery();

  const { incomingTransaction } = (data ?? {}) as ApproveIncomingTransactionQueryQuery;

  // region Effect

  React.useEffect(() => {
    const searchParamsId = searchParams.get('approveIncomingTransactionId');

    if (searchParamsId && searchParamsId === transactionId) {
      dialog.setTrue();
    }
  }, []);

  React.useEffect(() => {
    if (dialog.open) {
      fetchData({
        variables: {
          transactionId
        }
      });
    }
  }, [dialog.open]);

  // endregion

  // region On Approve

  const onApprove = async () => {
    try {
      isApproving.setTrue();

      const value = await approveMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'incomingTransactions'
        ],
        variables: {
          input: {
            transactionId
          }
        }
      });

      if (value.data?.approveIncomingTransaction) {
        snackbar.enqueueSnackbar('Successfully approved the incoming transaction', {
          variant: 'success'
        });
      } else {
        throw new Error();
      }
    } catch (e: any) {
      snackbar.enqueueSnackbar(e.message ?? 'An error occurred while approving the incoming transaction', {
        variant: 'error'
      });
    } finally {
      dialog.setFalse();
      isApproving.setFalse();
    }
  };

  // endregion

  return (
    <React.Fragment>
      <Button onClick={dialog.setTrue}>
        Approve
      </Button>

      {dialog.open && (
        <Dialog
          {...dialog}
          fullWidth
          maxWidth="sm"
        >
          <DialogContent>
            <Title
              title="Approve Transaction"
              subtitle="Confirm and authorize the incoming transaction"
            />

            {/* region Labels */}

            <InfoLabel
              label="Client"
              content={incomingTransaction?.client.name}
            />

            <InfoLabel
              label="Client Jurisdiction"
              content={incomingTransaction?.client.jurisdiction}
            />

            <InfoLabel
              label="Value"
              tooltip="Transaction value at the time of receiving it"
              content={
                incomingTransaction
                  ? CentsFormatter.format(incomingTransaction.value!, incomingTransaction.valueCurrency!)
                  : undefined
              }
            />

            <InfoLabel
              label="Network"
              content={incomingTransaction?.network}
            />

            <InfoLabel
              label="Token Symbol"
              content={incomingTransaction?.tokenSymbol}
            />

            <InfoLabel
              label="Token Amount"
              content={
                incomingTransaction
                  ? Number(ethers.utils.formatUnits(incomingTransaction.amount, incomingTransaction.decimals)).toFixed(6)
                  : undefined
              }
            />

            <InfoLabel
              label="Raw Amount"
              content={incomingTransaction?.amount}
            />

            <InfoLabel
              label="Decimals"
              content={incomingTransaction?.decimals}
            />

            {/* endregion */}

            <Box
              sx={{
                gap: '.5rem',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <LoadingButton
                color="secondary"
                disabled={isApproving.value}
                onClick={dialog.setFalse}
              >
                Later
              </LoadingButton>

              <LoadingButton
                loading={isApproving.value}
                onClick={onApprove}
              >
                Approve
              </LoadingButton>
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
