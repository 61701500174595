import React, { useMemo } from 'react';
import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography
} from '@mui/material';
import { gql } from '@apollo/client';
import {
  usePendingStatementsQuery,
  useMarkSettlementAsExecutedMutation
} from '@backed-fi/graphql';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { format } from 'date-fns';
// region Props

interface Props {
  custodyAccountId: string;
  settlementId: string;

  onDismiss: (shouldRefresh?: boolean) => void;
}

// endregion

// region Graph Declarations

const Graph = gql`
  query PendingStatements($where: CustodyAccountWhereUniqueInput!){
    custodyAccount(where: $where) {
      events {
        tookPlaceAt
        changeAmount
        changeCurrency
        description
        id
        type
      }
    }
  }

  mutation markSettlementAsExecuted($input: MarkSettlementAsExecutedInput!) {
    markSettlementAsExecuted(input: $input) {
      id
    }
  }
`;

// endregion

// region Form Schema

const Schema = z.object({
  statementId: z.string()
});

type Schema = z.infer<typeof Schema>;

// endregion

export const ManualSettlementCompletionDialog: React.FC<Props> = ({
  custodyAccountId,
  settlementId,
  onDismiss
}) => {
  const snackbar = useSnackbar();

  // region Networking
  const { data } = usePendingStatementsQuery({ variables: {
    where: { id: custodyAccountId }
  } });

  const sortedData = useMemo(() => {
    return data ?
      [...data.custodyAccount.events]
        .sort((a, b) => new Date(a.tookPlaceAt) < new Date(b.tookPlaceAt) ? 1 : -1)
        .map((x) => ({
          ...x,
          tookPlaceAt: format(new Date(x.tookPlaceAt), 'dd/MM/yyyy')
        }))
      : undefined;
  }, [data]);

  const [markAsExecuted] = useMarkSettlementAsExecutedMutation();

  // endregion

  // region Form Control

  const { formState, register, ...form } = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  const { errors } = formState;

  // endregion

  // region State

  const [loading, setLoading] = React.useState<boolean>(false);

  // endregion

  // region Effects

  React.useEffect(() => {
    return () => {
      form.reset();
    };
  }, []);

  // endregion

  // region Actions

  const onSave = async () => {
    setLoading(true);

    try {
      // action
      await markAsExecuted({
        variables: {
          input: {
            settlementId: settlementId,
            custodyAccountEventId: form.getValues().statementId!
          }
        }
      });

      onDismiss(true);
      snackbar.enqueueSnackbar(
        'Successfully marked as completed'
      );
    } finally {
      setLoading(false);
    }
  };

  // endregion

  return (
    <Dialog open fullWidth onClose={() => onDismiss()}>
      <DialogContent>
        {/* region Loader */}

        {!(sortedData) && <Typography>Loading</Typography>}

        {/* endregion */}

        {(sortedData) && (
          <React.Fragment>
            <Box>

              {/* region Execution Details Entry */}

              <Box mb={2}>
                <Typography variant="titleSmall">
                      Mark settlement as Completed
                </Typography>

                <Typography variant="subtitleSmall">
                      Please select account statement, that was responsible for
                      completion of the settlement
                </Typography>
              </Box>

              <TextField
                select
                fullWidth
                label="Custody Account Statements"
                {...register('statementId')}
                SelectProps={{
                  native: true
                }}
              >
                {sortedData.map((x) => {
                  return (
                    <option key={x?.id} value={x?.id}>
                      {x?.type} - {x?.tookPlaceAt} - {Number(x.changeAmount) / 100}/{x.changeCurrency}- {x.description}
                    </option>
                  );
                })}
              </TextField>
              <LoadingButton
                onClick={onSave}
                loading={loading}
                sx={{
                  mt: '8px',
                  float: 'right'
                }}
              >
                    Save
              </LoadingButton>

            </Box>
            {/* endregion */}
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );
};
