import React from 'react';
import * as Base from '@radix-ui/react-context-menu';

import { Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/PrivacyTipRounded';
import DiscussionIcon from '@mui/icons-material/ForumRounded';
import UploadIcon from '@mui/icons-material/CloudUploadRounded';
import DeleteIcon from '@mui/icons-material/DeleteForeverRounded';
import DownloadIcon from '@mui/icons-material/DownloadingRounded';
import CreateIcon from '@mui/icons-material/CreateNewFolderRounded';
import RenameIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';

import { useToggle } from '@backed-fi/hooks';
import { useDeleteFolder } from '@backed-fi/admin/src/app/components/Documents/hooks/useDeleteFolder';
import { useCreateNewFolder } from '@backed-fi/admin/src/app/components/Documents/hooks/useCreateNewFolder';
import { RenameDialog } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/Dialogs/RenameDialog';
import { ContextMenuAction } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/ContextMenuAction';
import { UploadFileAction } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/Actions/UploadFileAction';
import { DocumentInfoDialog } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/Dialogs/DocumentInfoDialog';
import { DocumentDiscussionDialog } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/Dialogs/DocumentDiscussionDialog';
import { useDownloadCurrentDocument } from '@backed-fi/admin/src/app/components/Documents/hooks/useDownloadCurrentDocument';
import { useRequestDocumentDeletion } from '@backed-fi/admin/src/app/components/Documents/hooks/useRequestDocumentDeletion';
import { AdminPermission } from '@backed-fi/graphql';
import { Protector } from '@backed-fi/compound';


// region Props

interface Props extends React.PropsWithChildren {
  documentId?: string;

  folderId?: string;
}

// endregion

export const DocumentContextMenu: React.FC<Props> = ({
  children,
  documentId,
  folderId
}) => {
  const renameToggle = useToggle();
  const discussionToggle = useToggle();
  const documentInfoToggle = useToggle();
  const uploadRef = React.useRef<HTMLInputElement>(null!);

  const deleteFolder = useDeleteFolder();
  const createNewFolder = useCreateNewFolder();

  const deleteDocument = useRequestDocumentDeletion();
  const downloadDocument = useDownloadCurrentDocument();

  return (
    <React.Fragment>
      <UploadFileAction ref={uploadRef} />

      {renameToggle.open && (
        <RenameDialog {...renameToggle} />
      )}

      {documentInfoToggle.open && (
        <DocumentInfoDialog {...documentInfoToggle} />
      )}

      {discussionToggle.open && (
        <DocumentDiscussionDialog {...discussionToggle} />
      )}


      <Base.Root>
        <Base.Trigger
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {children}
        </Base.Trigger>

        <Base.Portal>
          <Base.Content>
            <Box
              sx={({ palette }) => ({
                display: 'flex',
                flexFlow: 'column',
                gap: '.3rem',
                width: '225px',
                padding: '.25rem',
                borderRadius: '4px',
                boxShadow: 3,
                background: palette.utility.backgroundSubtle,
                border: `1px solid ${palette.utility.borderElement}`
              })}
            >
              <ContextMenuAction
                name="Rename"
                Icon={RenameIcon}
                onClick={() => {
                  renameToggle.setTrue();
                }}
              />

              {documentId && (
                <React.Fragment>

                  <Protector permissions={[AdminPermission.ComplianceRead]}>
                    <ContextMenuAction
                      name="Discussion"
                      Icon={DiscussionIcon}
                      onClick={() => {
                        discussionToggle.setTrue();
                      }}
                    />
                  </Protector>

                  <ContextMenuAction
                    name="Document Info"
                    Icon={InfoIcon}
                    onClick={() => {
                      documentInfoToggle.setTrue();
                    }}
                  />

                  <ContextMenuAction
                    name="Download Document"
                    Icon={DownloadIcon}
                    onClick={downloadDocument}
                  />

                  <ContextMenuAction
                    name="Delete Document"
                    Icon={DeleteIcon}
                    onClick={deleteDocument}
                  />
                </React.Fragment>
              )}

              {folderId && (
                <React.Fragment>
                  <ContextMenuAction
                    name="Upload File"
                    Icon={UploadIcon}
                    onClick={() => {
                      uploadRef?.current?.click();
                    }}
                  />

                  <ContextMenuAction
                    name="New Folder"
                    Icon={CreateIcon}
                    onClick={async () => {
                      await createNewFolder();
                    }}
                  />

                  <ContextMenuAction
                    name="Delete"
                    Icon={DeleteIcon}
                    variant="danger"
                    onClick={deleteFolder}
                  />
                </React.Fragment>
              )}
            </Box>
          </Base.Content>
        </Base.Portal>
      </Base.Root>
    </React.Fragment>
  );
};
