import React, { useEffect } from 'react';

import { z } from 'zod';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Box,
  Breadcrumbs,
  Link,
  TextField,
  Typography,
  useTheme
} from '@mui/material';

import { Protector } from '@backed-fi/compound';
import {
  TradeSide,
  TradingAssetType,
  useTradingAssetsQuery,
  useExecuteTradeMutation,
  useTradingBrokersQuery
} from '@backed-fi/graphql';
import { useSnackbar } from 'notistack';

const TradeSchema = z.object({
  baseAssetId: z.string().min(1),

  quoteAssetId: z.string().min(1),
  
  brokerId: z.string(),

  amount: z.preprocess(
    (value) => Number(value),
    z.number().int().nonnegative()
  ),

  side: z.enum([TradeSide.Buy, TradeSide.Sell])
});

const GraphQL = gql`
  # // ---- Queries ---- // #
  query TradingAssets {
    tradingAssets {
      id
      name
      type
    }
  }

  query TradingBrokers {
    brokers {
      nodes {
        id
        name
        tradingEngineSupport
      }
    }
  }

  # // ---- Mutations ---- // #
  mutation ExecuteTrade($input: ExecuteTradeInput!) {
    executeTrade(input: $input) {
      id
    }
  }
`;

export const ExecuteTradePage: React.FC = () => {
  const { palette } = useTheme();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  // ---- State ---- //
  const [creating, setCreating] = React.useState<boolean>(false);

  // ---- Form state ---- //
  const tradeForm = useForm<z.infer<typeof TradeSchema>>({
    resolver: zodResolver(TradeSchema)
  });
  const { baseAssetId } = tradeForm.watch();
  // ---- Networking ---- //
  const [executeTrade] = useExecuteTradeMutation();
  const { data: tradingAssets, ...collateralsQuery } =
  useTradingAssetsQuery();

  const { data: tradingBrokers } = useTradingBrokersQuery();
  // ---- Actions ---- //

  const onExecuteTrade = async () => {
    setCreating(true);

    try {
      // Validate the form
      await tradeForm.trigger();

      const input = TradeSchema.parse(tradeForm.getValues());
      
      // Execute trade
      const trade = await executeTrade({
        variables: {
          input
        },
        refetchQueries: [
          'TradesListPage'
        ]
      });

      snackbar.enqueueSnackbar('Trade successfully created');

      // Go back to the token list
      navigate('/trading-engine/trades/list', {
        state: {
          refresh: true
        }
      });
    } finally {
      setCreating(false);
    }
  };

  return (
    <Protector>
      <Box>
        <Box
          sx={{
            mb: '2rem'
          }}
        >
          <Typography variant="title">Execute Trade</Typography>

          <Breadcrumbs>
            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/"
            >
              Dashboard
            </Link>

            <Link
              component={RouterLink}
              underline="hover"
              color="inherit"
              to="/trading-engine/trades/list"
            >
              Trading
            </Link>

            <Typography color="primary.textLowContrast">Execute</Typography>
          </Breadcrumbs>
        </Box>

        <Box>
          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <Box
              sx={{
                minWidth: '20vw',
                maxWidth: '20vw'
              }}
            >
              <Typography variant="titleSmall">Trade</Typography>

              <Typography variant="subtitleSmall">
                Please specify parameters of the trade
              </Typography>
            </Box>

            <Box
              sx={{
                minWidth: '30vw',
                display: 'flex',
                flexFlow: 'column'
              }}
            >
              <TextField
                select
                SelectProps={{
                  native: true
                }}
                label="Broker"
                helperText="Where do You want to trade"
                {...tradeForm.register('brokerId')}
              >
                <option
                  style={{
                    display: 'none'
                  }}
                />
                {tradingBrokers &&
                tradingBrokers.brokers.nodes.filter((x) => x.tradingEngineSupport).map((broker) => (
                  <option key={broker.id} value={broker.id}>
                    {broker.name}
                  </option>
                ))}
              </TextField>
              <TextField
                select
                label="Base Asset"
                {...tradeForm.register('baseAssetId')}
                SelectProps={{
                  native: true
                }}
              >
                <option
                  style={{
                    display: 'none'
                  }}
                />

                {tradingAssets &&
                  tradingAssets.tradingAssets.filter((x) => x.type !== TradingAssetType.Fiat).map((asset) => (
                    <option key={asset.id} value={asset.id}>
                      {asset.name}
                    </option>
                  ))}
              </TextField>
              {tradeForm.getValues('baseAssetId') && (
                <TextField
                  select
                  SelectProps={{
                    native: true
                  }}
                  label="Side"
                  helperText="What do You want to do with selected asset"
                  {...tradeForm.register('side')}
                >
                  <option
                    style={{
                      display: 'none'
                    }}
                  />

                  <option value={TradeSide.Buy}>Buy</option>
                  <option value={TradeSide.Sell}>Sell</option>
                </TextField>
              )}
              {tradeForm.getValues('side') && (
                <TextField
                  select
                  SelectProps={{
                    native: true
                  }}
                  label="Quote Asset"
                  helperText="What do You want to do with selected asset"
                  {...tradeForm.register('quoteAssetId')}
                >
                  {tradingAssets &&
                    tradingAssets.tradingAssets.filter((x) => x.type === TradingAssetType.Fiat).map((asset) => (
                      <option key={asset.id} value={asset.id}>
                        {asset.name}
                      </option>
                    ))}
                </TextField>
              )}
              <TextField
                fullWidth
                label={'Amount'}
                {...tradeForm.register('amount')}
              />
            </Box>
          </Box>
          <Box
            sx={{
              py: '1rem',
              display: 'flex',
              justifyContent: 'flex-end',
              borderTop: `1px solid ${palette.utility.borderElement}`
            }}
          >
            <LoadingButton loading={creating} onClick={onExecuteTrade}>
              Execute
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Protector>
  );
};
