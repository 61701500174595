import React from 'react';

import { gql } from "@apollo/client";
import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { usePaginationModel } from '@backed-fi/hooks';
import { HashTile, PageHeading } from '@backed-fi/compound';
import { BridgeTransfersQuery, useBridgeTransfersQuery } from '@backed-fi/graphql';
import { CryptoFormatter, DateTimeFormatter } from '@backed-fi/shared';
import { AssetIcon } from '@backed-fi/shared/components/compound/AssetIcon';
import { BlockchainNetworkLabel } from '@backed-fi/shared/components/compound/BlockchainNetworkLabel';
import { BridgeTransferStatusBadge } from '../shared';

gql`
  query bridgeTransfers(
    $page: Int,
    $pageSize: Int
  ) {
    bridgeTransfers(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        createdAt

        sourceNetwork
        destinationNetwork
        destinationNetwork
        amount
        hash
        status
        explorerUrl
        ccipMessageId

        sourceTokenDeployment {
          token {
            symbol
            name
          }
        }
      }

      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }

    }
  }
`;

export const BridgeTransfersListPage: React.FC = () => {
  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  const { data, loading } = useBridgeTransfersQuery();

  const { bridgeTransfers } = (data || {}) as BridgeTransfersQuery;

  return <>
    <PageHeading
      title="Bridge Transfers"
      breadcrumbs={[
        {
          text: 'Bridge Transfers'
        }
      ]}
    >
    </PageHeading>

    <DataGrid
      {...pagination}
      autoHeight
      loading={loading}
      rows={bridgeTransfers?.nodes ?? []}
      rowCount={bridgeTransfers?.page.totalNodes}
      rowHeight={70}
      columns={[
        {
          width: 300,
          field: 'createdAt',
          headerName: 'Timestamp',
          renderCell: ({
            value,
            row
          }) => {
            return (
              <Typography
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                    textDecoration: 'underline'
                  }
                }}
              >
                {DateTimeFormatter.format(new Date(value))}
              </Typography>
            );
          }
        }, {
          flex: 1,
          minWidth: 150,
          field: 'sourceNetwork',
          headerName: 'Source chain',
          renderCell: ({ value }) => {
            return (<BlockchainNetworkLabel
              label={`${value} Network`}
              network={value}
              size={24}
            />)
          },
        }, {
          flex: 1,
          minWidth: 150,
          field: 'destinationNetwork',
          headerName: 'Destination chain',
          renderCell: ({ value }) => {
            return (<BlockchainNetworkLabel
              label={`${value} Network`}
              network={value}
              size={24}
            />)
          },
        },
        {
          flex: 1,
          minWidth: 150,
          field: 'status',
          headerName: 'Status',
          renderCell: ({ row }) => {
            return (
              <BridgeTransferStatusBadge status={row.status} />
            )
          },
        },
        {
          flex: 2,
          minWidth: 150,
          field: 'sourceTokenDeployment',
          headerName: 'Asset',
          renderCell: ({ row }) => {
            return (
              <>
                <AssetIcon
                  symbol={row.sourceTokenDeployment.token.symbol} />
                <Typography
                  sx={{
                    paddingLeft: '16px'
                  }}>
                  {
                    CryptoFormatter.format(
                      row.amount,
                      18,
                      row.sourceTokenDeployment.token.symbol,
                      false
                    )
                  }
                </Typography>
              </>
            )
          },
        },
        {
          flex: 1,
          minWidth: 150,
          field: 'hash',
          headerName: 'TX Hash',
          renderCell: ({ row }) => {
            return (
              <HashTile
                hash={row.hash}
                explorerUrl={row.explorerUrl}
              />
            )
          },
        },
        {
          flex: 1,
          minWidth: 150,
          field: 'ccipMessageId',
          headerName: 'CCIP Message',
          renderCell: ({ row }) => {
            return (
              <HashTile
                color="info"
                hash={row.ccipMessageId}
                explorerUrl={'https://ccip.chain.link/msg/' + row.ccipMessageId}
              />
            )
          },
        }
      ]}
    />
  </>;
}