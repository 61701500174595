import React from 'react';
import { gql } from '@apollo/client';
import { Chip } from '@mui/material';
import ClientsIcon from '@mui/icons-material/PeopleOutlineRounded';

import { ClientStatus, CommandMenuClientsSearchQuery, CommandMenuClientsSearchQueryVariables, CommandMenuQuery, FilteringMode } from '@backed-fi/graphql';
import { MenuCommand } from '@backed-fi/admin/src/app/components/CommandMenu/types';

export const ClientSearchMenuCommand: MenuCommand<
  CommandMenuClientsSearchQueryVariables,
  CommandMenuClientsSearchQuery
> = {
  Icon: ClientsIcon,
  name: 'Clients',
  description: 'Search for specific client',
  keywords: [
    'client',
    'clients',
    'user',
    'users'
  ],
  query: gql`
    query commandMenuClientsSearch($filter: ClientsFilter) {
      clients(where: $filter) {
        nodes {
          id
          humanId
          name
          type
          status
          isProfessional
          contactEmail
        }
      }
    }
  `,
  variables: (filter) => ({
    filter: {
      OR: [
        {
          id: {
            contains: filter
          }
        }, {
          humanId: {
            contains: filter
          }
        }, {
          name: {
            contains: filter,
            mode: FilteringMode.Insensitive
          }
        }, {
          contactEmail: {
            contains: filter,
            mode: FilteringMode.Insensitive
          }
        }
      ]
    }
  }),
  formatData: (data: CommandMenuQuery) => {
    return data.clients.nodes.map((node) => ({
      id: node.id,
      Icon: ClientsIcon,
      title: node.name,
      subtitle: node.contactEmail,
      endAdornment: (
        // Count how many `as any` you see
        <Chip
          label={(node as any).status}
          color={({
            [ClientStatus.Active]: 'primary',
            [ClientStatus.Rejected]: 'error',
            [ClientStatus.Closed]: 'error'
          } as any)[(node as any).status] as any}
        />
      ),
      onClick: ({ navigate }) => {
        navigate(`/clients/${node.id}/details/overview`);
      }
    }));
  },
  supportedInput: [
    {
      input: 'Client Id',
      description: 'The system generated unique UUID, assigned to each client'
    }, {
      input: 'Human ID',
      description: 'System generated, short 6 character ID, assigned to each client'
    }, {
      input: 'Client Name',
      description: 'The name of the client. (note: before client completes successfully the KYC it is their email)'
    }, {
      input: 'Client Contact Email',
      description: 'The email, that the client has provided for communication'
    }
  ]
};
