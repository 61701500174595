import React from 'react';

import { Box, Button, Chip, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { ClientFeature, ClientStatus, ClientType, RiskLevel, useAllSupplyControlOperationsQuery } from '@backed-fi/graphql';
import { usePaginationModel, useToggle } from '@backed-fi/hooks';
import { PageHeading } from '@backed-fi/compound';
import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import CrossIcon from '@mui/icons-material/HighlightOffRounded';
import { countries } from 'countries-list';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { RequestSupplyControlOperationDialog } from '@backed-fi/admin/src/app/domain/Blockchain/supply/components/RequestSupplyControlOperationDialog';

// region Graph

gql`
  query allSupplyControlOperations(
    $page: Int,
    $perPage: Int
  ) {
    supplyControlOperations(
      page: $page,
      pageSize: $perPage
    ) {
      nodes {
        id
        
        type
        status
        
        amount
        
        deployment {
          network
          
          token {
            name
            symbol
          }
        }
      }
      
      page {
        totalNodes
      }
    }
  }
`;

// endregion

export const SupplyControlOperationsListPage: React.FC = () => {
  const navigate = useNavigate();
  const requestToggle = useToggle();

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel();

  // region Networking

  const { data, loading } = useAllSupplyControlOperationsQuery({
    variables: paginationVariables
  });

  // endregion

  return (
    <Box>
      <RequestSupplyControlOperationDialog {...requestToggle} />

      <PageHeading
        title="Supply Control"
        breadcrumbs={[{
          text: 'Supply Control Operations'
        }]}
      >
        <Button onClick={requestToggle.setTrue}>
          Request
        </Button>
      </PageHeading>

      <DataGrid
        {...pagination}
        autoHeight
        loading={loading}
        rows={data?.supplyControlOperations?.nodes ?? []}
        rowCount={data?.supplyControlOperations?.page.totalNodes}
        onRowClick={({ row }) => {
          navigate(`/blockchain/supply/operation/details/${row.id}`);
        }}
        columns={[
          {
            field: 'type',
            headerName: 'Operation Type'
          }, {
            width: 200,
            field: 'status',
            headerName: 'Operation Status',
            renderCell: ({ value }) => (
              <Chip
                label={value}
              />
            )
          }
        ]}
      />
    </Box>
  )
}
