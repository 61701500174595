import { FormGroup } from '@backed-fi/compound';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button, TextField, Typography } from '@mui/material';
import { ethers } from 'ethers';
import React from 'react';

const Schema = z.object({
  safeAddress: z.string()
    .nonempty(),

  chainId: z.string()
    .nonempty(),

  to: z.string()
    .nonempty(),

  value: z.string()
    .nonempty()
    .transform((v) => ethers.utils.parseEther(v) as unknown as string),

  data: z.string()
    .optional(),

  operation: z.string()
    .nonempty(),

  safeTxGas: z.string()
    .nonempty(),

  baseGas: z.string()
    .nonempty(),

  gasPrice: z.string()
    .nonempty(),

  gasToken: z.string()
    .nonempty(),

  refundReceiver: z.string()
    .optional(),

  nonce: z.string()
    .nonempty()
});

type Schema = z.infer<typeof Schema>;

export const EIP712_SAFE_TX_TYPE = {
  // "SafeTx(address to,uint256 value,bytes data,uint8 operation,uint256 safeTxGas,uint256 baseGas,uint256 gasPrice,address gasToken,address refundReceiver,uint256 nonce)"
  SafeTx: [
    {
      type: 'address',
      name: 'to'
    }, {
      type: 'uint256',
      name: 'value'
    },
    {
      type: 'bytes',
      name: 'data'
    },
    {
      type: 'uint8',
      name: 'operation'
    },
    {
      type: 'uint256',
      name: 'safeTxGas'
    },
    {
      type: 'uint256',
      name: 'baseGas'
    },
    {
      type: 'uint256',
      name: 'gasPrice'
    },
    {
      type: 'address',
      name: 'gasToken'
    },
    {
      type: 'address',
      name: 'refundReceiver'
    },
    {
      type: 'uint256',
      name: 'nonce'
    }
  ]
};

export const SafeTxVerifier = () => {
  // ----- State ----- //
  const [safeTxHash, setSafeTxHash] = React.useState<string>();

  // ----- Form Control ----- //
  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  // ----- Actions ----- //
  const onCalculateHash = (data: Schema) => {
    const { safeAddress, chainId, ...tx } = data;

    setSafeTxHash(
      ethers.utils._TypedDataEncoder.hash({
        verifyingContract: safeAddress,
        chainId
      }, EIP712_SAFE_TX_TYPE, tx)
    );
  };

  // ----- Destructing ----- //
  const { errors } = form.formState;

  return (
    <FormGroup
      noSeparator
      title='SafeTX Hash Calculator'
    >
      <TextField
        fullWidth
        label='Safe Address'
        error={!!errors.safeAddress}
        helperText={errors.safeAddress?.message}
        {...form.register('safeAddress')}
      />

      <TextField
        fullWidth
        label='Chain ID'
        error={!!errors.chainId}
        helperText={errors.chainId?.message}
        {...form.register('chainId')}
      />

      <TextField
        fullWidth
        label='To'
        error={!!errors.to}
        helperText={errors.to?.message}
        {...form.register('to')}
      />

      <TextField
        fullWidth
        label='Value'
        error={!!errors.value}
        helperText={errors.value?.message}
        {...form.register('value')}
      />

      <TextField
        multiline
        fullWidth
        rows={4}
        label='Data'
        error={!!errors.data}
        helperText={errors.data?.message}
        {...form.register('data')}
      />

      <TextField
        fullWidth
        label='Operation'
        error={!!errors.operation}
        helperText={errors.operation?.message}
        {...form.register('operation')}
      />

      <TextField
        fullWidth
        label='Safe TX Gas'
        error={!!errors.safeTxGas}
        helperText={errors.safeTxGas?.message}
        {...form.register('safeTxGas')}
      />

      <TextField
        fullWidth
        label='Base Gas'
        error={!!errors.baseGas}
        helperText={errors.baseGas?.message}
        {...form.register('baseGas')}
      />


      <TextField
        fullWidth
        label='Gas Price'
        error={!!errors.gasPrice}
        helperText={errors.gasPrice?.message}
        {...form.register('gasPrice')}
      />

      <TextField
        fullWidth
        label='Gas Token'
        error={!!errors.gasToken}
        helperText={errors.gasToken?.message}
        {...form.register('gasToken')}
      />

      <TextField
        fullWidth
        label='Refund Receiver'
        error={!!errors.refundReceiver}
        helperText={errors.refundReceiver?.message}
        {...form.register('refundReceiver')}
      />

      <TextField
        fullWidth
        label='Nonce'
        error={!!errors.nonce}
        helperText={errors.nonce?.message}
        {...form.register('nonce')}
      />

      <Button onClick={form.handleSubmit(onCalculateHash)}>
        Calculate Safe TX Hash
      </Button>

      <Typography>
        {safeTxHash && `Your hash is ${safeTxHash}`}
      </Typography>
    </FormGroup>
  );
};
