import { gql } from '@apollo/client';
import React from 'react';
import { Box } from '@mui/material';
import { useUploadDocuments } from '@backed-fi/admin/src/app/components/Documents/hooks/useUploadDocuments';

// region GraphQL

gql`
  mutation uploadDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      id
    }
  }
`;

// endregion

// region Props

interface Props {
}

// endregion

const excludedFiles = [
  '.DS_Store'
];

export const UploadFileAction = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const uploadDocuments = useUploadDocuments();

  // region Actions

  const onFilesSelected = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    if (files?.length) {
      const filesArr: File[] = [];

      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)!;

        if (!excludedFiles.includes(file.name)) {
          filesArr.push(file);
        }
      }

      await uploadDocuments(filesArr);
    }
  };

  // endregion

  const AnyBox = (Box as any);

  return (
    <AnyBox
      ref={ref}
      component="input"
      type="file"
      onChange={onFilesSelected}
      sx={{
        display: 'none'
      }}

      webkitdirectory=""
      directory=""
      multiple=""
    />
  );
});
