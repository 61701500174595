import React from 'react';

import { Box } from '@mui/material';
import { useDrag, useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';

import FolderIcon from '@mui/icons-material/FolderRounded';
import DropHereIcon from '@mui/icons-material/SourceRounded';
import EmptyIcon from '@mui/icons-material/FolderZipRounded';
import ChevronDown from '@mui/icons-material/ExpandMoreRounded';
import ChevronRight from '@mui/icons-material/ChevronRightRounded';


import { Title } from '@backed-fi/compound';
import { useToggle } from '@backed-fi/hooks';
import { DocumentRow } from '@backed-fi/admin/src/app/components/Documents/DocumentRow';
import { DocumentFolder } from '@backed-fi/admin/src/app/components/Documents/DocumentFolder';
import { useMoveItem } from '@backed-fi/admin/src/app/components/Documents/hooks/useMoveItem';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';
import { useUploadDocuments } from '@backed-fi/admin/src/app/components/Documents/hooks/useUploadDocuments';
import { DocumentContextMenu } from '@backed-fi/admin/src/app/components/Documents/ContextMenu/DocumentContextMenu';


interface DNDItem {
  folderId?: string;
  documentId?: string;
}

export const DocumentFolderInternal: React.FC<{ hideParent?: boolean }> = ({ hideParent }) => {
  const {
    folder,
    ...context
  } = useDocumentContext();

  const {
    moveFolder,
    moveDocument
  } = useMoveItem();

  const expanded = useToggle(hideParent);
  const uploadDocuments = useUploadDocuments();

  // region Drag & Drop

  const [{ isDragging }, drag] = useDrag<DNDItem, any, { isDragging: boolean }>(() => ({
    type: 'Folder',
    item: {
      folderId: folder?.id!
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));

  const [{
    isOver,
    canDrop
  }, drop] = useDrop<DNDItem, any, { isOver: boolean, canDrop: boolean }>({
    accept: [
      'Folder',
      'Document'
    ],
    canDrop: (item) => {
      return item.folderId !== folder?.id;
    },
    drop: async (item) => {
      // File dropped
      if (item.documentId) {
        await moveDocument(item.documentId, folder?.id!);
      }

      // Folder dropped
      if (item.folderId) {
        await moveFolder(item.folderId, folder?.id!);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  });

  const [{ isOverWithFile }, dropFiles] = useDrop<any, any, { isOverWithFile: boolean, canDropFile: boolean }>({
    accept: [
      NativeTypes.FILE
    ],
    canDrop: () => true,
    drop: async (item: { files: File[] }) => {
      await uploadDocuments(item.files);
    },
    collect: (monitor) => ({
      isOverWithFile: monitor.isOver(),
      canDropFile: monitor.canDrop()
    })
  });

  // endregion

  // region Update Refetch

  React.useEffect(() => {
    const interval = setInterval(async () => {
      if (expanded) {
        await folder?.fetch();
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  });

  // endregion

  // region Fetch on Open

  React.useEffect(() => {
    if (expanded.open) {
      new Promise(async () => {
        await context.fetchFolder();
      });
    }
  }, [expanded.open]);

  // endregion

  // region Render Helpers

  const RenderFiles = (
    <Box
      sx={{
        ml: '1.5rem',
        gap: '.5rem',
        display: 'flex',
        flexFlow: 'column'
      }}
    >
      {(folder?.fetched) && folder.files!.map((file) => (
        <DocumentRow
          key={file.id}
          name={file.name}
          documentId={file.id}
        />
      ))}
    </Box>
  );

  // endregion

  return (
    <Box
      sx={{
        userSelect: 'none',
        borderRadius: '4px',

        ...((hideParent) && ({
          padding: '.5rem 0'
        })),

        ...((isOverWithFile) && ({
          backgroundColor: 'utility.elementBackgroundActive'
        }))
      }}
    >
      {!hideParent && (
        <DocumentContextMenu
          folderId={context.folderId}
        >
          <Box
            ref={dropFiles}
          >
            <Box ref={drop}>
              <Box
                ref={drag}
                onClick={async () => {
                  expanded.toggle();

                  if (!folder?.fetched) {
                    await context.fetchFolder();
                  }
                }}
                sx={{
                  my: '.1rem',
                  display: 'flex',
                  alignItems: 'center',
                  padding: '.5rem 1rem',
                  borderRadius: '4px',
                  cursor: 'pointer',

                  '&:hover': {
                    backgroundColor: 'utility.elementBackgroundHovered'
                  },

                  border: '1px solid transparent',

                  ...(isOver && ({
                    backgroundColor: 'utility.elementBackgroundActive',

                    ...((isOver && !canDrop) && ({
                      cursor: 'no-drop'
                    }))
                  })),

                  ...(isDragging && ({
                    opacity: '.3',
                    border: '1px dashed'
                  }))
                }}
              >
                {expanded.open ? (
                  <ChevronDown />
                ) : (
                  <ChevronRight />
                )}

                <FolderIcon />

                <Box
                  sx={{
                    ml: '1rem'
                  }}
                >
                  <Title
                    small
                    title={folder?.name ?? 'Loading...'}
                    subtitle={folder?.description!}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </DocumentContextMenu>
      )}

      {expanded.open && (
        <Box
          sx={{
            ...(!hideParent && ({
              my: '.5rem',
              paddingLeft: '2rem'
            }))
          }}
        >
          {!folder?.fetched && (
            <Box>
              Loading
            </Box>
          )}

          {((folder && folder.fetched) && !(folder.subFolders!.length + folder.files!.length)) && (
            <DocumentContextMenu
              folderId={context.folderId}
            >
              <Box
                sx={{
                  ml: '3rem',
                  gap: '1rem',
                  display: 'flex',
                  alignItems: 'center',

                  ...(hideParent && ({
                    padding: '3rem 0'
                  }))
                }}
              >
                {(hideParent) && (
                  <EmptyIcon />
                )}

                <Title
                  boxed
                  small
                  title="Empty Folder"
                  subtitle="Sorry! There seems to be nothing in this folder"
                />
              </Box>
            </DocumentContextMenu>
          )}

          {(folder && folder.fetched) && folder.subFolders!.map((subFolder) => (
            <DocumentFolder
              key={subFolder.id}
              name={subFolder.name}
              folderId={subFolder.id}
              description={subFolder.description}
            />
          ))}

          {!hideParent && (RenderFiles)}
        </Box>
      )}

      {hideParent && (
        <DocumentContextMenu
          folderId={context.folderId}
        >
          <Box ref={dropFiles}>
            <Box
              ref={drop}
              sx={{
                minHeight: '250px',
                borderRadius: '4px',

                display: 'flex',
                flexFlow: 'column',
                ...(isOver && ({
                  backgroundColor: 'utility.elementBackgroundActive'
                }))
              }}
            >
              {RenderFiles}

              {(isOver || isOverWithFile) && (
                <Box
                  sx={{
                    mt: '32px',
                    display: 'flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <DropHereIcon
                    sx={{
                      fontSize: '48px',
                      color: 'utility.textLowContrast'
                    }}
                  />

                  <Title
                    title={`Release to ${isOverWithFile ? 'upload the file' : 'move'} to the root folder`}
                  />
                </Box>
              )}

            </Box>
          </Box>
        </DocumentContextMenu>
      )}
    </Box>
  );
};
