import React from 'react';
import { Route } from 'react-router-dom';

import { RolesListPage } from './pages/RolesListPage';
import { RoleDetailsPage } from '@backed-fi/admin/src/app/domain/Internal/Roles/pages/RoleDetailsPage';

export const RolesPages = (
  <Route path='roles'>
    <Route
      index
      element={
        <RolesListPage />
      }
    />

    <Route
      path='details/:id'
      element={
        <RoleDetailsPage />
      }
    />
  </Route>
);
