import React from 'react';
import { CommandMenuContextProvider } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';
import { CommandMenuRoot } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuRoot';

export const CommandMenu: React.FC = () => {
  return (
    <CommandMenuContextProvider>
      <CommandMenuRoot />
    </CommandMenuContextProvider>
  );
};
