import React from 'react';

import { z } from 'zod';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { Dialog, DialogContent, TextField, Typography } from '@mui/material';

import { useAddClientUserMutation, useBasicClientQuery } from '@backed-fi/graphql';
import { gql } from '@apollo/client';

// region Schema

const Schema = z.object({
  firstName: z.string(),
  lastName: z.string(),

  email: z.string()
    .email()
});

// endregion

// region Networking

const Graph = gql`
  mutation addClientUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
    }
  }
`;

// endregion

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  /**
   * List of all the queries that should be refetch when a user is created
   */
  onCreatedRefetch?: string[];
}

// endregion

export const AddClientUserDialog: React.FC<Props> = (props) => {
  const params = useParams<{ id: string }>();

  // region Networking

  const { data } = useBasicClientQuery({
    variables: {
      id: params.id!
    }
  });

  const [addClientUserMutation] = useAddClientUserMutation({
    awaitRefetchQueries: true,
    refetchQueries: props.onCreatedRefetch || []
  });

  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const { errors, isSubmitting } = form.formState;

  // endregion

  // region Actions

  const onCreateUser = form.handleSubmit(async (data) => {
    await addClientUserMutation({
      variables: {
        input: {
          ...data,
          clientId: params.id
        }
      }
    });

    form.reset();

    if (typeof props.onClose === 'function') {
      (props as any).onClose();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
          Add User
        </Typography>

        <Typography variant='subtitleSmall'>
          Add user to {data?.client?.name}
        </Typography>


        <TextField
          fullWidth
          label='First Name'
          error={!!errors.firstName?.message}
          helperText={errors.firstName?.message}
          {...form.register('firstName')}
        />


        <TextField
          fullWidth
          label='Last Name'
          error={!!errors.lastName?.message}
          helperText={errors.lastName?.message}
          {...form.register('lastName')}
        />

        <TextField
          fullWidth
          label='Email'
          error={!!errors.email?.message}
          helperText={errors.email?.message}
          {...form.register('email')}
        />

        <LoadingButton
          loading={isSubmitting}
          onClick={onCreateUser}
          sx={{
            float: 'right'
          }}
        >
          Add User
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};
