import { Box, Button } from '@mui/material';
import React from 'react';
import { gql } from '@apollo/client';
import { useBlockchainAddressesQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { PageHeading, Title } from '@backed-fi/compound';
import { AddBlockchainAddressDialog } from './components/AddBlockchainAddressDialog';
import { useToggle } from '@backed-fi/hooks';

// region Graph

gql`
  query blockchainAddresses {
    blockchainAddresses {
      id

      address
      networks

      type
      description
    }
  }
`;

// endregion

export const BlockchainAddressesListPage: React.FC = () => {
  const addToggle = useToggle();

  const {
    data,
    loading
  } = useBlockchainAddressesQuery();

  return (
    <Box>
      <AddBlockchainAddressDialog {...addToggle} />

      <PageHeading
        title="Known Addresses"
        breadcrumbs={[
          {
            text: 'Blockchain'
          }, {
            text: 'Known Addresses'
          }
        ]}
      >
        <Button onClick={addToggle.setTrue}>
          Add Address
        </Button>
      </PageHeading>

      <DataGrid
        loading={loading}
        autoHeight
        rows={data?.blockchainAddresses ?? []}
        columns={[{
          minWidth: 400,
          field: 'address',
          headerName: 'Address'
        }, {
          minWidth: 200,
          headerName: 'Type',
          field: 'type'
        }, {
          flex: 1,
          headerName: 'Description',
          field: 'description'
        }]}

        slots={{
          noRowsOverlay: () => (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Title
                title="No Addresses"
                subtitle="There are no registered blockchian addresses"
              />
            </Box>
          )
        }}
      />

    </Box>
  );
};
