import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Chip, Typography } from '@mui/material';
import { ClientInfo } from '@backed-fi/admin/src/app/domain/Clients/components/ClientInfo';
import { InteractionTypeBadge } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionTypeBadge';
import { InteractionStatusBadge } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionStatusBadge';
import { CentsFormatter } from '@backed-fi/shared';
import { InteractionAssetsList } from '@backed-fi/app/src/app/domain/Transactions/components/InteractionAssetsList';
import { useNavigate } from 'react-router-dom';
import { usePaginationModel } from '@backed-fi/hooks';
import { useInteractionsListQuery } from '@backed-fi/graphql';
import { gql } from '@apollo/client';
import { InteractionPayoutMethodChip } from '@backed-fi/admin/src/app/domain/Interactions/components/InteractionPayoutMethodChip';
import { ExportButton } from '../../../components/ExportButton';

// region Graph

gql`
  fragment InteractionBankTransferDetails on BankTransfer {
    id
    amount
    currency
  }

  query interactionsList(
    $page: Int,
    $pageSize: Int,
    $where: InteractionWhereInput
  ) {
    interactions(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id

        createdAt

        type
        payout

        status
        currency

        flags

        actualTotalValue
        expectedTotalValue

        client {
          id

          name
          contactEmail
        }

        incomingTransactions {
          id

          hash
          network
          amount
          decimals
          explorerUrl
          type
          tokenSymbol
        }

        outgoingTransactions {
          id
          hash
          network
          explorerUrl
          createdAt
          tokenSymbol
          type

          amount
          decimals
        }

        incomingTransfers {
          ...InteractionBankTransferDetails
        }

        outgoingTransfers {
          ...InteractionBankTransferDetails
        }
      }

      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  /**
   * Show only interactions, that include at least one of
   * this token's deployments
   */
  tokenId?: string;

  clientId?: string;
}

// endregion


export const InteractionsTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();

  // region Pagination

  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(props.disablePagination);

  // endregion

  // region Networking

  const query = useInteractionsListQuery({
    notifyOnNetworkStatusChange: true,

    // If we are on the first page - poll, otherwise - don't
    pollInterval: paginationVariables.page
      ? undefined
      : 5000,

    variables: {
      ...paginationVariables,
      where: {
        tokenId: props.tokenId,
        clientId: props.clientId
      }
    }
  });

  const {
    data,
    previousData,
    loading
  } = query;

  // endregion

  const finalData = data || previousData;

  return (
    <Box
      sx={{
        height: '100%'
      }}>
      <Box
        mb={1}
        gap={1}
        display="flex"
        sx={{
          justifyContent: 'end'
        }}>
        {/* region Export */}
        <ExportButton
          csv
          json
          exportName="interactions"
          data={finalData?.interactions.nodes ?? []}
        >
          Export Current Page
        </ExportButton>
        {/* endregion */}
      </Box>
      <Box>
        <DataGrid
          {...pagination}
          autoHeight
          getRowHeight={({ model }) => {
            return Math.max(
              model.incomingTransactions.length * 50 + 12,
              model.outgoingTransactions.length * 50 + 12,
              70
            );
          }}
          loading={loading}
          rows={finalData?.interactions?.nodes ?? []}
          rowCount={finalData?.interactions?.page.totalNodes}
          onRowClick={({ row }) => {
            navigate(`/interactions/details/${row.id}/overview`);
          }}
          columns={[{
            width: 400,
            field: 'client',
            headerName: 'Client',

            renderCell: ({
              value,
              row
            }) => (
              <Box sx={{
                flex: 1,
                gap: '1rem',
                display: 'flex',
                alignItems: 'center'
              }}>
                <ClientInfo name={value.name} contactEmail={value.contactEmail} />
                {!!row.flags.length && (
                  <Chip
                    color="warning"
                    label="Flagged"
                  />
                )}
              </Box>
            )
          }, {
            width: 150,
            field: 'type',
            headerName: 'Interaction Type',

            renderCell: ({ value }) => (
              <InteractionTypeBadge type={value} />
            )
          }, {
            width: 250,
            field: 'status',
            headerName: 'Status',

            renderCell: ({ value }) => (
              <InteractionStatusBadge status={value} />
            )
          }, {
            width: 150,
            field: 'expectedTotalValue',
            headerName: 'Expected Value',

            renderCell: ({ row }) => (
              <Typography>
                {CentsFormatter.format(row.expectedTotalValue, row.currency)}
              </Typography>
            )
          }, {
            width: 150,
            field: 'payout',
            headerName: 'Payout Method',

            renderCell: ({ value }) => (
              <InteractionPayoutMethodChip method={value} />
            )
          }, {
            width: 300,
            field: 'incomingTransactions',
            headerName: 'Incoming Assets',

            renderCell: ({ row }) => (
              <Box
                sx={{
                  mt: '20px',
                  alignSelf: 'flex-start'
                }}
              >
                <InteractionAssetsList
                  small
                  assets={[
                    ...row.incomingTransfers.map((x) => ({
                      ...x,
                      type: 'Fiat' as const
                    })),

                    ...row.incomingTransactions.map((x) => ({
                      ...x,
                      assetType: x.type,
                      type: 'Blockchain' as const
                    }))
                  ] ?? []}
                />
              </Box>
            )
          }, {
            width: 300,
            field: 'outgoingTransactions',
            headerName: 'Outgoing Assets',

            renderCell: ({ row }) => (
              <Box
                sx={{
                  mt: '20px',
                  alignSelf: 'flex-start'
                }}
              >
                <InteractionAssetsList
                  small
                  assets={[
                    ...row.outgoingTransfers.map((x) => ({
                      ...x,
                      type: 'Fiat' as const
                    })),

                    ...row.outgoingTransactions.map((x) => ({
                      ...x,
                      assetType: x.type,
                      type: 'Blockchain' as const
                    }))
                  ] ?? []}
                />
              </Box>
            )
          }]}
        />
      </Box>
    </Box>
  );
};
