import React from 'react';
import { Chip } from '@mui/material';

import { EventStatus } from '@backed-fi/graphql';

interface Props {
  status: EventStatus
}

export const EventStatusBadge: React.FC<Props> = ({ status }) => {
  return (
    <Chip
      label={status}

      color={({
        [EventStatus.Pending]: 'warning',
        [EventStatus.Processing]: 'warning',
        [EventStatus.FailedFinal]: 'error',
        [EventStatus.FailedWillRetry]: 'warning',
        [EventStatus.Succeed]: 'primary'
      } as any)[status] ?? 'primary'}
    />
  );
};
