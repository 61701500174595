import React from 'react';
import { useSnackbar } from 'notistack';
import { gql } from '@apollo/client';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material';

import { useUnfreezeClientMutation } from '@backed-fi/graphql';

// region Props

interface Props extends React.ComponentProps<typeof Dialog> {
  onDismiss: () => void,


  clientId: string
}

// endregion

// region Networking

gql`
  mutation UnfreezeClient($input: UnfreezeClientInput!) {
    unfreezeClient(input: $input) {
      id
    }
  }
`;

// endregion

export const UnfreezeClientDialog: React.FC<Props> = ({ clientId, ...props }) => {
  const snackbar = useSnackbar();

  // region Networking

  const [ unfreezeClient, { loading } ] = useUnfreezeClientMutation({
    awaitRefetchQueries: true,
    refetchQueries:  [
      'clients',
      'clientOverview'
    ]
  });

  // endregion

  // region Actions

  const onUnfreezeClient = async () => {
    try {
      await unfreezeClient({ variables: {
        input: {
          clientId
        }
      } });

      snackbar.enqueueSnackbar('Client sucessfully unfrozen', { variant: 'success' });

      if (props.onClose) {
        props.onClose({}, 'escapeKeyDown');
      }
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to unfreeze client', { variant: 'error' });
    }
  };

  // endregion
  return (
    <Dialog
      fullWidth
      maxWidth='sm'
      {...props}
    >
      <DialogContent>
        <Typography variant='titleSmall'>
        Manually unfreeze client
        </Typography>

        <Typography variant='subtitleSmall'>
        Are you sure you want to unfreeze client?
        </Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Button
            onClick={props.onDismiss}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{
              marginRight: '16px'
            }}
            onClick={onUnfreezeClient}
            loading={loading}
          >
            Unfreeze
          </LoadingButton>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
