import React, { useMemo } from 'react';
import ReactECharts from 'echarts-for-react';

import { CustodyAccountFacet, CustodyAccountFacetType } from '@backed-fi/graphql';
import { DateShortFormatter } from '@backed-fi/shared';

interface Props {
  data: CustodyAccountFacet
}

export const BalanceHistoryChart: React.FC<Props> = ({ data }) => {
  const options = useMemo(() => {
    if (data) {
      return {
        title: {
          text: `${data.asset} balance`
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            restore: {},
            saveAsImage: {}
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: [data.asset]
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'time',
            boundaryGap: ['5%', '5%'],
            axisLabel: {
                formatter: ((value: number) => DateShortFormatter.format(new Date(value))),
                rotate: 45
            }
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [{
          name: data.asset,
          type: 'line',
          step: 'end',
          data: data.balanceChanges!
            .map((x) => [new Date(x.createdAt), data.type === CustodyAccountFacetType.Fiat ? Number(x.balance) / 100 : Number(x.balance)])
        }]
      };
    }
    return {
      title: {},
      tooltip: {},
      legend: {},
      grid: {},
      xAxis: [],
      yAxis: [],
      series: []
    };
  }, [data]);

  return <ReactECharts option={options} />;
};
