import { CircularProgress, Stack, TextField } from '@mui/material';
import React from 'react';
import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';
import { useCommandMenuData } from '@backed-fi/admin/src/app/components/CommandMenu/hooks/useCommandMenuData';

export const CommandMenuInput: React.FC = () => {
  const {
    filter,
    setFilter
  } = useCommandMenuContext();
  const { fetching } = useCommandMenuData();

  // region Actions

  const onInputChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setFilter(event.target.value);
  };

  // endregion

  return (
    <TextField
      fullWidth
      autoFocus
      variant="standard"
      placeholder="Search"
      value={filter}
      onChange={onInputChange}
      InputProps={{
        autoCorrect: 'off',
        autoComplete: 'off',
        disableUnderline: true,

        sx: {
          fontWeight: 500
        },

        endAdornment: fetching && (
          <Stack
            sx={{
              color: 'utility.textLowContrast'
            }}
          >
            <CircularProgress
              size={24}
              color="inherit"
              sx={{
                '& > .MuiCircularProgress-circle': {
                  strokeLinecap: 'round'
                }
              }}
            />
          </Stack>
        )
      }}
    />
  );
};
