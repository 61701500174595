import React from 'react';
import { Route } from 'react-router-dom';

import { RefundPages } from './Refunds/RefundPages';
import { InjectionPages } from './Injections/InjectionPages';
import { InteractionPages } from './Interactions/InteractionPages';

export const InteractionRoutes = (
  <Route path="interactions">
    {RefundPages}
    {InjectionPages}
    {InteractionPages}
  </Route>
);
