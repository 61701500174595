import React from 'react';
import { Box } from '@mui/material';

import { PageHeading } from '@backed-fi/compound';
import { OrdersTable } from '@backed-fi/admin/src/app/domain/Brokerage/pages/Brokerage/Orders/components/OrdersTable';


export const OrdersListPage = () => {
  return (
    <Box>
      <PageHeading
        title="Orders"
        breadcrumbs={[
          {
            text: 'Brokerage'
          },
          {
            text: 'Orders'
          }
        ]}
      />

      <OrdersTable />
    </Box>
  );
};
