// @ts-nocheck

import {
  Box,
  Button,
  LinearProgress
} from '@mui/material';
import { gql } from '@apollo/client';

import {
  useNetworkSimulatorRuleListQuery,
  useCreateNetworkSimulatorRuleMutation,
  useDeleteNetworkSimulatorRuleMutation
} from '@backed-fi/graphql';
import {
  DataGrid,
  GridRowModes,
  GridRowsProp,
  GridToolbarContainer,
  GridRowModel,
  GridActionsCellItem,
  GridRowId,
  useGridApiContext,
  GridApi,
} from '@mui/x-data-grid';
import React, { useEffect } from 'react';

import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { v4 as uuid } from 'uuid';
import { usePaginationModel } from '@backed-fi/hooks';

// region Graph Declaration

gql`
  query NetworkSimulatorRuleList(
    $page: Int = 1,
    $pageSize: Int
  ) {
    networkSimulatorRules(
      page: $page,
      pageSize: $pageSize
    ) {
      nodes {
        id

        createdAt
        updatedAt

        pathRegex
        payloadRegex
        transformScript
      }

      page {
        totalNodes
        totalPages
        currentPage

        hasNextPage
        hasPreviousPage
      }
    }
  }

  mutation createNetworkSimulatorRule(
    $input: CreateNetworkSimulatorRuleInput!
  ) {
    createNetworkSimulatorRule(input: $input) {
      id
    }
  }

  mutation deleteNetworkSimulatorRule(
    $input: DeleteNetworkSimulatorRuleInput!
  ) {
    deleteNetworkSimulatorRule(input: $input) {
      id
    }
  }
`;

// endregion

export const NetworkSimulator = () => {
  const {
    paginationVariables,
  } = usePaginationModel();

  // region State

  const [rows, setRows] = React.useState([] as any[]);

  // -- Sorting State
  const [rowModesModel, setRowModesModel] = React.useState<any>(
    {}
  );

  // endregion

  // region Networking

  const [createNetworkSimulatorRule] = useCreateNetworkSimulatorRuleMutation();
  const [deleteNetworkSimulatorRule] = useDeleteNetworkSimulatorRuleMutation();
  const query = useNetworkSimulatorRuleListQuery({
    variables: paginationVariables
  });

  const {
    data,
    loading
  } = query;

  useEffect(() => {
    setRows(data ? data.networkSimulatorRules!.nodes : []);
  }, [data]);


  React.useEffect(() => {
    (async () => {
      await query.refetch(paginationVariables);
    })();
  }, [paginationVariables]);

  // endregion

  // region Actions

  const handleSaveClick = (id: GridRowId, apiRef: React.MutableRefObject<GridApi>) => async () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View }
    } as any);
    apiRef.current.stopRowEditMode({
      id,
      ignoreModifications: false
    });
  };

  const handleDeleteClick = (id: GridRowId) => async () => {
    setRows(rows.filter((row) => row.id !== id));
    await deleteNetworkSimulatorRule({
      variables: {
        input: {
          id: id.toString()
        }
      }
    });
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: {
        mode: GridRowModes.View,
        ignoreModifications: true
      }
    } as any);

    setRows(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = async (newRow: GridRowModel) => {
    const updatedRow = { ...newRow } as any;
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    await createNetworkSimulatorRule({
      variables: {
        input: {
          ...updatedRow
        }
      }
    });
    return updatedRow;
  };
  // endregion

  return (
    <Box>
      {/* region Table With All Rules */}

      <DataGrid
        autoHeight
        editMode="row"
        loading={loading}
        rows={rows}
        processRowUpdate={processRowUpdate}
        components={{
          LoadingOverlay: LinearProgress,
          Toolbar: EditToolbar
        }}
        componentsProps={{
          toolbar: {
            setRows,
            setRowModesModel
          }
        }}
        columns={[
          {
            flex: 1,
            field: 'pathRegex',
            headerName: 'Path match',
            editable: true
          },
          {
            flex: 1,
            field: 'payloadRegex',
            headerName: 'Payload match',
            editable: true
          },
          {
            flex: 1,
            field: 'transformScript',
            headerName: 'Transform rule',
            editable: true
          },
          {
            width: 250,
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            cellClassName: 'actions',
            getActions: ({
              id
            }) => {
              const isInEditMode =
                (rowModesModel[id]?.mode as any) === GridRowModes.Edit;
              const apiRef = useGridApiContext();

              if (isInEditMode) {
                return [
                  <GridActionsCellItem
                    icon={<SaveIcon />}
                    label="Save"
                    onClick={handleSaveClick(id, apiRef)}
                  />,
                  <GridActionsCellItem
                    icon={<CancelIcon />}
                    label="Cancel"
                    className="textPrimary"
                    onClick={handleCancelClick(id)}
                    color="inherit"
                  />
                ];
              }
              return [
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={handleDeleteClick(id)}
                />
              ];
            }
          }
        ]}
      />

      {/* endregion */}
    </Box>
  );
};

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModes) => GridRowModes
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const {
    setRows,
    setRowModesModel
  } = props;

  const handleClick = () => {
    const id = uuid();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        pathRegex: '',
        payloadRegex: '',
        transformScript: ''
      }
    ]);
    setRowModesModel((oldModel) => ({
      ...(oldModel as any),
      [id]: {
        mode: GridRowModes.Edit,
        fieldToFocus: { value: 'pathRegex' }
      }
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}
