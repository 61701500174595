import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

const shortcuts = [
  {
    description: 'Shortcut to quickly go to the interactions list page',
    destination: '/interactions/all',

    key: 'i',
    shift: true,
    metaKey: true
  }, {
    description: 'Shortcut to quickly go to the orders list page',
    destination: '/financials/brokerage/orders',

    key: 'o',
    shift: true,
    metaKey: true
  }
];

export const Shortcuts: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      const shortcut = shortcuts.find((sc) => sc.key === e.key);

      if (shortcut) {
        if (shortcut.metaKey && !e.metaKey) {
          return;
        }

        if (shortcut.shift && !e.shiftKey) {
          return;
        }

        e.preventDefault();
        navigate(shortcut.destination);
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, []);

  return <Outlet />;
};
