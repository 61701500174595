import React from 'react';
import { useUserContext } from '@backed-fi/context';

export const TestPage: React.FC = () => {
  const userContext = useUserContext();

  return (
    <div>
      Test Page

      <div>
        <code
          style={{
            whiteSpace: 'pre'
          }}
        >
          {JSON.stringify(userContext, null, 2)}
        </code>
      </div>
    </div>
  );
};
