import React from 'react';
import { IconButton, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/SendRounded';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';
import { ComplianceCommentConnectionsInput, useCreateComplianceCommentMutation } from '@backed-fi/graphql';

interface Props extends ComplianceCommentConnectionsInput {
  refreshQueries?: string[];
  onCreate?: () => Promise<void>;
}

const Schema = z.object({
  comment: z.string()
    .nonempty('The comment is required!')
});

type Schema = z.infer<typeof Schema>;

gql`
  mutation createComplianceComment($input: CreateComplianceCommentInput!) {
    createComplianceComment(input: $input) {
      id
    }
  }
`;

export const CreateCommentField: React.FC<Props> = ({ refreshQueries, onCreate, ...connections }) => {
  // ---- Form ---- //
  const form = useForm<Schema>({
    resolver: zodResolver(Schema)
  });

  // ---- Networking ---- //
  const [createComment] = useCreateComplianceCommentMutation();

  // ---- Actions ---- //
  const onCreateComment = async (data: Schema) => {
    await createComment({
      refetchQueries: refreshQueries,
      awaitRefetchQueries: true,
      variables: {
        input: {
          comment: data.comment,

          connections: connections
        }
      }
    });

    form.resetField('comment');

    if (typeof onCreate == 'function') {
      await onCreate();
    }
  };

  return (
    <TextField
      fullWidth
      multiline
      size='small'
      placeholder='Comment'
      {...form.register('comment')}
      InputProps={{
        endAdornment: (
          <IconButton
            onClick={form.handleSubmit(onCreateComment)}
            sx={{
              justifySelf: 'flex-end'
            }}
          >
            <SendIcon />
          </IconButton>
        )
      }}
    />
  );
};
