import { Box, Tooltip } from '@mui/material';
import React from 'react';
import { InfoLabel, PageHeading, Title } from '@backed-fi/compound';
import { useParams } from 'react-router-dom';
import { AddSignature } from '@backed-fi/admin/src/app/domain/Blockchain/supply/components/AddSignature';
import { gql } from '@apollo/client';
import { useSupplyControlOperationDetailsQuery } from '@backed-fi/graphql';
import { ethers } from 'ethers';
import { DataGrid } from '@mui/x-data-grid';

import CheckIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import XIcon from '@mui/icons-material/HighlightOffRounded';


// region Query

gql`
  query supplyControlOperationDetails($id: String!) {
    supplyControlOperation(
      where: {
        id: $id
      }
    ) {
      id

      type
      amount
      status
      hash
      possibleSigners

      signatures {
        id
        address

        admin {
          id

          firstName
          lastName

          email
        }
      }

      deployment {
        id

        network
        address

        minterAddress
        burnerAddress

        systemWallet {
          address
        }

        token {
          name
          symbol
        }
      }
    }
  }
`;

// endregion

export const SupplyControlOperationDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { data } = useSupplyControlOperationDetailsQuery({
    variables: {
      id: params.id!
    },
    pollInterval: 10000
  });

  const operation = data?.supplyControlOperation!;
  const tokenDeployment = operation?.deployment!;

  const formatSignatures = () => {
    return operation.possibleSigners.map((possibleSigner) => {
      const signature = operation.signatures
        .find((x) => x.address.toLowerCase() === possibleSigner.toLowerCase());

      return ({
        id: possibleSigner,
        address: possibleSigner,

        hasProvidedSignature: !!signature,

        admin: signature?.admin
      });
    });
  };

  return operation && (
    <Box>
      <PageHeading
        title="Supply Control Operation Details"
        breadcrumbs={[
          {
            text: 'Supply Control Operations'
          }, {
            text: 'Details'
          }, {
            text: `[${params.id}]`
          }
        ]}
      />

      {/* Description of the token in question */}
      <Box>
        <Title
          title="Token Details"
          subtitle={`Details about the token that will be ${operation.type.toLowerCase()}ed`}
        />

        <InfoLabel
          label="Token"
          content={`${tokenDeployment.token.name} (${tokenDeployment.token.symbol})`}
        />

        <InfoLabel
          label="Network"
          content={tokenDeployment.network}
        />

        <InfoLabel
          label="Address"
          content={tokenDeployment.address}
        />

        <InfoLabel
          label={
            operation.type === 'Mint'
              ? 'Minter'
              : 'Burner'
          }

          content={
            operation.type === 'Mint'
              ? tokenDeployment.minterAddress
              : tokenDeployment.burnerAddress
          }
        />
      </Box>

      {/* Description of the operation */}
      <Box>
        <Title
          title="Operation Details"
          subtitle={`Details about the ${operation.type.toLowerCase()}`}
        />

        <InfoLabel
          label="Operation Status"
          content={operation.status}
        />

        <InfoLabel
          label={`${operation.type} Amount`}
          content={`${ethers.utils.formatEther(operation.amount)} ${tokenDeployment.token.symbol}`}
        />

        {operation.type === 'Mint' && (
          <Box>
            <InfoLabel
              copy
              label="Tokens destination"
              tooltip="The address to which the minted tokens will be sent"
              content={tokenDeployment.systemWallet.address}
            />
          </Box>
        )}

        <InfoLabel
          copy
          label="Operation Hash"
          content={operation.hash ?? 'Not Present'}
        />
      </Box>

      {/* Table of the signatures */}
      {operation.type === 'Mint' && (
        <Box>
          <Box
            sx={{
              maxWidth: '600px'
            }}
          >
            <Box
              sx={{
                pb: '1rem',
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <Title
                title="Signatures"
                subtitle=""
              />

              {operation.status === 'AwaitingSignatures' && (
                <AddSignature />
              )}
            </Box>

            <DataGrid
              autoHeight
              rows={formatSignatures()}
              columns={[
                {
                  width: 400,
                  field: 'address',
                  headerName: 'Signer'
                }, {
                  field: 'hasProvidedSignature',
                  headerName: 'Signed',
                  renderCell: ({
                    row,
                    value
                  }) => (
                    <Tooltip
                      title={value ? `Signed by ${row.admin ? `${row.admin?.firstName} ${row.admin?.lastName}` : 'the system'}` : 'Not Signed'}
                    >
                      {
                        value
                          ? <CheckIcon color="success" />
                          : <XIcon color="error" />
                      }
                    </Tooltip>
                  )
                }
              ]}
              sx={{
                backgroundColor: 'utility.backgroundSubtle'
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};
