import React from 'react';
import { Chip } from '@mui/material';

import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import PendingIcon from '@mui/icons-material/HourglassBottomRounded';
import SuccessIcon from '@mui/icons-material/CheckCircleOutlineRounded';

import { InteractionStatus } from '@backed-fi/graphql';

interface Props {
  status: InteractionStatus
}

export const InteractionStatusBadge: React.FC<Props> = ({ status }) => {
  return (
    <Chip
      label={status.replace(/([a-z])([A-Z])/g, '$1 $2')}

      icon={({
        Failed: <ErrorIcon />,
        Completed: <SuccessIcon />
      } as any)[status] ?? <PendingIcon />}

      color={({
        Completed: 'success',
        Failed: 'error'
      } as any)[status] ?? 'primary'}
    />
  );
};
