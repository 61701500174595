import React from 'react';
import { Box, Typography } from '@mui/material';
import { gql } from '@apollo/client';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AdminPermission, RiskAssessmentDetailsQuery, RiskAssessmentStatus, useRiskAssessmentDetailsQuery } from '@backed-fi/graphql';
import { PageHeading, Protector } from '@backed-fi/compound';
import { Comments } from '../../../components/Comments';
import { ClientClassificationData } from './components/ClientClassificationData';

// region Graph

const Graph = gql`
  query riskAssessmentDetails($riskAssessmentId: ID!) {
    riskAssessment(id: $riskAssessmentId) {
      id

      createdAt
      updatedAt

      concludedRisk
      concludedRiskPoints

      filedBy {
        id
        firstName
        lastName
      }

      status

      availableChecks {
        name
        riskFactor
        description
        displayName
      }

      isBusinessActivityInHighRiskCountry
      isBusinessActivityInHighRiskCountryNotes

      isBusinessActivityInHighRiskSector
      isBusinessActivityInHighRiskSectorNotes

      isBusinessActivityInNonServicedCountry
      isBusinessActivityInNonServicedCountryNotes

      isBusinessActivityStructureComplex
      isBusinessActivityStructureComplexNotes

      isDomicileNonServicedCountryBased
      isDomicileNonServicedCountryBasedNotes

      isDomiciledInHighRiskCountry
      isDomiciledInHighRiskCountryNotes

      isDomiciledInMonitoredTerritory
      isDomiciledInMonitoredTerritoryNotes

      isFrequentTransactor
      isFrequentTransactorNotes

      isHighRiskForOtherReasons
      isHighRiskForOtherReasonsNotes

      isHighWealth
      isHighWealthNotes

      isPoliticallyExposed
      isPoliticallyExposedNotes

      isUnderInvestigation
      isUnderInvestigationNotes


      client {
        id

        name
      }
    }

  }
`;

// endregion

export const RiskAssessmentDetailsPage: React.FC = () => {
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();


  // region Networking

  const { data } = useRiskAssessmentDetailsQuery({
    variables: {
      riskAssessmentId: params.id!
    }
  });

  // endregion

  // region Destructuring

  const { riskAssessment } = (data || {}) as RiskAssessmentDetailsQuery;

  // endregion

  // region Actions

  const onEdit = () => {
    navigate(location.pathname.replace('details', 'file'));
  };

  const onNavigateToClient = () => {
    navigate(`/clients/${riskAssessment.client.id}/details/compliance`);
  };

  // endregion

  return riskAssessment && (
    <Box>
      <PageHeading
        title={`Risk Assessment of ${riskAssessment.client.name}`}
        breadcrumbs={[
          {
            text: 'Compliance'
          }, {
            text: 'Risk Assessments'
          }, {
            text: `[${params.id}]`
          }, {
            text: 'Details'
          }
        ]}
      />

      {/* region Risk Assessment Overview - Active */}

      {riskAssessment.status === RiskAssessmentStatus.Active && (
        <Typography>
          An active risk assessment on client {riskAssessment.client.name} is being
          concluded. Check the current data on file bellow or continue editing the
          assessment{' '}
          <Typography
            sx={{
              display: 'inline',
              cursor: 'pointer',
              color: 'primary.textLowContrast'
            }}
            onClick={onEdit}
          >
            here
          </Typography>
        </Typography>
      )}

      {/* endregion */}

      {/* region Risk Assessment Overview - Not active */}

      {riskAssessment.status !== RiskAssessmentStatus.Active && (
        <Box>
          <Typography>
            On {riskAssessment.updatedAt} risk assessment for the client{' '}

            <Typography variant='link' onClick={onNavigateToClient}>
              {riskAssessment.client.name}
            </Typography>{' '}

            was filed by {riskAssessment.filedBy?.firstName} {riskAssessment.filedBy?.lastName} with concluding risk
            of{' '}
            {riskAssessment.concludedRisk} ({riskAssessment.concludedRiskPoints} risk points). Details for the risk
            assessment:
          </Typography>
          <ClientClassificationData riskAssessmentId={riskAssessment.id} />
        </Box>
      )}

      {/* endregion */}

      {/* region Risk Assessment Risk Factors */}

      <Box mt={3}>
        <Typography variant='title'>
          Risk Checks
        </Typography>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          <Box>
            {riskAssessment.availableChecks.map((check) => (
              <Box
                key={check.name}
                sx={{
                  margin: '1em 0'
                }}
              >
                <Box>
                  <Typography
                    variant='titleSmall'
                    sx={{
                      marginBottom: 0,
                      textTransform: 'capitalize'
                    }}
                  >
                    {check.displayName}
                  </Typography>

                  <Typography variant='subtitleSmall'>
                    {/** @ts-ignore */}
                    Checked marked as {riskAssessment[check.name] ? 'true' : 'false'}. The risk factor for that check
                    is {check.riskFactor} risk points
                  </Typography>

                  <Typography mt={1} mb={2}>
                    {/** @ts-ignore */}
                    {riskAssessment[`${check.name}Notes`] || 'No compliance notes were provided for that check'}
                  </Typography>
                </Box>
              </Box>
            ))}

          </Box>


          <Protector permissions={[AdminPermission.ComplianceRead]}>
            <Comments
              riskAssessmentId={params.id}
            />
          </Protector>
        </Box>
      </Box>

      {/* endregion */}
    </Box>
  );
};
