import React from 'react';
import { Route } from 'react-router-dom';
import { UtilsPage } from './pages/UtilsPage';
import { NetworkSimulatePage } from './pages/NetworkSimulatePage';

export const UtilsRoutes = (
  <React.Fragment>
    <Route
      path='utils'
      element={<UtilsPage />}
    />
    <Route
      path='simulate'
      element={<NetworkSimulatePage />}
    />
  </React.Fragment>
);
