import React from 'react';
import { MenuCommandSearchResult } from '@backed-fi/admin/src/app/components/CommandMenu/types';
import { Box } from '@mui/material';
import { Title } from '@backed-fi/compound';
import { useCommandMenuContext } from '@backed-fi/admin/src/app/components/CommandMenu/CommandMenuContext';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

// region Props

interface Props {
  result: MenuCommandSearchResult;
}

// endregion

export const CommandMenuItem: React.FC<Props> = ({ result }) => {
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const context = useCommandMenuContext();

  const [isFocused, setIsFocused] = React.useState(false);

  // region Effects

  React.useEffect(() => {
    setIsFocused(!!(context.focusedItem && context.focusedItem.id === result.id));
  }, [context.focusedItem]);

  // Key Listeners
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      // Working only if the element is focused
      if (isFocused) {
        if (e.key === 'Enter') {
          e.preventDefault();

          onResultAction();
        }
      }
    };

    document.addEventListener('keydown', down);

    return () => {
      document.removeEventListener('keydown', down);
    };
  }, [isFocused]);

  // endregion

  // region Helpers

  const onResultAction = async () => {
    if (typeof result.onClick === 'function') {
      const shouldSkipCleanup = await result.onClick({
        navigate,
        snackbar
      });

      if (!shouldSkipCleanup) {
        context.reset(true);
      }
    }
  };

  // endregion


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        padding: '.5rem',
        borderRadius: '4px',

        ...(isFocused && {
          backgroundColor: 'utility.backgroundSubtle'
        }),

        '&:hover': {
          backgroundColor: 'utility.backgroundSubtle'
        }
      }}
      onClick={onResultAction}
    >
      <Box
        sx={{
          mr: '1rem',
          fontSize: '24px',
          display: 'flex',
          alignItems: 'center'
        }}
      >
        <result.Icon />
      </Box>

      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <Title
          boxed
          small
          title={result.title}
          subtitle={result.subtitle}
        />

        {result.endAdornment && (
          <Box>
            {result.endAdornment}
          </Box>
        )}
      </Box>
    </Box>
  );
};
