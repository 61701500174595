import { MenuCommand } from '@backed-fi/admin/src/app/components/CommandMenu/types';


import { ClientSearchMenuCommand } from '@backed-fi/admin/src/app/components/CommandMenu/targets/ClientSearchMenuCommand';
import { TransactionsSearchMenuCommand } from '@backed-fi/admin/src/app/components/CommandMenu/targets/TransactionsSearchMenuCommand';

export const _export: MenuCommand[] = [
  ClientSearchMenuCommand,
  TransactionsSearchMenuCommand
];
