import React from 'react';
import { Box, Typography } from '@mui/material';

import { gql } from '@apollo/client';
import { useExecuteInjectionMutation, useUpsertInjectionMutation } from '@backed-fi/graphql';
import { useInjectionContext } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionContext';
import { NavigationButtons } from '@backed-fi/admin/src/app/domain/Interactions/Injections/components/NavigationButtons';
import { useNavigate } from 'react-router-dom';
import { useToggle } from '@backed-fi/hooks';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';

// region Graph

gql`
  mutation executeInjection($input: ExecuteInjectionInput!) {
    executeInjection(input: $input) {
      id

      interaction {
        id
      }
    }
  }
`;

// endregion

export const InjectConfirmation: React.FC = () => {
  const navigate = useNavigate();
  const snackbar = useSnackbar();
  const context = useInjectionContext();

  const injectingToggle = useToggle();
  const [upsertInjectionMutation] = useUpsertInjectionMutation();
  const [executeInjectionMutation] = useExecuteInjectionMutation();

  const onConfirm = async () => {
    injectingToggle.setTrue();

    try {
      const { data } = await upsertInjectionMutation({
        variables: {
          input: {
            payload: context.getCurrentState()
          }
        }
      });

      const { data: executionData } = await executeInjectionMutation({
        variables: {
          input: {
            injectionId: data?.upsertInjection?.id!
          }
        }
      });

      navigate(`/interactions/details/${executionData?.executeInjection?.interaction?.id}/overview`);
      snackbar.enqueueSnackbar('Successfully injected the interaction', {
        variant: 'success'
      });
    } catch (e) {
      snackbar.enqueueSnackbar('An error occurred injecting the interaction', {
        variant: 'error'
      });
    } finally {
      injectingToggle.setFalse();
    }
  };

  return (
    <Box>
      <Typography
        sx={{
          whiteSpace: 'pre'
        }}
      >
        {JSON.stringify(context.getCurrentState(), null, 4)}
      </Typography>

      <Box
        sx={{
          mt: '1rem',
          display: 'flex',
          gap: '.5rem',
          justifyContent: 'flex-end'
        }}
      >
        <NavigationButtons
          hideNext
        />

        <LoadingButton
          onClick={onConfirm}
          loading={injectingToggle.value}
        >
          Confirm
        </LoadingButton>
      </Box>
    </Box>
  );
};
