import React from 'react';

import { ethers } from 'ethers';
import { useSnackbar } from 'notistack';
import { Box, Typography } from '@mui/material';

import formatRelative from 'date-fns/formatRelative';

import { useInjectionContext } from '../../InjectionContext';
import { NavigationButtons } from '../../components/NavigationButtons';
import { InjectTransactionButton } from '../../components/InjectTransactionButton';

export const InjectIncomingTransactions: React.FC = () => {
  const snackbar = useSnackbar();
  const context = useInjectionContext();

  return (
    <Box>
      {context.incomingTransactions.length ? (
        context.incomingTransactions.map((tx, index) => (
          <Box key={index}>
            <Typography>
              Incoming transaction by {tx.client ? tx.client.name : 'unknown client'}{' '}
              executed {formatRelative(new Date(tx.blockTimestamp * 1000), new Date())}{' '}
              for {ethers.utils.formatUnits(tx.amount, tx.decimals)} {tx.symbol}
            </Typography>
          </Box>
        ))
      ) : (
        <Box>
          <Typography>
            No incoming transaction defined
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          my: '.5rem',
          gap: '.5rem',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <InjectTransactionButton
          transactionType="incoming"
        />

        <NavigationButtons />
      </Box>
    </Box>
  );
};
