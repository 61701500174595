import React from 'react';
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';

import { BlockchainNetwork, ClientStatus, ClientType, IncomingTransactionStatus, IncomingTransactionType, OutgoingTransactionStatus, OutgoingTransactionType } from '@backed-fi/graphql';
import { AppliedFilterTile } from './AppliedFilterTile';

export enum FilterType {
  ClientType = 'ClientType',
  ClientName = 'ClientName',
  ClientStatus = 'ClientStatus',
  Network = 'Network',
  TokenSymbol = 'TokenSymbol',
  IncomingTransactionType = 'IncomingTransactionType',
  IncomingTransactionStatus = 'IncomingTransactionStatus',
  OutgoingTransactionType = 'OutgoingTransactionType',
  OutgoingTransactionStatus = 'OutgoingTransactionStatus'
}

export type Filter = {
  type: FilterType;
  definition: FilterDefinition;
  value?: any;
}

export type FilterDefinition = {
  backendMap: string,
  inputType: 'select' | 'text',
  possibleValues: any[]
}

export const FilterConfig: Record<FilterType, FilterDefinition> = {
  ClientType: {
    backendMap: 'clientType',
    inputType: 'select',
    possibleValues: Object.keys(ClientType)
  },
  ClientName: {
    backendMap: 'clientName',
    inputType: 'text',
    possibleValues: []
  },

  ClientStatus: {
    backendMap: 'status',
    inputType: 'select',
    possibleValues: Object.keys(ClientStatus)
  },

  TokenSymbol: {
    backendMap: 'tokenSymbol',
    inputType: 'text',
    possibleValues: []
  },

  Network: {
    backendMap: 'network',
    inputType: 'select',
    possibleValues: Object.keys(BlockchainNetwork)
  },

  IncomingTransactionType: {
    backendMap: 'type',
    inputType: 'select',
    possibleValues: Object.keys(IncomingTransactionType)
  },
  IncomingTransactionStatus: {
    backendMap: 'status',
    inputType: 'select',
    possibleValues: Object.keys(IncomingTransactionStatus)
  },

  OutgoingTransactionType: {
    backendMap: 'type',
    inputType: 'select',
    possibleValues: Object.keys(OutgoingTransactionType)
  },
  OutgoingTransactionStatus: {
    backendMap: 'status',
    inputType: 'select',
    possibleValues: Object.keys(OutgoingTransactionStatus)
  }
};

export const filterToWhereQuery = (filters: Filter[]) => {
  const where: Record<string, any> = {};

  filters.map((filter) => {
    if (filter.type === 'ClientName' || filter.type === 'TokenSymbol') {
      where[filter.definition.backendMap] = {
        search: filter.value
      };

      return;
    }

    where[filter.definition.backendMap] = filter.value;
  });

  return where;
};


// region Props

interface Props<T extends {[key: string]: FilterDefinition} = {[key: string]: FilterDefinition}> {
  onFiltersChanged?: (filters: Filter[]) => any;

  filters: FilterType[];
  customFilters?: T
}

// endregion

export const Filters: React.FC<Props> = (props) => {
  // region State

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [activeFilters, setActiveFilters] = React.useState<Filter[]>([]);
  const [availableFilters, setAvailableFilters] =
    React.useState<(FilterType | string)[]>(Object.keys(props.customFilters || {}).concat(props.filters));

  // endregion

  // region Effects

  React.useEffect(() => {
    if (typeof props.onFiltersChanged === 'function') {
      props.onFiltersChanged(activeFilters);
    }
  }, [activeFilters]);

  // endregion

  // region Actions

  const handleOpenFilters = (event: React.MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const onFilterInit = (type: FilterType) => () => {
    setActiveFilters((prev) => {
      return [
        ...prev,
        {
          type,
          definition: type in FilterConfig ? FilterConfig[type as FilterType] : props?.customFilters![type]
        }
      ];
    });

    setAvailableFilters((prevState) => {
      return prevState.filter((f) => f !== type);
    });

    handleCloseFilter();
  };

  const onFilterDestruct = (filter: Filter) => () => {
    setActiveFilters((prevState) => prevState.filter((f) => f.type !== filter.type));
    setAvailableFilters((prevState) => {
      return [
        ...prevState,
        filter.type
      ];
    });
  };

  const onFilterValueChange = (filter: Filter) => (value: any) => {
    setActiveFilters((prev) => {
      return prev.map((f) => {
        if (f.type !== filter.type) {
          return f;
        }

        return {
          ...f,
          value
        };
      });
    });
  };

  // endregion

  return (
    <Box
      sx={{
        flex: 1,
        display: 'flex',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          gap: '8px',
          display: 'inline-flex'
        }}
      >
        {activeFilters.map((filter) => (
          <AppliedFilterTile
            key={filter.type}
            filter={filter}
            onFilterRemoved={onFilterDestruct(filter)}
            onFilterValueChange={onFilterValueChange(filter)}
          />
        ))}
      </Box>

      {/* region Add Filter Menu */}

      <Button
        onClick={handleOpenFilters}
      >
        Add Filter
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleCloseFilter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          marginTop: '.5rem'
        }}
      >
        {availableFilters
          .map((filterType: string) => (
            <MenuItem
              onClick={onFilterInit(filterType as FilterType)}
            >
              {filterType!.replace(/(?=[A-Z])/g, ' ')}
            </MenuItem>
          ))
        }

        {/* region No Available Filters */}

        {!availableFilters.length && (
          <Box
            sx={{
              margin: '1rem',
              textAlign: 'center'
            }}
          >
            <Typography>
              No available filters
            </Typography>
          </Box>
        )}

        {/* endregion */}
      </Menu>

      {/* endregion */}
    </Box>
  );
};
