import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import RegisteredIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import { useInjectionContext } from '@backed-fi/admin/src/app/domain/Interactions/Injections/InjectionContext';
import { NavigationButtons } from '../../components/NavigationButtons';

export const ExternalWalletRegistration = () => {
  const context = useInjectionContext();
  const addressesToRegister = React.useMemo(() => (
    Array.from(
      new Set(
        context.incomingTransactions.filter((x) => !x.client)
          .map((tx) => tx.benefactorAddress.toLowerCase())
      )
    )
  ), [context.incomingTransactions]);

  // region Action

  const onRegisterWallet = (walletId: string) => {
    context.setInjectionState((prev) => ({
      ...prev,
      walletsToRegister: [
        ...prev.walletsToRegister,
        walletId!
      ]
    }));
  };

  // endregion


  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: '1fr'
      }}
    >
      <Box
        sx={{
          my: '1rem'
        }}
      >
        {addressesToRegister.map((address) => (
          <Box
            key={address}
            sx={{
              display: 'flex',
              gap: '1rem',

              width: '600px',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <Typography>
              {address}
            </Typography>

            <Box
              sx={{
                width: '150px',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {context.walletsToRegister.some((x) => x === address)
                ? (
                  <Tooltip title="Wallet registration defined">
                    <RegisteredIcon
                      color="success"
                    />
                  </Tooltip>
                ) : (
                  <Button onClick={() => onRegisterWallet(address)}>
                    Register Wallet
                  </Button>
                )
              }
            </Box>
          </Box>
        ))}
      </Box>


      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <NavigationButtons />
      </Box>
    </Box>
  );
};
