import React from 'react';

import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useClientFolderQuery } from '@backed-fi/graphql';
import { DocumentFolder } from '@backed-fi/admin/src/app/components/Documents/DocumentFolder';

gql`
  query ClientFolder($id: ID!) {
    client(clientId: $id) {
      folder {
        id
        name
        description
      }
    }
  }
`;

export const ClientDocumentsPage = () => {
  const params = useParams<{ id: string }>();

  // ---- Networking ---- //
  const { data } = useClientFolderQuery({
    variables: {
      id: params.id!
    }
  });

  const folder = data?.client?.folder;

  return (
    <Box>
      {(folder) && (
        <DocumentFolder
          hideParent
          dndProvider
          name={folder.name}
          folderId={folder.id}
          description={folder.description ?? ''}
        />
      )}
    </Box>
  );
};
