import { useSnackbar } from 'notistack';

import { useRequestDocumentDeletionMutation } from '@backed-fi/graphql';
import { useDocumentContext } from '@backed-fi/admin/src/app/components/Documents/Context/DocumentContext';

export const useRequestDocumentDeletion = () => {
  const snackbar = useSnackbar();
  const document = useDocumentContext();

  const [requestDocumentDeletion] = useRequestDocumentDeletionMutation();

  return async () => {
    if (document.documentId) {
      await requestDocumentDeletion({
        variables: {
          input: {
            documentId: document.documentId!
          }
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          'folder'
        ]
      });

      snackbar.enqueueSnackbar('Successfully requested document deletion');
    } else {
      console.error('Cannot delete document. Outside of document context');
    }
  };
};
