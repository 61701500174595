import React from 'react';
import { Box, Button, Chip, IconButton, LinearProgress, TextField, Tooltip, Typography } from '@mui/material';
import { OrderExecutionPath, OrderSide, OrderStatus, useOrdersListQuery } from '@backed-fi/graphql';
import { DataGrid } from '@mui/x-data-grid';
import { CentsFormatter } from '@backed-fi/shared';
import DetailsIcon from '@mui/icons-material/VisibilityRounded';
import InteractionIcon from '@mui/icons-material/ReceiptLongRounded';
import { gql } from '@apollo/client';
import { usePaginationModel } from '@backed-fi/hooks';
import { ExecuteOrderDialog } from '@backed-fi/admin/src/app/domain/Brokerage/pages/Brokerage/Orders/components/ExecuteOrderDialog';
import { useNavigate } from 'react-router-dom';
import { OrderDisplayStatus, OrderDisplayStatuses, getUnderlyingStatuses, simplifyStatus } from '../helpers/simplifyStatus';


// region Graph Declaration

gql`
  query OrdersList(
    $page: Int,
    $pageSize: Int,
    $where: OrdersWhereInput
  ) {
    orders(
      page: $page,
      pageSize: $pageSize,
      where: $where
    ) {
      nodes {
        id

        createdAt
        updatedAt

        side
        status

        totalValue
        totalShares
        pricePerShare

        interactionId

        executionPath

        collateral {
          symbol
        }
      }

      page {
        totalNodes
        totalPages
        currentPage
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  hideFilters?: boolean;

  /**
   * If set to true the pagination will be disabled and
   * only the latest 25 elements displayed
   */
  disablePagination?: boolean;

  interactionId?: string;
}

// endregion

export const OrdersTable: React.FC<Props> = (props) => {
  const navigate = useNavigate();


  const {
    paginationVariables,
    ...pagination
  } = usePaginationModel(props.disablePagination);

  // -- Sorting State
  const [statuses, setStatuses] = React.useState<OrderStatus[]>();
  const [execute, setExecute] = React.useState<string>();

  // region Networking

  const query = useOrdersListQuery({
    variables: {
      ...paginationVariables,
      where: {
        OR: statuses?.map((status) => ({
          status
        })),
        interactionId: props.interactionId
      }
    }
  });

  const {
    data,
    loading,
    refetch
  } = query;

  const simplifiedData = data ? data!.orders.nodes.map((o) => {
    return {
      ...o,
      displayStatus: simplifyStatus(o.status)
    };
  }) : [];

  // endregion


  // region Actions

  const onOpenExecuteDialog = (orderId: string) => () => {
    setExecute(orderId);
  };

  const onDismissExecuteDialog = (shouldRefetch = false) => {
    if (shouldRefetch) {
      refetch()
        .then(() => {
          setExecute(undefined);
        });
    } else {
      setExecute(undefined);
    }
  };

  // endregion

  return (
    <React.Fragment>
      {/* region Execution Dialog */}

      {execute && (
        <ExecuteOrderDialog
          orderId={execute}
          onDismiss={onDismissExecuteDialog}
        />
      )}

      {/* endregion */}

      {/* region Sorting */}

      {!props.hideFilters && (
        <TextField
          select
          size="small"
          label="Status"
          onChange={(event) => {
            const value = getUnderlyingStatuses(event.target.value as OrderDisplayStatus);

            if (!value.length) {
              setStatuses(undefined);
            } else {
              setStatuses(value);
            }
          }}
        >
          <option value="all">All</option>

          {OrderDisplayStatuses.map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </TextField>

      )}

      {/* endregion */}

      {/* region Table With All Orders */}

      <DataGrid
        {...pagination}

        autoHeight
        loading={loading}
        rows={simplifiedData}
        rowCount={data?.orders?.page.totalNodes}
        components={{
          LoadingOverlay: LinearProgress
        }}
        columns={[{
          flex: 1,
          minWidth: 200,
          field: 'collateral.symbol',
          headerName: 'Collateral',
          renderCell: ({ row }) => (
            <Typography>
              {row.collateral.symbol}
            </Typography>
          )
        }, {
          width: 200,
          field: 'side',
          headerName: 'Order Side',
          renderCell: ({ value }) => (
            <Chip
              label={value + ' Order'}
              color={value === OrderSide.Buy ? 'success' : 'error'}
            />
          )
        }, {
          width: 200,
          field: 'displayStatus',
          headerName: 'Status',
          renderCell: ({ value }) => (
            <Chip
              color="primary"
              label={value}
            />
          )
        }, {
          width: 200,
          field: 'executionPath',
          headerName: 'Path',
          renderCell: ({ value }) => (
            <Chip
              color="primary"
              label={!value ? '-/-' : {
                [OrderExecutionPath.ExecutableImmediately]: 'Short',
                [OrderExecutionPath.RequiresShortAutomatedSteps]: 'Medium',
                [OrderExecutionPath.RequiresManualIntervention]: 'Long'
              }[value as OrderExecutionPath]}
            />
          )
        }, {
          width: 200,
          field: 'totalValue',
          headerName: 'Approximated Total Value',
          renderCell: ({
            row,
            value
          }) => (
            row.status === OrderStatus.Executed ? (
              <Typography>
                N/A
              </Typography>
            ) : (
              <Typography>
                {CentsFormatter.format(value)}
              </Typography>
            ))
        }, {
          width: 200,
          field: 'totalValueExecuted',
          headerName: 'Executed Total Value',
          renderCell: ({ row }) => (
            row.status === OrderStatus.Executed ? (
              <Typography>
                {CentsFormatter.format(Number(row.pricePerShare) * row.totalShares)}
              </Typography>
            ) : (
              <Typography>
                N/A
              </Typography>
            ))
        }, {
          width: 250,
          field: 'id',
          headerName: '',
          renderCell: ({ row }) => (
            <Box
              sx={{
                width: '100%',
                gap: '8px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              {(row.status === OrderStatus.MarkedForManualExecution || row.status === OrderStatus.SubmittedManually) && (
                <Button
                  size="small"
                  onClick={onOpenExecuteDialog(row.id)}
                >
                  Execute Manually
                </Button>
              )}

              <Tooltip
                title="View order details"
              >
                <IconButton
                  onClick={() => navigate(`/financials/brokerage/orders/${row.id}`)}
                >
                  <DetailsIcon />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={'View the interaction for which this order was created'}
              >
                <IconButton
                  onClick={() => {
                    navigate(
                      `/interactions/details/${row.interactionId}/overview`
                    );
                  }}
                >
                  <InteractionIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )
        }]}
      />


      {/* endregion */}
    </React.Fragment>
  );
};
