import React from 'react';
import { Dialog, DialogContent, DialogProps, TextField, Typography } from '@mui/material';
import { BlockchainNetwork, useRegisterOracleForwarderMutation } from '@backed-fi/graphql';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { gql } from '@apollo/client';

// region Props

interface OracleForwarder {
  address: string
}

interface Oracle {
  id: string
  address: string
  network: BlockchainNetwork,
  forwarder?: OracleForwarder | null
}
interface Props extends DialogProps {
  oracles: Oracle[]
}
// endregion

// region Form Schema

const Schema = z.object({
  address: z.string(),
  oracleId: z.string()
});

// endregion

// region Graph

gql`
  mutation registerOracleForwarder($input: RegisterOracleForwarderInput!) {
    registerOracleForwarder(input: $input) {
      id
    }
  }
`;

// endregion

export const RegisterOracleForwarderDialog: React.FC<Props> = ({
  oracles,
  ...props
}) => {
  // region Networking

  const [registerOracleForwarder] = useRegisterOracleForwarderMutation();


  const availableOracles = oracles.filter((o: Oracle) => !o.forwarder);
  // endregion

  // region Form Control

  const form = useForm<z.infer<typeof Schema>>({
    resolver: zodResolver(Schema)
  });

  const {
    errors,
    isSubmitting
  } = form.formState;

  // endregion

  // region Actions

  const onRegister = form.handleSubmit(async (data) => {
    await registerOracleForwarder({
      awaitRefetchQueries: true,
      refetchQueries: [
        'tokenCollateral'
      ],
      variables: {
        input: {
          ...data
        }
      }
    });

    form.reset();

    if (typeof props.onClose === 'function') {
      (props.onClose as any)();
    }
  });

  // endregion

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      {...props}
    >
      <DialogContent>
        <Typography variant="titleSmall">
          Register Oracle Forwarder
        </Typography>

        <Typography variant="subtitleSmall" mb="1rem">
          Register oracle forwarder in the system.
        </Typography>

        <TextField
          select
          fullWidth
          label="Oracle"
          {...form.register('oracleId')}
          SelectProps={{
            native: true
          }}
        >
          <option disabled selected>
            Please select
          </option>

          {availableOracles.map((oracle) => {
            return (
              <option key={oracle.id} value={oracle.id}>
                {oracle.network} {oracle.address}
              </option>
            );
          })}
        </TextField>

        <TextField
          fullWidth
          label="Contract Address"
          {...form.register('address')}
        />

        <LoadingButton
          loading={isSubmitting}
          onClick={onRegister}
          sx={{
            mt: '.25rem',
            float: 'right'
          }}
        >
          Register
        </LoadingButton>
      </DialogContent>
    </Dialog>
  );
};
