import React from 'react';
import { gql } from '@apollo/client';
import { useSnackbar } from 'notistack';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Dialog, DialogContent, IconButton, Tooltip, Typography } from '@mui/material';
import { useToggle } from '@backed-fi/hooks';

import { AdminPermission, Role, useUpdateRoleMutation } from '@backed-fi/graphql';
import { RoleForm, Schema } from './shared/RoleForm';
import { Title } from '@backed-fi/compound';

// region Graph

gql`
  mutation UpdateRole($input: UpdateRoleInput!) {
    updateRole(input: $input)
  }
`;

// endregion

interface Props {
  adminRole: Pick<Role, 'id' | 'name' | 'permissions' | 'description'> &
    {admins: { email: string, firstName: string, lastName: string }[]}
}

export const UpdateRoleDialog: React.FC<Props> = ({ adminRole }) => {
  const updateRoleDialogToggle = useToggle();
  const snackbar = useSnackbar();
  // region Networking

  const [updateRoleMutation] = useUpdateRoleMutation();

  // endregion

  // endregion

  const onUpdate = async (data: Schema, permissions: AdminPermission[]) => {
    try {
      await updateRoleMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
          'rolesListQuery',
          'admins'
        ],
        variables: {
          input: {
            roleId: adminRole.id,
            update: {
              ...data,
              permissions
            }
          }
        }
      });

      snackbar.enqueueSnackbar('Successfully updated role', { variant: 'success' });

      updateRoleDialogToggle.onClose();
    } catch (e) {
      snackbar.enqueueSnackbar('Failed to updated role', { variant: 'error' });
    }
  };


  return (
    <>
      <Box
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Tooltip title="Update role">
          <IconButton
            onClick={() => {
              updateRoleDialogToggle.toggle();
            }}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>

      </Box>
      { updateRoleDialogToggle.open && (
        <Dialog
          {...updateRoleDialogToggle}
          fullWidth
          maxWidth='sm'
        >
          <DialogContent>
            <Typography variant='title'>
              Update Role
            </Typography>

            <Box mb={1}>
              <Typography variant='subtitle'>
                Are you sure you want to update {adminRole.name} role?
              </Typography>
            </Box>

            {adminRole.admins.length > 0 && <Box mb={1}>
              <Typography variant='subtitleSmall'>
                Admins listed below will have their permissions changed:
              </Typography>
              <Box sx={{
                maxHeight: '210px',
                overflowY: 'scroll '
              }}>
                {adminRole.admins.map((admin, index) => (
                  <Box
                    key={index}
                    sx={{
                      p: '.5rem',
                      my: '.5rem',
                      borderRadius: '4px',
                      border: '1px solid',
                      borderColor: 'utility.borderSubtle',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <Box>
                      <Title
                        small
                        title={`${admin.firstName} ${admin.lastName}`}
                        subtitle={admin.email}
                      />
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>}

            {!(adminRole.admins.length > 0) && <Box mb={1}>
              <Typography variant='subtitleSmall'>
                No admins will be affected by this change
              </Typography></Box>}

            <RoleForm action='Update' onSubmit={onUpdate} role={adminRole}/>
          </DialogContent>
        </Dialog>)}
    </>
  );
};
