import React from 'react';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';

import { useClientRiskAssessmentQuery } from '@backed-fi/graphql';
import { InfoLabel } from '@backed-fi/compound';
import { get } from 'lodash';

// region Graph

gql`
  query ClientRiskAssessment($id: ID!) {
    riskAssessment(id: $id) {
      verification {
        clientType
        client {
          id
          name
          isProfessional
          type
          classification
          questionnaires (
            where: {
              type: OnboardingQuestionnaire
            }
          ) {
            id
            status
            payload
          }
        }
      }
    }
  }
`;

// endregion

// region Props

interface Props {
  riskAssessmentId: string;
}

// endregion

interface OnboardingQuestionnaire {
  'professional-investor': {
    'default': ('is-professional' | 'is-not-professional')[]
  }
  'eea-residency': {
    'in-the-eea': ('within-eea' | 'outside-eea')[]
  }
}

interface OnboardingQuestionnaireState {
  isProfessional: boolean;
  eeaResidency: boolean;
}

export const ClientClassificationData: React.FC<Props> = ({ riskAssessmentId }) => {
  const [onboardingQuestionnaire, setOnboardingQuestionnaire] = React.useState<OnboardingQuestionnaireState>();

  const { data } = useClientRiskAssessmentQuery({
    variables: {
      id: riskAssessmentId
    }
  });

  React.useEffect(() => {
    if (data) {
      if ((data.riskAssessment?.verification?.client?.questionnaires?.length ?? 0) > 0) {
        const questionnaire = data.riskAssessment?.verification?.client?.questionnaires[0].payload as OnboardingQuestionnaire;
        if (get(questionnaire, 'professional-investor.default.0') === 'is-professional') {

          if (get(questionnaire, 'eea-residency.in-the-eea.0') === 'within-eea') {
            setOnboardingQuestionnaire({
              eeaResidency: true,
              isProfessional: true,

            });
          } else {
            setOnboardingQuestionnaire({
              eeaResidency: false,
              isProfessional: true
            });
          }
        } else {
          setOnboardingQuestionnaire({
            isProfessional: false,
            eeaResidency: false
          });
        }
      }
    }
  }, [data]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      {data && (
        <React.Fragment>
          <Box sx={{ display: 'flex' }}>
            <InfoLabel
              label="Client Type"
              content={data?.riskAssessment?.verification?.client?.type}
              sx={{
                marginRight: '32px'
              }}
            />

            {data?.riskAssessment?.verification?.client?.classification !== 'Unknown' && (
              <InfoLabel
                label="Client Classification"
                content={data?.riskAssessment?.verification?.client?.classification}
              />
            )}
          </Box>

          {onboardingQuestionnaire && (
            <Box sx={{ display: 'flex' }}>
              {onboardingQuestionnaire.isProfessional && (
                <React.Fragment>
                  <InfoLabel
                    sx={{ marginRight: '32px' }}
                    label="Professional Classification"
                    content={
                      <Box
                        sx={{
                          textTransform: 'capitalize'
                        }}
                      >
                        {
                          onboardingQuestionnaire.isProfessional
                            ? 'Professional'
                            : 'Not Professional'
                        }
                      </Box>
                    }
                  />
                  <InfoLabel
                    sx={{ marginRight: '32px' }}
                    label="EEA Residency"
                    content={
                      onboardingQuestionnaire.eeaResidency
                        ? 'Yes'
                        : 'No'
                    }
                  />

                </React.Fragment>
              )}
            </Box>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};
