import { Box } from '@mui/material';
import { PageHeading } from '@backed-fi/compound';
import { NetworkSimulator } from './components/NetworkSimulator';

export const NetworkSimulatePage = () => {
  return (
    <Box>
      <PageHeading
        title='Simulate External calls'
        breadcrumbs={[{
          text: 'Simulation'
        }]}
      />

      <NetworkSimulator />
    </Box>
  );
};
